import axios from "axios"
import baseUrl from "./apiUrl";

export async function infoUser_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/details",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("INFO USER SERVICIO");
        if (response.status === 200 || response.status === 20) {
            // console.log(response.data.Respuesta);
            sessionStorage.setItem("infoUser", JSON.stringify(response.data.Respuesta.Usuario));
            if (response.data.Respuesta.Usuario.Tipo === 1) {
                localStorage.setItem("paquete", response.data.Respuesta.Usuario.Paquete);
                localStorage.setItem("paqueteStatus", response.data.Respuesta.Usuario.PaqueteStatus);
            }
            // console.log(response);
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        // console.log(error.status);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Mensaje : "Error desconocido" : error.message,
            "codigo": error.response !== undefined ? error.response.data.Codigo !== undefined ? error.response.data.Codigo : null : null,
        };

    }
}

export async function getCedulas_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CargarCedulas",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("SERVICIO CEDULAS");
        // console.log(response);
        // console.log("##################");
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function deleteCedula_request(idCedula) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/EliminarCedula/" + idCedula,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("ELIMINAR CEDULA SERVICIO");
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addCedula_request(cedulaData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/AltaCedula",
            method: "POST",
            data: cedulaData,
            headers:
            {

                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("ADD CEDULA SERVICIO");
        // console.log(response);
        if (response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR REGISTRO "+error.response);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function modifyProfile_request(profileData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/Configuracion",
            method: "POST",
            data: profileData,
            headers:
            {

                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("MODIFICAR PERFIL SERVICIO");
        // console.log(response.status);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR REGISTRO ");
        // console.log(error.response);
        // console.log(error.response.status);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}