import React from "react";

import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import visa from "../../Img/visa.png";
import mastercard from "../../Img/mastercard.png";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import MoreVert from "@mui/icons-material/MoreVert";

// import iconos
import CancelIcon from '@mui/icons-material/Cancel';

export default function CuentasCard({ cuentas, setIdCuenta, deleteCuenta }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };
    return (
        <>
            {/* {cuentas.map((cuenta, index) => (
                <div style={{ background: "white", padding: "1%", marginBottom: ".5%", display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "3%", width: "95%" }}>
                        <div>
                            <div>
                                <p style={{ fontWeight: "bold" }}>{cuenta.last4}</p>
                            </div>
                            <div>
                                <p>{cuenta.bank_name}</p>
                            </div>
                        </div>
                    </div>
                    <StyledEngineProvider >
                        <CssVarsProvider>
                            <PositionedMenu setId={setIdTarjeta} deleteFunction={deleteTarjeta} id={tarjeta.IdTarjeta} />
                            <IconButton
                                id="positioned-demo-button"
                                aria-controls={open ? "positioned-demo-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                variant="outlined"
                                color="neutral"
                                onClick={(e) => { handleClick(e); setId(id); }}
                            >
                                <MoreVert />
                            </IconButton>
                            {anchorEl !== null && (
                                <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
                                    <div>
                                        <Menu
                                            id="positioned-demo-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="positioned-demo-button"
                                            placement="bottom-end"

                                        >
                                            <MenuItem onClick={() => { handleClose(); deleteFunction(); }}>
                                                <ListItemDecorator sx={{ color: "inherit" }}>
                                                    <CancelIcon color="danger" />
                                                </ListItemDecorator>{" "}
                                                Eliminar
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            )}
                        </CssVarsProvider>
                    </StyledEngineProvider>
                </div>
            ))} */}

            <div style={{ background: "white", padding: "1%", marginBottom: ".5%", display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "3%", width: "95%" }}>
                    <div>
                        <div>
                            <p style={{ fontWeight: "bold" }}>{cuentas.last4}</p>
                        </div>
                        <div>
                            <p>{cuentas.bank_name}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
