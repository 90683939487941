import React, { useState } from 'react';
import { Label, LeyendaError } from '../GeneralComponents/Formularios';
import "../../pages/styles/Registro.scss"
import { Form, Input } from 'antd';

const InputPassAntd = ({ value, setStateValue, type, label, placeholder, id, textError, setStateError, valueError, expresionRegular,
    funcion, disabled, readOnly, vacio, maxLength, className, style, extras, styleLabel, styleDiv, required }) => {

    const [MensajeError, setMensajeError] = useState(textError !== undefined ? textError[0] : "");
    const [InputText, setInputText] = useState("");

    const onChange = (e) => {
        // console.log(e.target.value);
        setStateValue(e.target.value);
        setInputText(e.target.value);

        if (expresionRegular) {
            // console.log(expresionRegular.test(e.target.value));
            if (expresionRegular.test(e.target.value)) {
                if (e.target.value.length === 0) {
                    setMensajeError(textError[0]);
                    setStateError("false");
                    return new Error(textError[0]);
                    // return Promise.resolve();
                } else if (e.target.value.length > 0 && e.target.value.length < 8) {
                    setMensajeError(textError[1]);
                    setStateError("false");
                    return new Error(textError[1]);
                } else {
                    setStateError("true");
                }
            } else {

                if (e.target.value.length === 0) {
                    setMensajeError(textError[0]);
                    setStateError("false");
                    return new Error(textError[0]);
                } else if (e.target.value.length > 0 && e.target.value.length < 8) {
                    // console.log(textError[1]);
                    setMensajeError(textError[1]);
                    setStateError("false");
                    return new Error(textError[1]);
                } else if (e.target.value.length !== 0) {
                    // console.log();
                    setMensajeError(textError[2]);
                    setStateError("false");
                    return new Error(textError[2]);
                } else if (e.target.value.length >= 8) {
                    setStateError("true");
                }
            }
        }
    }

    // const validacion = (e) => {
    //     // console.log(e);

    //     if (expresionRegular) {
    //         // console.log(expresionRegular.test(InputText));
    //         if (expresionRegular.test(InputText)) {
    //             // setStateValue(va);

    //             if (InputText.length === 0) {
    //                 setMensajeError(textError[0]);
    //                 setStateError("false");
    //                 return new Error(textError[0]);
    //                 // return Promise.resolve();
    //             } else if (InputText.length > 0 && InputText.length < 8) {
    //                 setMensajeError(textError[1]);
    //                 setStateError("false");
    //                 return new Error(textError[1]);
    //             } else {
    //                 setStateError("true");
    //             }
    //         } else {

    //             if (InputText.length === 0) {
    //                 setMensajeError(textError[0]);
    //                 setStateError("false");
    //                 return new Error(textError[0]);
    //             } else if (InputText.length > 0 && InputText.length < 8) {
    //                 // console.log(textError[1]);
    //                 setMensajeError(textError[1]);
    //                 setStateError("false");
    //                 return new Error(textError[1]);
    //             } else if (InputText.length !== 0) {
    //                 // console.log();
    //                 setMensajeError(textError[2]);
    //                 setStateError("false");
    //                 return new Error(textError[2]);
    //         }
    //     }
    // }

    return (
        <div style={styleDiv}>
            {/* {(label !== undefined && !!label) && (<Label style={styleLabel} htmlFor={id} valido={valueError}>{label}</Label>)} */}
            <Form.Item
                label={<Label style={styleLabel}>{label}</Label>}
                name={id}
                rules={[
                    {
                        required: required,
                        message: MensajeError,
                    },
                    {

                        // type: "any",
                        pattern: expresionRegular,
                        message: MensajeError,
                    }
                ]}
                min={8}
            // validateStatus={valueError !== undefined ? valueError === 'true' ? "" : "error" : ""}
            >
                <Input.Password
                    style={style}
                    className={className}
                    type={type}
                    name={id}
                    // id={id}
                    value={value}
                    onChange={onChange}
                    // onKeyUp={validacion}
                    placeholder={placeholder}
                    disabled={disabled !== undefined ? disabled ? true : false : false}
                    readOnly={readOnly ? true : false}
                    maxLength={!!maxLength ? maxLength : ""}
                    status={valueError !== undefined ? valueError === 'true' ? "" : "error" : ""}
                />
            </Form.Item>
            {/* <LeyendaError valido={valueError}>{MensajeError}</LeyendaError> */}
        </div>
    );
}

export default InputPassAntd;