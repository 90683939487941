import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "../comp-styles/AsistenteCard.scss";
import Iconos from "../IconosDeSitio";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Row } from "antd";
// import PositionedMenu from "./menuvertical";

// Importando botones
import ButtonAntd from "../GeneralComponents/ButtonAntd"

export default function SolicitudesCard({ solicitudes, aceptar, cancelar }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (CambiarEditarADetalle) => {
    if (CambiarEditarADetalle !== 3) {
      // setAnchorEl(null);
      // setActiveMoadalAsist(1);
      // if (CambiarEditarADetalle === 1) {
      //   setAccionesAsist(1);
      // } else {
      //   setAccionesAsist(0);
      // }
    } else {
      setAnchorEl(null);
    }
  };
  //  // console.log(solicitudes);
  return (
    <>
      {solicitudes.map((solicitud) => (
        <Row className="ContenidoSoliCard" key={solicitud.IdNotificacion}>
          <Row
            style={{ display: "flex", gap: "1rem", alignItems: "center" }}
          // onClick={() => {
          //   handleClose(0);
          //   detailAssistant(asistente.IdAsistente);
          //   setAction(1);
          //   setInputsEditable(true);
          //   openModalDAE(true);
          // }}
          >
            <div>
              <img
                className="imgNotiCard"
                src={
                  !!solicitud.Imagen_Url
                    ? "https://" + solicitud.Imagen_Url
                    :
                    Iconos[0]
                }
                alt=""
              />
            </div>
            <div>
              <b>{solicitud.Nombre}</b>
              <p>{solicitud.VinculacionEstatus}</p>
            </div>
          </Row>
          <div style={{ display: "flex", gap: "1rem", alignItems:"center" }} >
            <ButtonAntd style={{ width: "100px" }} onClick={() => { aceptar(solicitud.IdEmisor) }}>
              <Row style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              Aceptar <CheckCircleIcon style={{width: 15}}/>
              </Row>
            </ButtonAntd>
            <ButtonAntd tipo={2} style={{ width: "100px" }} onClick={() => { cancelar(solicitud.IdSolicitud) }}>
              Rechazar
            </ButtonAntd>
          </div>
        </Row>
      ))}
    </>
  );
}
