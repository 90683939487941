import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//import css
import "../comp-styles/ExpedienteCard.scss";
import { Avatar, Image } from "antd";
import Imag from "../../Img/no-image.png";

export default function ExpedientesCard({ expedientes }) {
  // console.log(expedientes);
  const [Ocultas, setOcultas] = useState("");
  const handleModalContainerClick = (e) => e.stopPropagation();
  const navigate = useNavigate();
  // drowdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
        {expedientes.map((expe) => (
          <div key={`card_${expe.idContacto}`}>
            <div className="PrinCardEx" style={{ margin: ".5% 0" }}>
              <div className="ContainerEx">
                <div
                  className="CardEx"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    
                    navigate("/app/ExpedientesNuevoDetalle",
                        {
                          //  replace: false,
                          state: {
                            id: expe.idContacto,
                            imagen: expe.Imagen,
                            nombre: expe.Nombre,
                          },
                        });
                    // console.log(`card_${expe.idContacto}`);

                    // console.log(!Ocultas);
                  }}
                >
                    <Avatar size={45} 
                    style={{background:"#d7d7d7"}}
                    src={
                      <img 
                      id={`Img_ExpeCARD${expe.idContacto}`} 
                      onError={() => {
                        let img = document.getElementById(`Img_ExpeCARD${expe.idContacto}`);
                        img.setAttribute("src", Imag);
                      }}
                      src={
                      !!expe.Imagen
                        ? "https://" + expe.Imagen
                        : Imag
                    } alt="" />
                    } />
                  <div style={{width:"80%"}}>
                    <b>{expe.Nombre}</b>
                    <p className="Dia">
                      <span>
                        <b>Última actualización:&nbsp;</b>
                        &nbsp;<span>{expe.Fecha}</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {/* <hr /> */}
            </div>
          </div>
        ))}
    </>
  );
}
