import React from 'react'
import icoEliminar from "../../Img/Iconos/eliminar.svg"
import "../comp-styles/PerfilCard.scss"
import {deleteCedula_request} from "../../services/perfil_services";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function PerfilCard({cedulas, eliminarCedula}) { 
    return (
      cedulas.map((cedula) => (
        
    <>
      
        <div>
           <form action="">
            <div className='PerfilCardContainer'>
               <div>
               <label htmlFor=""><b>Cédula: {cedula.Cedula ?? ""}</b></label>
               <p>{cedula.Profesion}</p>
               </div>
               <div>
                <i cid={cedula.cedula}>
                  <img src={icoEliminar} alt="" onClick={
                    () => eliminarCedula(cedula.IdUserProfesion)
                  }/>
                </i>
               </div>
            </div>
           </form>
        </div>
        
    </>
    ))
  )
}

