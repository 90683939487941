import React from 'react'
import Iconos from "../IconosDeSitio";
import "../comp-styles/EstudiosCard.scss"
import { Avatar } from 'antd';

export default function OrdenesCard({ OpenDetalleEstud, OrdenesList, DetailOrdenes }) {

  return (
    OrdenesList.map((orden) => (
      <div key={orden.id} onClick={() => {
        // OpenDetalleEstud(true);
        // TipoDetalleEstudios(1);
        // DetailEstudios(estudio.IdEstudio);
        window.open(orden.url, "_blank");
      }}
        className='ContenedorCardOrdenes'
      >
        <Avatar size={50}
          src={!!orden.imagen ? "https://" + orden.imagen : Iconos[0]}
        />
        {/* <img className='ImgEstudiosCard' src={!!estudio.Imagen_url ? "https://" + estudio.Imagen_url : Iconos[0]} alt="" /> */}
        <div>
          <b>{orden.paciente}</b>
          <p>{orden.fecha}</p>
          <b>{orden.tipoOrden === 1 ? "Laboratorio" : "Interconsulta"}</b>
        </div>
      </div>
    ))
  )
}
