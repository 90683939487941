import React from "react";
import Iconos from "../IconosDeSitio";

// importando scss
import "../comp-styles/AnunciosCard.scss";

function AnunciosCard({ OpenDetailAnuncios, lista, setDetalleComunicado }) {

  return (
    <>
      {lista.map((comunicado) => (
        <div className="ContenedorPrinAnunCard" key={comunicado.IdPublicidad}>
          <div
            className="contenedormenuAnun"
            style={{ cursor: "pointer", width: "90%" }}
            onClick={() => {
              OpenDetailAnuncios(true);
              setDetalleComunicado(comunicado);
            }}>
            <div className="ContenedorCardAnun">
              <div className="BgrImg">
                {!!comunicado.Imagen && (<img src={`https://${comunicado.Imagen}`} alt="" />)}
              </div>

              <div style={{marginLeft:"2%"}}>
                <b>{comunicado.Titulo}</b>
                <p>{comunicado.Fecha}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default AnunciosCard;