const baseUrl =process.env.REACT_APP_API_URL;
//TODO remove
export const baseUrlPrueba = process.env.REACT_APP_API_TEST;
export const cloudFunction_token = process.env.REACT_APP_NOTIFICATIONS_URL_TOKEN;
export const cloudFunction_notifications = process.env.REACT_APP_NOTIFICATIONS_URL_NOTIFICATION;

export const gAnalytics_ID = process.env.REACT_APP_ANALITYCS_ID;

export const WhatsAppChatURL = `https://wa.me/message/${process.env.REACT_APP_WHATSAPPID}`;

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRECONFIG_APIKEY,
    authDomain: process.env.REACT_APP_FIRECONFIG_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIRECONFIG_PROJECTID,
    storageBucket: process.env.REACT_APP_FIRECONFIG_STOREBUCKET,
    messagingSenderId: process.env.REACT_APP_FIRECONFIG_MESSAGINGSENDER,
    appId: process.env.REACT_APP_FIRECONFIG_APPID,
    measurementId: process.env.REACT_APP_FIRECONFIG_MEASUREMENTID
  };

export const fireB_User=process.env.REACT_APP_FIREB_USER;
export const fireB_Pass=process.env.REACT_APP_FIREB_PASS;

export const fireB_Notification_usr=process.env.REACT_APP_NOTIFICATIONS_USR;
export const fireB_Notification_pwd=process.env.REACT_APP_NOTIFICATIONS_PWD;

export const fireB_VapidKey=process.env.REACT_APP_FIREB_VAPIDKEY;
export default baseUrl;