import axios from "axios"
import baseUrl from "./apiUrl";

export async function getConsultas_request(consultaData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/ConsultasHoy",
            method: "POST",
            data: consultaData,
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log("CONSULTAS HOY");
        // console.log(error);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function detailConsultas_request(idConsulta) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/VisualizarConsulta/" + idConsulta,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log("SERVICIO DETALLE CONSULTA");
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getCIIEDiagnosticos_request(palabra) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CIE/" + palabra,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta.Enfermedades
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error["response"]);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getMedicamentos_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/medicamentos",
            method: "GET",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log("CONSULTAS HOY");
        // console.log(error);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addConsulta_request(consultaData, archivosEstudio) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/NuevaConsulta`,
            method: "POST",
            params: consultaData,
            data: archivosEstudio,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
            }
        })
        // console.log("AGREGAR CONSULTA SERVICIO");
        // console.log(response);
        if (response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR CONSULTA SERVICIO");
        console.log(error);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : `Error desconocido ${error.response.data.message}` : error.message,
        };

    }
}

export async function addConsultaFormData_request(consultaData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/NuevaConsulta`,
            method: "POST",
            data: consultaData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data',
                // 'Content-Type': 'application/json',
                'Accept': 'application/json',
                // 'Content-Type': 'application/json',

            }
        })
        // console.log("AGREGAR CONSULTA SERVICIO");
        // console.log(response);
        if (response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        console.log(response);
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR CONSULTA SERVICIO");
        console.log(error);
        // console.log(error.status);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : `Error desconocido ${error.response.data.message}` : error.message,
        };

    }
}

export async function editConsulta_request(consultaData, idConsulta, estudiosData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/editarConsulta/${idConsulta}`,
            method: "POST",
            params: consultaData,
            data: estudiosData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data'
            }
        })
        // console.log("EDITAR CONSULTA SERVICIO");
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR EDITAR CONSULTA SERVICIO");
        // console.log(error);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getConsultasPeriod_request(consultaData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/ConsultasPeriodo",
            method: "POST",
            data: consultaData,
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log("CONSULTAS PERIODO");
        // console.log(error);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function vistaPreviaInterconsulta_request(interconsultaData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/previewOrden`,
            method: "POST",
            data: interconsultaData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("VISTA PREVIA INTERCONSULTA SERVICIO");
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR VISTA PREVIA INTERCONSULTA SERVICIO");
        // console.log(error);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addResumenMedico_request(resumenData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/crearResumen`,
            method: "POST",
            data: resumenData,
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log("SERVICIO ADD RESUMEN MEDICO");
        // console.log(error);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function previewResumenMedico_request(resumenData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/previewResumen`,
            method: "POST",
            data: resumenData,
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log("SERVICIO PREVIEW RESUMEN MEDICO");
        // console.log(error);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function documentoResumenMedico_request(idResumen) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/verResumen/${idResumen}`,
            method: "GET",
            // headers:
            // {
            //     'Authorization': 'Bearer ' + localStorage.getItem("token")
            // }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log("SERVICIO VER RESUMEN MEDICO");
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function vistaPreviaReceta_request(recetaData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/RecetaPreview`,
            method: "POST",
            data: recetaData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("VISTA PREVIA RECETA SERVICIO");
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR VISTA PREVIA RECETA SERVICIO");
        // console.log(error);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function vistaPreviaOrdenEstudios_request(ordenEstudiosData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/previewOrdenEstudio`,
            method: "POST",
            data: ordenEstudiosData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("VISTA PREVIA ORDEN ESTUDIOS SERVICIO");
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR VISTA PREVIA ORDEN ESTUDIOS SERVICIO");
        // console.log(error);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}