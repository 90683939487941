import React from 'react'
import { useNavigate } from "react-router-dom";

function ErrorPagina() {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "5%", background: "#148f9f", width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div style={{ background: "#fff", padding: "3%", borderRadius: ".5rem", }}>
        <h3 style={{ color: "#424242" }}>404</h3>
        <b style={{ color: "#424242" }}>No se no encontro esta pagina!</b>
        <div style={{ margin: "2% 0" }}>
          Ha llegado a una página que no existe en <b style={{ color: "#148f9f" }}>YGIA</b>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <div style={{ textAlign: "center", background: "#148f9f", width: "90%", padding: "1% 2%", color: "#fff", borderRadius: ".3rem", marginTop: "5%", cursor: "pointer" }}
            onClick={()=>{navigate("/", {replace: true});}}
          >
            Regresar a home
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPagina