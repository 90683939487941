import axios from "axios"
import baseUrl from "./apiUrl";

export async function getReportesFilter_request(filter, filterData) {
    let routeFilter = "";
    //// console.log(filter);
    switch (filter) {
        case 1:
            routeFilter = "ReporteHoy";
            break;

        case 2:
            routeFilter = "ReporteAyer";
            break;

        case 3:
            routeFilter = "ReporteSemana";
            break;

        case 4:
            routeFilter = "ReportePeriodo";
            break;

        default:
            break;
    }
    try {
        let resp;
        let response;
        if (filter !== 4) {
            response = await axios({
                url: baseUrl + `/${routeFilter}`,
                method: "GET",
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            })
        } else {
            response = await axios({
                url: baseUrl + `/${routeFilter}`,
                method: "POST",
                data: filterData,
                headers:
                {
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            })
        }
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}