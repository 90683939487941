import { createSlice } from "@reduxjs/toolkit";

const initialState = { show: false, text: "", type: "" };

export const snackbarSlice = createSlice({
    name: "snackbarGlobal",
    initialState,
    reducers: {
        showSnackbar: (state, action) => {
            const {show, text, type} = action.payload;
            state.show = show;
            state.text = text;
            state.type = type;
        },
    }
})


export const { showSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;