import React, { useState }from 'react';
import {Inputt, Label, GrupoInput, LeyendaError, IconoValidacion,InputR} from '../GeneralComponents/Formularios';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import "../../pages/styles/Registro.scss"




const ComponenteInput = ({estado, cambiarEstado, tipo, label, placeholder, name, leyendaError, expresionRegular, funcion, activo, vacio, length}) => {
	const onChange = (e) => {
		
		if(funcion && name === "nacimiento"){
			let validar = funcion();
			// console.log(validar);
			if (validar) {
				// console.log("true");
				cambiarEstado({...estado, valido: 'true'});
				cambiarEstado({...estado, campo: e.target.value});
			} else {
				// console.log("false");
				cambiarEstado({...estado, valido: 'false'});
				cambiarEstado({...estado, campo: e.target.value});
			}
		} else {
			cambiarEstado({...estado, campo: e.target.value});
		}

	}

	const validacion = () => {
		if(expresionRegular){
			// console.log(expresionRegular.test(estado.campo));
			if(expresionRegular.test(estado.campo)){
				cambiarEstado({...estado, valido: 'true'});
			} else {
				// console.log("ESTADO: "+!!estado.campo);
				// console.log("VACIO: "+vacio);
				if (vacio && !!!estado.campo) {
					// console.log("aqui");
					cambiarEstado({...estado, valido: null});
				} else {
					if (!vacio && !!!estado.campo) {
						// console.log("aca");
						cambiarEstado({...estado, valido: null});
					} else {
						// console.log("jeje");
						cambiarEstado({...estado, valido: 'false'});
					}
					// console.log("aca");
					// cambiarEstado({...estado, valido: 'false'});
				}
			}
		}

		if(funcion && name === "nacimiento"){
			let validar = funcion();
			// console.log(validar);
			if (validar) {
				cambiarEstado({...estado, valido: 'true'});
			} else {
				cambiarEstado({...estado, valido: 'false'});
			}
		}

		if (funcion && name !== "nacimiento" ) {
			funcion();
		}

		// if (vacio && !!estado.campo) {
		// 	cambiarEstado({...estado, valido: null});
		// } 
		// else if(!!estado.campo) {
		// 	cambiarEstado({...estado, valido: null});
		// }
	}

	
    
	return (
		<div>
			{(label !== undefined && !!label)&&(<Label htmlFor={name} valido={estado.valido}>{label}</Label>)   }
			<GrupoInput>
				<Inputt 
					type={tipo}
					placeholder={placeholder} 
					id={name}
					value={estado.campo}
					onChange={onChange}
					onKeyUp={validacion}
					// onBlur={validacion}
					valido={estado.valido}
					disabled = {activo ? true : false}
					maxLength={!!length ? length : ""}
					// {temp ? disabled : enable}
				/>
				<IconoValidacion 
					icon={estado.valido === 'true' ? faCheckCircle : faTimesCircle}
					valido={estado.valido}
				/>
			</GrupoInput>
			<LeyendaError valido={estado.valido}>{leyendaError}</LeyendaError>

		</div>
	);
}
 
export default ComponenteInput;