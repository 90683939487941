import React from "react";
import Iconos from "../IconosDeSitio";

// importando scss
import "../comp-styles/RecetasCard.scss";

function RecetasCard({ Opendetailrec, lista, detailReceta, setReenviarActivate }) {
  return (
    <>
      {lista.map((receta) => (
        <div className="PrinCardrec">
          <div className="menutrespuntosrec"
            style={{ cursor: "pointer", width: "80%" }}
            onClick={()=>{Opendetailrec(true); detailReceta(receta.IdReceta); setReenviarActivate(1);}}>
            <div className="Containerrec2">
              <div className="Cardrec">
                <div className="BgrImg">
                  <img src={!!receta.Imagen_url ? "https://" + receta.Imagen_url : Iconos[0]} alt="" />
                </div>
                <div>
                  <b>{receta.Contacto}</b>
                  <p>{receta.folio + " - " + receta.Fecha}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default RecetasCard;
