import React from "react";
import { useState, useEffect } from "react";
import { UseModal } from "../hooks/UseModal";
import { Button } from "antd";
import { getReportesFilter_request } from "../services/reportes_sevices";
import { Layout, Space } from "antd";

//importacion de scss
import "../pages/styles/Reportes.scss";

//Importacion de modal
import ModalFiltro from "../componentes/GeneralComponents/ModalFiltro";

//Importacion de iconos
import Filtro from "../Img/Iconos/Filtro.svg";

import { Loader } from "../componentes/GeneralComponents/Loader";
import { Cancel } from "@mui/icons-material";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const { Header, Footer, Sider, Content } = Layout;

function Reportes() {
  const [ListReportesFilter, setListReportesFilter] = useState([]);
  const [DatosReporte, setDatosReporte] = useState([]);
  const [EstadoFiltro, setEstadoFiltro] = useState("Hoy");
  const [NameFiltro, setNameFiltro] = useState("Hoy");
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  //Declaracion de variables
  const [FechaInicio, setFechaInicio] = useState("");
  const [FechaFin, setFechaFin] = useState("");
  const [FiltroActive, setFiltroActive] = useState(false);

  const [MensajeFechaInicio, setMensajeFechaInicio] = useState("true");
  const [MensajeFechaFin, setMensajeFechaFin] = useState("true");

  // Modal
  const [isOpenFiltroR, OpenFiltroR, CloseFiltroR] = UseModal(false);

  async function getReportesFilter(type) {
    setLoader(true);
    if (type !== 4) {
      let data = new FormData();
      if (type === 4) {
        data.append("FechaInicio", FechaInicio);
        data.append("FechaFin", FechaFin);
      }
      if (type === 1) {
        setNameFiltro("Hoy");
      } else if (type === 2) {
        setNameFiltro("Ayer");
      } else if (type === 3) {
        setNameFiltro("Semana");
      } else if (type === 4) {
        setNameFiltro(`Periodo ${FechaInicio} - ${FechaFin}`);
      }
      let response = await getReportesFilter_request(type, data);
      if (response.ok) {
        // console.log(response.data);
        setDatosReporte(response.data);
        CloseFiltroR(false);
        if (type !== 1) {
          setFiltroActive(true);
        }
      } else {
        setDatosReporte("");
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!FechaInicio && !!FechaFin) {
        let data = new FormData();
        if (type === 4) {
          data.append("FechaInicio", FechaInicio);
          data.append("FechaFin", FechaFin);
        }
        if (type === 1) {
          setNameFiltro("Hoy");
        } else if (type === 2) {
          setNameFiltro("Ayer");
        } else if (type === 3) {
          setNameFiltro("Semana");
        } else if (type === 4) {
          setNameFiltro(`Periodo ${FechaInicio} - ${FechaFin}`);
        }
        let response = await getReportesFilter_request(type, data);
        if (response.ok) {
          // console.log(response.data);
          setDatosReporte(response.data);
          CloseFiltroR(false);
          if (type !== 1) {
            setFiltroActive(true);
          }
        } else {
          setDatosReporte("");
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        if (!!!FechaInicio) {
          setMensajeFechaInicio("false");
        }

        if (!!!FechaFin) {
          setMensajeFechaFin("false");
        }

        setOpen(true);
        setTypeMessage("warning");
        setMessage("Debe introducir ambas fechas");
      }
    }
    setLoader(false);
    return;
  }

  useEffect(() => {
    getReportesFilter(1);
  }, []);

  return (
    <Stack>
      {loader ? (
        <Loader />
      ) : (
        <>
          <NavbarNuevo
            mensajesError={{
              setOpen: setOpen,
              setTypeMessage: setTypeMessage,
              setMessage: setMessage,
            }}
            modulo={"Reportes"}
            searchBar={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "white",
                  padding: "1% 3%",
                  alignItems: "center",
                }}
              >
                <b>Reportes</b>
                {/* <img onClick={OpenFiltroR} style={{ width: "30px", cursor: "pointer", alignItems: "center" }} src={Filtro} alt="" /> */}
                <div style={{ display: "flex", margin: "1% 0" }}>
                  {FiltroActive && (
                    <Button
                      style={{
                        background: "white",
                        color: "#148F9F",
                        border: "0",
                        height: "30px",
                        padding: "0 1%",
                        margin: ".8% 0",
                        // marginLeft: "3%",
                        marginRight: "0",
                      }}
                      onClick={() => {
                        setFiltroActive(false);
                        setFechaInicio("");
                        setFechaFin("");
                        getReportesFilter(1);
                      }}
                    >
                      <Cancel sx={{ color: "#148f9f" }} />
                      Filtro
                    </Button>
                  )}
                  <img
                    onClick={() => {
                      OpenFiltroR(true);
                      setMensajeFechaInicio("true");
                      setMensajeFechaFin("true");
                    }}
                    style={{ width: "30px", cursor: "pointer" }}
                    src={Filtro}
                    alt=""
                  />
                </div>
              </div>
            }
          />

          <Content
            className="site-layout"
            style={{
              padding: "1% 3%",
              width: "100%",
              overflow: "initial",
            }}
          >
            <div className="contenedorprinR">
              <h3 style={{ padding: "1%" }}>{NameFiltro}</h3>
              <h4 style={{ paddingBottom: "1%" }}>Ingresos</h4>
              <hr />
              <div>
                <div
                  style={{
                    paddingTop: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>Totales</p>
                  <p>{!!DatosReporte ? `$${DatosReporte.Total}` : ""}</p>
                </div>
                <div
                  style={{
                    paddingTop: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>Por consulta</p>
                  <p>
                    {!!DatosReporte
                      ? `$${DatosReporte.Consultas !== undefined
                        ? DatosReporte.Consultas.Consulta
                        : ""
                      }`
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    paddingTop: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>Por recetas</p>
                  <p>
                    {!!DatosReporte
                      ? `$${DatosReporte.Recetas !== undefined
                        ? DatosReporte.Recetas.Receta
                        : ""
                      }`
                      : ""}
                  </p>
                </div>
              </div>
              <br />
              <h4 style={{ paddingBottom: "1%" }}>Actividad</h4>
              <hr />
              <div>
                <div
                  style={{
                    paddingTop: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>No. de citas</p>
                  <p>
                    {!!DatosReporte
                      ? DatosReporte.Citas !== undefined
                        ? DatosReporte.Citas.Citas
                        : ""
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    paddingTop: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>No. de consultas</p>
                  <p>
                    {!!DatosReporte
                      ? DatosReporte.Consultas !== undefined
                        ? DatosReporte.Consultas.NumeroConsulta
                        : ""
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    paddingTop: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>No. de videoconsultas</p>
                  <p>
                    {!!DatosReporte
                      ? DatosReporte.VideoConsulta !== undefined
                        ? DatosReporte.VideoConsulta.VideoConsultas
                        : ""
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    paddingTop: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>Horas videoconsultas</p>
                  <p>
                    {!!DatosReporte
                      ? DatosReporte.HorasVideoConsulta !== undefined
                        ? DatosReporte.HorasVideoConsulta.HorasVideoConsulta
                        : ""
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    paddingTop: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>No. de chatconsultas</p>
                  <p>
                    {!!DatosReporte
                      ? DatosReporte.ChatConsultas
                        ? DatosReporte.ChatConsultas.ChatConsultas
                        : ""
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </Content>
        </>
      )}

      <ModalFiltro
        isOpen={isOpenFiltroR}
        closeModal={CloseFiltroR}
        titulos={["Ordena tus reportes", "Hoy", "Ayer", "Semana"]}
        OptionsSelect={[]}
        functions={[getReportesFilter, getReportesFilter]}
        statesVariables={{
          estadoFiltro: setEstadoFiltro,
          fechaInicio: setFechaInicio,
          mensajeFechaInicio: setMensajeFechaInicio,
          fechaFin: setFechaFin,
          mensajeFechaFin: setMensajeFechaFin,
        }}
        variables={{
          fechaInicio: FechaInicio,
          mensajeFechaInicio: MensajeFechaInicio,
          fechaFin: FechaFin,
          mensajeFechaFin: MensajeFechaFin,
        }}
      />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default Reportes;
