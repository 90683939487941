import React from "react";
import '../GenaralStyles/Tutorialcard.scss'
import { Padding } from "@mui/icons-material";
import ReactPlayer from "react-player";
import { Row } from "antd";

function Tutorialcard({videos}) {

  return (
    <>
    <Row className="videosResponsive"
    >

    {videos.map((listavideo) => (
      <div
      style={{
        background: "#fff",
        padding:"1rem",
        borderRadius: ".2rem",
        width: "300px",
      }}
    >
   <div>
   <ReactPlayer
            url={listavideo.videoUrl}
            width="100%"
            height="79%"
            // playing={Stop}
            // onPause={()=>{setStop(false);}}
            // onPlay={()=>{setStop(true);}}
            controls
            // onStart={()=>{// console.log("Si ENTRA EL VIDEO");}}
            volume={0.5}
          />
        <h4> {listavideo.titleVideo} </h4>
        <span>{listavideo.descripcion}</span>
    
   </div>
    </div>
    ))}
    </Row>
    
    </>
  );
}

export default Tutorialcard;
