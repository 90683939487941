import { Row } from 'antd'
import React from 'react'

export default function ResibosCard({ recibos }) {
  return (
    <>
      {recibos.map((Dato, index) => (
        <Row key={index} style={{ width: "100%", padding: "1%", background: "white", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "2px", borderBottom: "1px solid #d7d7d7", gap: "5px" }}>
          {/* <div style={{width:"50px"}}>
          <p style={{fontWeight:"500"}}>Folio</p>
          <p>{Dato.Folio}</p>
        </div> */}
          <div style={{ width: "82px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
            <p style={{ fontWeight: "500" }}>Plan</p>
            <p>Ygia Superior</p>
          </div>
          <div style={{ width: "70px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <p style={{ fontWeight: "500", }}>Estatus</p>
            {/* <p style={{color:`${recibos.Status == "paid" ? "green": Dato.status == "Pendiente"? "gray" : "green"}`}}>{Dato.status}</p> */}
            <p style={{ color: `${Dato.Status == "paid" ? "green" : "gray"}` }}>Pagado</p>
          </div>
          {/* <div style={{ width: "60px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
            <p style={{ fontWeight: "500", }}>Tarjeta</p>
            <p>{Dato.Tarjeta}</p>
          </div> */}
          <div style={{ width: "100px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
            <p style={{ fontWeight: "500" }}>Precio</p>
            <p>${Dato.Monto}.00 mxn</p>
          </div>
          <div style={{ width: "100px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
            <p style={{ fontWeight: "500" }}>Fecha de pago</p>
            <p>{Dato.FechaPago}</p>
          </div>
        </Row>
      ))}
    </>

  )
}
