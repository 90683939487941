import React from "react";
// import mui libreria
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import visa from "../../Img/visa-svg.svg";
import mastercard from "../../Img/mastercard-svg.svg";
import MoreVert from "@mui/icons-material/MoreVert";

// import iconos
import CancelIcon from '@mui/icons-material/Cancel';
import MenuVertical from "./menuvertical";

export default function TarjetasCard({ tarjetas, setIdTarjeta, deleteTarjeta, selectDefaultCard }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // console.log("ABRE");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, hola) => {
    // console.log("CIERRA");
    setAnchorEl(null);
  };

  return (
    <>
      {tarjetas.map((tarjeta, index) => (
        <div key={index} style={{ background: "white", padding: "1%", marginBottom: ".5%", display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "3%", width: "95%" }}>
            <img
              style={{ width: "40px", height: "40px", objectFit: "cover", objectPosition: "center", borderRadius: "5rem" }}
              src={tarjeta.Tipo === "Visa" ? visa : mastercard}
              alt=""
            />
            <div>
              <div>
                <p style={{ fontWeight: "bold" }}>{"**** **** **** " + tarjeta.UltimosDigitos}</p>
                {/* <p>{tarjeta.TipoTarjeta + " - " + tarjeta.Predeterminada === 1 && "predeterminada"}</p> */}
              </div>
              <div>
                {/* <p style={{ fontWeight: "500" }}>{"**** **** **** "+tarjeta.UltimosDigitos}</p> */}
                <p>{tarjeta.TipoTarjeta}{tarjeta.Predeterminado === 1 && " - predeterminada"}</p>
              </div>
            </div>
          </div>
          <MenuVertical tarjeta={tarjeta} setIdTarjeta={setIdTarjeta} deleteTarjeta={deleteTarjeta} selectDefaultCard={selectDefaultCard} />
        </div>
      ))}
    </>
  );
}
