import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space } from "antd";
export default function PreviewImage({ urlImage, style, imageName, urlImageSecond }) {
    function onDownloadImage() {
        fetch(urlImageSecond !== undefined ? urlImageSecond : urlImage)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.download = imageName !== undefined ? imageName : "image.png";
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                link.remove();
            });
    }
    return (
        <Image
            style={style}
            src={urlImage}
            alt="Archivo adjunto"
            preview={{
                toolbarRender: (
                    _,
                    {
                        transform: { scale },
                        actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                ) => (
                    <Space size={12} className="toolbar-wrapper">
                        <DownloadOutlined onClick={onDownloadImage} />
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                ),
            }}
        />
    );
}