import React from 'react'

import imagConstruccion from"../Img/sitio-en-construccion.png";
import NavbarNuevo from '../componentes/GeneralComponents/Navbar_Nuevo';

function EnContruccion() {
  return (

    <>
      <NavbarNuevo />
      <div style={{textAlign:"center"}}>
        <img style={{width:"50%"}} src={imagConstruccion} alt="" />
        <h3>Este modulo aun se encruentra en proceso</h3>
        <h4>...</h4>
    </div>
    </>
  )
}

export default EnContruccion