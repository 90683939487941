import React, { useState } from 'react';
import { Label, LeyendaError } from '../GeneralComponents/Formularios';
import "../../pages/styles/Registro.scss"
import { Select } from 'antd';




const SelectAntd = ({ id, label, value, setStateValue, placeholder, textError, setStateError, valueError, disabled, readOnly,
    style, styleDiv, options, extras, vacio, onClick, open, requiredICon }) => {
    const onSelect = (e) => {
        setStateValue(e);
        if (vacio !== undefined) {
            if (!vacio) {
                setStateError("true");
            }
        } else {
            if (setStateError !== undefined) {
                setStateError("true");
            }

        }

        if (id === "rol") {
            // console.log(e);
            if (e === "1") {
                extras[0](1);
            } else {
                extras[0](0);
                extras[1]();
            }
        }

    }

    return (
        <div style={styleDiv}>
            {(label !== undefined && !!label) && (<Label valido={valueError}>{vacio !== undefined ? (!vacio && requiredICon) && <b style={{ color: "#ff4d4f", fontSize: "14px", marginInlineEnd: "4px", fontFamily: "SimSun,sans-serif" }}>*</b> : ""}{label}</Label>)}
            <Select
                style={style}
                placeholder={placeholder}
                value={value}
                onSelect={onSelect}
                onClick={onClick}
                disabled={disabled !== undefined ? disabled ? true : false : false}
                readOnly={readOnly}
                status={valueError !== undefined && !!valueError ? valueError === 'true' ? "" : "error" : ""}
                options={options}
                open={open}
            />
            <LeyendaError valido={valueError}>{textError}</LeyendaError>
        </div>
    );
}

export default SelectAntd;