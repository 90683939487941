import * as React from "react";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListDivider from "@mui/joy/ListDivider";
import MoreVert from "@mui/icons-material/MoreVert";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";

// import iconos
import CancelIcon from '@mui/icons-material/Cancel';
import PaidIcon from '@mui/icons-material/Paid';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function PositionedMenu({ setIdCobro, extras }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {

    // console.log("ABRE");
    setAnchorEl(event.currentTarget);

  };


  const handleClose = (event) => {
    // console.log("CIERRA");
    setAnchorEl(null);
  };



  return (

    <>
      {/* <div style={{ background:"red", width:"100%", height:"100vh", zIndex:"999"}}> */}
      {extras[0].Estado === "Pendiente" && (
        <>
          <IconButton
            id="positioned-demo-button"
            aria-controls={open ? "positioned-demo-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            color="neutral"
            onClick={(e) => { handleClick(e); 
              setIdCobro(extras[0].IdCargo); 
              extras[1]({ nombre: extras[0].Contacto, imagen: extras[0].Imagen_url, idContacto: extras[0].IdContacto, total: parseFloat(extras[0].Total), idCobro:extras[0].IdCargo})
            }}
          >
            <MoreVert />
          </IconButton>
          {anchorEl !== null && (
            <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
              <div>
                <Menu
                  id="positioned-demo-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="positioned-demo-button"
                  placement="bottom-end"

                >
                  <MenuItem onClick={() => {
                    handleClose();
                    extras[3](true);
                  }}>
                    <ListItemDecorator sx={{ color: "inherit" }}>
                      <AddCircleIcon color='secondary' />
                    </ListItemDecorator>{" "}
                    Nuevo cobro
                  </MenuItem>

                  <MenuItem onClick={() => { 
                    handleClose();
                    extras[2](true);
                    }}>
                    <ListItemDecorator>
                      <PaidIcon color='success' />
                    </ListItemDecorator>{" "}
                    Liquidar cobro
                  </MenuItem>

                  <MenuItem onClick={()=>{handleClose(); extras[4]();}}>
                    <ListItemDecorator sx={{ color: "inherit" }}>
                      <CancelIcon color="danger" />
                    </ListItemDecorator>{" "}
                    Cancelar cobro
                  </MenuItem>
                </Menu>
              </div>
            </div>)}
        </>
      )}

      {extras[0].Estado === "Pagado" && (
        <>
          <IconButton
            id="positioned-demo-button"
            aria-controls={open ? "positioned-demo-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            color="neutral"
            onClick={(e) => { handleClick(e); setIdCobro(extras[0].IdCargo); 
              extras[1]({ nombre: extras[0].Contacto, imagen: extras[0].Imagen_url, idContacto: extras[0].IdContacto, total: parseFloat(extras[0].Total), idCobro:extras[0].IdCargo})
            }}
          >
            <MoreVert />
          </IconButton>
          {anchorEl !== null && (
            <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
              <div>
                <Menu
                  id="positioned-demo-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="positioned-demo-button"
                  placement="bottom-end"

                >
                  <MenuItem onClick={() => {
                    handleClose();
                    extras[3](true);
                  }}>
                    <ListItemDecorator sx={{ color: "inherit" }}>
                      <AddCircleIcon color='secondary' />
                    </ListItemDecorator>{" "}
                    Nuevo cobro
                  </MenuItem>

                  <MenuItem onClick={()=>{handleClose();}}>
                    <ListItemDecorator sx={{ color: "inherit" }}>
                      <CancelIcon color="danger" />
                    </ListItemDecorator>{" "}
                    Cancelar cobro
                  </MenuItem>
                </Menu>
              </div>
            </div>)}
        </>
      )}


    </>
  );
}
