import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const colores = {
	borde: "#0075FF",
	error: "#ff4d4f",
	exito: "#148f9f"
}

const Formulario = styled.form`
    width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr  1fr;
	padding: 5%;
	gap: 10px;
	

	h2{
		grid-column: span 3;
	}
	hr{
		grid-column: span 3;
	}

	@media (max-width: 800px){
		width: 100%;
		display: block;
		grid-template-columns: 1fr;
		h2{
			grid-column: span 1;
		}
		hr{
			grid-column: span 1;
		}
	}
`;


const Label = styled.label`
	display: block;
	font-weight: 600;
	font-size: 14px;
	padding: 5px 0;
	min-height: 30px;
	// cursor: pointer;

	${props => props.valido === 'false' && css`
		color: ${colores.error};
	`}
`;

const GrupoInput = styled.div`
	position: relative;
	z-index: 90;
`;

const Inputt = styled.input`
	width: 100%;
	background: #fff;
	border-radius: 3px;
	height: 45px;
	line-height: 45px;
	padding: 0 40px 0 10px;
	transition: .3s ease all;
	border: 1px solid #d7d7d7 ;
	color: #424242;

	&:focus {
		outline: 1px solid #00b7ff;
        box-shadow: 0 0 0 .2em #97b2c06c;
		
	}

	${props => props.valido === 'true' && css`
		border: 1px solid #d7d7d7;
	`}

	${props => props.valido === 'false' && css`
		border: 1px solid ${colores.error} !important;
	`}
`;


const LeyendaError = styled.p`
	font-size: 14px;
	margin-bottom: 0;
	color: ${colores.error};
	display: none;

	${props => props.valido === 'true' && css`
		display: none;
	`}

	${props => props.valido === 'false' && css`
		display: block;
	`}
`;

const IconoValidacion = styled(FontAwesomeIcon)`
	position: absolute;
	right: 10px;
	bottom: 14px;
	z-index: 100;
	font-size: 16px;
	opacity: 0;

	${props => props.valido === 'false' && css`
		opacity: 1;
		color: ${colores.error};
	`}

	${props => props.valido === 'true' && css`
		opacity: 1;
		color: ${colores.exito};
	`}
`;

const ContenedorTerminos = styled.div`
	grid-column: span 3;
	input {
		width: 20px;
	}

	@media (max-width: 800px){
		grid-column: span 1;
	}
`;

const ContenedorBotonCentrado = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-column: span 3;
	@media (max-width: 800px){
	grid-column: span 1;
	}
`;

const Boton = styled.button`
padding: .5%;
width: 20%;
border-radius: 5rem;
border: 1px solid #148f9f;
color: #148f9f;
@media (max-width: 800px){
	width: 50%;
	}

`;

const MensajeExito = styled.p`
	font-size: 14px;
	color: ${colores.exito};
`;

const MensajeError = styled.div`
	height: 45px;
	line-height: 45px;
	background: #bb2929;
	padding: 0px 15px;
	border-radius: 3px;
	grid-column: span 3;
	color: #fff;
	p {
		margin: 0;
	} 
	b {
		margin-left: 10px;
	}
`;

export {
	Formulario,
	Label,
	GrupoInput,
	Inputt,
	LeyendaError,
	IconoValidacion,
	ContenedorTerminos,
	ContenedorBotonCentrado,
	Boton,
	MensajeExito,
	MensajeError,

};
