import React from 'react'
import "../comp-styles/FacturacionCard.scss"
import Icon from "../IconosDeSitio"

const factura = [
    {    Img: Icon[0],
         Numero: "000001",
        descripcion: "Esta es la factura 1"
       },
      {
        Img: Icon[1],
        Numero: "000002",
        descripcion: "Esta es la factura 2"
       }
   ];

export default function FacturacionCard() {
    
  return (
  <>
  {factura.map((FacturaList) =>
    <div className='cardFactura'>
    <div style={{display:"flex", gap:"1%", alignItems:"center"}}>
    <div>
    <img className='PerfilImgfactura' src={FacturaList.Img} alt="" />
    </div>
    <div>
    <p><b>No.</b><span>{FacturaList.Numero}</span></p>
    <p>{FacturaList.descripcion}</p>
    </div>
    </div>
    </div>
      )}
  </>
    
  )
}
