import "./GenaralStyles/Modalde2.scss"

const Modal = ({children, isOpen,closeModal}) => {

  const handleModalContainerClick  = e => e.stopPropagation();

  return (
    <div onClick={closeModal} className={`modal ${isOpen ? "is-open" : ""}`}>
       <div className="modal-container2" onClick={handleModalContainerClick}> 
        <button className="modal-close2" onClick={closeModal}><span>X</span></button>
        {children}
       </div>
    </div>
  )
}

export default Modal