import "./GenaralStyles/InputFile.scss"
import { textAlign } from "@mui/system";
import { Row } from "antd";
import React from "react";
import { useState } from "react";
import Adjuntar from "../../Img/Iconos/AdjuntarArchivo.svg";
import Eliminar from "../../Img/Iconos/eliminar.svg";
import Icono from "../IconosDeSitio";
import { OndemandVideo } from "@mui/icons-material";
import { Image } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { showSnackbar } from '../../redux/snackbarSlice';

export default function InputFileSingle({
  texto,
  archivo,
  formatos,
  extras,
  archivoValue,
  id,
  mensajes
}) {
  const dispatch = useDispatch();
  // subir Archivos lista
  const [OpenArchivo, setOpenArchivo] = useState(1);
  const [Archivo, setArchivo] = useState({
    name: archivoValue !== undefined ? archivoValue.name : "",
    url: archivoValue !== undefined ? archivoValue.url : "",
    file: "",
    size: 0,
    formato: archivoValue !== undefined ? archivoValue.formato : "",
  });
  const [FormatoValido, setFormatoValido] = useState(1);
  const changeInput = (e) => {
    //aquí evaluamos si ya hay imagenes antes de este input, para saber en dónde debe empezar el index del proximo array
    let formato = e.currentTarget.files[0].type.split("/");
    // console.log(formato);
    switch (formatos) {
      case 1:
        if (formato[1] === "jpeg" || formato[1] === "png") {
          if (e.currentTarget.files[0].size <= 5000000) {
            // console.log(e);
            let newImgsToState = readmultifiles(e);
            // console.log(newImgsToState);
            setArchivo(newImgsToState);
            archivo(newImgsToState);
            setOpenArchivo(1);
            if (extras[3] !== undefined) {
              extras[3]("true");
            }
          } else {
              dispatch(showSnackbar({
                show: true,
                text: "El archivo excede el tamaño maximo permitido (5MB)",
                type: "warning",
              }));
              // extras[0](true);
              // extras[1]("warning");
              // extras[2]("El archivo excede el tamaño maximo permitido (5MB)");
          }
        } else {
          dispatch(showSnackbar({
            show: true,
            text: "Tipo de archivo no valido. Archivos permitidos: imagenes ( png, jpeg )",
            type: "warning",
          }));
          // extras[0](true);
          // extras[1]("warning");
          // extras[2](
          //   "Tipo de archivo no valido. Archivos permitidos: imagenes ( png, jpeg )"
          // );
        }
        break;
      case 2:
        if (formato[0] === "video") {
          if (e.currentTarget.files[0].size <= 10000000) {
            let newImgsToState = readmultifiles(e);
            // console.log(newImgsToState);
            setArchivo(newImgsToState);
            archivo(newImgsToState);
            setOpenArchivo(1);
            if (extras[3] !== undefined) {
              extras[3]("true");
            }
          } else {
              dispatch(showSnackbar({
                show: true,
                text: "El video excede el tamaño maximo permitido (10MB)",
                type: "warning",
              }));
              // extras[0](true);
              // extras[1]("warning");
              // extras[2]("El video excede el tamaño maximo permitido (10MB)");
          }
        } else {
          dispatch(showSnackbar({
            show: true,
            text: "Tipo de archivo no valido. Archivos permitidos: videos",
            type: "warning",
          }));
          // extras[0](true);
          // extras[1]("warning");
          // extras[2]("Tipo de archivo no valido. Archivos permitidos: videos");
        }
        break;
      case 3:
        if (formato[1] === "pdf") {
          if (e.currentTarget.files[0].size <= 5000000) {
            // console.log(e);
            let newImgsToState = readmultifiles(e);
            // console.log(newImgsToState);
            setArchivo(newImgsToState);
            archivo(newImgsToState);
            setOpenArchivo(1);
            if (extras[3] !== undefined) {
              extras[3]("true");
            }
          } else {
              dispatch(showSnackbar({
                show: true,
                text: "El archivo excede el tamaño maximo permitido (5MB)",
                type: "warning",
              }));
              // extras[0](true);
              // extras[1]("warning");
              // extras[2]("El archivo excede el tamaño maximo permitido (5MB)");
          }
        } else {
          dispatch(showSnackbar({
            show: true,
            text: "Tipo de archivo no valido. Archivos permitidos: archivos (pdf)",
            type: "warning",
          }));
          // extras[0](true);
          // extras[1]("warning");
          // extras[2](
          //   "Tipo de archivo no valido. Archivos permitidos: archivos (pdf)"
          // );
        }
        break;
      case 4:
        if (
          formato[1] === "pdf" ||
          formato[1] === "jpeg" ||
          formato[1] === "png"
        ) {
          if (e.currentTarget.files[0].size <= 5000000) {
            // console.log(e);
            let newImgsToState = readmultifiles(e);
            // console.log(newImgsToState);
            setArchivo(newImgsToState);
            archivo(newImgsToState);
            setOpenArchivo(1);
            if (extras[3] !== undefined) {
              extras[3]("true");
            }
          } else {
              dispatch(showSnackbar({
                show: true,
                text: "El archivo excede el tamaño maximo permitido (5MB)",
                type: "warning",
              }));
              // extras[0](true);
              // extras[1]("warning");
              // extras[2]("El archivo excede el tamaño maximo permitido (5MB)");
          }
        } else {
          dispatch(showSnackbar({
            show: true,
            text: "Tipo de archivo no valido. Archivos permitidos: imagenes y archivos ( png, jpeg, pdf )",
            type: "warning",
          }));
          // extras[0](true);
          // extras[1]("warning");
          // extras[2](
          //   "Tipo de archivo no valido. Archivos permitidos: imagenes y archivos ( png, jpeg, pdf )"
          // );
        }
        break;
      case 5:
        if (
          formato[1] === "pdf" ||
          formato[1] === "jpeg" ||
          formato[1] === "png" ||
          formato[1] === "doc" ||
          formato[1] === "docx" ||
          formato[1] === "msword" ||
          formato[1] ===
            "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          formato[1] === "jpg"
        ) {
          if (e.currentTarget.files[0].size <= 5000000) {
            // console.log(e);
            let newImgsToState = readmultifiles(e);
            // console.log(newImgsToState);
            setArchivo(newImgsToState);
            archivo(newImgsToState);
            setOpenArchivo(1);
            if (extras[3] !== undefined) {
              extras[3]("true");
            }
          } else {
              dispatch(showSnackbar({
                show: true,
                text: "El archivo excede el tamaño maximo permitido (5MB)",
                type: "warning",
              }));
              // extras[0](true);
              // extras[1]("warning");
              // extras[2]("El archivo excede el tamaño maximo permitido (5MB)");
          }
        } else {
          if (formato[0] === "video") {
            if (e.currentTarget.files[0].size <= 10000000) {
              let newImgsToState = readmultifiles(e);
              // console.log(newImgsToState);
              setArchivo(newImgsToState);
              archivo(newImgsToState);
              setOpenArchivo(1);
              if (extras[3] !== undefined) {
                extras[3]("true");
              }
            } else {
                dispatch(showSnackbar({
                  show: true,
                  text: "El video excede el tamaño maximo permitido (10MB)",
                  type: "warning",
                }));
                // extras[0](true);
                // extras[1]("warning");
                // extras[2]("El video excede el tamaño maximo permitido (10MB)");
            }
          } else {
            dispatch(showSnackbar({
              show: true,
              text: "Tipo de archivo no valido. Archivos permitidos: imagenes, archivos y videos ( png, jpeg, pdf, doc, docx )",
              type: "warning",
            }));
            // extras[0](true);
            // extras[1]("warning");
            // extras[2](
            //   "Tipo de archivo no valido. Archivos permitidos: imagenes, archivos y videos ( png, jpeg, pdf, doc, docx )"
            // );
          }
        }
        break;

      default:
        if (
          formato[1] === "pdf" ||
          formato[1] === "jpeg" ||
          formato[1] === "png" ||
          formato[1] === "doc" ||
          formato[1] === "docx" ||
          formato[1] === "msword" ||
          formato[1] ===
            "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          formato[1] === "jpg"
        ) {
          if (e.currentTarget.files[0].size <= 5000000) {
            // console.log(e);
            let newImgsToState = readmultifiles(e);
            // console.log(newImgsToState);
            setArchivo(newImgsToState);
            archivo(newImgsToState);
            setOpenArchivo(1);
            if (extras[3] !== undefined) {
              extras[3]("true");
            }
          } else {
              dispatch(showSnackbar({
                show: true,
                text: "El archivo excede el tamaño maximo permitido (5MB)",
                type: "warning",
              }));
              // extras[0](true);
              // extras[1]("warning");
              // extras[2]("El archivo excede el tamaño maximo permitido (5MB)");
          }
        } else {
          if (formato[0] === "video") {
            if (e.currentTarget.files[0].size <= 10000000) {
              let newImgsToState = readmultifiles(e);
              // console.log(newImgsToState);
              setArchivo(newImgsToState);
              archivo(newImgsToState);
              setOpenArchivo(1);
              if (extras[3] !== undefined) {
                extras[3]("true");
              }
            } else {
                dispatch(showSnackbar({
                  show: true,
                  text: "El video excede el tamaño maximo permitido (10MB)",
                  type: "warning",
                }));
                // extras[0](true);
                // extras[1]("warning");
                // extras[2]("El video excede el tamaño maximo permitido (10MB)");
            }
          } else {
            dispatch(showSnackbar({
              show: true,
              text: "Tipo de archivo no valido. Archivos permitidos: imagenes, archivos y videos ( png, jpeg, pdf, doc, docx )",
              type: "warning",
            }));
            // extras[0](true);
            // extras[1]("warning");
            // extras[2](
            //   "Tipo de archivo no valido. Archivos permitidos: imagenes, archivos y videos ( png, jpeg, pdf, doc, docx )"
            // );
          }
        }
        break;
    }

    document.getElementById(
      `${id !== undefined ? id : "FileArchivoSingle"}`
    ).value = "";
  };

  function readmultifiles(e) {
    const files = e.currentTarget.files;

    //el array con las imagenes nuevas
    let arrayImages = "";

    Object.keys(files).forEach((i) => {
      const file = files[i];
      let url = URL.createObjectURL(file);
      let formato = file.type.split("/");
      arrayImages = {
        name: file.name,
        url: url,
        file: file,
        size: file.size,
        formato: formato[1],
      };
    });
    // console.log(arrayImages);
    //despues de haber concluido el ciclo retornamos las nuevas imagenes
    return arrayImages;
  }

  function deleteImg() {
    setArchivo({ name: "", url: "", file: "", size: 0, formato: "" });
    archivo({ name: "", url: "", file: "", size: 0, formato: "" });
    document.getElementById(
      `${id !== undefined ? id : "FileArchivoSingle"}`
    ).value = "";
    setOpenArchivo(0);
    if (extras[3] !== undefined) {
      extras[3]("false");
    }
    return;
  }

  function selectFormato() {
    const Imagenes = {
      input: "image/png,image/jpeg",
      validacion: ["png", "jpeg"],
    };
    const Videos = {
      input: "video/*",
      validacion: [],
    };
    const Pdf = {
      input: "application/pdf",
      validacion: ["pdf"],
    };
    const Archivos = {
      input: "image/png,image/jpeg,application/pdf",
      validacion: ["png", "jpeg", "pdf"],
    };
    const Todos = {
      input:
        ".doc,.docx,image/png,image/jpeg,application/pdf,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      validacion: [
        "png",
        "jpeg",
        "pdf",
        "doc",
        "docx",
        "msword",
        "vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
    };

    if (formatos === 1) {
      // console.log("1");
      return Imagenes.input;
    } else if (formatos === 2) {
      // console.log("2");
      return Videos.input;
    } else if (formatos === 3) {
      // console.log("3");
      return Pdf.input;
    } else if (formatos === 4) {
      // console.log("4");
      return Archivos.input;
    } else if (formatos === 5 || formatos === undefined) {
      // console.log("5");
      return Todos.input;
    }
  }
  // console.log(Archivo);
  return (
    <div style={{ width: "100%" }}>
      <div>
        <label
          htmlFor={id !== undefined ? id : "FileArchivoSingle"}
          style={{ cursor: "pointer" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <img style={{ width: "16px" }} src={Adjuntar} alt="" />
            <b>{texto !== undefined ? texto : "Adjuntar"}</b>
          </div>
          <input
            hidden={true}
            id={id !== undefined ? id : "FileArchivoSingle"}
            type="file"
            accept={`${selectFormato}`}
            onChange={(e) => {
              changeInput(e);
            }}
            style={{ display: "none" }}
          />
        </label>
      </div>
      {!!archivoValue.url && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
           className="ObejoFile"
          >
            {archivoValue.formato === "png" ||
            archivoValue.formato === "jpeg" ||
            archivoValue.formato === "jpg" ? (
              <Row
                style={{width:"100%", display:"flex", justifyContent: "space-between", alignItems:"center", cursor: "pointer" }}
               
              >
                <div>
                   <Image style={{ width: "30px", height:"30px" }} src={archivoValue.url} alt="" />
                </div>
               
                <div
                  style={{
                    width: "40%",
                    height: "20px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  onClick={() => {
                    window.open(archivoValue.url, "_blank");
                  }}
                >
                  <span>{archivoValue.name}</span>
                </div>
                <div>
                  {!!archivoValue.size &&
                    `${(archivoValue.size / 1000000).toFixed(2)} MB`}
                </div>
              </Row>
            ) : archivoValue.formato === "pdf" ? (
              <Row
                style={{width:"100%", display:"flex", justifyContent: "space-between", cursor: "pointer" }}
                onClick={() => {
                  window.open(archivoValue.url, "_blank");
                }}
              >
                <img
                  style={{ width: "7%", marginRight: "3px" }}
                  src={Icono[22]}
                />
                <div
                  style={{
                    width: "40%",
                    height: "20px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span>{archivoValue.name}</span>
                </div>
                <div>
                  {!!archivoValue.size &&
                    `${(archivoValue.size / 1000000).toFixed(2)} MB`}
                </div>
              </Row>
            ) : (
              <Row
                style={{width:"100%", display:"flex", justifyContent: "space-between", cursor: "pointer" }}
                onClick={() => {
                  window.open(archivoValue.url, "_blank");
                }}
              >
                {/* <img style={{ width: "7%", marginRight: "3px" }} src={Icono[22]} /> */}
                <OndemandVideo sx={{ color: "#148F9F" }} />
                <div
                  style={{
                    width: "40%",
                    height: "20px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span>{archivoValue.name}</span>
                </div>
                <div>
                  {!!archivoValue.size &&
                    `${(archivoValue.size / 1000000).toFixed(2)} MB`}
                </div>
              </Row>
            )}
            <img
              style={{ width: "18px", cursor: "pointer" }}
              onClick={() => {
                deleteImg();
              }}
              src={Eliminar}
              alt=""
            />
          </div>
        </div>
      )}
    </div>
  );
}
