import dayjs from "dayjs";
import "dayjs/locale/es";

export const chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };

export const getCalendarRange = () => {
  const startDate = dayjs().subtract(1, 'month').startOf('month').toISOString();
  const endDate = dayjs().add(1, 'month').endOf('month').toISOString();

  return [startDate, endDate];
}