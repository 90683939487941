import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children }) => {
	const accessToken = localStorage.getItem("token");
	const paq = localStorage.getItem("paquete");
	const paqStatus = localStorage.getItem("paqueteStatus");
	// console.log(accessToken);
	// console.log(paq);
	// console.log(paqStatus);
	// if (!!accessToken && paq !== "0" && parseInt(paqStatus) === 1) {
	// 	// console.log("VALIDO");
	// }

	return (!!accessToken && paq !== "0" && parseInt(paqStatus) === 1) ? children : <Navigate to='/' />;
};
