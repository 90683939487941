import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    IdMedico: "",
    Imagen: "",
    Logged: null,
    Nombre: "",
    Profesion: ""
};

export const infoDoctorLogSlice = createSlice({
    name: "infoDoctorLog",
    initialState,
    reducers: {

        infoDoctorLog: (state, action) => {
            const { IdMedico, Imagen, Logged, Nombre, Profesion } = action.payload;
            state.IdMedico = IdMedico;
            state.Imagen = Imagen;
            state.Logged = Logged;
            state.Nombre = Nombre;
            state.Profesion = Profesion;
        },
    }
})


export const { infoDoctorLog } = infoDoctorLogSlice.actions;

export default infoDoctorLogSlice.reducer;