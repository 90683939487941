import React, { useState, useEffect } from "react";

// Importando stylos
import "./styles/Contactos.scss";

// Importando iconos
import Agregar from "../Img/Iconos/Agregar.svg";
import CloseIcon from '@mui/icons-material/Close';
import editarcontacto from "../Img/Iconos/editarIDBlanco.svg";
import iconos from "../componentes/IconosDeSitio";
import AgregarBlanco from "../Img/Iconos/AgregarBlanco.svg";

// importando modales
import Modaldetalle from "../componentes/GeneralComponents/ModalDetalle";
import ModalAddContacto from "../componentes/GeneralComponents/ModalDetalle";
import { UseModal } from "../hooks/UseModal";

// Importando inputs y formularios
import { Input, Row, Button, Layout, Modal, Empty, Image, Avatar } from "antd";
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import SelectAntd from "../componentes/GeneralComponents/SelectAntd";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
// Importando Tarjetas para lista
import ContactoCard from "../componentes/Contactos/Contactoscard";

// Importando de libreria mui
import {
  getContacts_request,
  contactDetail_request,
  addContact_request,
  deleteContact_request,
  editContact_request,
} from "../services/contactos_services";
import { infoUser_request } from "../services/perfil_services";
import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Search } from "@mui/icons-material";
import TextAreaAntd from "../componentes/GeneralComponents/TextAreaAntd";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const { Header, Content, Footer } = Layout;

function Contactos() {
  // let data = "";
  // let infoUser = "";
  // let tipoUsuario = "";
  let data = "";
  let infoUser = "";
  let tipoUsuario = "";
  // let tipoUsuario = 1;

  //Declaracion de los inputs
  const expresiones = {
    Numeros: /^\d{1,}$/, // numeros.
    Letras: /^[ñíóáéú a-zA-Z-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
    Correo: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i, //Letras y numeros sin espacios y caracteres especiales (-,_,.)
  };

  // Modales useState
  const [isOpenModalAddContacto, openModalAddContacto, CloseModalAddContacto] =
    UseModal(false);
  const [isOpenDetalleCont, openDetalleCont, CloseDetalleCont] =
    UseModal(false);

  const [medicosList, setMedicosList] = useState([]);
  const [pacientesList, setPacientesList] = useState([]);
  const [AsistentesList, setAsistentesList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [contactListFilter, setContactListFilter] = useState([]);
  const [infocontacto, setinfocontacto] = useState("");
  const [idContacto, setIdContacto] = useState("");

  const [openDetail, setOpenDetail] = useState(0);

  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  // Declaracion de Pestañas
  const [toggleState, setToggleState] = useState(1);

  //VARIABLES DETALLE Y EDITAR CONTACTO
  const [ProfileImage, setProfileImage] = useState({ name: "", url: "", file: "" });
  const [Tipocontacto, setTipocontacto] = useState(null);
  const [Nombre, setNombre] = useState("");
  const [ApellidoP, setApellidoP] = useState("");
  const [ApellidoM, setApellidoM] = useState("");
  const [Celular, setCelular] = useState("");
  const [Correo, setCorreo] = useState("");
  const [Especialidad, setEspecialidad] = useState("");
  const [NotaContacto, setNotaContacto] = useState("");

  //VARIABLES MODAL ADD CONTACTO
  const [AddProfileImage, setAddProfileImage] = useState({ name: "", url: "", file: "" });
  const [AddTipocontacto, setAddTipocontacto] = useState(null);
  const [AddNombre, setAddNombre] = useState("");
  const [AddApellidoP, setAddApellidoP] = useState("");
  const [AddApellidoM, setAddApellidoM] = useState("");
  const [AddCelular, setAddCelular] = useState("");
  const [AddCorreo, setAddCorreo] = useState("");
  const [AddEspecialidad, setAddEspecialidad] = useState("");
  const [AddNotaContacto, setAddNotaContacto] = useState("");

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  const [inputsEditable, setInputsEditable] = useState(true);

  const [FileName, setFileName] = useState("");
  const [File, setFile] = useState("");
  const [imageFile, setImageFile] = useState("");

  const [TipoUser, setTipoUser] = useState("");

  const [comboAddTipo, setComboAddTipo] = useState("true");

  const [MensajeNombre, setMensajeNombre] = useState("true");
  const [MensajeApellidoP, setMensajeApellidoP] = useState("true");
  const [MensajeApellidoM, setMensajeApellidoM] = useState("true");
  const [MensajeCelular, setMensajeCelular] = useState("true");
  const [MensajeCorreo, setMensajeCorreo] = useState("true");
  const [MensajeEspecialidad, setMensajeEspecialidad] = useState("true");

  const [MensajeAddNombre, setMensajeAddNombre] = useState("true");
  const [MensajeAddApellidoP, setMensajeAddApellidoP] = useState("true");
  const [MensajeAddApellidoM, setMensajeAddApellidoM] = useState("true");
  const [MensajeAddCelular, setMensajeAddCelular] = useState("true");
  const [MensajeAddCorreo, setMensajeAddCorreo] = useState("true");
  const [MensajeAddEspecialidad, setMensajeAddEspecialidad] = useState("true");

  // ******* FUNCIONES *******

  const saveContact = async (e) => {
    e.preventDefault();
    setLoaderModal(true);
    let data = new FormData();
    // setTimeout(() => {
    // console.log("ESPERANDO...");
    // },5000);
    // console.log("SALIO");
    // setLoaderModal(false);
    // console.log("PASO");
    // return;
    if (AddTipocontacto === "1") {
      if (
        (AddTipocontacto !== "" &&
          !!AddNombre && MensajeAddNombre === "true" &&
          !!AddApellidoP && MensajeAddApellidoP === "true" &&
          !!AddEspecialidad && MensajeAddEspecialidad === "true" &&
          MensajeAddApellidoM === "true" &&
          MensajeAddCelular === "true" &&
          MensajeAddCorreo === "true"
        )
      ) {
        data.append("Nombre", AddNombre);
        data.append("ApellidoPaterno", AddApellidoP);
        data.append("ApellidoMaterno", AddApellidoM);
        data.append("Celular", AddCelular);
        data.append("Email", AddCorreo);
        data.append("Tipo", AddTipocontacto === "1" ? "Médico" : "Paciente");
        data.append("especialidad", AddEspecialidad);
        data.append("Nota", AddNotaContacto);
        if (AddProfileImage.file !== "") {
          data.append("Imagen", AddProfileImage.file, AddProfileImage.name);
        }
        let response = await addContact_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Contacto guardado");
          getContactos();
          CloseModalAddContacto(false);
          clean_inputsAdd();
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
        if (!!!AddNombre) {
          setMensajeAddNombre("false");
        }

        if (!!!AddApellidoP) {
          setMensajeAddApellidoP("false");
        }

        if (!!!AddTipocontacto) {
          setComboAddTipo("false");
        }
      }
    } else {
      if (
        (AddTipocontacto !== "" &&
          !!AddNombre && MensajeAddNombre === "true" &&
          !!AddApellidoP && MensajeAddApellidoP === "true" &&
          MensajeAddApellidoM === "true" &&
          MensajeAddCelular === "true" &&
          MensajeAddCorreo === "true"
        )
      ) {
        data.append("Nombre", AddNombre);
        data.append("ApellidoPaterno", AddApellidoP);
        data.append("ApellidoMaterno", AddApellidoM);
        data.append("Celular", AddCelular);
        data.append("Email", AddCorreo);
        data.append("Tipo", AddTipocontacto === "1" ? "Médico" : "Paciente");
        data.append("especialidad", AddEspecialidad);
        data.append("Nota", AddNotaContacto);
        if (AddProfileImage.file !== "") {
          data.append("Imagen", AddProfileImage.file, AddProfileImage.name);
        }
        let response = await addContact_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Contacto guardado");
          getContactos();
          CloseModalAddContacto(false);
          clean_inputsAdd();
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
        if (!!!AddNombre) {
          setMensajeAddNombre("false");
        }

        if (!!!AddApellidoP) {
          setMensajeAddApellidoP("false");
        }

        if (!!!AddTipocontacto) {
          setComboAddTipo("false");
        }
      }
    }

    setLoaderModal(false);
    return;
  };

  async function getContactos() {
    setLoader(true);
    let response = await getContacts_request();
    let pacientes = [];
    let medicos = [];
    let asistentes = [];
    if (response.ok) {
      // console.log(response.data);
      setContactList(response.data);
      setContactListFilter(response.data);

      response.data.forEach((contacto) => {
        if (contacto.Type === 3) {
          pacientes.push(contacto);
        } else if (contacto.Type === 1) {
          medicos.push(contacto);
        } else if (contacto.Type === 4) {
          asistentes.push(contacto);
        }
      });

      setMedicosList(medicos);
      setPacientesList(pacientes);
      setAsistentesList(asistentes);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function contactDetail(id, option) {
    // console.log(option);
    // console.log(id);
    // console.log(idContacto);
    // return;
    setLoaderModal(true);

    let response = await contactDetail_request(option === 1 ? id : idContacto);
    if (response.ok) {
      if (document.domain !== "ygia.app") {
        console.log(response.data);
      }
      setinfocontacto(response.data);
      setTipocontacto(response.data.Type.toString());
      setNombre(response.data.Nombre);
      setApellidoP(response.data.ApellidoPaterno);
      setApellidoM(response.data.ApellidoMaterno);
      setCelular(response.data.Celular);
      setCorreo(response.data.Email);
      openDetalleCont(true);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderModal(false);
    return;
  }

  async function deleteContact() {
    setLoader(true);

    let response = await deleteContact_request(idContacto);
    if (response.ok) {
      // console.log(response.data);
      getContactos();
      setOpen(true);
      setTypeMessage("success");
      setMessage("Contacto eliminado");
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }

    setLoader(false);
    return;
  }

  async function getInfoUser(info) {
    // localStorage.removeItem("token");
    // setLoader(true);
    setTipoUser(info.Tipo);
    // setLoader(false);
    return;
  }

  function validateInputs(validar) {
    setNombre(infocontacto.Nombre);
    setMensajeNombre("true");
    setApellidoP(infocontacto.ApellidoPaterno);
    setMensajeApellidoP("true");
    setApellidoM(infocontacto.ApellidoMaterno);
    setMensajeApellidoM("true");
    setCelular(infocontacto.Celular);
    setMensajeCelular("true");
    setCorreo(infocontacto.Email);
    setMensajeCorreo("true");
    // if (validar === 0) {
    //   setNombre(infocontacto.Nombre);
    //   setMensajeNombre("true");
    //   setApellidoP(infocontacto.ApellidoPaterno);
    //   setMensajeApellidoP("true");
    //   setApellidoM(infocontacto.ApellidoMaterno);
    //   setMensajeApellidoM("true");
    //   setCelular(infocontacto.Celular);
    //   setMensajeCelular("true");
    //   setCorreo(infocontacto.Email);
    //   setMensajeCorreo("true");
    // } else {
    //   setNombre({
    //     campo: infocontacto.Nombre,
    //     valido: !!infocontacto.Nombre ? "true" : null,
    //   });
    //   setApellidoP({
    //     campo: infocontacto.ApellidoPaterno,
    //     valido: !!infocontacto.ApellidoPaterno ? "true" : null,
    //   });
    //   setApellidoM({
    //     campo: infocontacto.ApellidoMaterno,
    //     valido: !!infocontacto.ApellidoMaterno ? "true" : null,
    //   });
    //   setCelular({
    //     campo: infocontacto.Celular,
    //     valido: !!infocontacto.Celular ? "true" : null,
    //   });
    //   setCorreo({
    //     campo: infocontacto.Email,
    //     valido: !!infocontacto.Email ? "true" : null,
    //   });
    // }
  }

  function clean_inputs() {
    setFile("");
    setTipocontacto(null);
    setNombre("");
    setApellidoP("");
    setApellidoM("");
    setCelular("");
    setCorreo("");
    setProfileImage({ name: "", file: "", url: "" })
  }

  function clean_inputsAdd() {

    setAddProfileImage({ name: "", url: "", file: "" });
    setAddTipocontacto(null);
    setAddNombre("");
    setAddApellidoP("");
    setAddApellidoM("");
    setAddCelular("");
    setAddCorreo("");
    setComboAddTipo("true");
  }

  function filterList(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;

    let filterPaciente = [];
    let filterMedico = [];
    let filterAsistente = [];
    contactList.forEach((contacto) => {
      // if (
      //   contacto.Nombre.replace(expr, function (e) {
      //     return chars[e];
      //   })
      //     .toLowerCase()
      //     .indexOf(
      //       text.target.value
      //         .replace(expr, function (e) {
      //           return chars[e];
      //         })
      //         .toLowerCase()
      //     ) > -1 ||
      //   contacto.ApellidoPaterno.replace(expr, function (e) {
      //     return chars[e];
      //   })
      //     .toLowerCase()
      //     .indexOf(
      //       text.target.value
      //         .replace(expr, function (e) {
      //           return chars[e];
      //         })
      //         .toLowerCase()
      //     ) > -1 ||
      //   contacto.ApellidoMaterno.replace(expr, function (e) {
      //     return chars[e];
      //   })
      //     .toLowerCase()
      //     .indexOf(
      //       text.target.value
      //         .replace(expr, function (e) {
      //           return chars[e];
      //         })
      //         .toLowerCase()
      //     ) > -1
      // ) 
      if (!!contacto.Email && !!contacto.Celular) {
        if (`${contacto.Nombre} ${contacto.ApellidoPaterno}${!!contacto.ApellidoMaterno ? !!contacto.ApellidoMaterno.trim() ? ` ${contacto.ApellidoMaterno}` : "" : ""}`.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
          contacto.Email.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
          contacto.Celular.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1) {
          if (contacto.Type === 3) {
            filterPaciente.push(contacto);
          } else if (contacto.Type === 1) {
            filterMedico.push(contacto);
          } else if (contacto.Type === 4) {
            filterAsistente.push(contacto);
          }
        }
      } else {
        if (!!contacto.Email) {
          if (`${contacto.Nombre} ${contacto.ApellidoPaterno}${!!contacto.ApellidoMaterno ? !!contacto.ApellidoMaterno.trim() ? ` ${contacto.ApellidoMaterno}` : "" : ""}`.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
            contacto.Email.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1) {
            if (contacto.Type === 3) {
              filterPaciente.push(contacto);
            } else if (contacto.Type === 1) {
              filterMedico.push(contacto);
            } else if (contacto.Type === 4) {
              filterAsistente.push(contacto);
            }
          }
        }

        if (!!contacto.Celular) {
          if (`${contacto.Nombre} ${contacto.ApellidoPaterno}${!!contacto.ApellidoMaterno ? !!contacto.ApellidoMaterno.trim() ? ` ${contacto.ApellidoMaterno}` : "" : ""}`.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
            contacto.Celular.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1) {
            if (contacto.Type === 3) {
              filterPaciente.push(contacto);
            } else if (contacto.Type === 1) {
              filterMedico.push(contacto);
            } else if (contacto.Type === 4) {
              filterAsistente.push(contacto);
            }
          }
        }

      }
    });

    setPacientesList(filterPaciente);
    setMedicosList(filterMedico);
    setAsistentesList(filterAsistente);
  }

  const editContact = async (e) => {
    e.preventDefault();
    setLoaderModal(true);
    // if (!!Correo) {
    // console.log("CON CORREO");
    // } else {
    // console.log("VACIO");
    // }
    // return;
    if (!!Tipocontacto &&
      !!Nombre && MensajeNombre === "true" &&
      !!ApellidoP && MensajeApellidoP === "true" &&
      MensajeApellidoM === "true" &&
      MensajeCelular === "true" &&
      MensajeCorreo === "true"
    ) {
      let data = new FormData();
      data.append("Nombre", Nombre);
      data.append("ApellidoPaterno", ApellidoP);
      data.append("ApellidoMaterno", !!ApellidoM ? ApellidoM : "");
      data.append("Celular", !!Celular ? Celular : "");
      data.append("Email", !!Correo ? Correo : "");
      data.append("Tipo", Tipocontacto === "1" ? "Médico" : "Paciente");
      data.append("Nota", NotaContacto);
      if (!!FileName) {
        data.append("Imagen", imageFile, FileName);
      }

      let response = await editContact_request(data, infocontacto.IdContacto);
      if (response.ok) {
        getContactos();
        setOpen(true);
        setTypeMessage("success");
        setMessage("Contacto editado");
        clean_inputs();
        setOpenDetail(0);
        setInputsEditable(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Nombre) {
        setMensajeNombre("false");
      }

      if (!!!ApellidoP) {
        setMensajeApellidoP("false");
      }

      if (!!!Tipocontacto) {
        setComboAddTipo("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderModal(false);
    return;
  };

  useEffect(() => {
    // alert(`cargando cosas ${navigator.userAgent}`);
    // data = JSON.parse(sessionStorage.getItem("infoUser"));
    // infoUser = data;
    // tipoUsuario = infoUser.tipo;
    // setTipoUser(infoUser.tipo);
    // console.log(tipoUsuario);
    let dataInit = JSON.parse(sessionStorage.getItem("infoUser"));
    getInfoUser(dataInit);
    getContactos();
    // data = JSON.parse(sessionStorage.getItem("infoUser"));
    // infoUser = data;
    // tipoUsuario = infoUser.tipo;
    // if (navigator.userAgent.match(/Windows/i)
    //   || navigator.userAgent.match(/macintosh/i)) {

    // }
  }, []);

  return (
    <Stack>
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        modulo={"Contactos"}
        searchBar={
          <>
            <div className="Encabezadocontacto">
              <div className="espaciado">
                <div>
                  <b>Contactos</b>
                </div>
                <ButtonAntd
                  tipo={1}
                  style={{ width: "100px" }}
                  onClick={() => {
                    openModalAddContacto(true);
                    clean_inputsAdd();
                  }}

                  children={
                    <Row style={{ gap: ".5rem", width: "100%", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                      Nuevo
                      <img style={{ width: "12px" }} src={AgregarBlanco} alt="" />
                    </Row>
                  }
                />
              </div>
              <Input
                prefix={<Search style={{ color: "#d7d7d7", width: "18px" }} />}
                style={{ width: "31%" }}
                placeholder="Nombre, correo, celular"
                type="text"
                onChange={filterList}
              />
            </div>
            <div className="botones">
              <div
                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                onClick={() => setToggleState(1)}
              >
                <b>Pacientes</b>
              </div>
              <div
                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                onClick={() => setToggleState(2)}
              >
                <b>Médicos</b>
              </div>
              {TipoUser === 1 && (
                <div
                  className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                  onClick={() => setToggleState(3)}
                >
                  <b>Asistentes</b>
                </div>
              )}
            </div>
          </>
        } />

      <Content
        className="site-layout"
        style={{
          padding: '0 3%',
          width: "100%",
          overflow: 'initial',
        }}
      >

        {loader ? (
          <Loader />
        ) : (
          <>
            {toggleState === 1 && (
              <>
                <ContactoCard
                  openDetalleCont={openDetalleCont}
                  setOpenDetail={setOpenDetail}
                  lista={pacientesList}
                  contactDetail={contactDetail}
                  deleteContact={deleteContact}
                  setIdContacto={setIdContacto}
                  setInputsEditable={setInputsEditable}
                  validateInputs={validateInputs}
                />
                {pacientesList.length === 0 && (
                  <Empty description={<span style={{ color: "black" }}> No se encontraron pacientes</span>} />

                )}
              </>
            )}

            {toggleState === 2 && (
              <>
                <ContactoCard
                  openDetalleCont={openDetalleCont}
                  setOpenDetail={setOpenDetail}
                  lista={medicosList}
                  contactDetail={contactDetail}
                  deleteContact={deleteContact}
                  setIdContacto={setIdContacto}
                  setInputsEditable={setInputsEditable}
                  validateInputs={validateInputs}
                />
                {medicosList.length === 0 && (
                  <Empty description={<span style={{ color: "black" }}> No se encontraron médicos</span>} />

                )}
              </>
            )}

            {toggleState === 3 && (
              <>
                <ContactoCard
                  openDetalleCont={openDetalleCont}
                  setOpenDetail={setOpenDetail}
                  lista={AsistentesList}
                  contactDetail={contactDetail}
                  deleteContact={deleteContact}
                  setIdContacto={setIdContacto}
                  setInputsEditable={setInputsEditable}
                  validateInputs={validateInputs}
                />
                {AsistentesList.length === 0 && (
                  <Empty description={<span style={{ color: "black" }}>No se encontraron asistentes</span>} />

                )}
              </>
            )}
          </>
        )}
      </Content>

      <Modaldetalle
        isOpen={isOpenDetalleCont}
        closeModal={CloseDetalleCont}
      >
        <>
          {loaderModal ? (
            <LoaderModals className={"ModalConsultasStyle"} />
          ) : (
            <div>
              <div
                style={{
                  width: "100%",
                  background: "#148f9f",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1% 2%",
                }}
              >

                Detalle de contacto
                <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }}
                  onClick={() => {
                    validateInputs(0);
                    CloseDetalleCont(true);
                    setInputsEditable(true);
                    setinfocontacto("");
                    clean_inputs();

                  }} />
              </div>
              <div className="ModalConsultasStyle">
                <header style={{ position: "sticky", top: "0", zIndex: "999", display: "flex", justifyContent: "end" }}>
                  {inputsEditable && Tipocontacto !== "4" ? (
                    <Button className="GeneralBoton" onClick={() => {
                      setInputsEditable(false);
                      validateInputs(1);
                    }}>
                      <img
                        style={{ cursor: "pointer", width: "15px" }}
                        src={editarcontacto}

                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file && file.type.substring(0, 5) === "image") {
                            setProfileImage({
                              name: e.target.value,
                              url: URL.createObjectURL(e.target.files[0]),
                              file: e.target.files[0],
                            });
                          } else {
                            setOpen(true);
                            setTypeMessage("error");
                            setMessage("Tipo de archivo no válido");
                          }
                        }}
                      />
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </header>
                {/* <div className="encabezadodetallecontacto"> */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Row>
                    <label htmlFor="imagenContacto1">
                      {(inputsEditable === true ? (<div>
                        <Avatar size={70} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className='GeneralNoImage' src={
                          <Image
                            // className="imagencont"
                            src={
                              !!ProfileImage.file
                                ? ProfileImage.url
                                : !!infocontacto.Imagen_url
                                  ? "https://" + infocontacto.Imagen_url
                                  : iconos[19]
                            }
                          />} />
                      </div>) : (<div>
                        <Avatar size={70} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className='GeneralNoImage' src={
                          <img
                            // className="imagencont"
                            src={
                              !!ProfileImage.file
                                ? ProfileImage.url
                                : !!infocontacto.Imagen_url
                                  ? "https://" + infocontacto.Imagen_url
                                  : iconos[19]
                            }
                          />} />
                      </div>))}
                    </label>
                    {(infocontacto.YgiaUser === "Sin Ygia" &&
                      !inputsEditable) && (
                        <input
                          type="file"
                          id="imagenContacto1"
                          accept="/image/x-PNG,image/jpeg"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file && file.type.substring(0, 5) === "image") {
                              setProfileImage({
                                name: e.target.value,
                                url: URL.createObjectURL(e.target.files[0]),
                                file: e.target.files[0],
                              });
                            }
                          }}
                        />
                      )}



                  </Row>
                </div>
                {/* </div> */}
                {/* <form onSubmit={editContact}> */}
                <div>
                  {/* {Tipocontacto === "4" || infocontacto.YgiaUser === "Con Ygia" ? (
                    <InputAntd

                      value="Asistente"
                      label="Tipo de contacto:"
                      style={{ margin: "1% 0" }}
                      placeholder="Tipo de contacto"
                      type="text"
                      vacio={true}
                      readOnly={true}
                    />
                  ) : ( */}
                  <p style={{ textAlign: "center" }}>
                    {"Usuario " + infocontacto.YgiaUser}
                  </p>

                  <SelectAntd
                    label="Tipo de contacto:"
                    value={Tipocontacto}
                    style={{ width: "100%", margin: "1% 0" }}
                    placeholder="Tipo de contacto"
                    options={[
                      { value: "1", label: "Médico" },
                      { value: "3", label: "Paciente" },
                    ]}
                    setStateValue={setTipocontacto}
                    disabled={Tipocontacto === "4" || infocontacto.YgiaUser === "Con Ygia" ? true : inputsEditable}
                  />
                  {/* // )} */}
                  <InputAntd

                    value={Nombre}
                    setStateValue={setNombre}
                    label="Nombre:"
                    style={{ margin: "1% 0" }}
                    placeholder="Escribe tu nombre"
                    setStateError={setMensajeNombre}
                    textError={["Debe escribir un nombre", "Solo se permiten letras (max. 20 caracteres)"]}
                    type="text"
                    valueError={MensajeNombre}
                    vacio={false}
                    disabled={inputsEditable}
                    expresionRegular={expresiones.Letras}
                    maxLength={40}
                  />

                  <InputAntd

                    value={ApellidoP}
                    setStateValue={setApellidoP}
                    label="Apellido paterno:"
                    style={{ margin: "1% 0" }}
                    placeholder="Escribe tu primer apellido"
                    setStateError={setMensajeApellidoP}
                    textError={["Debe escribir un apellido", "Solo se permiten letras (max. 20 caracteres)"]}
                    type="text"
                    valueError={MensajeApellidoP}
                    vacio={false}
                    disabled={inputsEditable}
                    expresionRegular={expresiones.Letras}
                    maxLength={20}
                  />

                  <InputAntd

                    value={ApellidoM}
                    setStateValue={setApellidoM}
                    label="Apellido materno:"
                    style={{ margin: "1% 0" }}
                    placeholder="Escribe tu segundo apellido"
                    setStateError={setMensajeApellidoM}
                    textError={["", "Solo se permiten letras (max. 20 caracteres)"]}
                    type="text"
                    valueError={MensajeApellidoM}
                    vacio={true}
                    disabled={inputsEditable}
                    expresionRegular={expresiones.Letras}
                    maxLength={20}
                  />

                  <InputAntd

                    value={Celular}
                    setStateValue={setCelular}
                    label="Celular:"
                    id="celular"
                    style={{ margin: "1% 0" }}
                    placeholder="Escribe tu celular"
                    setStateError={setMensajeCelular}
                    textError={["", "Celular solo puede contener numeros", "Celular debe contener 10 dígitos"]}
                    type="text"
                    valueError={MensajeCelular}
                    vacio={true}
                    disabled={inputsEditable}
                    expresionRegular={expresiones.Numeros}
                    maxLength={10}
                  />

                  <InputAntd

                    value={Correo}
                    setStateValue={setCorreo}
                    label="Correo:"
                    style={{ margin: "1% 0" }}
                    placeholder="Escribe tu correo eléctronico"
                    setStateError={setMensajeCorreo}
                    textError={["", "Formato incorrecto"]}
                    type="text"
                    valueError={MensajeCorreo}
                    vacio={true}
                    disabled={inputsEditable}
                    expresionRegular={expresiones.Correo}
                  />

                  <TextAreaAntd
                    label="Comentarios o notas:"
                    value={AddNotaContacto}
                    placeholder="Escriba un comentario o nota (opcional)"
                    style={{
                      marginBottom: ".5%",
                      fontFamily: "sans-serif",
                    }}
                    columns="30"
                    autoSize={true}
                    setStateValue={setAddNotaContacto}
                  />

                </div>
                {!inputsEditable && (
                  <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "20px" }}>
                    {/* <Button
                      className="btn2cont"
                      onClick={() => {
                        validateInputs(0);
                        setInputsEditable(true);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button type="button" onClick={(e) => { editContact(e) }}>Guardar cambios</Button> */}
                    <Button
                      style={{
                        background: "white",
                        border: "1",
                        borderColor: "#148F9F",
                        color: "#148F9F",
                        width: "40%",
                      }}
                      onClick={() => {
                        validateInputs(0);
                        setInputsEditable(true);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      style={{
                        background: "#148F9F",
                        border: "#148F9F",
                        width: "40%",
                        color: "white"
                      }}
                      onClick={(e) => { editContact(e) }}
                    >
                      Guardar
                    </Button>
                  </div>
                )}
                {/* </form> */}
              </div>
            </div>
          )}
        </>
      </Modaldetalle>

      <ModalAddContacto
        styleDet={styleDet}
        isOpen={isOpenModalAddContacto}
        closeModal={CloseModalAddContacto}
        optionClose={false}
      // setComboAddTipo={setComboAddTipo}
      >
        <div className="TopNuevoCotacto">
          Nuevo contacto
          <CloseIcon style={{ cursor: "pointer", fontSize: "20" }}
            onClick={CloseModalAddContacto}
          />
        </div>
        <div className="MidModalAddContracto">
          {loaderModal ? (
            <LoaderModals />
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <label htmlFor="imagenContacto2">
                  <img
                    style={{ cursor: "pointer" }}
                    className="imagencont"
                    src={!!AddProfileImage.url ? AddProfileImage.url : iconos[19]}
                  />
                </label>
                <input
                  type="file"
                  id="imagenContacto2"
                  accept="/image/x-PNG,image/jpeg"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.type.substring(0, 5) === "image") {
                      setAddProfileImage({
                        name: e.target.value,
                        url: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0],
                      });
                    } else {
                      setOpen(true);
                      setTypeMessage("error");
                      setMessage("Tipo de archivo no válido");
                    }
                  }}
                />
              </div>

              <SelectAntd
                label="Tipo de contacto:"
                value={AddTipocontacto}
                style={{ width: "100%" }}
                placeholder="Selecciona un tipo"
                options={[
                  { value: "1", label: "Médico" },
                  { value: "3", label: "Paciente" },
                ]}
                setStateValue={setAddTipocontacto}
                disabled={false}
              />

              <InputAntd
                value={AddNombre}
                setStateValue={setAddNombre}
                label="Nombre:"
                style={{ margin: "2% 0" }}
                placeholder="Escribe tu nombre"
                setStateError={setMensajeAddNombre}
                textError={["Debe escribir un nombre", "Solo se permiten letras (max. 20 caracteres)"]}
                type="text"
                valueError={MensajeAddNombre}
                vacio={false}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={40}
              />

              <InputAntd
                className="inputblue"
                value={AddApellidoP}
                setStateValue={setAddApellidoP}
                label="Apellido Paterno:"
                style={{ margin: "2% 0" }}
                placeholder="Escribe tu primer apellido"
                setStateError={setMensajeAddApellidoP}
                textError={["Debe escribir un apellido paterno", "Solo se permiten letras (max. 20 caracteres)"]}
                type="text"
                valueError={MensajeAddApellidoP}
                vacio={false}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={20}
              />

              <InputAntd
                className="inputblue"
                value={AddApellidoM}
                setStateValue={setAddApellidoM}
                label="Apellido Materno:"
                style={{ margin: "2% 0" }}
                placeholder="Escribe tu segundo apellido"
                setStateError={setMensajeAddApellidoM}
                textError={["", "Solo se permiten letras (max. 20 caracteres)"]}
                type="text"
                valueError={MensajeAddApellidoM}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={20}
              />

              {AddTipocontacto === "1" && (
                <InputAntd

                  value={AddEspecialidad}
                  setStateValue={setAddEspecialidad}
                  label="Especialidad:"
                  style={{ margin: "2% 0" }}
                  placeholder="Escriba una especialidad"
                  setStateError={setMensajeAddEspecialidad}
                  textError={["Debe escribir una especialidad", "Solo se permiten letras (max. 20 caracteres)"]}
                  type="text"
                  valueError={MensajeAddEspecialidad}
                  vacio={false}
                  disabled={false}
                  expresionRegular={expresiones.Letras}
                  maxLength={30}
                />
              )}

              <InputAntd

                value={AddCelular}
                setStateValue={setAddCelular}
                label="Celular"
                id="celular"
                style={{ margin: "2% 0" }}
                placeholder="Escribe tu celular"
                setStateError={setMensajeAddCelular}
                textError={["", "Celular solo puede contener numeros", "Celular debe contener 10 dígitos"]}
                type="text"
                valueError={MensajeAddCelular}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Numeros}
                maxLength={10}
              />

              <InputAntd

                value={AddCorreo}
                setStateValue={setAddCorreo}
                label="Correo"
                style={{ margin: "2% 0" }}
                placeholder="Escribe tu correo eléctronico"
                setStateError={setMensajeAddCorreo}
                textError={["", "Formato incorrecto"]}
                type="text"
                valueError={MensajeAddCorreo}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Correo}
              />

              <TextAreaAntd
                label="Comentarios o notas:"
                value={AddNotaContacto}
                placeholder="Escriba un comentario o nota (opcional)"
                style={{
                  marginBottom: ".5%",
                  fontFamily: "sans-serif",
                }}
                columns="30"
                autoSize={true}
                setStateValue={setAddNotaContacto}
              />

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    background: "#148F9F",
                    border: "#148F9F",
                    width: "40%",
                    color: "white",
                    marginTop: "20px"
                  }}
                  onClick={(e) => { saveContact(e); }}
                >
                  Guardar
                </Button>
              </div>
            </>
          )}
        </div>

      </ModalAddContacto>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

    </Stack>
  );
}

export default Contactos;
