import React, { useEffect, useState, useRef } from "react";
import { Avatar, Image } from "antd";

import PositionedMenu from '../componentes/Citas/menuvertical.js';

// Estilos de citas y consultas
import "./styles/Citas.scss";
import "./styles/Consultas.scss";

import { useNavigate, useLocation } from "react-router-dom";

// Libreria de formularios
import {
  Label,
  ContenedorBotonCentrado,
  LeyendaError,
} from "../componentes/GeneralComponents/Formularios2Grids";

import InputCustome from "../componentes/GeneralComponents/Input";

import {
  Input,
  Select,
  TimePicker,
  Row,
  Button,
  Calendar,
  Layout,
  DatePicker,
  Modal,
  Col,
  Tabs,
  Radio,
  Empty,
} from "antd";
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import SelectAntd from "../componentes/GeneralComponents/SelectAntd";
import TextAreaAntd from "../componentes/GeneralComponents/TextAreaAntd";
import Accordionn from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Campos from "../componentes/GeneralComponents/campos";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import DatePickerAntd from "../componentes/GeneralComponents/DatePickerAntd";
import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import TextArea from "antd/lib/input/TextArea";
import Cards from "../componentes/Citas/CitasCard";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";

// hooks de funcionamiento del modal
import { UseModal } from "../hooks/UseModal";

// Importando modales
import ModalCitas1 from "../componentes/GeneralComponents/ModalDetalle";
import ModalCitasCalendar from "../componentes/GeneralComponents/ModalDetalle";
import ModalCitasCalendarPropuesta from "../componentes/GeneralComponents/ModalDetalle";
import Addcitas from "../componentes/Citas/ModalCitasAdd";
import ModalDetalle from "../componentes/GeneralComponents/ModalDetalle";
import ModalCancelCita from "../componentes/GeneralComponents/ModalDetalle";
import ModalFiltro from "../componentes/GeneralComponents/ModalFiltro";
import ModalTipoUsuario from "../componentes/GeneralComponents/ModalDetalle";
import ModalAddContacto from "../componentes/GeneralComponents/ModalDetalle";
import ModalAddNota from "../componentes/GeneralComponents/ModalDetalle";
import ModalEditContacto from "../componentes/GeneralComponents/ModalDetalle";
import ModalFicha from "../componentes/GeneralComponents/ModalDetalle";
import ModalAddCitas from "../componentes/GeneralComponents/ModalDetalle";
import ModalAlertConfirm from "../componentes/GeneralComponents/ModalDetalle";

// importando iconos
import CloseIcon from "@mui/icons-material/Close";
import Confi from "../Img/Iconos/Configuracion.svg";
import Confi_white from "../Img/Iconos/Configuracion_white.svg";
import Agregarcitas from "../Img/Iconos/AgregarBlanco.svg";
import Filtrocitas from "../Img/Iconos/Filtro.svg";
import Iconos from "../componentes/IconosDeSitio";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  ExclamationCircleOutlined,
  EyeFilled,
  EyeOutlined,
} from "@ant-design/icons";
import AddIcon from "@mui/icons-material/AddCircle";
import {
  BorderColor,
  Cancel,
  CancelOutlined,
  CancelRounded,
  CheckCircleOutline,
  Edit,
  Search,
  AddOutlined,
  EditOutlined,
  DeleteForeverOutlined
} from "@mui/icons-material";

// importando imagenes
import Imag from "../Img/no-image.png";
import CancelarCita from "../Img/Iconos/cancelarcita.svg";
import Editarcita from "../Img/Iconos/Editarcita.svg";
import CheckGuardar from "../Img/Iconos/checkGuardar.svg";
import EliminarCita from "../Img/Iconos/EliminarCita.svg";
import {
  getCitas_request,
  detailCitas_request,
  addCita_request,
  cancelCitas_request,
  deleteCitas_request,
  getHorarios_request,
  reprogramarCita_request,
  getConfigCitas_request,
  getCitasFilter_request,
  atenderCitas_request,
  confirmCitas_request,
  getCitasDisponibles_request,
} from "../services/citas_services";
import {
  getContacts_request,
  addContact_request,
  contactDetail_request,
  editContact_request,
} from "../services/contactos_services";
import { addExpediente_request } from "../services/expediente_service";
import { getHistoriasClinicas_request } from "../services/expediente_service";
import {
  getEstado_request,
  getLocalidad_request,
} from "../services/expediente_service";

import dayjs from "dayjs";
import "dayjs/locale/es";
import locale from "antd/es/calendar/locale/es_ES";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CitasAgendadasCard from "../componentes/Citas/CitasAgendadasCard";
import { useSelector, useDispatch } from 'react-redux';

import ReactGA from "react-ga4";
import { navBarProps } from "../redux/navBarProps";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from 'moment';
import { showSnackbar } from "../redux/snackbarSlice";
import { getAuthFirebase_request } from "../services/notificaciones_services";
import Agenda from "../componentes/GeneralComponents/calendarioCitas";

import { chars, getCalendarRange } from '../utils'
import CalendarPage from "../componentes/GeneralComponents/googleCalendar";
import { SetFechaInicial, SetFechaFinal, SetCalendarDate } from '../redux/citas';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const { Header, Content, Footer } = Layout;
const { RangePicker } = DatePicker;

function Citas() {
  // ******** VARIABLES ********
  const dispatch = useDispatch();
  const location = useLocation();

  const userInfo = useSelector((state) => state.userInfo);
  const { fechaInicio, fechaFinal } = useSelector((state) => state.appointments);

  let fecha_format = new Date(Date.now());
  let mes = fecha_format.getMonth() + 1;
  let mes_correcto = mes.toString().length === 1 ? `0${mes}` : mes;
  let fecha_hoy =
    fecha_format.getFullYear() +
    "-" +
    mes_correcto +
    "-" +
    fecha_format.getDate();

  const hoy = new Date();
  const primerDia = new Date(hoy.setDate(hoy.getDate() - hoy.getDay() + 1)); // Ajustar a 1 para lunes, use 0 para domingo
  const ultimoDia = new Date(hoy.setDate(hoy.getDate() - hoy.getDay() + 7)); // Ajustar a 7 para domingo

  const diaInicio = primerDia.toLocaleDateString('es-ES', { year: "numeric", month: "2-digit", day: "2-digit" });
  const diaFin = ultimoDia.toLocaleDateString('es-ES', { year: "numeric", month: "2-digit", day: "2-digit" });

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const styleFichaContacto = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const styleCancelMotivo = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  // Expresiones
  const expresiones = {
    Numeros: /^\d{1,}$/, // numeros.
    // telefono: /^\d{1,10}$/, // 7 a 14 numeros.
    // telefono: /^[0-9]{1,10}$/, // numeros.
    Letras: /^[ñíóáéú a-zA-Z-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
    Correo: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i, //Letras y numeros sin espacios y caracteres especiales (-,_,.)
  };

  // ******* USE STATES *******

  // Modales de citas
  const [isOpenModalcita1, OpenModalcita1, CloseModalcita1] = UseModal(false);
  const [isOpenModalFiltro, openModalFiltro, CloseModalFiltro] = UseModal(false);
  const [
    isOpenModalcitaCalendar,
    openModalcitaCalendar,
    CloseModalcitaCalendar,
  ] = UseModal(false);
  const [isOpenModalDetalle, openModalDetalle, CloseModalDetalle] = UseModal(false);
  const [IsOpenTipoUsuario, OpenTipoUsuario, ClosedTipoUsuario] = UseModal(false);
  const [IsOpenAddContacto, OpenAddContacto, ClosedAddContacto] = UseModal(false);
  const [isOpenModalAddNota, OpenModalAddNota, CloseModalAddNota] = UseModal(false);
  const [isOpenEditContacto, openEditContacto, CloseEditContacto] = UseModal(false);
  const [isOpenFichaContacto, openFichaContacto, CloseFichaContacto] = UseModal(false);
  const [
    isOpenAddCitasDisponibles,
    openAddCitasDisponibles,
    CloseAddCitasDisponibles,
  ] = UseModal(false);
  const [IsOpenAlertConfirm, OpenAlertConfirm, ClosedAlertConfirm] = UseModal(false);
  const [IsOpenCancelCita, OpenCancelCita, ClosedCancelCita] = UseModal(false);
  // MODAL ELIMINAR CITA
  const [modal, ModalEliminarCita] = Modal.useModal();

  const [EstatusModalEliminarCita, setEstatusModalEliminarCita] = useState(false);

  const navigate = useNavigate();

  const [listCitas, setListCitas] = useState([]);
  const [calendarCitas, setCalendarCitas] = useState([]);
  const [listCitasFilter, setListCitasFilter] = useState([]);
  const [listUrgencia, setListUrgencia] = useState([]);
  const [listPrimeraVez, setListPrimeraVez] = useState([]);
  const [listSeguimiento, setListSeguimiento] = useState([]);
  const [AddHorarios, setAddHorarios] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [listCitasOcupadas, setListCitasOcupadas] = useState([]);
  const [listHorariosDisponibles, setListHorariosDisponibles] = useState([]);

  const [fecha, cambiarFecha] = useState("");

  const [Tipocita, setTipocita] = useState(null);
  const [Horario, setHorario] = useState("");
  const [Motivo, setMotivo] = useState("");
  const [Comentario, setComentario] = useState("");
  const [Fecha, setFecha] = useState("");

  const [DatosContacto, setDatosContacto] = useState({
    id: "",
    nombre: "",
    imagen: "",
  });
  const [DatosCita, setDatosCita] = useState({
    nombre: "",
    tipo: "",
    estado: "",
    hora: "",
    fecha: "",
  });
  const [AddTipoContacto, setAddTipoContacto] = useState(null);
  const [AddTipocita, setAddTipocita] = useState(null);
  const [AddHorario, setAddHorario] = useState(null);
  const [AddMotivo, setAddMotivo] = useState("");
  const [AddComentario, setAddComentario] = useState("");
  const [AddFecha, setAddFecha] = useState(fecha_hoy);

  const [infocontacto, setinfocontacto] = useState("");
  const [ImagenContacto, setImagenContacto] = useState({
    name: "",
    url: "",
    file: "",
    size: 0,
    formato: "",
  });
  const [Tipocontacto, setTipocontacto] = useState(null);
  const [Nombre, setNombre] = useState("");
  const [ApellidoP, setApellidoP] = useState("");
  const [ApellidoM, setApellidoM] = useState("");
  const [Celular, setCelular] = useState("");
  const [Correo, setCorreo] = useState("");
  const [NotaContacto, setNotaContacto] = useState("");

  const [estatusCita, setEstatusCita] = useState("");

  const [loader, setLoader] = useState(true);
  const [loaderDetalle, setLoaderDetalle] = useState(false);

  const [idCita, setIdCita] = useState("");
  const [infoCita, setInfoCita] = useState("");

  const [inputsEditable, setInputsEditable] = useState(true);

  const [contactList, setContactList] = useState([]);
  const [contactListFilter, setContactListFilter] = useState([]);
  const [idContacto, setIdContacto] = useState("");

  // VARIABLES MODAL ADD CONTACTO
  const [ProfileImage, setProfileImage] = useState({
    name: "",
    url: "",
    file: "",
  });
  const [AddNombrecontacto, setAddNombrecontacto] = useState("");
  const [AddApellidoPcontacto, setAddApellidoPcontacto] = useState("");
  const [AddApellidoMcontacto, setAddApellidoMcontacto] = useState("");
  const [AddCelularcontacto, setAddCelularcontacto] = useState("");
  const [AddCorreocontacto, setAddCorreocontacto] = useState("");
  const [AddNotaContacto, setAddNotaContacto] = useState("");

  const [EstadosList, setEstadosList] = useState([]);
  const [LocalidadesList, setLocalidadesList] = useState([]);

  // VARIABLES MODAL FICHA CONTACTO
  const [DataParejaExist, setDataParejaExist] = useState(false);
  const [DataFichaId, setDataFichaId] = useState({ historial: null, ficha: null });
  const [TipoSangre, setTipoSangre] = useState("");
  const [Alergias, setAlergias] = useState("");
  const [Refiere, setRefiere] = useState("");
  const [ObservacionesPaciente, setObservacionesPaciente] = useState("");
  const [EstadoCivil, setEstadoCivil] = useState(null);
  const [Ocupacion, setOcupacion] = useState("");
  const [Sexo, setSexo] = useState(null);
  const [Nacimiento, setNacimiento] = useState("");
  const [Edad, setEdad] = useState(0);
  const [Nacionalidad, setNacionalidad] = useState("");
  const [Estado, setEstado] = useState(null);
  const [Localidad, setLocalidad] = useState(null);
  const [NombrePareja, setNombrePareja] = useState("");
  const [EdadPareja, setEdadPareja] = useState("");
  const [OcupacionPareja, setOcupacionPareja] = useState("");
  const [NotasPareja, setNotasPareja] = useState("");

  // EXPLORACION FISICA
  const [TArterial, setTArterial] = useState("");
  const [FCardiaca, setFCardiaca] = useState("");
  const [FRespiratoria, setFRespiratoria] = useState("");
  const [Temperatura, setTemperatura] = useState("");
  const [Altura, setAltura] = useState("");
  const [Peso, setPeso] = useState("");
  const [IMC, setIMC] = useState("");
  const [Talla, setTalla] = useState("");
  const [SOxigeno, setSOxigeno] = useState("");
  const [ExploracionFisica, setExploracionFisica] = useState("");

  const [existConfigCitas, setExistConfigCitas] = useState(0);
  const [toggleState, setToggleState] = useState(1);
  const [AddCitaVista, setAddCitaVista] = useState(0);

  const [FiltroActive, setFiltroActive] = useState(false);

  // NUEVA CONSULTA
  const [MotivoConsulta, setMotivoConsulta] = useState("");
  const [TipoConsulta, setTipoConsulta] = useState(null);

  const [HorarioInicio, setHorarioInicio] = useState("");
  const [HorarioFin, setHorarioFin] = useState("");

  const [FechaHoraCita, setFechaHoraCita] = useState({ fecha: "", hora: "" });

  const [TabsAddCita, setTabsAddCita] = useState("1");

  // MOTIVO DE CANCELACION
  const [MotivoCancelacion, setMotivoCancelacion] = useState("");

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);

  const [OpenSetting, setOpenSetting] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [MensajeAddTipoContacto, setMensajeAddTipoContacto] = useState("true");
  const [ComboAddTipoCita, setComboAddTipoCita] = useState("true");
  const [ComboAddHorarioCita, setComboAddHorarioCita] = useState("true");
  const [MensajeContacto, setMensajeContacto] = useState("true");

  const [MensajeFechaInicio, setMensajeFechaInicio] = useState("true");
  const [MensajeFechaFin, setMensajeFechaFin] = useState("true");

  // Estatus mensajes ADD CONTACTO
  const [MensajeAddNombrecontacto, setMensajeAddNombrecontacto] = useState("true");
  const [MensajeAddApellidoPcontacto, setMensajeAddApellidoPcontacto] = useState("true");
  const [MensajeAddApellidoMcontacto, setMensajeAddApellidoMcontacto] = useState("true");
  const [MensajeAddCelularcontacto, setMensajeAddCelularcontacto] = useState("true");
  const [MensajeAddCorreocontacto, setMensajeAddCorreocontacto] = useState("true");

  const [MensajeMotivoConsulta, setMensajeMotivoConsulta] = useState("true");
  const [MensajeTipoConsulta, setMensajeTipoConsulta] = useState("true");

  const [MensajeNombre, setMensajeNombre] = useState("true");
  const [MensajeApellidoP, setMensajeApellidoP] = useState("true");
  const [MensajeApellidoM, setMensajeApellidoM] = useState("true");
  const [MensajeCelular, setMensajeCelular] = useState("true");
  const [MensajeCorreo, setMensajeCorreo] = useState("true");

  // MENSAJES GENERO Y FECHA NACIMIENTO
  const [MensajeNacimiento, setMensajeNacimiento] = useState("true");
  const [MensajeSexo, setMensajeSexo] = useState("true");

  // MENSAJE MOTIVO DE CANCELACION
  const [MensajeMotivoCancelacion, setMensajeMotivoCancelacion] = useState("true");

  const StatusIcons = [
    <CheckCircleOutline color="disabled" />,
    <CheckCircleOutline color="warning" />,
    <CancelOutlined color="error" />,
    <CheckCircleOutline color="success" />
  ]

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getDateFromString = (fechaString, horaString) => {

    const horaStringArray = horaString.split(' ');
    let hora = horaStringArray[0].split(':');
    let resultHora = horaStringArray[0];

    if (horaString.includes("p. m.") && parseInt(hora[0]) !== 12) {
      hora[0] = parseInt(hora[0]) + 12;
      resultHora = `${hora[0]}:${hora[1]}`;
    }

    let fechaCita = `${fechaString.substring(
      fechaString.length - 10,
      fechaString.length - 8
    )}-${fechaString.substring(
      fechaString.length - 7,
      fechaString.length - 5
    )}-${fechaString.substring(fechaString.length - 4, fechaString.length)}`;

    const resultDate = dayjs(`${fechaCita} ${resultHora}`, 'DD-MM-YYYY HH:mm');

    let tiempoCita = localStorage.getItem("duracionCita");

    return [
      resultDate.toLocaleString(),
      resultDate.add(parseInt(tiempoCita), 'minutes').toLocaleString(),
    ];
  }

  async function getCitas() {
    setLoader(true);
    let response = await getCitas_request();
    if (response.ok) {
      setListCitasFilter(response.data);
      setListCitas(response.data);
      let urgencia = [];
      let primeraVez = [];
      let seguimiento = [];
      response.data.forEach((element) => {
        if (element.TipoCita === 0) {
          urgencia.push(element);
        } else if (element.TipoCita === 1) {
          primeraVez.push(element);
        } else if (element.TipoCita === 2) {
          seguimiento.push(element);
        }
      });
      setListUrgencia(urgencia);
      setListPrimeraVez(primeraVez);
      setListSeguimiento(seguimiento);
      CloseModalFiltro(true);
    } else {
      setListUrgencia([]);
      setListPrimeraVez([]);
      setListSeguimiento([]);
    }
    setLoader(false);
    return;
  }

  async function getCitasFilterDate(dateIni, dateFin) {
    setLoader(true);
    let data = new FormData();
    data.append("FechaInicio", dateIni);
    data.append("FechaFin", dateFin);

    let response = await getCitasFilter_request(4, data);

    if (response.ok) {
      let citas = [];
      response.data.map(cita => {
        let fecha = getDateFromString(cita.FechaCita, cita.HoraCita);
        let horaCita = `${String(new Date(fecha[0]).getHours()).padStart(2, '0')}:${String(new Date(fecha[0]).getMinutes()).padStart(2, '0')}`;

        citas.push({
          title: `${horaCita} ${cita.Nombre} ${cita.ApellidoPaterno} ${cita.ApellidoMaterno}`,
          start: new Date(fecha[0]),
          end: new Date(fecha[1]),
          id: cita.IdCita,
          idContacto: cita.IdContacto,
          icon: StatusIcons[cita.Status]
        });
      });
      setCalendarCitas(citas);
      setListCitas(response.data);
    } else {
      setListUrgencia([]);
      setListPrimeraVez([]);
      setListSeguimiento([]);
      setListCitas([]);
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
  }

  async function getCitasDisponibles(date, hours) {
    setLoaderDetalle(true);
    let data = new FormData();
    let horarios_disponibles = [];

    data.append("FechaInicio", date);
    data.append("FechaFin", date);

    let response = await getCitasFilter_request(4, data);
    if (response.ok) {
      response.data.forEach((cita) => {
        let hora = `${cita.HoraCita.substring(0, 1) === "0"
          ? cita.HoraCita.substring(1, 5)
          : cita.HoraCita.substring(0, 5)
          } ${cita.HoraCita.substring(
            cita.HoraCita.length - 5,
            cita.HoraCita.length
          ) === "a. m."
            ? "a.m"
            : "p.m"
          }`;
        hours.Horario.forEach((horario) => {
          if (hora !== horario) {
            horarios_disponibles.push(horario);
          }
        });
      });
      setListCitasOcupadas(response.data);
      setListHorariosDisponibles(horarios_disponibles);
    } else {
      setListCitasOcupadas([]);
      if (hours.Horario.length > 0) {
        hours.Horario.forEach((horario) => {
          horarios_disponibles.push(horario);
        });
        setListHorariosDisponibles(horarios_disponibles);
      }

    }
    setLoaderDetalle(false);
    return;
  }

  async function detailCita(id, option) {
    openModalDetalle(true);
    setLoaderDetalle(true);
    let response = await detailCitas_request(option === 1 ? id : idCita);
    if (response.ok) {
      setInfoCita(response.data);
      setDatosCita({ nombre: response.data.Full_Name, estado: response.data.Estado, fecha: response.data.FechaCita, hora: response.data.HoraCita, tipo: response.data.TipoCita });
      let fecha_temp = response.data.FechaCita.substring(
        response.data.FechaCita.length - 10,
        response.data.FechaCita.length
      );
      setDatosContacto({ id: response.data.IdContacto, nombre: response.data.Full_Name, imagen: response.data.Imagen_url });
      let year = fecha_temp.substring(fecha_temp.length - 4, fecha_temp.length);
      let month = fecha_temp.substring(
        fecha_temp.length - 7,
        fecha_temp.length - 5
      );
      let day = fecha_temp.substring(
        fecha_temp.length - 10,
        fecha_temp.length - 8
      );
      setFecha(year + "-" + month + "-" + day);
      setEstatusCita(response.data.Estado);
      setHorario(response.data.HoraCita);
      setMotivoCancelacion(
        !!response.data.CancelacionMotivo &&
          response.data.CancelacionMotivo !== "null"
          ? response.data.CancelacionMotivo
          : ""
      );
      setMotivo(
        !!response.data.Comentarios && response.data.Comentarios !== "null"
          ? response.data.Comentarios
          : ""
      );
      setComentario(
        !!response.data.Adicional && response.data.Adicional !== "null"
          ? response.data.Adicional
          : ""
      );
      setTipocita(
        response.data.TipoCita === "Primera vez"
          ? "1"
          : response.data.TipoCita === "Urgencia"
            ? "0"
            : "2"
      );
      cambiarFecha(year + "-" + month + "-" + day);
      if (option === 1) {
        setIdCita(id);
      }
      if (option === 2) {
        setInputsEditable(false);
        getHorarios(fecha);
      }
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
      CloseModalDetalle(true);
    }
    setLoaderDetalle(false);
    return;
  }

  async function getHorarios(fecha) {
    setLoaderDetalle(true);

    let data = { Fecha: fecha };
    let response = await getHorarios_request(data);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Horarios existentes");
      let array = [];
      response.data.forEach((element) => {
        array.push({ value: element, label: element });
      });
      setHorarios(array);
      getCitasDisponibles(fecha, { Horario: response.data });
    } else {
      setHorarios([]);
      getCitasDisponibles(fecha, { Horario: [] });
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function getContactos() {
    setLoader(true);
    let response = await getContacts_request();
    if (response.ok) {
      let list_secondary = [];
      response.data.forEach((element) => {
        if (element.Type === 3 || element.Type === 5) {
          list_secondary.push(element);
        }
      });
      setContactList(list_secondary);
      setContactListFilter(list_secondary);
    }
    setLoader(false);
    return;
  }

  async function getConfigCitas() {
    let response = await getConfigCitas_request();
    if (response.ok) {
      localStorage.setItem("duracionCita", response.data.TiempoCita ? response.data.TiempoCita.toString() : "60");
      setExistConfigCitas(1);
    } else {
      setExistConfigCitas(2);
    }
  }

  async function saveCita() {
    setLoaderDetalle(true);

    if (AddTipocita === "3") {
      if (!!HorarioInicio && !!HorarioFin) {
        return;
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
        if (!!!AddTipocita) {
          setComboAddTipoCita("false");
        }

        if (!!!HorarioInicio || !!!HorarioFin) {
          setComboAddHorarioCita("false");
        }
      }
    } else {
      if (!!AddHorario && !!DatosContacto.id && !!AddTipocita) {
        let data = new FormData();
        data.append("Nombre", DatosContacto.id);
        data.append("Fecha", AddFecha);
        data.append("Hora", AddHorario);
        data.append("Comentarios", AddMotivo);
        data.append("Adicional", AddComentario);
        data.append("TipoCita", AddTipocita);
        let response = await addCita_request(data);
        if (response.ok) {
          ReactGA.event({
            category: "Citas",
            action: "Cita_exitosa",
            label: "Cita agregada exitosamente", // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cita creada");
          clearInputs(1);
          CloseAddCitasDisponibles(false);
          // TODO cambiar para agregar nueva cita al array
          await getCitasFilterDate(fechaInicio, fechaFinal);
        } else {
          ReactGA.event({
            category: "Citas",
            action: "Cita_erronea",
            label: `${response.mensaje}`, // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        if (!!!AddHorario) {
          setComboAddHorarioCita("false");
        }

        if (!!!DatosContacto.id) {
          setMensajeContacto("false");
        }

        if (!!!AddTipocita) {
          setComboAddTipoCita("false");
        }

        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      }
    }
    setLoaderDetalle(false);
    setLoader(false);
    return;
  }

  async function saveCitaNewContact() {
    setLoaderDetalle(true);
    if (AddTipocita === "3") {
      if (!!HorarioInicio && !!HorarioFin) {
        return;
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
        if (!!!AddTipocita) {
          setComboAddTipoCita("false");
        }

        if (!!!HorarioInicio || !!!HorarioFin) {
          setComboAddHorarioCita("false");
        }
      }
    } else {
      if (!!AddHorario && !!DatosContacto.id && !!AddTipocita) {
        let data = new FormData();
        data.append("Nombre", DatosContacto.id);
        data.append("Fecha", AddFecha);
        data.append("Hora", AddHorario);
        data.append("Comentarios", AddMotivo);
        data.append("Adicional", AddComentario);
        data.append("TipoCita", AddTipocita);
        let response = await addCita_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cita creada");
          clearInputs(1);

          CloseAddCitasDisponibles(false);
          getCitasFilterDate(fechaInicio, fechaFinal);
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        if (!!!AddHorario) {
          setComboAddHorarioCita("false");
        }

        if (!!!DatosContacto.id) {
          setMensajeContacto("false");
        }

        if (!!!AddTipocita) {
          setComboAddTipoCita("false");
        }

        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      }
    }
    setLoaderDetalle(false);
    return;
  }

  const reprogramarCita = async (e) => {
    e.preventDefault();
    setLoaderDetalle(true);

    if (!!Tipocita && !!Horario && !!Fecha) {
      let data = new FormData();
      data.append("Fecha", Fecha);
      data.append("Hora", Horario);
      data.append("Comentarios", Motivo);
      data.append("Adicional", Comentario);
      data.append("TipoCita", Tipocita);
      data.append("Status", infoCita.Status);
      let response = await reprogramarCita_request(data, idCita);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Cita reagendada");
        clearInputs(2);
        CloseModalDetalle(true);

        reloadCalendar();
        setInputsEditable(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderDetalle(false);
    return;
  };

  async function saveContactDos() {

    setLoaderDetalle(true);
    let data = new FormData();
    if (
      !!AddNombrecontacto &&
      !!AddApellidoPcontacto &&
      MensajeAddNombrecontacto !== "false" &&
      MensajeAddApellidoPcontacto !== "false" &&
      MensajeAddApellidoMcontacto !== "false" &&
      MensajeAddCelularcontacto !== "false" &&
      MensajeAddCorreocontacto !== "false"
    ) {
      data.append("Nombre", AddNombrecontacto);
      data.append("ApellidoPaterno", AddApellidoPcontacto);
      data.append("ApellidoMaterno", AddApellidoMcontacto);
      data.append("Nota", AddNotaContacto);
      if (!!AddCelularcontacto) {
        data.append("Celular", AddCelularcontacto);
      }
      if (!!AddCorreocontacto) {
        data.append("Email", AddCorreocontacto);
      }

      data.append("Tipo", "Paciente");
      if (ProfileImage.file !== "") {
        data.append("Imagen", ProfileImage.file, ProfileImage.name);
      }
      let response = await addContact_request(data);
      if (response.ok) {
        ReactGA.event({
          category: "Citas",
          action: "Nuevo_contacto_exitoso",
          label: "Boton para guardar contacto", // optional
          value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
        getContactos();
        setOpen(true);
        setTypeMessage("success");
        setMessage("Contacto guardado");
        ClosedAddContacto(true);
        CloseModalcita1(true);
        cleanInputsContacto();
        setDatosContacto({
          id: response.data.IdContacto,
          nombre: `${response.data.Nombre} ${response.data.ApellidoPaterno} ${response.data.ApellidoMaterno}`,
          imagen: response.data.Imagen_url,
        });

        openAddCitasDisponibles(true);
      } else {
        ReactGA.event({
          category: "Citas",
          action: "Nuevo_contacto_erroneo",
          label: `${response.mensaje}`, // optional
          value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!AddNombrecontacto) {
        setMensajeAddNombrecontacto("false");
      }

      if (!!!AddApellidoPcontacto) {
        setMensajeAddApellidoPcontacto("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }

    setLoaderDetalle(false);
    return;
  }

  async function cancelarCita() {
    setLoaderDetalle(true);
    if (!!MotivoCancelacion) {
      let data = new FormData();
      data.append("Motivo", MotivoCancelacion);
      let response = await cancelCitas_request(data, idCita);
      if (response.ok) {
        ReactGA.event({
          category: "Citas",
          action: "Cita_cancelada_exitosa",
          label: "Boton para cancelar cita", // optional
          value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
        setOpen(true);
        setTypeMessage("success");
        setMessage("Cita cancelada");

        reloadCalendar();
        ClosedCancelCita(true);
      } else {
        ReactGA.event({
          category: "Citas",
          action: "Cita_cancelada_erronea",
          label: `${response.mensaje}`, // optional
          value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      setMensajeMotivoCancelacion("false");
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Debe escribir un motivo de cancelación");
    }
    setLoaderDetalle(false);
  }

  async function confirmarCita() {
    setLoader(true);
    let response = await confirmCitas_request(idCita);
    if (response.ok) {
      ReactGA.event({
        category: "Citas",
        action: "Cita_confirmada_exitosa",
        label: "cita confirmada exitosa", // optional
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cita confirmada");
      reloadCalendar();
    } else {
      ReactGA.event({
        category: "Citas",
        action: "Cita_confirmada_erronea",
        label: `${response.mensaje}`, // optional
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function atenderCita() {
    setLoader(true);
    let response = await atenderCitas_request(idCita);
    if (response.ok) {
      ReactGA.event({
        category: "Citas",
        action: "Cita_atendida_exitosa",
        label: "Cita atendida exitosa", // optional
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cita atendida");
      reloadCalendar();
    } else {
      ReactGA.event({
        category: "Citas",
        action: "Cita_atendida_erronea",
        label: `${response.mensaje}`, // optional
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function eliminarCita() {
    setLoader(true);
    let response = await deleteCitas_request(idCita);
    if (response.ok) {
      setDatosCita({
        nombre: "",
        tipo: "",
        estado: "",
        hora: "",
        fecha: "",
      });
      ReactGA.event({
        category: "Citas",
        action: "Cita_eliminada_exitosa",
        label: "cita eliminada exitosa", // optional
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cita eliminada");
      // getCitasFilterDate(FechaFilter);
      reloadCalendar();
      setEstatusModalEliminarCita(false);
    } else {
      ReactGA.event({
        category: "Citas",
        action: "Cita_eliminada_erronea",
        label: `${response.mensaje}`, // optional
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function contactDetail() {
    openEditContacto(true);

    setLoaderDetalle(true);

    let response = await contactDetail_request(DatosContacto.id);
    if (response.ok) {

      setinfocontacto(response.data);
      setTipocontacto(response.data.Type.toString());
      setNombre(response.data.Nombre);
      setApellidoP(response.data.ApellidoPaterno);
      setApellidoM(response.data.ApellidoMaterno);
      setCelular(response.data.Celular);
      setCorreo(response.data.Email);
      setNotaContacto(response.data.Nota);
      setImagenContacto({
        name: "",
        url: `https://${response.data.Imagen_url}`,
        file: "",
        size: 0,
        formato: "",
      });

    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function editContact() {
    setLoaderDetalle(true);

    if (
      !!Tipocontacto &&
      !!Nombre &&
      MensajeNombre === "true" &&
      !!ApellidoP &&
      MensajeApellidoP === "true" &&
      MensajeApellidoM === "true" &&
      MensajeCelular === "true" &&
      MensajeCorreo === "true"
    ) {
      let data = new FormData();
      data.append("Nombre", Nombre);
      data.append("ApellidoPaterno", ApellidoP);
      data.append("ApellidoMaterno", !!ApellidoM ? ApellidoM : "");
      data.append("Celular", !!Celular ? Celular : "");
      data.append("Email", !!Correo ? Correo : "");
      data.append("Tipo", Tipocontacto === "1" ? "Médico" : "Paciente");
      data.append("Nota", NotaContacto);
      if (!!ImagenContacto.name) {
        data.append("Imagen", ImagenContacto.file, ImagenContacto.name);
      }

      let response = await editContact_request(data, infocontacto.IdContacto);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Contacto editado");
        CloseEditContacto(true);
        getCitasFilterDate(fechaInicio, fechaFinal);
        getContactos();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Nombre) {
        setMensajeNombre("false");
      }

      if (!!!ApellidoP) {
        setMensajeApellidoP("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderDetalle(false);
    return;
  }

  async function addFichaContacto() {
    setLoaderDetalle(true);
    if (!!Sexo && !!Nacimiento) {

      let data = {
        idUsuario: DatosContacto.id,
        antecedentesHereditarios: "",
        antecedentesPatologicos: "",
        antecedentesNoPatologicos: "",
        sintomasGenerales: "",
        aparatoRespiratorio: "",
        aparatoDigestivo: "",
        aparatoCardiovascular: "",
        sistemaNefrourologico: "",
        sistemaEndocrino: "",
        sistemaHematopoyetico: "",
        sistemaNervioso: "",
        sistemaMusculo: "",
        piel: "",
        organosSentidos: "",
        esferaPsiquica: "",
        tipoSangre: TipoSangre,
        alergias: Alergias,
        observaciones: ObservacionesPaciente,
        refiere: Refiere,
        sexo: Sexo,
        nacimiento: Nacimiento,
        nacionalidad: Nacionalidad,
        estado: Estado,
        localidad: Localidad,
        cp: "",
        peso: Peso,
        altura: Altura,
        Talla: Talla,
        tArterial: TArterial,
        fCardiaca: FCardiaca,
        fRespiratoria: FRespiratoria,
        sOxigeno: SOxigeno,
        temperatura: Temperatura,
        // exploracionFisica: ExploracionFisica,
        ocupacion: Ocupacion,
        estadoCivil: EstadoCivil,
        nombrePareja: NombrePareja,
        edadPareja: EdadPareja,
        ocupacionPareja: OcupacionPareja,
        nota: NotasPareja,
      };

      let response = await addExpediente_request(data, DatosContacto.id);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("ficha guardada");
        cleanInputsFicha();
        CloseFichaContacto(true);
        openModalDetalle(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Sexo) {
        setMensajeSexo("false");
      }
      if (!!!Nacimiento) {
        setMensajeNacimiento("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderDetalle(false);
    return;
  }

  async function getHistoriaClinica(idContacto) {
    setLoaderDetalle(true);
    let response = await getHistoriasClinicas_request(idContacto);
    if (response.ok) {
      setDataFichaId({ historial: response.data.historial, ficha: response.data.ficha });
      if (response.data.historial !== null) {

        let historial = response.data.historial;

        setTipoSangre(historial.tipoSangre);
        setAlergias(historial.alergias);
        setRefiere(historial.refiere);

        setAltura(!!historial.altura ? historial.altura : "");
        setTArterial(historial.tArterial);
        setFCardiaca(historial.fCardiaca);
        setFRespiratoria(historial.fRespiratoria);
        setTemperatura(historial.temperatura);
        setSOxigeno(historial.sOxigeno);
        setPeso(historial.peso);
        setEstadoCivil(!!historial.estadoCivil ? historial.estadoCivil.toString() : null);
        setOcupacion(historial.ocupacion);
        setNombrePareja(historial.nombrePareja);
        setEdadPareja(historial.edadPareja);
        setOcupacionPareja(historial.ocupacionPareja);
        setNotasPareja(historial.nota);

        if (!!historial.peso && !!historial.altura) {
          let peso_temp = historial.peso;
          let altura_temp = historial.altura;
          let imc = peso_temp / (altura_temp * altura_temp);
          setIMC(imc.toFixed(2));
        }
      }
      if (response.data.ficha !== null) {

        let fichaId = response.data.ficha;

        setSexo(fichaId.sexo);

        if (!!fichaId.fechaNacimiento) {
          let arrayFechaTemp = fichaId.fechaNacimiento.split("-");
          let fechaTemp = `${arrayFechaTemp[2]}-${arrayFechaTemp[1]}-${arrayFechaTemp[0]}`;
          getEdad(fechaTemp);
          setNacimiento(fechaTemp);
        }
        setNacionalidad(fichaId.nacionalidad);
        if (!!fichaId.estado) {
          setEstado(fichaId.estado);
          getLocalidades(fichaId.estado);
          setLocalidad(fichaId.localidad);
        }
      }
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function getEstados() {
    let response = await getEstado_request();
    if (response.ok) {
      let estados_temp = [];
      response.data.forEach((estado) => {
        estados_temp.push({
          value: estado.id.toString(),
          label: estado.Estado,
        });
      });
      setEstadosList(estados_temp);
    } else {
      setEstadosList([]);
    }
  }

  async function getLocalidades(estado) {
    let response = await getLocalidad_request(estado);
    if (response.ok) {
      let localidades_temp = [];
      response.data.forEach((estado) => {
        localidades_temp.push({ value: estado, label: estado });
      });
      setLocalidadesList(localidades_temp);
    } else {
      setLocalidadesList([]);
    }
  }

  function cleanInputsContacto() {
    setAddNombrecontacto("");
    setAddApellidoPcontacto("");
    setAddApellidoMcontacto("");
    setAddCelularcontacto("");
    setAddCorreocontacto("");
    setMensajeAddNombrecontacto("true");
    setMensajeAddApellidoPcontacto("true");
    setMensajeAddApellidoMcontacto("true");
    setMensajeAddCelularcontacto("true");
    setMensajeAddCorreocontacto("true");
  }

  function filterContacts(text) {
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];
    contactListFilter.forEach((contacto) => {
      if (!!contacto.Celular) {
        if (`${contacto.Nombre} ${contacto.ApellidoPaterno}${!!contacto.ApellidoMaterno ? !!contacto.ApellidoMaterno.trim() ? ` ${contacto.ApellidoMaterno}` : "" : ""}`.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
          contacto.ApellidoPaterno.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
          contacto.ApellidoMaterno.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
          contacto.Celular.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1) {

          filter.push(contacto);
        }
      } else {
        if (`${contacto.Nombre} ${contacto.ApellidoPaterno} ${!!contacto.ApellidoMaterno ? !!contacto.ApellidoMaterno.trim() ? ` ${contacto.ApellidoMaterno}` : "" : ""}`.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
          contacto.ApellidoPaterno.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
          contacto.ApellidoMaterno.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1) {

          filter.push(contacto);
        }
      }
    });

    setContactList(filter);
  }

  function filterList(text) {
    var expr = /[áàéèíìóòúùñ]/gi;

    let filterUrgencia = [];
    let filterPrimeraVez = [];
    let filterSeguimiento = [];
    let filterTodas = [];
    listCitasFilter.forEach((cita) => {
      if (
        cita.Full_Name.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        cita.FechaCita.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        cita.Estado.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        if (cita.TipoCita === 0) {
          filterUrgencia.push(cita);
        } else if (cita.TipoCita === 1) {
          filterPrimeraVez.push(cita);
        } else if (cita.TipoCita === 2) {
          filterSeguimiento.push(cita);
        }
        filterTodas.push(cita);
      }
    });
    setListUrgencia(filterUrgencia);
    setListPrimeraVez(filterPrimeraVez);
    setListSeguimiento(filterSeguimiento);
    setListCitas(filterTodas);
  }

  function filterOptions(input, option) {
    var expr = /[áàéèíìóòúùñ]/gi;
    return (
      option?.label
        .replace(expr, function (e) {
          return chars[e];
        })
        .toLowerCase()
        .indexOf(
          input
            .replace(expr, function (e) {
              return chars[e];
            })
            .toLowerCase()
        ) > -1
    );
  }

  function filterSort(optionA, optionB) {
    var expr = /[áàéèíìóòúùñ]/gi;
    return (
      optionA?.label
        .replace(expr, function (e) {
          return chars[e];
        })
        .toLowerCase() ?? ""
    )
      .replace(expr, function (e) {
        return chars[e];
      })
      .toLowerCase()
      .localeCompare(
        (
          optionB?.label
            .replace(expr, function (e) {
              return chars[e];
            })
            .toLowerCase() ?? ""
        )
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
      );
  }

  function clearInputs(opcion) {
    if (opcion === 1) {
      setDatosContacto({ id: "", nombre: "", imagen: "" });
      setAddTipoContacto(null);
      setAddTipocita(null);
      setAddHorario(null);
      setAddMotivo("");
      setAddComentario("");
    } else {
      setTipocita(null);
      setHorario(null);
      setMotivo("");
      setFecha(fecha_hoy);
    }
  }

  function getEdad(dateString) {
    let hoy = new Date();
    let fNacimiento = new Date(dateString.replace(/-/g, "/"));
    let edad = hoy.getFullYear() - fNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fNacimiento.getDate())
    ) {
      edad--;
    }
    setEdad(edad);
  }

  function cleanInputsFicha() {
    // VARIABLES MODAL FICHA CONTACTO
    setTipoSangre("");
    setAlergias("");
    setRefiere("");
    setObservacionesPaciente("");
    setEstadoCivil(null);
    setOcupacion("");
    setSexo(null);
    setNacimiento("");
    setNacionalidad("");
    setEstado(null);
    setLocalidad(null);
    setNombrePareja("");
    setEdadPareja("");
    setOcupacionPareja("");
    setNotasPareja("");

    // EXPLORACION FISICA
    setTArterial("");
    setFCardiaca("");
    setFRespiratoria("");
    setTemperatura("");
    setAltura("");
    setPeso("");
    setIMC("");
    setTalla("");
    setSOxigeno("");
    setExploracionFisica("");

    setMensajeNacimiento("true");
    setMensajeSexo("true");
  }

  const confirm = () => {
    modal.confirm({
      title: "¿Desea eliminar esta cita?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: () => eliminarCita(),
      onCancel: () => {
        setDatosCita({
          nombre: "",
          tipo: "",
          estado: "",
          hora: "",
          fecha: "",
        });
        setEstatusModalEliminarCita(false);
      },
      open: EstatusModalEliminarCita,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>Paciente: {DatosCita.nombre}</p>
          <p>Fecha: {DatosCita.fecha}</p>
          <p>Hora: {DatosCita.hora}</p>
          <p>
            Tipo:{" "}
            {DatosCita.tipo}
          </p>
          <p>Estado: {DatosCita.estado}</p>
        </div>
      ),
    });
  };

  async function readModule() {
    await getAuthFirebase_request({ accion: 1, modulo: "citas", userId: userInfo.id });
  }

  useEffect(() => {
    const loadData = async () => {
      readModule();
      let startMonth = dayjs(fechaInicio).format('YYYY-MM-D');
      let endMonth = dayjs(fechaFinal).format('YYYY-MM-D');

      if (!fechaInicio && !fechaFinal) {
        const calendarRange = getCalendarRange();

        dispatch(SetFechaInicial(calendarRange[0]));
        dispatch(SetFechaFinal(calendarRange[1]));
        dispatch(SetCalendarDate(new Date().toISOString()));

        startMonth = dayjs(calendarRange[0]).format('YYYY-MM-D');
        endMonth = dayjs(calendarRange[1]).format('YYYY-MM-D');
      }

      await getConfigCitas();
      await getCitasFilterDate(startMonth, endMonth);
      await getContactos();
      await getEstados();

      setLoader(false);
    }

    if (!!!location.state) {
    } else {
      if (!!location.state.idCita) {
        setDatosContacto({
          id: location.state.id,
          imagen: location.state.imagen,
          nombre: location.state.nombre,
        });
        detailCita(location.state.idCita, 1);
      }
    }

    loadData();
  }, []);

  const getCitaMenuOptions = (cita) => {
    let userData = JSON.parse(sessionStorage.getItem("infoUser"));
    let Status = cita.Status;

    let buttons = [];

    if (userData && userData.Tipo === 1) {
      buttons.push({
        Name: 'Consultar',
        Icon: <AddOutlined color='secondary' />,
        Callback: () => OpenModalAddNota(true)
      })
    }

    if (Status !== 1) {
      buttons.push({
        Name: 'Confirmar',
        Icon: <CheckCircleOutline color="warning" />,
        Callback: () => confirmarCita()
      });
    }

    if (Status !== 3) {
      buttons.push({
        Name: 'Atender',
        Icon: <CheckCircleOutline color="success" />,
        Callback: () => atenderCita()
      });
    }

    buttons.push({
      Name: 'Editar Contacto',
      Icon: <EditOutlined color='secondary' />,
      Callback: () => {
        CloseModalDetalle(true);
        contactDetail();
      }
    });

    if (Status !== 2) {
      buttons.push({
        Name: 'Cancelar',
        Icon: <CancelOutlined color="danger" />,
        Callback: () => {
          CloseModalDetalle(true);
          OpenCancelCita(true);
        }
      });
    }

    buttons.push({
      Name: 'Eliminar',
      Icon: <DeleteForeverOutlined color="danger" />,
      Callback: () => confirm()
    });

    return buttons;
  }

  const nuevaCitaHandler = (fecha) => {
    let fechaDate = new Date(fecha);
    setAddFecha(fecha);

    ReactGA.event({
      category: "Citas",
      action: "Nueva_cita",
      label: "Boton para abrir form de cita nueva", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    if (existConfigCitas === 1) {
      getHorarios(fecha);
      openAddCitasDisponibles(true);
      clearInputs(1);
    } else {
      setOpenSetting(true);
      setTypeMessage("warning");
      setMessage(
        "Configure un horario, en caso de ya tenerlo recarge la página"
      );
    }
  }

  const reloadCalendar = async () => {
    setLoader(true);
    let start = dayjs(fechaInicio).format('YYYY-MM-D');
    let end = dayjs(fechaFinal).format('YYYY-MM-D');

    await getCitasFilterDate(start, end);

    setLoader(false);
  }

  return (
    <Stack>
      <NavbarNuevo
        modulo="Citas"
        detalle={detailCita}
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        searchBar={
          <>
            <div className="Cebecera">
              <div className="Encabesado">
                <div>
                  <b>Citas</b>
                </div>
                <div
                  style={{
                    color: "#148f9f",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    onClick={() => {
                      navigate("/app/CitasConfig");
                    }}
                    style={{ width: "20px" }}
                    src={Confi}
                    alt=""
                  />
                  &nbsp;&nbsp;
                  <ButtonAntd
                    tipo={1}
                    style={{ width: "100px" }}
                    onClick={() => nuevaCitaHandler(fecha_hoy)}
                    children={<Row style={{ width: "100%", display: "flex", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                      Nueva <AddCircleIcon style={{ fontSize: 15 }} />
                    </Row>
                    }
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: "1rem", marginTop: "1%" }}>
                <Row style={{ alignItems: "center" }}>
                  <CheckCircleOutline color="disabled" />
                  Pendiente
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <CheckCircleOutline color="warning" />
                  Confirmada
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <CheckCircleOutline color="success" /> Atendida
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <CancelOutlined color="error" /> Cancelada
                </Row>
              </div>
            </div>
          </>
        }
      />

      <Content
        className="site-layout"
        style={{
          padding: "0 3%",
          width: "100%",
          overflow: "initial",
          backgroundColor: 'white'
        }}
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            <div style={{ width: "100%", }}>
              <CalendarPage ClickCalendarCallback={(date) => nuevaCitaHandler(date)} ClickEventCallback={detailCita} citas={calendarCitas} ChangeRangeCallback={reloadCalendar} historiaClinica={getHistoriaClinica} />
            </div>
          </>
        )}
      </Content>

      <ModalDetalle isOpen={isOpenModalDetalle} closeModal={CloseModalDetalle}>
        <div>
          <div className="TopCitasModal">
            <p style={{ color: "#ffffff" }}>Detalle de cita</p>
            <CloseIcon
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                if (inputsEditable) {
                  CloseModalDetalle(true);
                  setMotivoCancelacion("");
                } else {
                  setInputsEditable(true);
                  setMotivo(infoCita.Comentarios);
                  setHorario(infoCita.HoraCita);
                }
              }}
            />
          </div>
          <div className="ModalDetalleCita">
            {loaderDetalle ? (
              <LoaderModals className={"ModalDetalleCita"} />
            ) : (
              <div className="GridItem2">
                <div className="all">

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      onError={(e) => { console.log(e); }}
                      size={75}
                      style={{
                        borderRadius: "5rem",
                        border: "1px solid #d5d5d5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                      }}
                      id="Img-cont"
                      src={
                        <Image src={
                          !!infoCita.Imagen_url
                            ? "https://" + infoCita.Imagen_url
                            : Imag
                        } />
                      }
                    />
                  </div>

                  <h3>{infoCita.Full_Name}</h3>

                  <Row

                    style={{
                      width: "100%",
                      gap: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >


                    {inputsEditable ? (
                      <>
                        <Button
                          type="button"
                          style={{
                            background: "#148f9f",
                            border: "0",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                          }}
                          onClick={() => {
                            if (DataFichaId.ficha !== null) {
                              navigate("/app/ExpedientesNuevoDetalle", {
                                state: {
                                  id: DatosContacto.id,
                                  imagen: DatosContacto.imagen,
                                  nombre: DatosContacto.nombre,
                                },
                              });
                            } else {
                              setOpen(true);
                              setTypeMessage("error");
                              setMessage("Contacto sin historial");
                            }
                          }}
                        >
                          Expediente
                          <EyeFilled />
                        </Button>
                        {(infoCita.Estado === "Confirmada" ||
                          infoCita.Estado === "Pendiente") ?
                          <Button
                            type="button"
                            style={{
                              background: "#148f9f",
                              border: "0",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              gap: ".5rem",
                            }}
                            onClick={() => {
                              CloseModalDetalle(true);
                              openFichaContacto(true);
                            }}
                          >
                            Ficha
                            <EyeFilled />
                          </Button> : <div></div>
                        }

                        {(infoCita.Estado === "Confirmada" ||
                          infoCita.Estado === "Pendiente") ? <Button
                            style={{
                              background: "#148f9f",
                              border: "0",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              gap: ".5rem",
                            }}
                            onClick={() => {
                              setInputsEditable(false);
                              getHorarios(fecha);
                            }}
                          >
                          Editar <Edit style={{ fontSize: 15 }} />
                        </Button> : <div></div>
                        }
                      </>

                    ) : (
                      <div style={{ display: "flex", width: "100%", alignContent: "center", justifyContent: "end" }}>

                        <Button
                          onClick={reprogramarCita}
                          style={{

                            background: "#148f9f",
                            border: "0",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                          }}

                        >
                          Guardar
                        </Button>

                      </div>

                    )}
                  </Row>


                  <div className="Contenido-detalle">
                    <div style={{ position: "relative", width: '100%', height: '48px', marginTop: '10px' }}>
                      <div style={{ position: 'absolute', right: '0px' }}>
                        <PositionedMenu
                          setIdCita={setIdCita}
                          Cita={infoCita}
                          MenuOptions={getCitaMenuOptions(infoCita)}
                          extras={[
                          ]} />
                      </div>
                    </div>
                    {!inputsEditable && (
                      <Calendar
                        locale={locale}
                        value={dayjs(Fecha)}
                        fullscreen={false}
                        onSelect={(e) => {
                          let temp = e.$d;
                          let mesObtenido = temp.getMonth() + 1;
                          let mesCalculo =
                            mesObtenido.toString().length === 1
                              ? `0${mesObtenido}`
                              : mesObtenido;
                          let fecha_enviar =
                            temp.getFullYear() +
                            "-" +
                            mesCalculo +
                            "-" +
                            temp.getDate();
                          setHorarios([]);
                          setFecha(fecha_enviar);
                          getHorarios(fecha_enviar);
                          setHorario("");
                        }}
                      />
                    )}
                    {inputsEditable && (
                      <InputAntd
                        className="inputblue"
                        value={fecha}
                        label="Fecha:"
                        style={{ margin: "2% 0" }}
                        type="date"
                        disabled={true}
                      />
                    )}
                    <Row
                      style={{ justifyContent: "space-between", gap: "1rem" }}
                    >
                      <SelectAntd
                        label="Horario:"
                        placeholder="Elige un horario"
                        options={horarios}
                        setStateValue={setHorario}
                        setStateError={setComboAddHorarioCita}
                        textError="Debe seleccionar un horario"
                        value={Horario}
                        valueError={ComboAddHorarioCita}
                        style={{ width: "100%", fontWeight: "normal" }}
                        styleDiv={{ width: "45%" }}
                        disabled={inputsEditable}
                      />

                      <SelectAntd
                        label="Tipo cita:"
                        placeholder="Elige un tipo de cita"
                        options={[
                          { value: "0", label: "Urgencia" },
                          { value: "1", label: "Primera Vez" },
                          { value: "2", label: "Seguimiento" },
                        ]}
                        style={{ width: "100%", fontWeight: "normal" }}
                        styleDiv={{ width: "45%" }}
                        setStateValue={setTipocita}
                        value={Tipocita}
                        disabled={inputsEditable}
                      />
                    </Row>

                    <InputAntd
                      className="inputblue"
                      value={infoCita.Estado}
                      label="Estatus:"
                      style={{
                        color:
                          infoCita.Estado === "Confirmada"
                            ? "#EB9C37"
                            : infoCita.Estado === "Cancelada"
                              ? "red"
                              : infoCita.Estado === "Atendida"
                                ? "green"
                                : "grey",
                        margin: "2% 0",
                      }}
                      type="text"
                      readOnly={true}
                    />
                    {infoCita.Estado === "Cancelada" && (
                      <TextAreaAntd
                        label="Motivo de cancelación:"
                        value={MotivoCancelacion}
                        placeholder="Escriba un motivo de cancelación"
                        style={{
                          marginBottom: ".5%",
                          fontFamily: "sans-serif",
                        }}
                        columns="30"
                        autoSize={true}
                        readOnly={true}
                      />
                    )}

                    <InputAntd
                      className="inputblue"
                      value={infoCita.Creado}
                      label="Cita creada por:"
                      style={{ margin: "2% 0" }}
                      type="text"
                      readOnly={true}
                    />

                    <TextAreaAntd
                      label="Motivo de cita:"
                      value={Motivo}
                      placeholder="Escriba un motivo de cita (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setMotivo}
                      readOnly={inputsEditable}
                    />

                    <TextAreaAntd
                      label="Comentarios o notas:"
                      value={Comentario}
                      placeholder="Escriba un comentario o nota (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setComentario}
                      readOnly={inputsEditable}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalDetalle>

      <ModalCitas1
        styleDet={styleDet}
        isOpen={isOpenModalcita1}
        closeModal={CloseModalcita1}
      >
        <div className="TopCitasModal">
          <p>Nueva cita</p>
          <CloseIcon
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={() => {
              CloseModalcita1(true);
              openAddCitasDisponibles(true);
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "right", margin: "1%" }}>
          <Button
            type="button"
            onClick={() => {
              ReactGA.event({
                category: "Citas",
                action: "Nuevo_contacto",
                label: "Boton para abrir form de contacto nuevo", // optional
                value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              });
              CloseModalcita1(true);
              OpenAddContacto(true);
            }}
            style={{
              width: "150px",
              backgroundColor: "#148f9f",
              color: "white",
            }}
          >
            Nuevo paciente
          </Button>
        </div>
        <div className="ModalAddCita">
          <form action="" onSubmit={() => { }} style={{ padding: "0" }}>
            <div className="Buscar">
              <p>Buscar:</p>
              <input
                style={{ width: "80%" }}
                type="text"
                className="inputblue"
                placeholder="Escribe nombre o celular"
                onChange={filterContacts}
              />
            </div>
            <br />
            <p>Contacto:</p>

            <Addcitas
              contactList={contactList}
              closeModal={CloseModalcita1}
              setIdContacto={setIdContacto}
              extras={[setDatosContacto, openAddCitasDisponibles]}
            />
          </form>
        </div>
      </ModalCitas1>

      <ModalCitasCalendar
        isOpen={isOpenModalcitaCalendar}
        closeModal={CloseModalcitaCalendar}
        optionClose={false}
      >
        <div className="TopCitasModal">
          <p>Nueva</p>{" "}
          <CloseIcon
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={CloseModalcitaCalendar}
          />
        </div> {loaderDetalle ? (
          <LoaderModals className={"ModalAddCita"} />
        ) : (
          <div className="ModalAddCita">

            <>
              <Header
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 999,
                  width: "100%",
                  background: "white",
                  padding: "0 10px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    type="button"
                    onClick={() => {
                      saveCita();
                    }}
                    style={{
                      width: "30%",
                      backgroundColor: "#148f9f",
                      color: "white",
                    }}
                  >
                    Crear
                  </Button>
                </div>
              </Header>
              <Content
                className="site-layout"
                style={{
                  padding: "0 10px",
                  width: "100%",
                  overflow: "initial",
                }}
              >
                <Calendar
                  mode="month"
                  locale={locale}
                  value={dayjs(AddFecha)}
                  fullscreen={false}
                  onSelect={(e) => {
                    let temp = e.$d;
                    let mesObtenido = temp.getMonth() + 1;
                    let mesCalculo =
                      mesObtenido.toString().length === 1
                        ? `0${mesObtenido}`
                        : mesObtenido;
                    let fecha_enviar =
                      temp.getFullYear() +
                      "-" +
                      mesCalculo +
                      "-" +
                      temp.getDate();
                    setHorarios([]);
                    setAddHorario(null);
                    setAddFecha(fecha_enviar);
                    getHorarios(fecha_enviar);
                  }}
                />

                <Row style={{ justifyContent: "space-between", gap: "1rem" }}>
                  {AddTipocita === "3" ? (
                    <Campos
                      label="Seleciona un rango de hora"
                      textError="Debe seleccionar un rango de hora"
                      valueError={ComboAddHorarioCita}
                    >
                      <TimePicker.RangePicker
                        inputReadOnly
                        placeholder={["Hora inicio", "Hora fin"]}
                        style={{ width: "100%" }}
                        value={[
                          !!HorarioInicio ? dayjs(HorarioInicio, "h:mm A") : "",
                          !!HorarioFin ? dayjs(HorarioFin, "h:mm A") : "",
                        ]}
                        use12Hours={true}
                        format="h:mm A"
                        onChange={(e) => {
                          // console.log(e);
                          if (!!e) {
                            setHorarioInicio(e[0].format("h:mm A"));
                            setHorarioFin(e[1].format("h:mm A"));
                            setComboAddHorarioCita("true");
                          } else {
                            setHorarioInicio("");
                            setHorarioFin("");
                            setComboAddHorarioCita("false");
                          }
                        }}
                        status={ComboAddHorarioCita === "false" && "error"}
                        locale={locale}
                      />
                    </Campos>
                  ) : (
                    <SelectAntd
                      label="Horario:"
                      requiredICon={true}
                      placeholder={
                        horarios.length === 0
                          ? "Sin horarios"
                          : "Elige un horario"
                      }
                      options={horarios}
                      setStateValue={setAddHorario}
                      setStateError={setComboAddHorarioCita}
                      textError="Debe seleccionar un horario"
                      value={AddHorario}
                      valueError={ComboAddHorarioCita}
                      style={{ width: "100%", fontWeight: "normal" }}
                      styleDiv={{ width: "45%" }}
                      disabled={horarios.length === 0 && true}
                    />
                  )}
                  <Campos
                    label="Tipo cita:"
                    requiredICon={true}
                    valueError={ComboAddTipoCita}
                    textError="Debe seleccionar un tipo"
                    styleDiv={{ width: "45%" }}
                  >
                    <Select
                      value={AddTipocita}
                      status={ComboAddTipoCita === "false" && "error"}
                      style={{ width: "100%", fontWeight: "normal" }}
                      placeholder="Elige un tipo de cita"
                      options={[
                        { value: "0", label: "Urgencia" },
                        { value: "1", label: "Primera Vez" },
                        { value: "2", label: "Seguimiento" },
                        // { value: "3", label: "Procedimiento" },
                      ]}
                      onSelect={(e) => {
                        if (e === "3") {
                          setHorario(null);
                          setMensajeAddNombrecontacto("true");
                          setMensajeAddApellidoPcontacto("true");
                          setMensajeAddApellidoMcontacto("true");
                          setMensajeAddCelularcontacto("true");
                          setMensajeAddCorreocontacto("true");
                          setMensajeContacto("true");
                        }
                        setAddTipocita(e);
                        setComboAddTipoCita("true");
                      }}
                    />
                  </Campos>
                </Row>

                <SelectAntd
                  label="Contacto:"
                  requiredICon={true}
                  value={
                    !!DatosContacto.id
                      ? {
                        value: "",
                        label: (
                          <div style={{ display: "flex", gap: "1" }}>
                            <img
                              className="ImgMedicoSelect"
                              src={
                                !!DatosContacto.imagen
                                  ? `https://${DatosContacto.imagen}`
                                  : ""
                              }
                            />{" "}
                            <div style={{ marginLeft: "1%" }}>
                              {DatosContacto.nombre}
                            </div>
                          </div>
                        ),
                      }
                      : null
                  }
                  style={{
                    width: "100%",
                    margin: "1% 0",
                  }}
                  placeholder="Selecciona un contacto"
                  onClick={() => {
                    OpenModalcita1(true);
                    CloseModalcitaCalendar(true);
                  }}
                  setStateError={setMensajeContacto}
                  valueError={MensajeContacto}
                  textError="Debe seleccionar un contacto"
                  readOnly={true}
                  open={false}
                  vacio={false}
                />

                <TextAreaAntd
                  label="Motivo de cita:"
                  value={AddMotivo}
                  placeholder="Escriba un motivo de cita (opcional)"
                  style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setAddMotivo}
                />

                <TextAreaAntd
                  label="Comentarios o notas:"
                  value={AddComentario}
                  placeholder="Escriba un comentario o nota (opcional)"
                  style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setAddComentario}
                />
              </Content>
            </>

          </div>)}
      </ModalCitasCalendar>

      <ModalAddCitas
        isOpen={isOpenAddCitasDisponibles}
        closeModal={CloseAddCitasDisponibles}
        optionClose={false}
      >
        <div className="TopCitasModal">
          <p>Nueva cita</p>{" "}
          <CloseIcon
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={CloseAddCitasDisponibles}
          />
        </div>
        <div className="ModalAddCita">
          {loaderDetalle ? (
            <LoaderModals className={"ModalAddCita"} />
          ) : (
            <>
              <header
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 999,
                  width: "100%",
                  background: "white",
                  padding: "0 10px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  type="button"
                  onClick={() => {
                    saveCita();
                  }}
                  style={{
                    width: "120px",
                    backgroundColor: "#148f9f",
                    color: "white",
                  }}
                >
                  Crear
                </Button>
              </header>

              <Content
                className="site-layout"
                style={{
                  padding: "0",
                  width: "100%",
                  overflow: "initial",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Calendar
                    locale={locale}
                    value={dayjs(AddFecha)}
                    fullscreen={false}
                    onSelect={(e) => {
                      let temp = e.$d;
                      let mesObtenido = temp.getMonth() + 1;
                      let mesCalculo =
                        mesObtenido.toString().length === 1
                          ? `0${mesObtenido}`
                          : mesObtenido;
                      let fecha_enviar =
                        temp.getFullYear() +
                        "-" +
                        mesCalculo +
                        "-" +
                        temp.getDate();
                      setHorarios([]);
                      setAddHorario(null);
                      setAddFecha(fecha_enviar);
                      getHorarios(fecha_enviar);
                    }}
                    mode="month"
                  />
                </div>
                <Tabs
                  centered
                  activeKey={TabsAddCita}
                  defaultActiveKey="1"
                  items={[
                    { key: "1", label: "Nueva" },
                    { key: "2", label: `Agendadas ${FechaHoraCita.fecha}` },
                  ]}
                  onChange={(e) => {
                    setTabsAddCita(e);
                  }}
                  style={{ background: "white" }}
                />

                {TabsAddCita === "1" && (
                  <form style={{ padding: "0 10px" }}>
                    <Row
                      style={{ justifyContent: "space-between", gap: "1rem" }}
                    >
                      {AddTipocita === "3" ? (
                        <Campos
                          label="Seleciona un rango de hora"
                          textError="Debe seleccionar un rango de hora"
                          valueError={ComboAddHorarioCita}
                        >
                          <TimePicker.RangePicker
                            inputReadOnly
                            placeholder={["Hora inicio", "Hora fin"]}
                            style={{ width: "100%" }}
                            value={[
                              !!HorarioInicio
                                ? dayjs(HorarioInicio, "h:mm A")
                                : "",
                              !!HorarioFin ? dayjs(HorarioFin, "h:mm A") : "",
                            ]}
                            use12Hours={true}
                            format="h:mm A"
                            onChange={(e) => {
                              // console.log(e);
                              if (!!e) {
                                setHorarioInicio(e[0].format("h:mm A"));
                                setHorarioFin(e[1].format("h:mm A"));
                                setComboAddHorarioCita("true");
                              } else {
                                setHorarioInicio("");
                                setHorarioFin("");
                                setComboAddHorarioCita("false");
                              }
                            }}
                            status={ComboAddHorarioCita === "false" && "error"}
                            locale={locale}
                          />
                        </Campos>
                      ) : (
                        <SelectAntd
                          label="Horario:"
                          requiredICon={true}
                          placeholder={
                            horarios.length === 0
                              ? "Sin horarios"
                              : "Elige un horario"
                          }
                          options={horarios}
                          setStateValue={setAddHorario}
                          setStateError={setComboAddHorarioCita}
                          textError="Debe seleccionar un horario"
                          value={AddHorario}
                          valueError={ComboAddHorarioCita}
                          style={{ width: "100%", fontWeight: "normal" }}
                          styleDiv={{ width: "45%" }}
                          disabled={horarios.length === 0 && true}
                          vacio={false}
                        />
                      )}
                      <Campos
                        label="Tipo cita:"
                        requiredICon={true}
                        valueError={ComboAddTipoCita}
                        textError="Debe seleccionar un tipo"
                        styleDiv={{ width: "45%" }}
                        vacio={false}
                      >
                        <Select
                          value={AddTipocita}
                          status={ComboAddTipoCita === "false" && "error"}
                          style={{ width: "100%", fontWeight: "normal" }}
                          placeholder="Elige un tipo de cita"
                          options={[
                            { value: "0", label: "Urgencia" },
                            { value: "1", label: "Primera Vez" },
                            { value: "2", label: "Seguimiento" },
                            // { value: "3", label: "Procedimiento" },
                          ]}
                          onSelect={(e) => {
                            if (e === "3") {
                              setHorario(null);
                              setMensajeAddNombrecontacto("true");
                              setMensajeAddApellidoPcontacto("true");
                              setMensajeAddApellidoMcontacto("true");
                              setMensajeAddCelularcontacto("true");
                              setMensajeAddCorreocontacto("true");
                              setMensajeContacto("true");
                            }
                            setAddTipocita(e);
                            setComboAddTipoCita("true");
                          }}
                        />
                      </Campos>
                    </Row>

                    <SelectAntd
                      label="Contacto:"
                      requiredICon={true}
                      value={
                        !!DatosContacto.id
                          ? {
                            value: "",
                            label: (
                              <div style={{ display: "flex", gap: "1" }}>
                                <img
                                  className="ImgMedicoSelect"
                                  src={
                                    !!DatosContacto.imagen
                                      ? `https://${DatosContacto.imagen}`
                                      : ""
                                  }
                                />{" "}
                                <div style={{ marginLeft: "1%" }}>
                                  {DatosContacto.nombre}
                                </div>
                              </div>
                            ),
                          }
                          : null
                      }
                      style={{
                        width: "100%",
                        margin: "1% 0",
                      }}
                      placeholder="Selecciona un contacto"
                      onClick={() => {
                        OpenModalcita1(true);
                        CloseAddCitasDisponibles(true);
                      }}
                      setStateError={setMensajeContacto}
                      valueError={MensajeContacto}
                      textError="Debe seleccionar un contacto"
                      readOnly={true}
                      open={false}
                      vacio={false}
                    />

                    <TextAreaAntd
                      label="Motivo de cita:"
                      value={AddMotivo}
                      placeholder="Escriba un motivo de cita (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setAddMotivo}
                    />

                    <TextAreaAntd
                      label="Comentarios o notas:"
                      value={AddComentario}
                      placeholder="Escriba un comentario o nota (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setAddComentario}
                    />
                  </form>
                )}

                {TabsAddCita === "2" && (
                  <div style={{ background: "#eaeef0" }}>
                    <CitasAgendadasCard listCitas={listCitasOcupadas} />
                    {listCitasOcupadas.length === 0 && (
                      <p className="noCitas">Sin citas agendadas</p>
                    )}
                  </div>
                )}
              </Content>
            </>
          )}
        </div>
      </ModalAddCitas>

      <ModalAddNota
        isOpen={isOpenModalAddNota}
        closeModal={CloseModalAddNota}
        styleDet={styleDet}
      >
        <div className="topCloseModalNotas1">
          <span>Datos de consulta</span>{" "}
          <CloseIcon
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={() => {
              CloseModalAddNota(true);
              setMotivoConsulta("");
              setTipoConsulta(null);
              setDatosContacto({ id: "", nombre: "", imagen: "" });
            }}
          />
        </div>
        <div className="ModalDetalleDatosConsulta">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "75px",
                height: "75px",
                borderRadius: "5rem",
                overflow: "hidden",
                border: "1px solid gray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="Img-cont"
            >
              <Image
                width={80}
                src={
                  !!DatosContacto.imagen
                    ? "https://" + DatosContacto.imagen
                    : Imag
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h4>{DatosContacto.nombre}</h4>
          </div>
          <div>
            <SelectAntd
              value={TipoConsulta}
              style={{ width: "100%", margin: "2% 0" }}
              placeholder="selecciona un tipo"
              options={[
                { value: "1", label: "Primera vez" },
                { value: "2", label: "Seguimiento" },
              ]}
              setStateValue={setTipoConsulta}
              setStateError={setMensajeTipoConsulta}
              valueError={MensajeTipoConsulta}
              textError="Debe elegir un tipo"
            />
            <TextAreaAntd
              value={MotivoConsulta}
              placeholder="Motivo de consulta"
              style={{
                margin: "2% 0",
                fontFamily: "sans-serif",
              }}
              columns="30"
              autoSize={true}
              setStateValue={setMotivoConsulta}
              setStateError={setMensajeMotivoConsulta}
              valueError={MensajeMotivoConsulta}
              textError={["Debe escribir un motivo de consulta"]}
              vacio={false}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  width: "100%",
                  background: "#148F9F",
                  border: "#148F9F",
                  color: "white",
                }}
                onClick={() => {
                  if (!!TipoConsulta && !!MotivoConsulta) {
                    CloseModalAddNota(true);
                    navigate("/app/NuevaConsulta", {
                      state: {
                        id: DatosContacto.id,
                        imagen: DatosContacto.imagen,
                        nombre: DatosContacto.nombre,
                        motivo: MotivoConsulta,
                        tipoConsulta: TipoConsulta,
                        idCita: idCita,
                      },
                    });
                  } else {
                    if (!!!TipoConsulta) {
                      setMensajeTipoConsulta("false");
                    }

                    if (!!!MotivoConsulta) {
                      setMensajeMotivoConsulta("false");
                    }
                    setOpen(true);
                    setTypeMessage("warning");
                    setMessage("Faltan campos por completar");
                  }
                }}
              >
                Continuar
              </Button>
            </div>
          </div>
        </div>
      </ModalAddNota>

      {ModalEliminarCita}

      <ModalEditContacto
        isOpen={isOpenEditContacto}
        closeModal={CloseEditContacto}
        styleDet={styleDet}
        optionClose={false}
      >
        <>

          <div>
            <div
              style={{
                width: "100%",
                background: "#148f9f",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1% 2%",
              }}
            >
              <span>Detalle de contacto</span>
              <CloseIcon
                style={{ fontSize: 20, cursor: "pointer" }}
                onClick={() => {
                  CloseEditContacto(true);
                  openModalDetalle(true);
                }}
              />
            </div>
            {loaderDetalle ? (
              <LoaderModals className={"ModalConsultasStyle"} />
            ) : (
              <div className="ModalConsultasStyle">
                {/* <div className="encabezadodetallecontacto"> */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Row>
                    <div>
                      <label htmlFor="imagenEditContacto">
                        <img
                          className="imagencont"
                          src={
                            !!ImagenContacto.url
                              ? ImagenContacto.url
                              : Iconos[19]
                          }
                        />
                      </label>
                      {infocontacto.YgiaUser === "Sin Ygia" && (
                        <input
                          type="file"
                          id="imagenEditContacto"
                          accept="/image/x-PNG,image/jpeg"
                          style={{ display: "none" }}
                        />
                      )}
                    </div>
                  </Row>
                </div>
                {/* </div> */}
                <form>
                  <p
                    style={{
                      textAlign: "center",
                      color: "rgb(20, 143, 159)",
                      fontWeight: "500",
                    }}
                  >
                    {"Usuario " + infocontacto.YgiaUser}
                  </p>
                  <div>
                    {Tipocontacto === "4" ? (
                      <InputAntd
                        label="Tipo de contacto:"
                        requiredICon={true}
                        className="inputblue"
                        value="Asistente"
                        style={{ margin: "1% 0" }}
                        placeholder="Tipo de contacto"
                        type="text"
                        vacio={true}
                        readOnly={true}
                      />
                    ) : (
                      <SelectAntd
                        label="Tipo de contacto:"
                        requiredICon={true}
                        value={Tipocontacto}
                        style={{ width: "100%", margin: "1% 0" }}
                        placeholder="Tipo de contacto"
                        options={[
                          { value: "1", label: "Médico" },
                          { value: "3", label: "Paciente" },
                        ]}
                        setStateValue={setTipocontacto}
                        disabled={true}
                      />
                    )}
                    <InputAntd
                      label="Nombre:"
                      requiredICon={true}
                      className="inputblue"
                      value={Nombre}
                      setStateValue={setNombre}
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu nombre"
                      setStateError={setMensajeNombre}
                      textError={[
                        "Debe escribir un nombre",
                        "Solo se permiten letras (max. 20 caracteres)",
                      ]}
                      type="text"
                      valueError={MensajeNombre}
                      vacio={false}
                      expresionRegular={expresiones.Letras}
                      maxLength={40}
                    />

                    <InputAntd
                      label="Apellido paterno:"
                      requiredICon={true}
                      className="inputblue"
                      value={ApellidoP}
                      setStateValue={setApellidoP}
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu primer apellido"
                      setStateError={setMensajeApellidoP}
                      textError={[
                        "Debe escribir un apellido",
                        "Solo se permiten letras (max. 20 caracteres)",
                      ]}
                      type="text"
                      valueError={MensajeApellidoP}
                      vacio={false}
                      expresionRegular={expresiones.Letras}
                      maxLength={20}
                    />

                    <InputAntd
                      label="Apellido materno:"
                      className="inputblue"
                      value={ApellidoM}
                      setStateValue={setApellidoM}
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu segundo apellido"
                      setStateError={setMensajeApellidoM}
                      textError={[
                        "",
                        "Solo se permiten letras (max. 20 caracteres)",
                      ]}
                      type="text"
                      valueError={MensajeApellidoM}
                      vacio={true}
                      expresionRegular={expresiones.Letras}
                      maxLength={20}
                    />

                    <InputAntd
                      label="Celular:"
                      className="inputblue"
                      value={Celular}
                      setStateValue={setCelular}
                      id="celular"
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu celular"
                      setStateError={setMensajeCelular}
                      textError={[
                        "",
                        "Celular solo puede contener numeros",
                        "Celular debe contener 10 dígitos",
                      ]}
                      type="text"
                      valueError={MensajeCelular}
                      vacio={true}
                      expresionRegular={expresiones.Numeros}
                      maxLength={10}
                    />

                    <InputAntd
                      label="Correo:"
                      className="inputblue"
                      value={Correo}
                      setStateValue={setCorreo}
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu correo eléctronico"
                      setStateError={setMensajeCorreo}
                      textError={["", "Formato incorrecto"]}
                      type="text"
                      valueError={MensajeCorreo}
                      vacio={true}
                      expresionRegular={expresiones.Correo}
                    />

                    <TextAreaAntd
                      label="Comentarios o notas:"
                      value={NotaContacto}
                      placeholder="Escriba un comentario o nota (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setNotaContacto}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "white",
                      }}
                      onClick={() => {
                        editContact();
                      }}
                    >
                      Guardar
                    </Button>
                  </div>
                </form>
              </div>)}
          </div>

        </>
      </ModalEditContacto>

      <ModalAddContacto
        isOpen={IsOpenAddContacto}
        closeModal={ClosedAddContacto}
        optionClose={false}
        styleDet={styleDet}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          <span>Nuevo paciente</span>

          <CloseIcon
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={() => {
              ClosedAddContacto(true);
              OpenModalcita1(true);
              cleanInputsContacto();
            }}
          />
        </div>
        <div className="scrolMotivoConsulta">
          {loaderDetalle ? (
            <LoaderModals className={"scrolMotivoConsulta"} />
          ) : (
            <div className="NuevoPacienteCitaDetall">
              <div style={{ textAlign: "center", marginBottom: "1%" }}>
                <label htmlFor="imgPerfilConsulta">
                  <img
                    style={{
                      width: " 45px",
                      height: "45px",
                      overflow: "hidden",
                      borderRadius: "5rem",
                      objectFit: "cover",
                      objectPosition: "center",
                      border: "2px solid #d7d7d7",
                    }}
                    src={!!ProfileImage.url ? ProfileImage.url : Iconos[0]}
                    alt=""
                  />
                  <input
                    id="imgPerfilConsulta"
                    style={{ display: "none" }}
                    type="file"
                    accept="/image/x-PNG,image/jpeg"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.type.substring(0, 5) === "image") {
                        setProfileImage({
                          name: e.target.value,
                          url: URL.createObjectURL(e.target.files[0]),
                          file: e.target.files[0],
                        });
                      }
                    }}
                  />
                </label>
              </div>
              <br />
              <InputAntd
                label="Nombre:"
                requiredICon={true}
                className="inputblue"
                value={AddNombrecontacto}
                setStateValue={setAddNombrecontacto}
                style={{ margin: "2% 0" }}
                placeholder="Nombre"
                setStateError={setMensajeAddNombrecontacto}
                textError={[
                  "Debe escribir un nombre",
                  "Solo se permiten letras",
                ]}
                type="text"
                valueError={MensajeAddNombrecontacto}
                vacio={false}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={40}
              />

              <InputAntd
                label="Apellido Paterno:"
                requiredICon={true}
                className="inputblue"
                value={AddApellidoPcontacto}
                setStateValue={setAddApellidoPcontacto}
                style={{ margin: "2% 0" }}
                placeholder="Apellido Paterno:"
                setStateError={setMensajeAddApellidoPcontacto}
                textError={[
                  "Debe escribir un apellido paterno",
                  "Solo se permiten letras",
                ]}
                type="text"
                valueError={MensajeAddApellidoPcontacto}
                vacio={false}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={25}
              />
              <InputAntd
                label="Apellido Materno:"
                className="inputblue"
                value={AddApellidoMcontacto}
                setStateValue={setAddApellidoMcontacto}
                style={{ margin: "2% 0" }}
                placeholder="Apellido Materno"
                setStateError={setMensajeAddApellidoMcontacto}
                textError={["", "Solo se permiten letras"]}
                type="text"
                valueError={MensajeAddApellidoMcontacto}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={25}
              />
              <InputAntd
                label="Celular:"
                className="inputblue"
                value={AddCelularcontacto}
                setStateValue={setAddCelularcontacto}
                id="celular"
                style={{ margin: "2% 0" }}
                placeholder="Celular:"
                setStateError={setMensajeAddCelularcontacto}
                textError={[
                  "",
                  "Celular solo puede contener numeros",
                  "Celular debe contener 10 dígitos",
                ]}
                type="text"
                valueError={MensajeAddCelularcontacto}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Numeros}
                maxLength={10}
              />

              <InputAntd
                label="Correo:"
                className="inputblue"
                value={AddCorreocontacto}
                setStateValue={setAddCorreocontacto}
                style={{ margin: "2% 0" }}
                placeholder="Correo:"
                setStateError={setMensajeAddCorreocontacto}
                textError={["", "Formato incorrecto"]}
                type="text"
                valueError={MensajeAddCorreocontacto}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Correo}
              />

              <TextAreaAntd
                label="Comentarios o notas:"
                value={AddNotaContacto}
                placeholder="Escriba un comentario o nota (opcional)"
                style={{
                  marginBottom: ".5%",
                  fontFamily: "sans-serif",
                }}
                columns="30"
                autoSize={true}
                setStateValue={setAddNotaContacto}
              />

              <div>
                <Button
                  style={{
                    width: "100%",
                    background: "#148f9f",
                    borderColor: "#148f9f",
                    color: "white",
                  }}
                  size="sm"
                  onClick={(e) => {
                    saveContactDos();
                  }}
                >
                  Crear Paciente
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalAddContacto>

      <ModalFicha
        isOpen={isOpenFichaContacto}
        closeModal={CloseFichaContacto}
        optionClose={false}
        styleDet={styleFichaContacto}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          Ficha de información
          <CloseIcon

            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={() => {
              CloseFichaContacto(true);
              OpenAlertConfirm(true);
            }}
          />
        </div>
        <div className="ModalFichaContacto">
          {loaderDetalle ? (
            <LoaderModals className={"ModalFichaContacto"} />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1% 2%",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  style={{
                    background: "#148f9f",
                    border: "0",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                  }}
                  onClick={() => {
                    addFichaContacto();
                  }}
                >
                  Guardar
                  <CheckCircleIcon fontSize="15px" />
                </Button>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Avatar
                  className="GeneralNoImage"
                  size={65}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  src={
                    <Image
                      src={
                        !!DatosContacto.imagen
                          ? `https://${DatosContacto.imagen}`
                          : Iconos[0]
                      }
                      alt=""
                    />
                  }
                />
              </div>


              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1%",
                }}
              >
                <b>
                  {!!DatosContacto.nombre ? DatosContacto.nombre : "Usuario"}
                </b>
              </div>

              <div style={{ padding: "1% 2%" }}>
                {/* INFORMACION GENERAL */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {MensajeSexo === "false" &&
                        MensajeNacimiento === "false" && (
                          <>
                            <CancelRounded color="error" />
                            &nbsp;
                          </>
                        )}
                      Información general
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <InputAntd
                      label="Tipo de sangre:"
                      className="inputblue"
                      value={TipoSangre}
                      setStateValue={setTipoSangre}
                      placeholder="Tipo de sangre"
                      vacio={true}
                      style={{ width: "30%" }}
                      maxLength={15}
                      disabled={
                        DataFichaId.historial !== null
                          ? !!DataFichaId.historial.tipoSangre
                            ? true
                            : false
                          : false
                      }
                    />
                    <TextAreaAntd
                      label="Alergias:"
                      value={Alergias}
                      placeholder="Alergias"
                      style={{
                        // margin: ".5% 0",
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setAlergias}
                      disabled={
                        DataFichaId.historial !== null
                          ? !!DataFichaId.historial.alergias
                            ? true
                            : false
                          : false
                      }
                    />

                    <TextAreaAntd
                      label="Quien Refiere:"
                      value={Refiere}
                      placeholder="Quien Refiere"
                      style={{
                        // margin: ".5% 0",
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setRefiere}
                      disabled={
                        DataFichaId.historial !== null
                          ? !!DataFichaId.historial.refiere
                            ? true
                            : false
                          : false
                      }
                    />

                    <Row className="rowsToCol">
                      <SelectAntd
                        label="Estado civil:"
                        value={EstadoCivil}
                        style={{ width: "100%" }}
                        placeholder="Seleccione un estado civil"
                        options={[
                          { value: "0", label: "Soltero/a" },
                          { value: "1", label: "Casado/a" },
                          { value: "2", label: "Viudo/a" },
                          { value: "3", label: "Divorciado/a" },
                          { value: "4", label: "Separado/a" },
                          { value: "5", label: "Concubinato" },
                        ]}
                        setStateValue={setEstadoCivil}
                      />
                      <InputAntd
                        className="inputblue"
                        value={Ocupacion}
                        setStateValue={setOcupacion}
                        label="Ocupación:"
                        placeholder="Escribe una ocupación"
                        vacio={true}
                      />
                    </Row>
                    <Row className="rowsToCol">
                      <SelectAntd
                        label="Género:"
                        value={Sexo}
                        style={{ width: "100%" }}
                        placeholder="Género"
                        options={[
                          { value: "Femenino", label: "Femenino" },
                          { value: "Masculino", label: "Masculino" },
                        ]}
                        setStateValue={setSexo}
                        setStateError={setMensajeSexo}
                        valueError={MensajeSexo}
                        textError={"Debe seleccionar un género"}
                        disabled={
                          DataFichaId.historial !== null
                            ? !!DataFichaId.ficha.sexo
                              ? true
                              : false
                            : false
                        }
                      />
                      <DatePickerAntd
                        label="Fecha de nacimiento:"
                        placeholder="DD/MM/AAAA"
                        style={{ width: "100%", background: "white" }}
                        value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                        setStateValue={setNacimiento}
                        setEdad={setEdad}
                        setStateError={setMensajeNacimiento}
                        valueError={MensajeNacimiento}
                        textError={["Debe elegir una fecha de nacimiento"]}
                        disabled={
                          DataFichaId.historial !== null
                            ? !!DataFichaId.ficha.fechaNacimiento
                              ? true
                              : false
                            : false
                        }
                      />
                    </Row>
                    <InputAntd
                      className="inputblue"
                      value={Nacionalidad}
                      setStateValue={setNacionalidad}
                      label="Nacionalidad:"
                      placeholder="Nacionalidad"
                      vacio={true}
                      disabled={
                        DataFichaId.historial !== null
                          ? !!DataFichaId.ficha.nacionalidad
                            ? true
                            : false
                          : false
                      }
                    />

                    <Row className="rowsToCol">
                      <Campos
                        label="Estado:"
                        children={
                          <Select
                            showSearch
                            style={{
                              width: "100%",
                              margin: ".5% 0",
                            }}
                            placeholder="Estado"
                            optionFilterProp="children"
                            filterOption={filterOptions}
                            filterSort={filterSort}
                            value={Estado}
                            onSelect={(e) => {
                              setEstado(e);
                              getLocalidades(e);
                            }}
                            options={EstadosList}
                            disabled={
                              DataFichaId.historial !== null
                                ? !!DataFichaId.ficha.estado
                                  ? true
                                  : false
                                : false
                            }
                          />
                        }
                      />

                      <Campos
                        label="Localidad:"
                        children={
                          <Select
                            showSearch
                            style={{
                              width: "100%",
                              margin: ".5% 0",
                            }}
                            placeholder="Localidad"
                            optionFilterProp="children"
                            filterOption={filterOptions}
                            filterSort={filterSort}
                            value={Localidad}
                            onSelect={(e) => {
                              setLocalidad(e);
                            }}
                            options={LocalidadesList}
                            disabled={
                              LocalidadesList.length === 0
                                ? true
                                : DataFichaId.historial !== null
                                  ? !!DataFichaId.ficha.estado
                                    ? true
                                    : false
                                  : false
                            }
                          />
                        }
                      />
                    </Row>
                  </AccordionDetails>
                </Accordionn>
                {/* DATOS DE PAREJA */}
                {!!!DataFichaId.historial ? (
                  <Accordionn>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Datos de pareja</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InputAntd
                        label="Nombre:"
                        className="inputblue"
                        value={NombrePareja}
                        setStateValue={setNombrePareja}
                        placeholder="Escriba un nombre completo"
                        vacio={true}
                        styleDiv={{ width: "100%" }}
                      />
                      <Row className="rowsToCol">
                        <InputAntd
                          className="inputblue"
                          value={OcupacionPareja}
                          setStateValue={setOcupacionPareja}
                          label="Ocupación:"
                          placeholder="Escribe una ocupación"
                          vacio={true}
                        />

                        <InputAntd
                          label="Edad:"
                          className="inputblue"
                          value={EdadPareja}
                          setStateValue={setEdadPareja}
                          placeholder="Edad"
                          vacio={true}
                          maxLength={2}
                        />
                      </Row>
                      <TextAreaAntd
                        label="Notas/Comentarios:"
                        value={NotasPareja}
                        placeholder="Escriba una nota o comentario"
                        style={{
                          marginBottom: ".5%",
                          fontFamily: "sans-serif",
                        }}
                        columns="30"
                        autoSize={true}
                        setStateValue={setNotasPareja}
                      />
                    </AccordionDetails>
                  </Accordionn>
                ) : (
                  !!!DataFichaId.historial.edadPareja &&
                  !!!DataFichaId.historial.ocupacionPareja &&
                  !!!DataFichaId.historial.nota &&
                  !!!DataFichaId.historial.nombrePareja && (
                    <Accordionn>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Datos de pareja</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <InputAntd
                          label="Nombre:"
                          className="inputblue"
                          value={NombrePareja}
                          setStateValue={setNombrePareja}
                          placeholder="Escriba un nombre completo"
                          vacio={true}
                          styleDiv={{ width: "100%" }}
                        />
                        <Row className="rowsToCol">
                          <InputAntd
                            className="inputblue"
                            value={OcupacionPareja}
                            setStateValue={setOcupacionPareja}
                            label="Ocupación:"
                            placeholder="Escribe una ocupación"
                            vacio={true}
                          />

                          <InputAntd
                            label="Edad:"
                            className="inputblue"
                            value={EdadPareja}
                            setStateValue={setEdadPareja}
                            placeholder="Edad"
                            vacio={true}
                            maxLength={2}
                          />
                        </Row>
                        <TextAreaAntd
                          label="Notas/Comentarios:"
                          value={NotasPareja}
                          placeholder="Escriba una nota o comentario"
                          style={{
                            // margin: ".5% 0",
                            marginBottom: ".5%",
                            fontFamily: "sans-serif",
                          }}
                          columns="30"
                          autoSize={true}
                          setStateValue={setNotasPareja}
                        />
                      </AccordionDetails>
                    </Accordionn>
                  )
                )}

                {/* EXPLORACION FISICA */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography> Exploración física</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="exploFisicaDetalle">
                      <InputAntd
                        label="Altura:"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        id="altura"
                        placeholder="Altura (Mtrs.)"
                        value={Altura}
                        setStateValue={setAltura}
                        vacio={true}
                        disabled={false}
                        extras={["IMC", setIMC, Peso]}
                      />

                      <InputAntd
                        label="Peso:"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        id="peso"
                        placeholder="Peso (Kg.)"
                        value={Peso}
                        setStateValue={setPeso}
                        vacio={true}
                        disabled={false}
                        extras={["IMC", setIMC, Altura]}
                      />

                      <InputAntd
                        label="IMC:"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="IMC"
                        value={IMC}
                        setStateValue={setIMC}
                        vacio={true}
                        readOnly={true}
                      />

                      <Campos
                        label="Tipo de peso"
                        children={
                          <Input
                            style={{ width: "100%" }}
                            type=""
                            placeholder="Tipo de peso"
                            value={
                              !!IMC
                                ? IMC < 18
                                  ? "Insuficiente"
                                  : IMC >= 18 && IMC <= 25
                                    ? "Peso normal"
                                    : IMC > 25 && IMC <= 30
                                      ? "Sobrepeso"
                                      : IMC > 30 && IMC <= 40
                                        ? "Obesidad"
                                        : IMC > 40 && "Obesidad mórbida"
                                : ""
                            }
                            readOnly={true}
                            status={
                              !!IMC
                                ? IMC < 18
                                  ? "warning"
                                  : IMC >= 18 && IMC <= 25
                                    ? ""
                                    : IMC > 25 && IMC <= 30
                                      ? "warning"
                                      : IMC > 30 && IMC <= 40
                                        ? "warning"
                                        : IMC > 40 && "error"
                                : ""
                            }
                          />
                        }
                      />
                    </div>
                    <div className="exploFisicaDetalle">
                      <InputAntd
                        label="T. Arterial"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="T. Arterial"
                        value={TArterial}
                        setStateValue={setTArterial}
                        vacio={true}
                        disabled={false}
                      />

                      <InputAntd
                        label="F. Cardiaca"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="F. Cardiaca"
                        value={FCardiaca}
                        setStateValue={setFCardiaca}
                        vacio={true}
                        disabled={false}
                      />

                      <InputAntd
                        label="F. Respiratoria"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="F. Respiratoria"
                        value={FRespiratoria}
                        setStateValue={setFRespiratoria}
                        vacio={true}
                        disabled={false}
                      />

                      <InputAntd
                        label="Temperatura"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="Temperatura"
                        value={Temperatura}
                        setStateValue={setTemperatura}
                        vacio={true}
                        disabled={false}
                      />

                      <InputAntd
                        label="O2"
                        style={{ width: "100%" }}
                        type=""
                        placeholder="O2"
                        styleLabel={{ fontSize: "14px" }}
                        value={SOxigeno}
                        setStateValue={setSOxigeno}
                        vacio={true}
                        disabled={false}
                      />
                    </div>
                  </AccordionDetails>
                </Accordionn>
              </div>
            </>
          )}
        </div>
      </ModalFicha>

      <ModalAlertConfirm
        isOpen={IsOpenAlertConfirm}
        closeModal={ClosedAlertConfirm}
        optionClose={true}
        styleDet={styleDet}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          <b>¡Advertencia!</b>
          <b style={{ cursor: "pointer" }} onClick={ClosedAlertConfirm}>
            x
          </b>
        </div>
        <div className="ModalListContactosConsult" style={{ padding: "2%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0",
            }}
          >
            <b>¿Desea regresar a la vista anterior?</b>
          </div>
          <div style={{ display: "flex", gap: "1%" }}>
            <Button
              style={{
                width: "100%",
                background: "#148f9f",
                borderColor: "#148f9f",
                color: "white",
              }}
              size="sm"
              onClick={() => {
                ClosedAlertConfirm(true);
                openFichaContacto(true);
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{
                width: "100%",
                background: "#148f9f",
                borderColor: "#148f9f",
                color: "white",
              }}
              size="sm"
              onClick={() => {
                cleanInputsFicha();
                ClosedAlertConfirm(true);
                openModalDetalle(true);
              }}
            >
              Atrás
            </Button>
            <Button
              style={{
                width: "100%",
                background: "#148f9f",
                borderColor: "#148f9f",
                color: "white",
              }}
              size="sm"
              onClick={() => {
                if (!!Sexo && !!Nacimiento) {
                  ClosedAlertConfirm(true);
                  openFichaContacto(true);
                  addFichaContacto();
                } else {
                  ClosedAlertConfirm(true);
                  openFichaContacto(true);
                  if (!!!Sexo) {
                    setMensajeSexo("false");
                  }
                  if (!!!Nacimiento) {
                    setMensajeNacimiento("false");
                  }

                  setOpen(true);
                  setTypeMessage("warning");
                  setMessage("Faltan campos por completar");
                }
              }}
            >
              Atrás y guardar
            </Button>
          </div>
        </div>
      </ModalAlertConfirm>

      <ModalCancelCita
        isOpen={IsOpenCancelCita}
        closeModal={ClosedCancelCita}
        styleDet={styleCancelMotivo}
        optionClose={false}
      >
        <>
          <div>
            <div className="TopCitasModal">
              <p style={{ color: "#ffffff" }}>Cancelación de cita</p>
              <CloseIcon
                style={{ fontSize: 20, cursor: "pointer" }}
                onClick={() => {
                  ClosedCancelCita(true);
                  setMotivoCancelacion("");
                  setMensajeMotivoCancelacion("true");
                  openModalDetalle(true);
                }}
              />
            </div>
            <div className="ModalCancelarCita">
              {loaderDetalle ? (
                <LoaderModals />
              ) : (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "75px",
                        height: "75px",
                        borderRadius: "5rem",
                        overflow: "hidden",
                        border: "2px solid #d5d5d5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      id="Img-cont"
                    >
                      <Image
                        width={80}
                        src={
                          !!DatosContacto.imagen
                            ? "https://" + DatosContacto.imagen
                            : Imag
                        }
                      />
                    </div>
                  </div>
                  <h3 style={{ textAlign: "center" }}>{DatosContacto.nombre}</h3>
                  <TextAreaAntd
                    label="Motivo de cancelación:"
                    value={MotivoCancelacion}
                    requiredICon={true}
                    placeholder="Escriba un motivo de cancelación"
                    style={{
                      marginBottom: ".5%",
                      fontFamily: "sans-serif",
                    }}
                    columns="30"
                    autoSize={true}
                    setStateValue={setMotivoCancelacion}
                    setStateError={setMensajeMotivoCancelacion}
                    valueError={MensajeMotivoCancelacion}
                    textError={["Debe escribir un motivo de cancelación"]}
                    vacio={false}
                  />
                  <Button
                    style={{
                      width: "100%",
                      background: "#148f9f",
                      borderColor: "#148f9f",
                      color: "white",
                      marginTop: "10px",
                    }}
                    size="sm"
                    onClick={(e) => {
                      cancelarCita();
                    }}
                  >
                    Cancelar cita
                  </Button>
                </>
              )}
            </div>
          </div>
        </>
      </ModalCancelCita>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={OpenSetting}
        autoHideDuration={6000}
        onClose={() => setOpenSetting(false)}
      >
        <Alert
          onClose={() => setOpenSetting(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
          <img style={{ width: "20px" }} src={Confi_white} alt="" />
        </Alert>
      </Snackbar>
    </Stack>
  );
}
export default Citas;
