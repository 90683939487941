import React, { useState } from 'react';
import { Label, LeyendaError } from './Formularios';
import "../../pages/styles/Registro.scss"
import { Button, Input } from 'antd';

const ButtonAntd = ({ children, onClick, style, tipo, htmlType }) => {

    // variable "TIPO"
    // 1 - BOTON AZUL
    // 2 - BOTON BLANCO

    return (
        <Button type='button' htmlType={htmlType} className={`${ tipo !== undefined ? tipo === 1  ? "GeneralBoton" : "GeneralBotonWhite" : "GeneralBoton"}`} style={style} onClick={onClick}>
            {children}
        </Button>
    );
}

export default ButtonAntd;