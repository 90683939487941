import { createSlice } from "@reduxjs/toolkit";

const initialState = { notificationsList: [] };

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        notifications: (state, action) => {
                const { notificationsList } = action.payload;
                state.notificationsList = notificationsList;
            
        },
    }
})


export const { notifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;