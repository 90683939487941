import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// importando scss
import "../comp-styles/CitasCard.scss";

// importar iconos
import Iconos from "../IconosDeSitio";
import Citasjson from "../GeneralComponents/General-Json";
import { Row } from "antd";
import PositionedMenu from "./menuvertical";
import { CancelOutlined, CancelRounded, CheckCircleOutline } from "@mui/icons-material";

function CitasAgendadasCard({ listCitas }) {
  // console.log("hola citas");
  return (
    <>
      {listCitas.map((cita) => (
        <div key={cita.IdCita}>
          <div className="PrinCardcitas2">
            <div
              className="menucitas"
              style={{ width: "90%" }}
              onClick={() => { }}>
              <div className="Containercitas2">
                <div className="CardC">
                  {cita.Status === 0 && (<CheckCircleOutline color="disabled" />)}
                  {cita.Status === 1 && (<CheckCircleOutline color="warning" />)}
                  {cita.Status === 2 && (<CancelOutlined color="error" />)}
                  {cita.Status === 3 && (<CheckCircleOutline color="success" />)}
                  <img
                    id={`img_card_cita${cita.IdCita}`}
                    className="BgrImg"
                    src={!!cita.Imagen_url ? `https://${cita.Imagen_url}` : Iconos[0]}
                    alt=''
                    onError={() => {
                      let img = document.getElementById(`img_card_cita${cita.IdCita}`);
                      img.setAttribute("src", Iconos[0]);
                    }}
                  />


                  <div style={{ width: "95%" }}>
                    <b>{cita.Nombre + " "}{cita.ApellidoPaterno + " "}{!!cita.ApellidoMaterno ? cita.ApellidoMaterno : ""}</b>
                    <Row style={{ width: "95%", justifyContent: "space-between", gap: "2%" }}>
                      <p style={{ fontWeight: "normal" }}>{cita.FechaCita}</p>
                      <p style={{ fontWeight: "normal" }}>{cita.HoraCita}</p>
                      <b style={{ color: cita.TipoCita === 0 ? "red" : cita.TipoCita === 1 ? "green" : "#148f9f" }}>
                        {cita.TipoCita === 0 ? "Urgencia" : cita.TipoCita === 1 ? "Primera vez" : "Seguimiento"}
                      </b>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default CitasAgendadasCard;
