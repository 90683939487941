import React, { useEffect, useState } from "react";
import "../pages/styles/Anuncios.scss";
import Anuncioscard from "../componentes/Anuncios/AnunciosCard";
import { UseModal } from "../hooks/UseModal";
import { Button, Checkbox, Input, Row } from "antd";
import ListaAnun from "../componentes/GeneralComponents/General-Json";
import {
  Formulario,
  Label,
  ContenedorBotonCentrado,
  Boton,
  MensajeExito,
  MensajeError,
} from "../componentes/GeneralComponents/Formularios2Grids";
import InputFile from "../componentes/GeneralComponents/agregarimagen";
import ModalAgregar from "../componentes/GeneralComponents/ModalDetalle";
import ModalDetalles from "../componentes/GeneralComponents/ModalDetalle";

import { getAnuncios_request, addAnuncios_request, addScheduleAnuncios_request } from "../services/anuncios_services";

//Importacion de iconos e imagenes
import Agregar from "../Img/Iconos/Agregar.svg";
import Imagen from "../Img/Caras/Recurso25.png";
import Iconos from "../componentes/IconosDeSitio";
import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextArea from "antd/es/input/TextArea";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import { Content } from "antd/es/layout/layout";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Anuncios() {
  // ******** VARIABLES ********
  const styleAgregar = {
    maxHeight: "calc(100vh - 52px)",
    // maxWidth: "calc(100vw - 52px)",
  };

  // ******** USE STATES ********

  // Modales
  const [isOpenAgregar, OpenAgregar, CloseAgregar] = UseModal(false);

  const [isOpenDetalle, OpenDetalle, CloseDetalle] = UseModal(false);

  const [toggleState, setToggleState] = useState(2);
  const texto = [{ Adjuntar: "Adjuntar", Adjuntar2: "+ Adjuntar imagen" }];


  const [ListaComunicados, setListaComunicados] = useState([]);
  const [ListaComunicadosFilter, setListaComunicadosFilter] = useState([]);
  const [DetalleComunicado, setDetalleComunicado] = useState("");

  const [ListaPublicidad, setListaPublicidad] = useState([]);
  const [ListaPublicidadFilter, setListaPublicidadFilter] = useState([]);

  const [ProgramarComunicado, setProgramarComunicado] = useState(0);

  const [Asunto, setAsunto] = useState("");
  const [Descripcion, setDescripcion] = useState("");
  const [ImagenAnuncio, setImagenAnuncio] = useState({ name: "", url: "", file: "" });
  const [FechaProgramada, setFechaProgramada] = useState("");

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [MensajeAsunto, setMensajeAsunto] = useState("true");
  const [MensajeDescripcion, setMensajeDescripcion] = useState("true");
  const [MensajeFechaProgramada, setMensajeFechaProgramada] = useState("true");

  // ******** FUNCIONES ********

  const toggleTab = (index) => {
    setToggleState(index);
  };

  async function getAnuncios() {
    setLoader(true);
    let response = await getAnuncios_request();
    if (response.ok) {
      // console.log(response.data);
      setListaComunicados(response.data.Comunicados);
      setListaComunicadosFilter(response.data.Comunicados);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  };

  async function addComunicado() {
    // console.log(Asunto);
    // console.log(Descripcion);
    // console.log(ImagenAnuncio);
    // return;
    setLoaderModal(true);
    if (!!Asunto && !!Descripcion) {
      if (ProgramarComunicado === 0) {
        let data = new FormData();
        data.append("Titulo", Asunto);
        data.append("Descripcion", Descripcion);
        // console.log();
        if (!!ImagenAnuncio.file) {
          data.append("Imagen", ImagenAnuncio.file, ImagenAnuncio.name);
        }
        let response = await addAnuncios_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Comunicado publicado");
          cleanInputs();
          CloseAgregar(true);
          getAnuncios();
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        if (!!FechaProgramada) {
          let data = new FormData();
          data.append("Titulo", Asunto);
          data.append("Descripcion", Descripcion);
          if (!!ImagenAnuncio.file) {
            data.append("Imagen", ImagenAnuncio.file, ImagenAnuncio.name);
          }
          data.append("Fecha", FechaProgramada);
          let response = await addScheduleAnuncios_request(data);

          if (response.ok) {
            setOpen(true);
            setTypeMessage("success");
            setMessage("Comunicado programado");
            cleanInputs();
            CloseAgregar(true);
            getAnuncios();
          } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
          }
        } else {
          setMensajeFechaProgramada("false");
          setOpen(true);
          setTypeMessage("warning");
          setMessage("Faltan campos por completar");
        }
      }
    } else {
      if (!!!MensajeAsunto) {
        setMensajeAsunto("false");
      }
      if (!!!MensajeDescripcion) {
        setMensajeDescripcion("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderModal(false);
    return;
  }

  function cleanInputs() {
    setAsunto("");
    setDescripcion("");
    setImagenAnuncio({ name: "", url: "", file: "" });
    setFechaProgramada("");
    setMensajeAsunto("true");
    setMensajeDescripcion("true");
    setMensajeFechaProgramada("true");
  }

  useEffect(() => {
    getAnuncios();

    return;
  }, [])


  return (
    <Stack>
      {loader ? (<Loader />) : (
        <>
          <NavbarNuevo
            mensajesError={{
              setOpen: setOpen,
              setTypeMessage: setTypeMessage,
              setMessage: setMessage,
            }}
            modulo={"Anuncios"}
            searchBar={
              <>
                <div className="EncabezadoA">
                  <div className="espaciadoA">
                    <div>
                      <h3>Anuncios</h3>
                    </div>
                    <div>
                      <img onClick={OpenAgregar} style={{ width: "20px", cursor: "pointer" }} src={Agregar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="Contenedorbtn">
                  <button className={toggleState === 2 ? "containerAnun  active-containerAnun" : "containerAnun"} onClick={() => toggleTab(2)}>
                    Comunicados
                  </button>
                </div>
              </>
            } />

          <Content
            className="site-layout"
            style={{
              padding: '0 10px',
              width: "100%",
              overflow: 'initial',
            }}
          >
            <Anuncioscard OpenDetailAnuncios={OpenDetalle} lista={ListaComunicados} setDetalleComunicado={setDetalleComunicado} />
          </Content>
        </>
      )}

      <ModalAgregar styleDet={styleAgregar} isOpen={isOpenAgregar} closeModal={CloseAgregar}>
        <div className="TopdetailAnun">
          <span>Crear comunicado</span>
          <span style={{ cursor: "pointer" }} onClick={CloseAgregar}>X</span>
        </div>
        {loaderModal ? (<LoaderModals />) : (
          <div className="ContenedormodalA">
            <form action="" style={{ padding: "0", width: "100%" }}>
              <div style={{ padding: "1% 2%", }}>
                <p style={{ textAlign: "left", margin: "0" }}>Asunto:</p>
                <Input
                  value={Asunto}
                  style={{ marginBottom: "4%" }}
                  type="text"
                  placeholder="Escribir asunto"
                  onChange={(e) => {
                    setAsunto(e.target.value);
                  }}
                />
                <p style={{ textAlign: "left", margin: "0" }}>Contenido del mensaje:</p>
                <TextArea
                  value={Descripcion}
                  style={{ width: "100%", marginBottom: "5%" }}
                  className="inputblue"
                  cols="30"
                  rows="5"
                  placeholder="Escribir el mensaje"
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
                <InputFile texto={"Adjuntar imagen"} archivosAdmitidos={1} setImagen={setImagenAnuncio} />
                <br />
                <br />
                <Checkbox onChange={(e) => {
                  // console.log(e.target.checked);
                  if (e.target.checked) {
                    setProgramarComunicado(1);
                  } else {
                    setProgramarComunicado(0);
                  }
                }}> Programar comunicado</Checkbox>

                {ProgramarComunicado === 1 ? (
                  <>
                    <p style={{ textAlign: "left", paddingTop: "5%" }}>Fecha:</p>
                    <Input style={{ marginBottom: "2%" }} type="date" />
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                  </>
                )}
                <ContenedorBotonCentrado>
                  {/* {ProgramarComunicado === 0 ? (
                  <Boton style={{ width: "45%", height: "30px", backgroundColor: "#148f9f", color: "white" }}>Publicar ahora</Boton>
                ) : (
                  <Boton style={{ width: "45%", height: "30px", backgroundColor: "#148f9f", color: "white" }}>Programar</Boton>
                )} */}
                  <Boton
                    type="button"
                    onClick={() => addComunicado()}
                    style={{ width: "45%", height: "30px", backgroundColor: "#148f9f", color: "white" }}>Publicar</Boton>
                  <br />
                </ContenedorBotonCentrado>
              </div>
            </form>
          </div>
        )}
      </ModalAgregar>

      <ModalDetalles styleDet={styleAgregar} isOpen={isOpenDetalle} closeModal={CloseDetalle} optionClose={true}>
        <div className="TopdetailAnun">
          <span>Detalle comunicado</span>
          <span style={{ cursor: "pointer" }} onClick={CloseDetalle}>X</span>
        </div>
        <div className="ContenedormodalA">
          <form action="" style={{ padding: "0" }}>
            <div style={{ padding: "1% 2%" }}>
              <div>
                <div>
                  {!!DetalleComunicado.Imagen && (<img className="imgDetalleAnuncio" src={`https://${DetalleComunicado.Imagen}`} alt="" />)}
                  <h3 style={{ padding: "2% 0" }}>{DetalleComunicado.Titulo}</h3>
                  <p style={{ padding: "2% 0" }}>{DetalleComunicado.Descripcion}</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalDetalles>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={typeMessage} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
export default Anuncios;