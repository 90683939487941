import React from 'react'
import Iconos from "../IconosDeSitio";
import "../comp-styles/ConsultasCard.scss"
import { Row } from 'antd';
import PositionedMenu from './menuvertical';
import Avatar from 'antd/es/avatar/avatar';
export default function ConsultasCard({ setOpenDetail, lista, setIdConsulta, navigateEditConsulta, setDatosContacto, expediente }) {
  return (
    lista.map((consulta, index) => (
      <div
        className='ContenedorCardConsul'
        key={index}
      >
        <div
          onClick={() => {
            // console.log("ABRIENDO DETALLE");
            setOpenDetail(consulta.IdConsulta);
            setDatosContacto({ id: consulta.IdContacto, nombre: consulta.Paciente, imagen: consulta.Imagen_url });
            setIdConsulta(consulta.IdConsulta);
          }}
          style={{ display: "flex", width: "100%", margin:"0.5%", gap:"1%" }}
          
        ><Avatar size={45} src={consulta.Imagen_url !== null ? "https://" + consulta.Imagen_url : Iconos[0]}/>
          <div style={{ width: "100%" }}>
            <b>{consulta.Paciente}</b>
            <Row style={{ justifyContent: "space-between" }}>
              <p>{consulta.FechaConsulta}</p>
              <p>{consulta.Hora}</p>
            </Row>
          </div>
        </div>
        <PositionedMenu
          setIdConsulta={setIdConsulta}
          extras={[
            consulta,
            navigateEditConsulta,
            setDatosContacto,
            expediente
          ]}

        />
      </div>
    ))
  )
}
