import React, { useState } from 'react';
import { Label, LeyendaError } from '../GeneralComponents/Formularios';
import "../../pages/styles/Registro.scss"
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import "dayjs/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";




const DatePickerAntd = ({ value, setStateValue, type, label, placeholder, id, textError, setStateError, valueError, disabled, 
    readOnly, vacio, maxLength, className, style, styleDiv, extras, styleLabel, mayorEdad, setEdad, requiredICon }) => {
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

    const [MensajeError, setMensajeError] = useState(textError !== undefined ? textError[0] : "");

    function getEdad(dateString) {
        // console.log(dateString.replace(/-/g, "/"));
        let hoy = new Date()
        let fechaNacimiento = new Date(dateString.replace(/-/g, "/"))
        let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
        let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
        if (
            diferenciaMeses < 0 ||
            (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
        ) {
            edad--
        }
        // console.log("EDAD: "+edad);
        if (setEdad !== undefined) {
            setEdad(edad);
        }
        if (edad >= 18) {
            // setEdad(true);
            return true;
        } else {
            // setEdad(false);
            return false;
        }

    }

    const onSelect = (e) => {
        let temp = e.$d;
        let mesObtenido = temp.getMonth() + 1;
        // console.log(mesObtenido);
        let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
        // console.log(mesCalculo);
        let fecha_enviar = temp.getFullYear() + "-" + mesCalculo + "-" + temp.getDate();
        // console.log(fecha_enviar);
        setStateValue(fecha_enviar);
        let edad = getEdad(fecha_enviar);
        if (mayorEdad !== undefined) {
            if (edad) {
                setStateError("true");
            } else {
                setMensajeError(textError[1]);
                setStateError("false");
            }
        } else {

            if (setStateError !== undefined) {
                setStateError("true");
            }
        }

    }
    const onBlur = (e) => {
        // console.log(e.target.value);
        // console.log(e.target.value.split("-"));
        let arrayFechaFormat = e.target.value.split("-");
        let arrayFecha = e.target.value.split("/");
        if (arrayFecha.length === 3) {
            let fecha = new Date(`${arrayFecha[1]}/${arrayFecha[0]}/${arrayFecha[2]}`);
            // let temp = e.$d
            // console.log(fecha.getMonth());
            let mesObtenido = fecha.getMonth() + 1;
            // console.log(mesObtenido);
            let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
            // console.log(mesCalculo);
            let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
            // console.log(fecha_enviar);
            setStateValue(fecha_enviar);
            let edad = getEdad(fecha_enviar);
            if (mayorEdad !== undefined) {
                if (edad) {
                    setStateError("true");
                } else {
                    setMensajeError(textError[1]);
                    setStateError("false");
                }
            } else {
                if (setStateError !== undefined) {
                    setStateError("true");
                }
            }
        } else if (arrayFechaFormat.length === 3) {
            let fecha = new Date(`${arrayFechaFormat[1]}/${arrayFechaFormat[0]}/${arrayFechaFormat[2]}`);
            // let temp = e.$d
            // console.log(fecha.getMonth());
            let mesObtenido = fecha.getMonth() + 1;
            // console.log(mesObtenido);
            let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
            // console.log(mesCalculo);
            let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
            // console.log(fecha_enviar);
            setStateValue(fecha_enviar);
            let edad = getEdad(fecha_enviar);
            if (mayorEdad !== undefined) {
                if (edad) {
                    setStateError("true");
                } else {
                    setMensajeError(textError[1]);
                    setStateError("false");
                }
            } else {
                if (setStateError !== undefined) {
                    setStateError("true");
                }
            }
        }

    }

    return (
        <div style={styleDiv}>
            {(label !== undefined && !!label) && (<Label valido={valueError}>{vacio !== undefined ? (!vacio && requiredICon) && <b style={{ color: "#ff4d4f", fontSize: "14px", fontFamily: "SimSun,sans-serif" }}>*&nbsp;</b> : ""}{label}</Label>)}
            <DatePicker
                placeholder={placeholder}
                style={style}
                value={!!value ? dayjs(value) : ""}
                status={valueError !== undefined && !!valueError ? valueError === 'true' ? "" : "error" : ""}
                format={dateFormatList}
                locale={locale}
                onSelect={onSelect}
                onBlur={onBlur}
                disabled={disabled !== undefined && disabled}
            />
            <LeyendaError valido={valueError}>{MensajeError}</LeyendaError>

        </div>
    );
}

export default DatePickerAntd;