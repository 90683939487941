import React, { useState } from 'react';
import { Label, LeyendaError } from '../GeneralComponents/Formularios';
import "../../pages/styles/Registro.scss"
import TextArea from "antd/es/input/TextArea";

const TextAreaAntd = ({ value, setStateValue, label, placeholder, id, textError, setStateError, valueError, expresionRegular,
	disabled, readOnly, vacio, maxLength, className, style, styleDiv, rows, columns, autoSize, extras, requiredICon }) => {
	const [MensajeError, setMensajeError] = useState(textError !== undefined ? textError[0] : "");
	const onChange = (e) => {
		setStateValue(e.target.value);
		if (vacio !== undefined) {
			if (!vacio) {
				if (e.target.value.trim().length > 0) {
					setStateError("true");
				} else {
					setMensajeError(textError[0]);
					setStateError("false");
				}
			} else {
				if (setStateError !== undefined) {
					setStateError("true");
				}
			}
		} else {
			if (setStateError !== undefined) {
				setStateError("true");
			}

		}

		if (extras !== undefined) {
			if (extras[0] === "acordionError") {
				extras[1](true);
			}
		}
	}

	const validacion = () => {
		if (expresionRegular) {
			if (expresionRegular.test(value)) {
				setStateError("true");
			} else {
				if (vacio) {
					if (value.length > 0) {
						setMensajeError(textError[1]);
						setStateError("false");
					} else {
						setStateError("true");
					}
				} else {
					if (value.trim().length > 0) {
						setMensajeError(textError[1]);
					} else {
						setMensajeError(textError[0]);
					}
					setStateError("false");
				}

			}
		}
	}



	return (
		<div style={styleDiv}>
			{(label !== undefined && !!label) && (<Label valido={valueError}>{vacio !== undefined ? (!vacio && requiredICon) && <b style={{ color: "#ff4d4f", fontSize: "14px", fontFamily: "SimSun,sans-serif" }}>*&nbsp;</b> : ""}{label}</Label>)}
			{/* {(label !== undefined && !!label) && (<Label valido={valueError}>{label}</Label>)} */}
			<TextArea
				style={style}
				className={className}
				placeholder={placeholder}
				id={id}
				value={value}
				onChange={onChange}
				onKeyUp={validacion}
				disabled={disabled !== undefined ? disabled ? true : false : false}
				readOnly={readOnly ? true : false}
				maxLength={!!maxLength ? maxLength : ""}
				status={valueError !== undefined ? valueError === 'true' ? "" : "error" : ""}
				rows={rows !== undefined ? rows : ""}
				cols={columns !== undefined ? columns : ""}
				autoSize={autoSize}
			/>
			<LeyendaError valido={valueError}>{MensajeError}</LeyendaError>

		</div>
	);
}

export default TextAreaAntd;