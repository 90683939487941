// importar iconos de img/iconos
import icoPerfil1 from "../Img/perfil.jpg";
import icoperfil2 from "../Img/Caras/Recurso23.png";
import icoPerfil3 from "../Img/no-image.png"
import icoNotific from "../Img/Iconos/Notificaciones.svg";
import citas from "../Img/Iconos/citas.svg";
import contactos from "../Img/Iconos/Contactos.svg";
import Anuncios from "../Img/Iconos/Anuncios.svg";
import Chat from "../Img/Iconos/chat.svg";
import Consultas from "../Img/Iconos/consultas.svg"
import resetas from "../Img/Iconos/Reseta.svg";
import estudios from"../Img/Iconos/Estudios.svg";
import expediente from"../Img/Iconos/Expediente.svg";
import asisistentes from"../Img/Iconos/Asistentes.svg";
import puntoDeVenta from "../Img/Iconos/PuntoDeVenta.svg";
import IngresosEgresos from "../Img/Iconos/ingresos.svg";
import Metodos from "../Img/Iconos/Metodos.svg"
import reportes from"../Img/Iconos/Reportes.svg"
import vinculacion from "../Img/Iconos/Vinculacion.svg"
import configuracion from "../Img/Iconos/Configuracion.svg"
import agregar from "../Img/Iconos/Agregar.svg"
import Aseptadas from "../Img/Iconos/Aceptar.svg"
import canceladas from "../Img/Iconos/cancelar.svg"
import pdf from "../Img/pdf.png"
import Atras from"../Img/Iconos/atras.svg"
import Agregar2 from "../Img/Iconos/add.svg"
import Contactoexpediente from "../Img/Iconos/Expedientecontactos.svg"
import Contactochat from "../Img/Iconos/chatsContactos.svg"
import menutrespuntos from "../Img/Iconos/trepuntos.svg"
import Filtro from "../Img/Iconos/Filtro.svg"

//json de iconos
export default [
    // icono de Perfil-Iconos[0]
    icoPerfil1,
    // icono de Perfil-Iconos[1]
    icoperfil2,
    // icono de Notificaciones-Iconos[2] 
    icoNotific, 
    // icono de Citas-Iconos[3]
    citas, 
    // icono de contactos-Icono[4]
    contactos, 
    // icono de Anuncios-Icono [5]
    Anuncios,
    // icono de chat-Icono [6]
    Chat,
    // icono de consultas-Icono [7]
    Consultas,
    // icono de Resetas-Icono [8] 
    resetas,
    // icono de estudios-Icono [9]
    estudios, 
    // icono de expediente-Icono [10]
    expediente,
    // icono de asistentes-Icono [11] 
    asisistentes,
    // icono de Punto de venta-Icono [12]
    puntoDeVenta, 
    // icono de Ingresos y egresos-Icono [13]
    IngresosEgresos,
    // icono de Metodos-Icono [14]
    Metodos,
    // icono de Reportes-Icono [15]
    reportes,
    // icono de vinculacion-Icono [16]   
    vinculacion,
    // icono de configuracion-Icono [17]
    agregar, 
    // icono de configuracion-Icono [18]
    configuracion,
    // Sin imagen- icono[19]
    icoPerfil3,
    // icono Citas aseptadas- icono[20]
    Aseptadas,
    // Icono de citas Canceladas- icono[21]
    canceladas,
    // Icono de citas Canceladas- icono[22]
    pdf,
    // Icono de citas Canceladas- icono[23]
    Atras,
    // Icono de citas Canceladas- icono[24]
    Agregar2,
    // Icono de contactos Expediente- icono[25]
    Contactoexpediente,
    // Icono de contactos Chat- icono[26]
    Contactochat,
    // Iconno de menu de tres puntos[27]
    menutrespuntos,
    // Iconno de menu de tres puntos[28]
    Filtro
];
