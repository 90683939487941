import axios from "axios"
import baseUrl from "./apiUrl";

export async function getCargos_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/Cobros",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta.Cobros
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getCobrosFilter_request(filter, filterData) {
    let routeFilter = "";
    // console.log(filter);
    switch (filter) {
        case 1:
            routeFilter = "CobrosHoy";
            break;

        case 2:
            routeFilter = "CobrosAyer";
            break;

        case 3:
            routeFilter = "CobrosSemana";
            break;

        case 4:
            routeFilter = "CobrosPeriodo";
            break;

        default:
            break;
    }
    try {
        let resp;
        // console.log(filterData);
        // console.log(routeFilter);
        const response = await axios({
            url: baseUrl + `/${routeFilter}`,
            method: "POST",
            data: filterData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta.Cobros
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function detailCobro_request(idCobro) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CobroFormato/" + idCobro,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addCobroEfectivo_request(cobroData, idContacto) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CobroNuevo/" + idContacto,
            method: "POST",
            data: cobroData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("AGREGAR COBRO EFECTIVO");
        // console.log(response);
        if (response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR REGISTRO ");
        // console.log(error);
        // console.log(error.response);
        // console.log(error.response.status);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addCobroTarjeta_request(cobroData, idContacto) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CobroTarjeta/" + idContacto,
            method: "POST",
            data: cobroData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("AGREGAR COBRO TARJETA");
        // console.log(response.status);
        if (response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR REGISTRO ");
        // console.log(error.response);
        // console.log(error.response.status);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addCobroTransfer_request(cobroData, idContacto) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CobroTarjeta/" + idContacto,
            method: "POST",
            data: cobroData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("AGREGAR COBRO TARJETA");
        // console.log(response.status);
        if (response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR REGISTRO ");
        // console.log(error.response);
        // console.log(error.response.status);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function payCobro_request(cobroData, idCobro) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/PagarCobroPendiente/" + idCobro,
            method: "POST",
            data: cobroData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("AGREGAR COBRO EFECTIVO");
        console.log(response);
        if (response.status === 200) {

            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        console.log("ERROR");
        console.log(error.response);
        // console.log("ERROR PAGAR COBRO ");
        // console.log(error);
        // console.log(error.response);
        // console.log(error.response.status);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function cancelarCobro_request(idCobro) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CobrosCancelar/" + idCobro,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}