import axios from "axios"
import baseUrl from "./apiUrl";

export async function getAsistentes_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CargarAsistentes",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function detailAsistentes_request(idAssistant) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/ObtenerPermisos/" + idAssistant,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getAsistentesToConfig_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/listaAsistentes",
            method: "GET",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function editAssitant_request(AssistantData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/EditarAsistente",
            method: "POST",
            data: AssistantData,
            headers:
            {

                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("EDITAR ASISTENTE SERVICIO");
        // console.log(response.status);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        console.log(error);
        // console.log("ERROR EDITAR ASISTENTE SERVICIO ");
        // console.log(error.response);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addAssitant_request(AssisstantData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/AltaAsistenteMedico",
            method: "POST",
            data: AssisstantData,
            headers:
            {

                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("EDITAR ASISTENTE SERVICIO");
        // console.log(response.status);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR AGREGAR ASISTENTE SERVICIO ");
        // console.log(error.response);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function deleteAsistentes_request(idAssistant) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/EliminarAsistente/" + idAssistant,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getDoctorsLink_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/listaMedicos",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getDoctorsToAdd_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/Medicos",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function loginMedico_request(idMedico) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/seleccionarMedico/" + idMedico,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            if (document.domain === "localhost") {
                console.log(response.data.Respuesta);
            }
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}