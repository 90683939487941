import { borderBottom, padding } from "@mui/system";
import React from "react";
import "./styles/Facturacion.scss";
import Inputt from "../componentes/GeneralComponents/Input";
import { useState } from "react";
import {
  Formulario,
  Boton,
  ContenedorBotonCentrado,
} from "../componentes/GeneralComponents/Formularios2Grids";

import Modal from "../componentes/GeneralComponents/ModalDe2";
import { UseModal } from "../hooks/UseModal";
import FacturacionCard from "../componentes/Facturacion/FacturacionCard";
import Agregar from "../Img/Iconos/Agregar.svg"

export default function Facturacion() {
  const [isOpenModal, OpenModal, CloseModal] = UseModal(false);

  // Declarando Inputs
  const [Rfc, setRfc] = useState({ campo: "", valido: null });
  const [RazonSocial, setRazonSocial] = useState({ campo: "", valido: null });
  const [RegimenFiscal, setRegimenFiscal] = useState({
    campo: "",
    valido: null,
  });
  const [CodigoPostal, setCodigoPostal] = useState({ campo: "", valido: null });
  const [CertificadoSat, setCertificadoSat] = useState({
    campo: "",
    valido: null,
  });
  const [PrivateKeySat, setPrivateKeySat] = useState({
    campo: "",
    valido: null,
  });
  const [ContraKeySat, setContraKeySat] = useState({ campo: "", valido: null });

  return (
    <div className="GeneralContainerFacturas">
      <div style={{ background: "#fff", padding: "1%" }}>
        <div
          style={{
            background: "#fff",
            padding: "1%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ color: "#148f9f", cursor: "pointer" }}
            onClick={OpenModal}
          >
            <b>Modulo facturación</b>
          </div>
          <div
            style={{ color: "#148f9f", cursor: "pointer" }}
            onClick={OpenModal}
          >
            <b>Datos fiscales <img style={{width:"16px",height:"16px"}} src={Agregar} alt="" /></b>
          </div>
        </div>
        <input
          className="inputblue"
          type="text"
          placeholder="Buscar factura..."
        />
      </div>
      <div className="TablaFacturacion">
        <FacturacionCard/>
      </div>
      <Modal isOpen={isOpenModal} closeModal={CloseModal}>
        <p>
          <b>Introduce tus datos de Facturación</b>
        </p>
        <Formulario style={{ padding: "0", borderTop: "1px solid #d7d7d7" }}>
          <Inputt
            estado={Rfc}
            cambiarEstado={setRfc}
            tipo="text"
            label="RFC"
            placeholder="Escribe tu RFC..."
            name="rfc"
            leyendaError=""
            // funcion={}
          />
          <Inputt
            estado={RazonSocial}
            cambiarEstado={setRazonSocial}
            tipo="text"
            label="Razón social"
            placeholder="Escribe tu razón social"
            name="RazonSocial"
            leyendaError=""
          />
          <Inputt
            estado={RegimenFiscal}
            cambiarEstado={setRegimenFiscal}
            tipo="text"
            label="Régimen fiscal"
            placeholder="Régimen fiscal"
            name="RegFiscal"
            leyendaError=""
          />
          <Inputt
            estado={CodigoPostal}
            cambiarEstado={setCodigoPostal}
            tipo="text"
            label="C.P."
            placeholder=" Código Postal fiscal"
            name="CadigoPostal"
            leyendaError=""
          />
          <Inputt
            estado={CertificadoSat}
            cambiarEstado={setCertificadoSat}
            tipo="text"
            label="Certificado del sat (.CER)"
            placeholder="Certificado del sat (.CER)"
            name="CertifSat"
            leyendaError=""
          />
          <Inputt
            estado={PrivateKeySat}
            cambiarEstado={setPrivateKeySat}
            tipo="text"
            label=" Private key del sat (.KEY)"
            placeholder="Private key del sat (.KEY))"
            name="PivSat"
            leyendaError=""
          />
          <Inputt
            estado={ContraKeySat}
            cambiarEstado={setContraKeySat}
            tipo="text"
            label="Contraseña del key del sat"
            placeholder="Contraseña del key del sat"
            name="ContraKeySat"
            leyendaError=""
          />

          <ContenedorBotonCentrado>
            <button className="btn1" style={{ width: "200px" }} type="submit">
              Guardar datos
            </button>
          </ContenedorBotonCentrado>
        </Formulario>
      </Modal>
    </div>
  );
}
