import "./GenaralStyles/ModalDetalle.scss"

const ModalDetalle = ({ children, isOpen, styleDet, closeModal, optionClose, extras }) => {


  const handleModalContainerClick = e => e.stopPropagation();

  return (
    <div onClick={() => {
      if (optionClose || optionClose === undefined) {
        closeModal(true);
      }
      if (extras !== undefined) {
        if (extras[0] === 1) {
          extras[1](0);
          extras[2]("");
        }
        if (extras[0] === 11) {
          extras[1]("");
        }
        if (extras[0] === "detalle_consulta") {
          extras[1]("");
          extras[2]("");
          extras[3]();
        }
      }
    }} className={isOpen ? "modalDetalle is-open" : "modalDetalle"}>
      <div className={`modalDetalle is-open`}>
        <div style={styleDet} className="modalDetalle-container" onClick={handleModalContainerClick}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default ModalDetalle