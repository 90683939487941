import { Carousel } from 'antd';
import React from 'react';
const contentStyle = {
  margin: 0,
  height: '500px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#148f9f',
};
const Slider = () => {
  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };
  return (
    <Carousel afterChange={onChange} className='carrusel'>
      <div>
        <div style={{ paddingTop: "50%", paddingLeft: "15%", paddingRight: "15%" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            {/* <img style={{margin:"auto",width:"15%", marginTop:"5%",marginBottom:"20%"}} src={Logo} alt="" /> */}
          </div>
          <h1 style={{ color: "white", textAlign: "left" }}>Somos tu mejor aliado con tus pacientes </h1>
          <p style={{ color: "white", textAlign: "left", fontSize: "18px" }}>Mejoramos la relación con tus pacientes para fortalecer la gestión de tu consultorio</p>
        </div>
      </div>
      <div >
        <div style={{ padding: "3%" }}>
          <div style={{ paddingTop: "50%", paddingLeft: "15%", paddingRight: "15%" }}>
            {/* <img style={{margin:"auto",width:"15%", marginTop:"5%",marginBottom:"20%"}} src={Logo} alt="" /> */}
          </div>
          <h1 style={{ color: "white", textAlign: "left" }}>El A,B,C, de tus pacientes al instante</h1>
          <p style={{ color: "white", textAlign: "left", fontSize: "18px" }}>Datos personales, estudios y todo lo necesario para ofrecer diagnósticos y tratamientos de forma ágil, ordenada y en cabal cumplimiento normativo</p>
        </div>
      </div>
      <div>
        <div style={{ padding: "3%" }}>
          <div style={{ paddingTop: "50%", paddingLeft: "15%", paddingRight: "15%" }}>
            {/* <img style={{margin:"auto",width:"15%", marginTop:"5%",marginBottom:"20%"}} src={Logo} alt="" /> */}
          </div>
          <h1 style={{ color: "white", textAlign: "left" }}>Gestiona de forma fácil y rápida todos los procesos administrativos</h1>
          <p style={{ color: "white", textAlign: "left", fontSize: "18px" }}>Agenda digital, facturación electrónica y mucho más</p>
        </div>
      </div>
    </Carousel>
  );
};
export default Slider;