import { Row, Select } from "antd";
import MenuItem from "antd/es/menu/MenuItem";
import Iconos from "../IconosDeSitio";
import "../comp-styles/EstudiosCard.scss";

export default function selectMedicosReenviar({ lista, acciones }) {
    return (
        <Select style={{ width: "100%" }} onSelect={(e) => {
            // console.log(e);
            if (acciones[0] === 1) {
                acciones[1](e);
            }
        }}
            placeholder="Selecciona un médico"
        >
            <MenuItem value="">Selecciona un médico</MenuItem>
            {lista.map((medico) => (
                <MenuItem value={medico.id} key={medico.id}>
                    <Row>
                        <img className="ImgEstudiosCard" style={{ width: "30px", height: "30px" }} src={!!medico.imagen ? "https://" + medico.imagen : Iconos[0]} alt="" />
                        {/* <div style={{marginBottom:"2%"}}> */}
                        {medico.label}
                        {/* </div>     */}
                    </Row>

                </MenuItem>
            ))}
        </Select>
    );
}