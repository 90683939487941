import * as React from "react";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import MoreVert from "@mui/icons-material/MoreVert";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";

export default function PositionedMenu({ funciones }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // console.log("ABRE");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    // console.log("CIERRA");
    setAnchorEl(null);
  };



  return (
    <>
      <IconButton
        id="positioned-demo-button"
        aria-controls={open ? "positioned-demo-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        color="neutral"
        onClick={(e) => {
          handleClick(e);
          funciones[1](funciones[0].IdMovimiento);
          funciones[4](funciones[0].Concepto);
          funciones[5](funciones[0].TipoMovimiento === "Ingreso" ? "0" : "1");
          funciones[6](parseFloat(funciones[0].Cantidad));
        }}
      >
        <MoreVert />
      </IconButton>
      {anchorEl !== null && (<div
        style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}
      >
        <div>
          <Menu
            id="positioned-demo-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            aria-labelledby="positioned-demo-button"
            placement="bottom-end"

          >
            <MenuItem onClick={() => { handleClose(); funciones[3](1); funciones[7](true);}}>
              <ListItemDecorator sx={{ color: "inherit" }}>
                <Edit color="help" />
              </ListItemDecorator>{" "}
              Editar
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); funciones[2](); }}>
              <ListItemDecorator sx={{ color: "inherit" }}>
                <DeleteForever color="danger" />
              </ListItemDecorator>{" "}
              Cancelar
            </MenuItem>
          </Menu>
        </div>
      </div>)}
    </>
  );
}
