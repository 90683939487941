// importando libreria react
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../logo.svg";

// Metodo de formulario
import { useForm } from "../../hooks/useForm";
import Slider from "./Slider";
import { Button, Col, Form, Input, Row, Select } from "antd";

import { login_request } from "../../services/login_services";
import { infoUser_request } from "../../services/perfil_services";

/*Importando logo*/

/*Login styles*/
import "../comp-styles/Login.scss";

// importando componentes
import Stack from "@mui/material/Stack";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Content } from "antd/es/layout/layout";
import Campos from "../GeneralComponents/campos";
import InputAntd from "../GeneralComponents/InputAntd";
import InputAntdForm from "../GeneralComponents/InputAntdForm";
import CamposForm from "../GeneralComponents/CamposForm";
import InputPassAntd from "../GeneralComponents/InputPassAntd";
import { CircularProgress } from "@mui/material";
import { Sendrequest, getTokenFirebase, getTokenFirebaseSitio } from "../../firebase";
import ReactGA from "react-ga4";
import SelectAntd from "../GeneralComponents/SelectAntd";
import { Label } from "../GeneralComponents/Formularios";
import { useDispatch } from 'react-redux';
import { userSlice } from "../../redux/userSlice";
const { Option } = Select;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const expresiones = {
    password: /^.{8,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    textNumber: /^[a-zA-Z0-9\s]{8,}$/, // Letras, Numeros y espacios.
  };

  const { email, password, onInputChange, onResetForm } = useForm({
    email: "",
    password: "",
  });

  const [Correo, setCorreo] = useState("");
  const [Password, setPassword] = useState("");
  const [TipoUsuario, setTipoUsuario] = useState(null);

  const [Loader, setLoader] = useState(false);

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [MensajeCorreo, setMensajeCorreo] = useState("true");
  const [MensajePassword, setMensajePassword] = useState("true");
  const [MensajeTipoUsuario, setMensajeTipoUsuario] = useState("true");

  const log = async (e) => {
    setLoader(true);
    // return;
    if (!!Correo.trim() && !!Password.trim() && !!TipoUsuario && MensajeCorreo === "true" && MensajePassword === "true" && MensajeTipoUsuario === "true") {

      let firebasetoken = localStorage.getItem("firebaseToken");
      // let correo = document.getElementById("email").value;
      // let pass = document.getElementById("password").value;
      // console.log(TipoUsuario);
      if (!!firebasetoken) {
        console.log("Con token firebase");
      } else {
        console.log("Sin token firebase");
      }
      // return;
      let request = {
        "Correo": Correo,
        "password": Password,
        "device_token": !!firebasetoken ? firebasetoken : "",
        "Tipo": TipoUsuario
      };
      let response = await login_request(request);
      // console.log(response);
      if (response.ok) {
        getTokenFirebase();
        localStorage.setItem("logged", true);
        // return;
        if (response.data.Usuario.Tipo === 1 || response.data.Usuario.Tipo === 4) {
          ReactGA.event({
            category: "Login",
            action: "Login exitoso",
            label: "Inicio de sesion exitoso", // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });

          // if (response.data.Usuario.Tipo === 1) {
          //   navigate("/", {
          //     replace: true,
          //     state: {
          //       logged: true
          //     },
          //   });
          // } else if (response.data.Usuario.Tipo === 4) {

          //   navigate("/selectMedico", {
          //     replace: true,
          //     state: {
          //       logged: true
          //     },
          //   });

          // }
          localStorage.setItem("tipoUsuario", response.data.Usuario.Tipo);
          localStorage.setItem("loginAsistente", "0");
          navigate("/", {
            replace: true,
            state: {
              logged: true
            },
          });
          setLoader(false);

          onResetForm();
        } else {
          setLoader(false);
          setOpen(true);
          setTypeMessage("error");
          setMessage("Tipo de usuario no valido");
        }
      } else {
        ReactGA.event({
          category: "Login",
          action: "Login erroneo",
          label: `${response.mensaje}`, // optional
          value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
        setLoader(false);
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Correo) {
        setMensajeCorreo("false");
      }

      if (!!!Password) {
        setMensajePassword("false");
      }

      if (!!!TipoUsuario) {
        setMensajeTipoUsuario("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan datos de inicio de sesión");
    }
    setLoader(false);
    // console.log(response);
  }

  useEffect(() => {
    if (document.domain !== "localhost") {
      // console.log("enviando analytics");
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "Login screen",
        // user_id: `${response.data.Usuario.id}`
      });
    }

    // if (navigator.userAgent.match(/Windows/i)
    //   || navigator.userAgent.match(/macintosh/i)) {
    // console.log(Notification.permission);
    //   if (Notification.permission === "default" || Notification.permission === "denied") {
    //     Sendrequest("login");
    //   }

    //   if ('showTrigger' in Notification.prototype) {
    //     /* Se admiten los activadores de notificación */
    //     // console.log("Se admiten los activadores de notificación");
    //   }
    // }
  }, []);


  return (
    <Stack>
      <Content
        className="site-layout"
        style={{
          // padding: "0 3%",
          // height: "100vh",
          width: "100%",
          overflow: "initial",
        }}
      >
        <Row>
          <Col className="colLeft">
            <Form
              // onFinish={() => {
              // console.log("AQUI");
              //   log();
              // }}
              onSubmitCapture={() => {
                // if (!!!TipoUsuario) {
                //   setMensajeTipoUsuario("false");
                // } else {

                // }
                log();
              }}
              layout="vertical"
            >
              <img src={logo} alt="" className="lgo" />

              {/* <Form.Item
                name={"select"}
                label={<Label valido={MensajeTipoUsuario}>{"Tipo de usuario:"}</Label>}
                rules={[
                  {
                    required: true,
                    message: "Debe seleccionar un tipo de usuario"
                  },
                ]}
                style={{ marginTop: "20px" }}
              >
                <Select
                  value={TipoUsuario}
                  options={[{ value: "1", label: "Médico" }, { value: "4", label: "Asistente" }]}
                  onChange={(e) => {
                    setTipoUsuario(e);
                    // setMensajeTipoUsuario("true");
                  }}
                  style={{ width: "100%", height: "45px", color: "black" }}
                  placeholder="Seleccionar tipo"
                />

              </Form.Item> */}
              <InputAntdForm
                styleDiv={{ marginTop: "50px" }}
                label="Correo:"
                required={true}
                className="InputLogin"
                type="email"
                id="email"
                value={Correo}
                setStateValue={setCorreo}
                setStateError={setMensajeCorreo}
                valueError={MensajeCorreo}
                textError={["Debe escribir un correo", "Formato de correo incorrecto"]}
                vacio={false}
                placeholder="usuario@ejemplo"
                expresionRegular={expresiones.correo}
              />


              <InputPassAntd
                styleDiv={{ marginTop: "20px" }}
                label="Contraseña:"
                className="InputLogin"
                type="password"
                id="password"
                value={Password}
                setStateValue={setPassword}
                required={true}
                placeholder="Contraseña"
                textError={["Debe escribir una contraseña", "Debe contener más de 8 caracteres", "No se permiten caracteres especiales"]}
                setStateError={setMensajePassword}
                valueError={MensajePassword}
                expresionRegular={expresiones.textNumber}
              />

              <SelectAntd
                label={"Tipo de usuario:"}
                placeholder="Seleccionar tipo"
                value={TipoUsuario}
                requiredICon={true}
                vacio={false}
                textError={"Debe seleccionar un tipo de usuario"}
                valueError={MensajeTipoUsuario}
                options={[{ value: "1", label: "Médico" }, { value: "4", label: "Asistente" }]}
                setStateValue={setTipoUsuario}
                setStateError={setMensajeTipoUsuario}
                style={{ width: "100%", height: "45px", color: "black", marginTop: "20px" }}
              />

              {/* Yardiel cambia link a div agregando onclick */}
              <h5 className="div-rigth" >
                <Link to="/recuperacion"><span className="other-options-button-link" onClick={() => {
                  ReactGA.event({
                    category: "Login",
                    action: "Olvidar contraseña",
                    label: "Boton para recuperar contraseña", // optional
                    value: 99, // optional, must be a number
                    nonInteraction: true, // optional, true/false
                    transport: "xhr", // optional, beacon/xhr/image
                  });
                }}> Olvidé mi contraseña </span></Link>
              </h5>



              {/* <div className="BotonAlineLogin">
                <Button className="buttonLogin" type="submit"> Iniciar Sesión </Button>
              </div> */}
              {Loader ? (
                <div style={{ paddingTop: "30px", width: "100%", height: "45px", display: "flex", justifyContent: "center" }}><CircularProgress size={40} sx={{ color: "#148f9f" }} /></div>
              ) : (

                <Button className="buttonLogin" htmlType="submit" type="button"> Iniciar Sesión </Button>

              )}



              <div className="other-options">


                <h5 className="HCentrado" >
                  <span >¿No tienes cuenta?  </span>
                  <Link to="/registrarme"><span className="other-options-button-link" onClick={() => {
                    ReactGA.event({
                      category: "Login",
                      action: "Registrarse",
                      label: "Redireccion a registro", // optional
                      value: 99, // optional, must be a number
                      nonInteraction: true, // optional, true/false
                      transport: "xhr", // optional, beacon/xhr/image
                    });
                  }}> Registrarme ahora</span></Link>
                </h5>
              </div>


            </Form>

            {/* <Campos
              label={"Tipo de usuario:"}
              requiredICon={true}
              vacio={false}
              textError={"Debe seleccionar un tipo de usuario"}
              valueError={MensajeTipoUsuario}
            >
              <Select
                value={TipoUsuario}
                options={[{ value: "1", label: "Médico" }, { value: "4", label: "Asistente" }]}
                onChange={(e) => {
                  setTipoUsuario(e);
                  setMensajeTipoUsuario("true");
                }}
                style={{ width: "100%", height: "45px", color: "black" }}
                placeholder="Seleccionar tipo"
              />
            </Campos> */}
          </Col>

          <Col className="colRight">
            <div>
              <Slider />
            </div>
          </Col>

        </Row>
      </Content >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={typeMessage} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack >
  );
}

export default Login;
