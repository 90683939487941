import axios from "axios"
import baseUrl from "./apiUrl";


export async function register_request(registerData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/usuarios",
            method: "POST",
            data: registerData,
            // headers:
            // {

            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            //     'X-Requested-With': 'XMLHttpRequest',
            // }
        })
        // console.log();
        if (response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR REGISTRO "+error.response);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function emailValidation_request(email) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/VerificarCorreo",
            method: "POST",
            data: email,
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        // console.log();
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function activationAccount_request(code) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/activacion",
            method: "POST",
            data: code,
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        // console.log();
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}