import React from "react";
import LogoSite from "../../logoBlanco.svg";
import "./GenaralStyles/Splash.scss";

function Splash() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "#148f9f",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{width:"100%", textAlign:"center"}}>
        <img style={{ width: "150px" }} src={LogoSite} alt="" />
        <div>
        <div className="loadingio-spinner-spinner-9jfz1c5sj88">
          <div className="ldio-83wcifvjiwh">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Splash;
