import axios from "axios"
import baseUrl, { cloudFunction_notifications, cloudFunction_token, fireB_Notification_usr, fireB_Notification_pwd } from "./apiUrl";

export async function getNotificaciones_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/notificaciones",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getAuthFirebase_request({ accion, idNotification, modulo, userId }) {
    let data = {
        "email": fireB_Notification_usr,
        "password": fireB_Notification_pwd,
    };
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: cloudFunction_token,
            method: "POST",
            data: data,
            headers:
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        })
        // console.log(response);
        if (response.status === 201) {
            switch (accion) {
                case 0:
                    resp = await readNotificacion_request(idNotification, response.data.token);
                    break;
                case 1:
                    resp = await readModuleNotificacion_request({ modulo: modulo, token: response.data.token, userId: userId, });
                    break;

                default:
                    resp = {
                        "ok": false,
                        "status": 500
                    }
                    break;
            }
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

async function readNotificacion_request(idNotification, token) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${cloudFunction_notifications}/${idNotification}/markAsRead`,
            method: "DELETE",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

async function readModuleNotificacion_request({ userId, modulo, token }) {
    try {
        // console.log(token);
        // console.log(userId);
        // console.log(modulo);
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${cloudFunction_notifications}/${userId}/${modulo}/markAsRead`,
            method: "DELETE",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}


