import React from "react";
import "../comp-styles/ModalAddVinculacion.scss";
import Iconos from "../IconosDeSitio";

function VincAddCardModal({ usersToAdd, sendSolicitudes, closeModal }) {
  let listaContactos = usersToAdd;
  return (
    <>
      {listaContactos.map((lista) => (
        <div key={lista.id}>
          <div className="GeneralContVincul">
            <div className="ContainerVincula">
              <div className="CardVincula">
                <div style={{ display: "flex", gap: "2%", width:"100%" }}   onClick={() => {
                      sendSolicitudes(lista.id);
                      closeModal(true);
                    }}>
                  <div className="BgrImgv">
                    <img
                      src={
                        lista.imagen !== null
                          ? "https://" + lista.imagen
                          : Iconos[0]
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <b style={{width:"80%", overflow:"hidden", textOverflow:"ellipsis"}} >{lista.nombre}</b>
                    <div className="Dia">
                      <span>
                        <b>
                          {lista.tipo === 1
                            ? "Médico"
                            : lista.tipo === 3
                            ? "Paciente"
                            : lista.tipo === 4
                            ? "Asistente"
                            : "Familiar"}
                        </b>
                        &nbsp;&nbsp; {lista.correo}
                      </span>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default VincAddCardModal;
