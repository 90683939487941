import { createSlice } from "@reduxjs/toolkit";

const initialState = { citas: 0, chat: 0, notificaciones: 0, menu: 0, estudios: 0, vinculacion: 0 };

export const badgesSlice = createSlice({
    name: "badges",
    initialState,
    reducers: {
        badges: (state, action) => {

            const { modulo, numero } = action.payload;
            switch (modulo) {
                case "citas":
                    state.citas = numero;
                    break;
                case "chat":
                    state.chat = numero;
                    break;
                case "vinculacion":
                    state.vinculacion = numero;
                    break;
                case "estudios":
                    state.estudios = numero;
                    break;
                case "menu":
                    state.menu = numero;
                    break;
                case "general":
                    state.notificaciones = numero;
                    break;

                default:
                    state.notificaciones = numero;
                    break;
            }
        },
    }
})


export const { badges } = badgesSlice.actions;

export default badgesSlice.reducer;