import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "../comp-styles/AsistenteCard.scss";
import Iconos from "../IconosDeSitio";
import PositionedMenu from "./menuvertical";


const Asist = [
  { Nombre: "Fansisco Solis Perez", imgPerfA: Iconos[0] },
  { Nombre: "Arturo Ortega", imgPerfA: Iconos[1] },
  { Nombre: "Fansisco Solis Perez", imgPerfA: Iconos[0] },
  { Nombre: "Arturo Ortega", imgPerfA: Iconos[1] },
  { Nombre: "Fansisco Solis Perez", imgPerfA: Iconos[0] },
  { Nombre: "Arturo Ortega", imgPerfA: Iconos[1] },
  { Nombre: "Fansisco Solis Perez", imgPerfA: Iconos[0] },
  { Nombre: "Arturo Ortega", imgPerfA: Iconos[1] },
  { Nombre: "Fansisco Solis Perez", imgPerfA: Iconos[0] },
  { Nombre: "Arturo Ortega", imgPerfA: Iconos[1] },
  { Nombre: "Fansisco Solis Perez", imgPerfA: Iconos[0] },
  { Nombre: "Arturo Ortega", imgPerfA: Iconos[1] },
];

export default function AsistentesCard( {
  setActiveMoadalAsist,
  setAccionesAsist,
  AssistantList,
  setIdAssistant,
  detailAssistant,
  deleteAssistant,
  setAction,
  setInputsEditable,
  openModalDAE
} ) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (CambiarEditarADetalle) => {

    if (CambiarEditarADetalle !== 3) {
      setAnchorEl(null);
     setActiveMoadalAsist(1);
    if (CambiarEditarADetalle === 1) {
      setAccionesAsist(1);
   
      
      
    } else {
      setAccionesAsist(0);
    }
      
    } else{
      setAnchorEl(null);
    }
  
  };


  return (
    <>
      {AssistantList.map((asistente) => (
        <div className="ContenedorCardAsist">
          <div className="AcomodoCardAsist">
            <div className="DespliegeAsistentCard" onClick={()=>{handleClose(0);detailAssistant(asistente.IdAsistente); setAction(1); setInputsEditable(true); openModalDAE(true);}}>
              <div>
                <img 
                  className="ImgPerfAsist"
                  src={!!asistente.Imagen_url ? "https://"+asistente.Imagen_url : Iconos[0]}
                  alt=""
                />
               
              </div>
              <b>
                {asistente.Asistente}
              </b>
            </div>
            <div>
              <div>
                <PositionedMenu setIdAssistant={setIdAssistant} extras={[asistente,deleteAssistant]}/>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
