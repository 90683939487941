import React, { useState } from 'react';
import { Label, LeyendaError } from '../GeneralComponents/Formularios';
import "../../pages/styles/Registro.scss"
import { Form, Input } from 'antd';

const InputAntdForm = ({ value, setStateValue, type, label, placeholder, id, textError, setStateError, valueError, expresionRegular,
	funcion, disabled, readOnly, vacio, maxLength, className, style, extras, styleLabel, styleDiv, required }) => {

	const [MensajeError, setMensajeError] = useState(textError !== undefined ? textError[0] : "");

	const onChange = (e) => {
		// console.log(e.target.value);
		setStateValue(e.target.value);
		if (vacio !== undefined) {
			if (vacio) {
				if (setStateError !== undefined) {
					setStateError("true");
				}
			} else {

				if (e.target.value.trim().length > 0) {
					setStateError("true");
				} else {
					setMensajeError(textError[0]);
					setStateError("false");
				}
			}
		} else {
			if (setStateError !== undefined) {
				if (e.target.value.trim().length > 0) {
					setStateError("true");
				}
			}

		}
		if (extras !== undefined) {
			// console.log(extras);
			if (extras[0] === "IMC") {
				let valor = e.target.value;
				if (valor.length > 0) {


					if (id === "altura") {
						if (!!extras[2]) {
							let imc = extras[2] / (valor * valor);
							extras[1](imc.toFixed(2));
						}
					} else {
						if (!!extras[2]) {
							let imc = valor / (extras[2] * extras[2]);
							extras[1](imc.toFixed(2));
						}
					}

				} else {
					extras[1]("");
				}

			}
		}

		if (id === "password") {
			extras();
		}

	}

	return (
		<div style={styleDiv}>
			{/* {(label !== undefined && !!label) && (<Label style={styleLabel} htmlFor={id} valido={valueError}>{label}</Label>)} */}
			<Form.Item
				label={<Label valido={valueError}>{label}</Label>}
				name={id}
				rules={[
					{
						required: required,
						message: MensajeError
					},
					{
						type: "email",
						// required:true,
						message: textError[1]
					}
				]}
			>
				<Input
					style={style}
					className={className}
					type={type}
					placeholder={placeholder}
					id={id}
					value={value}
					onChange={onChange}
					// onKeyUp={validacion}
					disabled={disabled !== undefined ? disabled ? true : false : false}
					readOnly={readOnly ? true : false}
					maxLength={!!maxLength ? maxLength : ""}
					status={valueError !== undefined ? valueError === 'false' && "error" : ""}
				// status={"error"}
				// {temp ? disabled : enable}
				/>
				{/* <LeyendaError valido={valueError}>{MensajeError}</LeyendaError> */}
			</Form.Item>


		</div>
	);
}

export default InputAntdForm;