import "./GenaralStyles/ModalDetalle.scss";
import { Label, LeyendaError } from "./Formularios2Grids";
import ModalFiltro from "./ModalDetalle";
import { Input, Select, Button } from "antd";
import CloseIcono from "@mui/icons-material/Close";

const Filtro = ({
  isOpen,
  closeModal,
  styleFilter,
  functions,
  titulos,
  statesVariables,
  variables,
  OptionsSelect,
}) => {
  const styleFiltro = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };
  const handleModalContainerClick = (e) => e.stopPropagation();

  return (
    <div
      onClick={() => {
        closeModal(true);
      }}
      className={isOpen ? "modalDetalle is-open" : "modalDetalle"}
    >
      <div className={`modalDetalle is-open`}>
        <div
          style={styleFiltro}
          className="modalDetalle-container"
          onClick={handleModalContainerClick}
        >
          <div className="FlexAliniado CabezeraModal">
            <span>Filtrar</span>
            <CloseIcono style={{fontSize: 20, cursor:"pointer", color:"white"}}  onClick={closeModal}/>
          
          </div>
          <div className="DetalModalFil" style={{ padding: "3%" }}>
            {/* <h3>Ordena tus movimientos</h3> */}
            <p style={{fontWeight:"500", color:"#424242"}}>{titulos[0]}</p>
            <form action="" style={{ padding: "0" }}>
              {variables.estadoFiltro !== undefined && (
                <Select
                  value={variables.estadoFiltro}
                  style={{ width: "100%", padding: "0%" }}
                  // className="inputblue"
                  options={OptionsSelect}
                  onSelect={(e) => {
                    statesVariables.estadoFiltro(e);
                  }}
                />
              )}

              <div className="Filtro3Botones">
                <button
                  type="button"
                  onClick={() => {
                    functions[1](1);
                  }}
                >
                  {titulos[1]}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    functions[1](2);
                  }}
                >
                  {titulos[2]}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    functions[1](3);
                  }}
                >
                  {titulos[3]}
                </button>
              </div>
              <br />
              <Label  style={{fontWeight:"500", color:"#424242", padding:"0"}}  >Periodo de fechas</Label>

              <Label style={{fontWeight:"500", color:"#424242", padding:"0"}}>
                <Input
                  value={variables.fechaInicio}
                  type="date"
                  onChange={(e) => {
                    statesVariables.fechaInicio(e.target.value);
                    statesVariables.mensajeFechaInicio("true");
                  }}
                />
                <LeyendaError
                  valido={variables.mensajeFechaInicio}
                  style={{
                    display:
                      variables.mensajeFechaInicio === "false"
                        ? "flex"
                        : "none",
                  }}
                >
                  Debe seleccionar fecha inicio
                </LeyendaError>
              </Label>

              <Label style={{fontWeight:"500", color:"#424242", padding:"0"}}>
                <Input
                  value={variables.fechaFin}
                  type="date"
                  onChange={(e) => {
                    statesVariables.fechaFin(e.target.value);
                    statesVariables.mensajeFechaFin("true");
                  }}
                />
                <LeyendaError
                  valido={variables.mensajeFechaFin}
                  style={{
                    display:
                      variables.mensajeFechaFin === "false" ? "flex" : "none",
                  }}
                >
                  Debe seleccionar fecha fin
                </LeyendaError>
              </Label>
              <div>
                <div style={{textAlign:"center"}}>
                  <Button
                    style={{width:"100%", background:"#148F9F", color:"white"}}
                    type="button"
                    onClick={() => {
                      functions[1](4);
                    }}
                  >
                    Filtrar
                  </Button>
                </div>
             
                  <Button className="GeneralBotonWhite" style={{width:"100%", margin:"1% 0"}}
                    type="button"
                    onClick={() => {
                      closeModal(true);
                      // if (variables.estadoFiltro !== undefined) {
                      //   functions[0]();
                      // } else {
                      //   functions[0](1);
                      //   statesVariables.fechaInicio("");
                      //   statesVariables.fechaFin("");
                      // }
                    }}
                  >
                    Cancelar filtro
                  </Button>
             
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filtro;
