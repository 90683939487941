/*Importando Librerias*/

import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";


// importando cargador
import { Loader } from "./componentes/GeneralComponents/Loader";

/*Importando logo*/
import logo from "./logo.svg";
import IcoFactura from "./Img/Iconos/Factura.svg";
import Menu from "./Img/Iconos/Menu.svg";
import * as Heroicons from "react-icons/hi";
/*Importando Componente de iconos*/
import Iconos from "./componentes/IconosDeSitio";

/*Importando estilos*/
import "../src/componentes/comp-styles/Navbar.scss";
import "./componentes/comp-styles/Sidebar.scss";
import "../src/componentes/comp-styles/Sitio.scss";

// peticiones a api
import { infoUser_request } from "./services/perfil_services";
import { getDoctorsLink_request, loginMedico_request } from "./services/asistentes_services";

import { Dropdown, Row, Space, Button, Select, Layout, Drawer } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

import ModalNotificaciones from "./componentes/GeneralComponents/ModalDetalle";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ArrowBack } from "@mui/icons-material";
import NavBarNuevo from "./componentes/GeneralComponents/Navbar_Nuevo";
// import { UseModal } from "./hooks/UseModal";
import { Sendrequest, getBadgeNotificationsFirestore, getNotificationsFirestore, getTokenFirebase } from "./firebase";
import NotificationSlide from "./componentes/Notificaciones/notificaciones_slide";
import ReactGA from "react-ga4";
import { useDispatch } from 'react-redux';
import { infoUser } from "./redux/userSlice";
import { doctorsToAssistant } from "./redux/doctorsToAssistant";
import { infoDoctorLog } from "./redux/infoDoctorLog";
// import { getTokenFunction } from "./firebase";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const { Header, Content, Footer } = Layout;

/*Variable para esconder*/

function Sitio() {
  let datosMedico = JSON.parse(localStorage.getItem("datosMedico"));
  // console.log(datosMedico);

  // Metodo de login
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [IsOpenNotificaciones, OpenNotificaciones, ClosedNotificaciones] = UseModal(false);


  const [loader, setLoader] = useState(false);

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  // console.log(state);

  async function getInfoUser() {
    // localStorage.setItem("token", "");
    let response = await infoUser_request();
    if (response["ok"]) {
      // console.log("USUARIO");
      // console.log(response.data);
      // console.log("###########3");
      dispatch(infoUser(response.data.Usuario));
      let info = response.data.Usuario;

      // console.log(localStorage.getItem("logged"));

      if (response.data.Usuario.Tipo === 1) {
        if (document.domain !== "ygia.app") {
          // console.log(response.data.Usuario.Paquete);
          // console.log(response.data.Usuario.PaqueteStatus);
        }
        if (response.data.Usuario.Paquete == "0" && response.data.Usuario.PaqueteStatus == 1) {
          navigate("/app/licencia", { replace: true });
        } else if (info.Paquete == "1" && info.PaqueteStatus > 1) {
          navigate("/app/licencia", { replace: true });
        }
      } else {
        let loginAsistente = localStorage.getItem("loginAsistente");
        // console.log(loginAsistente);
        localStorage.setItem("paquete", response.data.Usuario.Paquete);
        localStorage.setItem("paqueteStatus", response.data.Usuario.PaqueteStatus);
        if (loginAsistente === "0") {
          navigate("/selectMedico", {
            replace: true,
            state: {
              logged: true
            },
          });
        } else {
          // if (document.domain === "localhost") {
          //   console.log(info);
          // }
          getDoctorsLink();
          // if (info.Paquete !== "0" && info.PaqueteStatus === 1) {
          //   navigate("/app/citas", { replace: true });
          // } else {
          //   navigate("/app/licencia", { replace: true });
          // }
          if (response.data.Usuario.Paquete == "0" && response.data.Usuario.PaqueteStatus == 1) {
            navigate("/app/licencia", { replace: true });
          } else if (info.Paquete == "1" && info.PaqueteStatus > 1) {
            navigate("/app/licencia", { replace: true });
          }

          // navigate("/app/citas", { replace: true });
        }
      }
      if (document.domain !== "localhost") {
        // console.log("enviando analytics");
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname + window.location.search,
          title: "user log sitio",
          user_id: `${response.data.Usuario.id}`
        });
      }
      // if (document.domain !== "ygia.app") {
      getNotisFirestore(info.id);
      // }
    } else {
      // console.log(response);
      if (response.codigo === 401) {
        sessionStorage.removeItem("infoUser");
        localStorage.removeItem("permisos");
        localStorage.setItem("loginAsistente", 0);
        localStorage.removeItem("token");
        localStorage.removeItem("tipoUsuario");
        localStorage.removeItem("datosMedico");
        localStorage.removeItem("logged");
        navigate("/login", {
          replace: true,
          state: {
            logged: false,
          },
        });
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    }
    return;
  }

  async function getDoctorsLink() {
    let response = await getDoctorsLink_request();
    if (response.ok) {
      // console.log(response.data);
      dispatch(doctorsToAssistant({ doctors: response.data }));
      let logged = 0;
      response.data.forEach(element => {

        if (element.Logged === 1) {
          if (document.domain === "localhost") {
            console.log(element);
          }
          logged = 1;
          dispatch(infoDoctorLog(element));
        }
      });
      // console.log(logged);
      if (logged === 0) {
        localStorage.setItem("loginAsistente", 0);
        navigate("/selectMedico", {
          replace: true,
          state: {
            logged: true
          },
        });
      }

      // setListaMedicosVinculados(array);
      // setListaMedicosVinculadosFilter(response.data);
    } else {
      // dispatch(doctorsToAssistant([]));
      dispatch(doctorsToAssistant({ doctors: [] }));
    }
  }

  async function getNotisFirestore(idUser) {
    // await getNotificationsFirestoreSecond(dispatch);
    await getBadgeNotificationsFirestore(dispatch, idUser);
    await getNotificationsFirestore(dispatch, idUser);
  }

  useEffect(() => {
    // console.log(localStorage.getItem("firebaseToken"));
    getInfoUser();
    // console.log(navigator.userAgent);
    // alert("sitio");
    if (navigator.userAgent.match(/Windows/i)
      || navigator.userAgent.match(/macintosh/i)) {
      // console.log(Notification.permission);
      if (Notification.permission === "default" || Notification.permission === "denied") {
        getTokenFirebase();
      }

      if ('showTrigger' in Notification.prototype) {
        /* Se admiten los activadores de notificación */
        // console.log("Se admiten los activadores de notificación");
      }
    }

  }, []);

  return (
    <Stack>
      {loader ? (<Loader />) : (
        <>
          {/* <NavBarNuevo/> */}
          <div
            className="conteOutlet"
          >
            <Outlet />
          </div>
        </>
      )
      }
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack >
  );
}

export default Sitio;
