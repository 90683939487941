import React from "react";
import GeneralJson from "../GeneralComponents/General-Json";

import { useState } from "react";
import IconosDeSitio from "../IconosDeSitio";
// import mui libreria
import PositionedMenu from "./menuvertical";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import "../../componentes/comp-styles/PuntoDeVentaCard.scss";
import { Avatar } from "antd";


let folio = "521";
let dia = "09";

const Colores = [{ color: "red" }, { color: "grey" }, { color: "green" }];

export default function PuntoDeVentaCard({ OpenDetalleModal, CobrosList, detailCobro, setIdCobro, setDatosContact, OpenPagarModal, OpenAddModal, cancelarCobro }) {

  return (
    <>
      {CobrosList.map((cobro, index) => (
        <div
          className="GenPvCard"
          style={{
            background: "white",
            padding: "1%",
            marginBottom: ".5%",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "95%",
            }}
            onClick={
              () => {

                OpenDetalleModal(true);
                detailCobro(cobro.IdCargo);
               

              }}
          >         
          <Avatar
          className="GeneralNoImage"
          size={50}
          src={!!cobro.Imagen_url ? "https://" + cobro.Imagen_url : IconosDeSitio[0]}
          />
     
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <p style={{ fontWeight: "500" }}>{cobro.Contacto}</p>
                <p>Folio: {cobro.Folio}</p>
                <p>{cobro.Expedido}</p>
              </div>
              <div style={{textAlign:"end"}}>
                <p style={{fontWeight:"500", color:"#424242"}}>Monto</p>
                <p style={cobro.Estado === "Pendiente" ? Colores[1] : cobro.Estado === "Cancelado" ? Colores[0] : Colores[2]}>${cobro.Total}</p>
                <p style={cobro.Estado === "Pendiente" ? Colores[1] : cobro.Estado === "Cancelado" ? Colores[0] : Colores[2]}>{cobro.Estado}</p>
              </div>
            </div>
          </div>
          <StyledEngineProvider injectFirst>
            <CssVarsProvider>
              <PositionedMenu setIdCobro={setIdCobro} extras={[cobro, setDatosContact, OpenPagarModal, OpenAddModal, cancelarCobro]} />
            </CssVarsProvider>
          </StyledEngineProvider>
        </div>
      ))}
    </>
  );
}
