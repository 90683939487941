import React from "react";
import Iconos from "../../IconosDeSitio";

// importando scss
import "../../comp-styles/RecetasCard.scss";

function RecetasCard({ lista, detailReceta }) {
  return (
    <>
      {lista.map((receta) => (
        <div className="cardRecExpe" onClick={() => { detailReceta(receta.id); }}>
          <div>
            <p style={{fontWeight:"500", color:"#424242"}}>{receta.folio}</p>
          </div>
          <div>
            <p>{receta.fecha}</p>
          </div>
        </div>
      ))}
    </>
  );
}

export default RecetasCard;
