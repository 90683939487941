import { createSlice } from "@reduxjs/toolkit";

const initialState = { doctors: [] };

export const doctorsToAssistantSlice = createSlice({
    name: "doctorsLinkToAssistant",
    initialState,
    reducers: {

        doctorsToAssistant: (state, action) => {
            const { doctors } = action.payload;
            state.doctors = doctors;
        },
    }
})


export const { doctorsToAssistant } = doctorsToAssistantSlice.actions;

export default doctorsToAssistantSlice.reducer;