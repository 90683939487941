import * as React from "react";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import MoreVert from "@mui/icons-material/MoreVert";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import { ChangeCircle, ChangeCircleOutlined } from "@mui/icons-material";

export default function MenuVertical({ tarjeta, setIdTarjeta, deleteTarjeta, selectDefaultCard }) {
    // console.log(data.tipo);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        // console.log("ABRE");
        setAnchorEl(event.currentTarget);

    };

    const handleClose = (event) => {
        // console.log("CIERRA");
        setAnchorEl(null);
    };

    return (

        <StyledEngineProvider>
            <CssVarsProvider>
                <IconButton
                    id="positioned-demo-button"
                    aria-controls={open ? "positioned-demo-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="outlined"
                    color="neutral"
                    onClick={(e) => {
                        handleClick(e);
                        setIdTarjeta(tarjeta.IdTarjeta);
                    }}
                >
                    <MoreVert />
                </IconButton>
                {tarjeta.Predeterminado == 1 ? (
                    <>
                        {anchorEl !== null && (
                            <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
                                <div>
                                    <Menu
                                        id="positioned-demo-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="positioned-demo-button"
                                        placement="bottom-end"

                                    >
                                        <MenuItem onClick={(e) => { handleClose(e); deleteTarjeta(); }}>
                                            <ListItemDecorator sx={{ color: "inherit" }}>
                                                <DeleteForever color="danger" />
                                            </ListItemDecorator>{" "}
                                            Eliminar
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>)}
                    </>
                ) : (
                    <>
                        {anchorEl !== null && (
                            <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
                                <div>
                                    <Menu
                                        id="positioned-demo-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="positioned-demo-button"
                                        placement="bottom-end"

                                    >
                                        <MenuItem onClick={(e) => { handleClose(e); deleteTarjeta(); }}>
                                            <ListItemDecorator sx={{ color: "inherit" }}>
                                                <DeleteForever color="danger" />
                                            </ListItemDecorator>{" "}
                                            Eliminar
                                        </MenuItem>
                                        {selectDefaultCard !== undefined && <MenuItem onClick={(e) => { handleClose(e); selectDefaultCard() }}>
                                            <ListItemDecorator sx={{ color: "inherit" }}>
                                                <ChangeCircle />
                                            </ListItemDecorator>{" "}
                                            Predeterminada
                                        </MenuItem>}

                                    </Menu>
                                </div>
                            </div>)}
                    </>
                )}

            </CssVarsProvider>
        </StyledEngineProvider>
    );
}
