import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UseModal } from "../hooks/UseModal";

import "./styles/Estudios.scss";

import CloseIcono from "@mui/icons-material/Close";
import Iconos from "../componentes/IconosDeSitio";
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import FiltroIco from "../Img/Iconos/Filtro.svg";
import flechaAbajo from "../Img/Iconos/FlechaAbajo.svg";
import { Cancel, OndemandVideo, Search } from "@mui/icons-material";

import { Label } from "../componentes/GeneralComponents/Formularios2Grids";
import AddLista from "../componentes/GeneralComponents/ModListDeContactos";
import { Input, Row, Select, Button, Empty, Image, Checkbox } from "antd";
import { LeyendaError } from "../componentes/GeneralComponents/Formularios";
import AddListaDetalle from "../componentes/EstudiosYAnalisis/AddListaDetalle";
import EstudiosCard from "../componentes/EstudiosYAnalisis/EstudiosCard";
import InputFile from "../componentes/GeneralComponents/InputFileList";
import MenuItem from "antd/es/menu/MenuItem";
import TextArea from "antd/es/input/TextArea";
import SelectMedicos from "../componentes/GeneralComponents/lista_medicos_reenviar";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import { Content } from "antd/es/layout/layout";
import TextAreaAntd from "../componentes/GeneralComponents/TextAreaAntd";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";

import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";

import Modal from "../componentes/GeneralComponents/ModalDetalle";
import ModalFiltro from "../componentes/GeneralComponents/ModalFiltro";
import ModalDetalle from "../componentes/GeneralComponents/ModalDetalle";

import {
  getEstudios_request,
  getDetailEstudios_request,
  addEstudio_request,
  forwardEstudio_request,
  getEstudiosFilter_request,
} from "../services/estudios_services";
import { getContacts_request } from "../services/contactos_services";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ModalMedicos from "../componentes/Consultas/ModalMedicos";
import SelectAntd from "../componentes/GeneralComponents/SelectAntd";
import Avatar from "antd/es/avatar/avatar";
import { getAuthFirebase_request } from "../services/notificaciones_services";
import { useSelector } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Estudios() {
  // ******** VARIABLES ********

  const location = useLocation();

  const userInfo = useSelector((state) => state.userInfo);

  const SelectTopo = [
    { Tipo: "Nuevo Estudio o Analisis" },
    { Tipo: "Vista previa de detalle" },
  ];

  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  // ******** USE STATES ********

  // Mostrar y ocultar detalle
  const [ActModalDetalleEstudios, setActModalDetalleEstudios] = useState(0);

  //use Modal add
  const [isOpenModalAdd, OpenModalAdd, CloseModalAdd] = UseModal(false);
  const [isOpenModalFiltro, OpenModalFiltro, CloseModalFiltro] =
    UseModal(false);

  // Modal del detalle
  const [isOpenDetalleEstud, OpenDetalleEstud, CloseDetalleEstud] =
    UseModal(false);

  const [IsOpenModalMedicos, OpenModalMedicos, ClosedModalMedicos] =
    UseModal(false);

  // FILTRO
  const [FechaInicio, setFechaInicio] = useState("");
  const [FechaFin, setFechaFin] = useState("");
  const [EstadoFiltro, setEstadoFiltro] = useState("Todos");
  const [FiltroActive, setFiltroActive] = useState(false);

  const [EstudiosList, setEstudiosList] = useState([]);
  const [EstudiosListFilter, setEstudiosListFilter] = useState([]);

  const [ContactList, setContactList] = useState([]);
  const [ContactListFilter, setContactListFilter] = useState([]);

  const [MedicosList, setMedicosList] = useState([]);
  const [MedicosListFilter, setMedicosListFilter] = useState([]);

  const [EnviarLabTest, setEnviarLabTest] = useState(true);
  const [DetailEstudio, setDetailEstudio] = useState("");
  const [TipoEstudioAnalisis, setTipoEstudioAnalisis] = useState("");
  const [DatosContact, setDatosContact] = useState({ nombre: "", imagen: "", id: "" });
  const [DescripcionEstudio, setDescripcionEstudio] = useState("");

  const [ImagesList, setImagesList] = useState([]);

  const [DatosMedico, setDatosMedico] = useState({
    id: "",
    nombre: "",
    imagen: "",
  });

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [inputsEditable, setInputsEditable] = useState(true);

  const [IdContact, setIdContact] = useState("");
  const [IdMedico, setIdMedico] = useState("");

  const [ComboAddTipoEstudio, setComboAddTipoEstudio] = useState("true");
  const [textAreaAddEstudio, setTextAreaDescripcionEstudio] = useState("true");
  const [imagenesListError, setImagenesListError] = useState("true");

  // Mensajes Filtro
  const [MensajeFechaInicio, setMensajeFechaInicio] = useState("true");
  const [MensajeFechaFin, setMensajeFechaFin] = useState("true");

  const [MensajeDoctorSelect, setMensajeDoctorSelect] = useState("true");

  const [loader, setLoader] = useState(false);
  const [loaderDetalle, setLoaderDetalle] = useState(false);

  // ******** FUNCIONES ********

  function activarEstudios(variable) {
    // console.log(variable);
    if (ActModalDetalleEstudios === 0) {
      setActModalDetalleEstudios(1);
    } else {
      setActModalDetalleEstudios(0);
    }
  }

  async function getEstudios() {
    setLoader(true);
    let response = await getEstudios_request();
    if (response.ok) {
      // console.log(response);
      // response.data.forEach(element => {
      // console.log(element.Nombre);
      // });
      setEstudiosList(response.data);
      setEstudiosListFilter(response.data);
    } else {
      setEstudiosList([]);
      setEstudiosListFilter([]);
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function DetailEstudios(idEstudio) {
    OpenDetalleEstud(true);
    setLoaderDetalle(true);
    // console.log(idEstudio);
    let response = await getDetailEstudios_request(idEstudio);
    if (response.ok) {
      // console.log(response.data);
      setDetailEstudio(response.data.Estudio);
      setTipoEstudioAnalisis(response.data.Estudio.Tipo);
      setDatosContact({
        id: response.data.Estudio.IdContacto,
        nombre: response.data.Estudio.Paciente,
        imagen: response.data.Estudio.Imagen_url,
      });
      setImagesList(
        response.data.Documentos !== undefined ? response.data.Documentos : []
      );
    } else {
      CloseDetalleEstud(true);
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function getContactosToAddEstudio() {
    let response = await getContacts_request();
    if (response.ok) {
      // console.log(response.data);
      let array = [];
      let medicos = [];
      response.data.forEach((element) => {
        if (element.Type !== 1 && element.Type !== 4) {
          array.push({
            usuario: `${element.Nombre} ${element.ApellidoPaterno} ${element.ApellidoMaterno}`,
            imagen: `${!!element.Imagen_url ? element.Imagen_url : ""}`,
            id: `${element.IdContacto}`,
          });
        } else if (element.Type === 1) {
          if (element.YgiaUser === "Con Ygia") {
            medicos.push(element);
          }
        }
      });
      setContactList(array);
      setContactListFilter(array);
      setMedicosList(medicos);
      setMedicosListFilter(medicos);
    } else {
    }
  }

  async function addLabTest(e) {
    e.preventDefault();
    // console.log(TipoEstudioAnalisis);
    // console.log(DescripcionEstudio);
    // console.log(IdContact);
    // console.log(ImagesList);
    // console.log(ImagesList);
    // return;
    setLoaderDetalle(true);
    if (!!DescripcionEstudio || ImagesList.length > 0) {
      let data = new FormData();
      data.append("Tipo", "Estudio");
      data.append("Descripcion", DescripcionEstudio);
      data.append("IdContacto", IdContact);
      data.append("Enviar", EnviarLabTest ? 1 : 0);

      if (IdMedico !== "") {
        data.append("IdUsuario", IdMedico);
      }

      ImagesList.forEach((element, index) => {
        data.append(`Archivo[${index}]`, element.file, element.name);
      });
      let response = await addEstudio_request(data, IdMedico);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage(`Estudio guardado`);
        CloseDetalleEstud(true);
        cleanInputs();
        getEstudios();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!TipoEstudioAnalisis) {
        setComboAddTipoEstudio("false");
      }

      if (!!!DescripcionEstudio) {
        setTextAreaDescripcionEstudio("false");
      }

      if (ImagesList.length === 0) {
        setImagenesListError("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderDetalle(false);
    return;
  }

  async function reenviarEstudio(e) {
    e.preventDefault();
    if (IdMedico !== "") {
      let data = new FormData();
      data.append("IdUsuario", IdMedico);
      let response = await forwardEstudio_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Reenviado con éxito");
        CloseDetalleEstud(true);
        cleanInputs();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      setMensajeDoctorSelect("false");
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Debe seleccinar un médico");
    }

    return;
  }

  function cleanInputs() {
    setTipoEstudioAnalisis("");
    setDescripcionEstudio("");
    setImagesList([]);
    setComboAddTipoEstudio("true");
    setTextAreaDescripcionEstudio("true");
    setImagenesListError("true");
    setInputsEditable(true);
    setDatosContact({ nombre: "", imagen: "" });
  }

  function filterList(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];

    // console.log(text.target.value);
    // console.log(EstudiosListFilter);
    EstudiosListFilter.forEach((estudio) => {
      if (
        estudio.Nombre.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        // estudio.Nombre.replace(expr, function (e) {return chars[e];}).toLowerCase().indexOf(text.target.value.replace(expr, function (e) {return chars[e];}).toLowerCase()) > -1 ||
        // estudio.Tipo.replace(expr, function (e) {return chars[e];}).toLowerCase().indexOf(text.target.value.replace(expr, function (e) {return chars[e];}).toLowerCase()) > -1 ||
        estudio.Fecha.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(estudio);
      }
    });
    setEstudiosList(filter);
  }

  function filterContacts(text) {
    // console.log(text.target.value);
    // console.log(solicitudesSearch);
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];
    // console.log(MedicosList);
    // console.log(MedicosListFilter);
    ContactListFilter.forEach((contacto) => {
      if (
        contacto.usuario
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(contacto);
      }
    });

    setContactList(filter);
  }

  function filterMedicos(text) {
    // console.log(text.target.value);
    // console.log(solicitudesSearch);
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];
    // console.log(MedicosList);
    // console.log(MedicosListFilter);
    MedicosListFilter.forEach((contacto) => {
      if (
        contacto.Nombre.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        contacto.ApellidoPaterno.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        contacto.ApellidoMaterno.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(contacto);
      }
    });

    setMedicosList(filter);
  }

  async function getEstudiosFilter(type) {
    setLoader(true);
    if (type === 4) {
      if (!!!FechaInicio || !!!FechaFin) {
        if (!!!FechaInicio) {
          setMensajeFechaInicio("false");
        }
        if (!!!FechaFin) {
          setMensajeFechaFin("false");
        }
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      } else {
        let data = new FormData();
        data.append("Estado", EstadoFiltro);
        data.append("FechaInicio", FechaInicio);
        data.append("FechaFin", FechaFin);

        // console.log(type);

        let response = await getEstudiosFilter_request(type, data);
        if (response.ok) {
          // console.log(response.data);
          // return;
          setEstudiosList(response.data);
          setEstudiosListFilter(response.data);
          CloseModalFiltro(true);
          setFiltroActive(true);
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      }
    } else {
      if (MensajeFechaInicio === "false" || MensajeFechaFin === "false") {
        inputsFilter();
      }
      let data = new FormData();
      data.append("Estado", EstadoFiltro);

      // console.log(type);

      let response = await getEstudiosFilter_request(type, data);
      if (response.ok) {
        // console.log(response.data);
        // return;
        setEstudiosList(response.data);
        setEstudiosListFilter(response.data);
        CloseModalFiltro(true);
        setFiltroActive(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    }
    // } else {
    //   if (!!!FechaInicio) {
    //     setMensajeFechaInicio("false");
    //   }
    //   if (!!!FechaFin) {
    //     setMensajeFechaFin("false");
    //   }
    //   setOpen(true);
    //   setTypeMessage("warning");
    //   setMessage("Faltan campos por completar");
    // }
    setLoader(false);
    return;
  }

  function inputsFilter() {
    setMensajeFechaInicio("true");
    setMensajeFechaFin("true");
  }

  async function readModule() {
    await getAuthFirebase_request({ accion: 1, modulo: "estudios", userId: userInfo.id });
  }

  useEffect(() => {
    readModule();
    if (!!!location.state) {
    } else {
      // DatosContact({
      //   imagen: location.state.imagen,
      //   nombre: location.state.nombre,
      // });
      DetailEstudios(location.state.idEstudio);
    }
    getEstudios();
    getContactosToAddEstudio();
  }, []);

  return (
    <Stack>
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        modulo={"Estudios"}
        searchBar={
          <div style={{ padding: "1% 3%", background: "#fff" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <b>Estudios</b>
              </div>

              <ButtonAntd
                tipo={1}
                style={{ width: "100px" }}
                onClick={() => {
                  OpenModalAdd();
                  cleanInputs();
                }}
                children={
                  <Row style={{ gap: ".5rem", width: "100%", color: "white", display: "flex", justifyContent: "space-between", alignContent: "center" }}>
                    <p>Nuevo</p>
                    <img style={{ width: "12px" }} src={Agregar} alt="" />
                  </Row>
                }
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "1%" }}>
              {/* <Input
                  style={{ width: "45%" }}
                  type="text"
                  // className="inputblue"
                  placeholder="Buscar por nombre"
                  onChange={filterList}
                /> */}
              <Input
                prefix={<Search style={{ color: "#d7d7d7", width: "18px" }} />}
                style={{ width: "45%" }}
                placeholder="Paciente"
                type="text"
                onChange={filterList}
              />
              <div style={{ display: "flex", width: "100%" }}>
                <img
                  onClick={() => {
                    OpenModalFiltro(true);
                    setMensajeFechaInicio("true");
                    setMensajeFechaFin("true");
                  }}
                  style={{ width: "30px", cursor: "pointer" }}
                  src={FiltroIco}
                  alt=""
                />
                {FiltroActive && (
                  <Button
                    style={{
                      background: "white",
                      color: "#148F9F",
                      border: "0",
                      height: "30px",
                      padding: "0 1%",
                      margin: ".8% 0",
                      // marginLeft: "3%",
                      marginRight: "0",
                    }}
                    onClick={() => {
                      setFiltroActive(false);
                      setEstadoFiltro("Todos");
                      setFechaInicio("");
                      setFechaFin("");
                      getEstudios();
                    }}
                  >
                    <Cancel sx={{ color: "#148f9f" }} />
                    Filtro
                  </Button>
                )}
              </div>
            </div>
          </div>
        }
      />

      <Content
        className="site-layout"
        style={{
          // marginTop: "100px",
          padding: ".5% 3%",
          // height: "calc(100vh - 52px)",
          width: "100%",
          overflow: "initial",
        }}
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            <EstudiosCard
              TipoEstudioAnalisis={TipoEstudioAnalisis}
              activarEstudios={activarEstudios}
              OpenDetalleEstud={OpenDetalleEstud}
              EstudiosList={EstudiosList}
              DetailEstudios={DetailEstudios}
            />
            {EstudiosList.length === 0 && (
              <Empty
                description={
                  <span style={{ color: "black" }}>Sin estudios</span>
                }
              />
            )}
          </>
        )}
      </Content>

      <Modal
        styleDet={styleDet}
        optionClose={true}
        isOpen={isOpenModalAdd}
        closeModal={CloseModalAdd}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1% 2%",
            background: "#148f9f",
            color: "#fff",
          }}
        >
          <p>Nuevo estudio</p>{" "}
          <CloseIcono onClick={CloseModalAdd} style={{ cursor: "pointer", color: "white", fontSize: 20 }} />

        </div>
        <div className="ModalDetalEs" style={{ padding: "2%" }}>
          <p style={{ marginBottom: "1.5%" }}>Nombre:</p>
          <Input
            placeholder="Buscar..."
            type="search"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={filterContacts}
          />

          <div
            className="contCardModal"
            style={{ borderTop: "1px solid #d5d5d5" }}
          >
            <AddLista
              CloseModal={CloseModalAdd}
              OpenModal={OpenDetalleEstud}
              funcionExtra={[
                1,
                setIdContact,
                setDatosContact,
                setInputsEditable,
              ]}
              Lista={ContactList}
            />
          </div>
        </div>
      </Modal>

      <ModalFiltro
        isOpen={isOpenModalFiltro}
        closeModal={CloseModalFiltro}
        titulos={["Ordena tus estudios", "Hoy", "Ayer", "Semana"]}
        // OptionsSelect={[
        //   { label: "Todos", value: "Todos" },
        //   { label: "Guardados", value: "Guardados" },
        //   { label: "Recibidos", value: "Recibidos" },
        //   { label: "Enviados", value: "Enviados" },
        // ]}
        functions={[getEstudios, getEstudiosFilter]}
        statesVariables={{
          estadoFiltro: setEstadoFiltro,
          fechaInicio: setFechaInicio,
          mensajeFechaInicio: setMensajeFechaInicio,
          fechaFin: setFechaFin,
          mensajeFechaFin: setMensajeFechaFin,
        }}
        variables={{
          // estadoFiltro: EstadoFiltro,
          fechaInicio: FechaInicio,
          mensajeFechaInicio: MensajeFechaInicio,
          fechaFin: FechaFin,
          mensajeFechaFin: MensajeFechaFin,
        }}
      />

      <ModalDetalle
        styleDet={styleDet}
        isOpen={isOpenDetalleEstud}
        closeModal={CloseDetalleEstud}
        optionClose={inputsEditable ? true : false}
      >
        <div className="DetalleEstudios" style={{ padding: "0" }}>
          <div className="MenuOpDetalleEstudios">
            <p style={{ color: "white" }}>
              {" "}
              {!inputsEditable
                ? "Nuevo estudio"
                : "Detalle estudio"}{" "}
            </p>
            <CloseIcono
              style={{ fontSize: 20, cursor: "pointer", color: "white" }}
              onClick={() => {
                cleanInputs();
                CloseDetalleEstud(true);
              }}
            />
          </div>
          {loaderDetalle ? (
            <LoaderModals className={"DetalleEstudios"} />
          ) : (
            <div >
              <div style={{ textAlign: "center", width: "100%", margin: "1%", position: "sticky" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Avatar className="ImageNone" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} size={70} src={
                    <Image
                      // id={`img_detalle_Estud${DatosContact.id}`}

                      // onError={() => {
                      //   let img = document.getElementById(`img_detalle_Estud${DatosContact.id}`);
                      //   img.setAttribute("src", Iconos[0]);
                      // }}  
                      src={
                        !!DatosContact.imagen
                          ? "https://" + DatosContact.imagen
                          : Iconos[0]
                      }
                      alt=""
                    />
                  } />



                </div>
                <h4>{DatosContact.nombre}</h4>
              </div>
              <div className="DetalleEstAnalisis">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {!inputsEditable && (
                    <Checkbox
                      defaultChecked
                      value={EnviarLabTest}
                      onChange={(e) => setEnviarLabTest(e.checked)}
                      style={{ marginBottom: "2%" }}
                    >Enviar a paciente</Checkbox>
                  )}
                </div>
                <form style={{ padding: "2%" }} action="">
                  <TextAreaAntd
                    label="Descripción:"
                    placeholder="Descripción"
                    style={{
                      margin: "2% 0",
                      fontFamily: "sans-serif",
                    }}
                    columns="30"
                    autoSize={true}
                    value={
                      !inputsEditable
                        ? DescripcionEstudio
                        : DetailEstudio.Descripcion
                    }
                    setStateValue={setDescripcionEstudio}
                    vacio={true}
                    readOnly={inputsEditable}
                  />
                  <br />
                  {!inputsEditable && (
                    <div style={{ textAlign: "center" }}>
                      <InputFile
                        valueArrayImg={ImagesList}
                        arrayImg={setImagesList}
                        formatos={5}
                        extras={[
                          setOpen,
                          setTypeMessage,
                          setMessage,
                          setImagenesListError,
                        ]}
                      />
                      <LeyendaError valido={imagenesListError}>
                        Debe adjuntar al menos un archivo
                      </LeyendaError>
                    </div>
                  )}

                  {inputsEditable &&
                    ImagesList.length > 0 &&
                    ImagesList.map((imagen, index) => (
                      <>
                        <div


                        >
                          {imagen.Multimedia.substring(
                            imagen.Multimedia.toString().length,
                            imagen.Multimedia.toString().length - 3
                          ) === "jpeg" ||
                            imagen.Multimedia.substring(
                              imagen.Multimedia.toString().length,
                              imagen.Multimedia.toString().length - 3
                            ) === "png" ||
                            imagen.Multimedia.substring(
                              imagen.Multimedia.toString().length,
                              imagen.Multimedia.toString().length - 3
                            ) === "jpg" ? (
                            <Row
                              className="ListaDetalleEsAna"

                            >
                              <Image
                                style={{ width: "50px", height: "50px" }}
                                src={"https://" + imagen.Multimedia}
                                alt=""
                              />
                              <div
                                style={{
                                  width: "60%",
                                  height: "20px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}

                              >
                                <span>{`${DatosContact.nombre}_${!!TipoEstudioAnalisis &&
                                  TipoEstudioAnalisis !== "null"
                                  ? TipoEstudioAnalisis
                                  : "Estudio"
                                  }_${DetailEstudio.Fecha}_${index + 1}`}</span>
                              </div>
                              <div></div>
                            </Row>
                          ) : imagen.Multimedia.substring(
                            imagen.Multimedia.toString().length,
                            imagen.Multimedia.toString().length - 3
                          ) === "pdf" ? (
                            <Row
                              className="ListaDetalleEsAna"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                window.open(
                                  "https://" + imagen.Multimedia,
                                  "_blank"
                                );
                              }}
                            >
                              <img
                                style={{ width: "7%", marginRight: "3px" }}
                                src={Iconos[22]}
                              />
                              <div
                                style={{
                                  width: "60%",
                                  height: "20px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <span>{`${DatosContact.nombre}_${!!TipoEstudioAnalisis &&
                                  TipoEstudioAnalisis !== "null"
                                  ? TipoEstudioAnalisis
                                  : "Estudio"
                                  }_${DetailEstudio.Fecha}_${index + 1}`}</span>
                              </div>
                              <div></div>
                            </Row>
                          ) : (
                            <Row
                              className="ListaDetalleEsAna"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                window.open(
                                  "https://" + imagen.Multimedia,
                                  "_blank"
                                );
                              }}
                            >
                              <OndemandVideo sx={{ color: "#148F9F" }} />
                              <div
                                style={{
                                  width: "60%",
                                  height: "20px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <span>{`${DatosContact.nombre}_${!!TipoEstudioAnalisis &&
                                  TipoEstudioAnalisis !== "null"
                                  ? TipoEstudioAnalisis
                                  : "Estudio"
                                  }_${DetailEstudio.Fecha}_${index + 1}`}</span>
                              </div>
                              <div></div>
                            </Row>
                          )}
                        </div>
                        <br />
                      </>
                    ))}
                  <div style={{ marginLeft: "2%" }}>
                    {/* {" "} */}
                    {/* <p>&nbsp;Enviar a:</p>
                    <SelectMedicos
                      lista={MedicosList}
                      acciones={[1, setIdMedico]}
                    /> */}

                    <SelectAntd
                      label="Enviar a:"
                      value={
                        !!DatosMedico.id
                          ? {
                            value: "",
                            label: (
                              <div style={{ display: "flex", gap: "1" }}>
                                <img
                                  className="ImgMedicoSelect"
                                  src={
                                    !!DatosMedico.imagen
                                      ? `https://${DatosMedico.imagen}`
                                      : ""
                                  }
                                />{" "}
                                <div style={{ marginLeft: "1%" }}>
                                  {DatosMedico.nombre}
                                </div>
                              </div>
                            ),
                          }
                          : null
                      }
                      style={{
                        width: "100%",
                        margin: "1% 0",
                      }}
                      placeholder="Selecciona un médico"
                      onClick={() => {
                        CloseDetalleEstud(true);
                        OpenModalMedicos(true);
                      }}
                      setStateError={setMensajeDoctorSelect}
                      valueError={MensajeDoctorSelect}
                      textError="Debe seleccionar un médico"
                      readOnly={true}
                      open={false}
                      vacio={false}
                    />
                    <b style={{ fontWeight: "500", color: "gray" }}>
                      {" "}
                      Nota: Seleccionar, solo si se desea compartir con otro
                      médico
                    </b>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "2% 0",
                      }}
                    >
                      {!inputsEditable && (
                        <Button
                          className="GeneralBoton"
                          style={{ width: "100%", margin: "5px 0" }}
                          type="button"
                          id="boton_guardar"
                          onClick={addLabTest}
                        >
                          Guardar
                        </Button>
                      )}
                      {inputsEditable && (
                        <Button
                          className="GeneralBoton"
                          style={{ width: "100%", margin: "5px 0" }}
                          type="button"
                          id="boton_reenviar"
                          onClick={reenviarEstudio}
                        >
                          Reenviar
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </ModalDetalle>

      <Modal
        styleDet={styleDet}
        optionClose={true}
        isOpen={IsOpenModalMedicos}
        closeModal={ClosedModalMedicos}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1% 2%",
            background: "#148f9f",
            color: "#fff",
          }}
        >
          <h3>Reenviar estudio</h3>{" "}
          <b
            onClick={() => {
              ClosedModalMedicos(true);
              OpenDetalleEstud(true);
            }}
            style={{ cursor: "pointer" }}
          >
            x
          </b>
        </div>
        <div className="ModalDetalEs" style={{ padding: "2%" }}>
          <p style={{ marginBottom: "1.5%" }}>Nombre:</p>
          <Input
            placeholder="Buscar..."
            type="search"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={filterMedicos}
          />

          <div
            className="contCardModal"
            style={{ borderTop: "1px solid #d5d5d5" }}
          >
            <ModalMedicos
              contactList={MedicosList}
              closeModal={ClosedModalMedicos}
              openModal={OpenDetalleEstud}
              setDatosMedico={setDatosMedico}
              mensajeError={setMensajeDoctorSelect}
            />
          </div>
        </div>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default Estudios;
