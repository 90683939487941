import React from "react";
// importando scss
import "../../comp-styles/NotasMedicasCard.scss";

// importar iconos

function NotasMedicas({notas, setDesplegarInfoNotas, getDetailNotaMedica}) {
  return (
    <>
      {notas.map((lista) => (
        <div>
          <div className="PrinCard">
            <div className="">
              <div className="CardNotas" onClick={()=>{setDesplegarInfoNotas(1); getDetailNotaMedica(lista.id);}}>
                  <p style={{fontWeight:"500", color:"#424242"}}>{lista.motivo}</p>
                  <p>{lista.fecha}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default NotasMedicas;
