import React from 'react'
import "../comp-styles/Loader.scss"
import { useState } from 'react'

export const Loader = ({ texto, marginTop }) => {

  return (
    < >
      <div>
        <div style={{
          zIndex: "998",
          position: "fixed",
          // position:"absolute",
          // padding:"0 "
          left: "0",
          background: "rgba(255, 255, 255, 0.850)",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: marginTop === undefined && "90vh", marginTop: marginTop !== undefined && marginTop }}>
            <div className="lds-heart"><div></div></div>
          </div>
          {texto !== undefined && <div>
            <h4 style={{ textAlign: "center" }}>{texto}</h4>
          </div>}

        </div>
      </div>
    </>

  )
}
