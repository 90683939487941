import React, { useEffect, useState } from 'react'

import { useNavigate, useLocation } from "react-router-dom";

import { Checkbox, DatePicker, Table, Row, Col, Button, Modal } from "antd";
import { LeyendaError, Label, IconoValidacion } from "../GeneralComponents/Formularios";
import Editar from "../../Img/Iconos/editarID.svg";
import Icono from "../IconosDeSitio";

import { getHistoriasClinicas_request } from "../../services/expediente_service";
import {
    getCIIEDiagnosticos_request,
    editConsulta_request,
    detailConsultas_request,
    getMedicamentos_request,
    vistaPreviaInterconsulta_request,
    vistaPreviaReceta_request,
    previewResumenMedico_request,
    vistaPreviaOrdenEstudios_request
} from "../../services/consultas_services";
import { getCedulas_request } from "../../services/perfil_services";
import { getDetailEstudios_request } from "../../services/estudios_services";
import { getEstado_request, getLocalidad_request } from "../../services/expediente_service";
import { getContacts_request } from "../../services/contactos_services";
// aUTOCOMPLETE
import Autosuggest from "react-autosuggest";

// importando Iconos
import CheckGuardar from "../../Img/Iconos/checkmark_white.svg";
import Iconos from "../IconosDeSitio";
import Eliminar from "../../Img/Iconos/eliminar.svg";
import Agregar from "../../Img/Iconos/Agregar.svg";
import AddIcon from "@mui/icons-material/AddCircle";

import "../../pages/styles/Consultas.scss"
import TextArea from "antd/es/input/TextArea";
import { Select, Input } from "antd";
import InputAntd from "../GeneralComponents/InputAntd"
import SelectAntd from "../GeneralComponents/SelectAntd"
import TextAreaAntd from "../GeneralComponents/TextAreaAntd"

// Importando metodo de modales
import { UseModal } from "../../hooks/UseModal";

// Acordion libreria mui
import Accordionn from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from 'dayjs';
import "dayjs/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { Loader } from "../GeneralComponents/Loader";
import { Add, CancelRounded, OndemandVideo } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import ModalDetalleConsulta from "../GeneralComponents/ModalDetalle";
import ModalDetalleEstudio from "../GeneralComponents/ModalDetalle";
import ModalCostoConsulta from "../GeneralComponents/ModalDetalle";
import ModalResumenMedico from "../GeneralComponents/ModalDetalle";
import ModalAlertConfirm from "../../componentes/GeneralComponents/ModalDetalle";

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DatePickerAntd from '../GeneralComponents/DatePickerAntd';
import NavbarNuevo from '../GeneralComponents/Navbar_Nuevo';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import InputFileList from '../GeneralComponents/InputFileList';
import Campos from '../GeneralComponents/campos';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function EditConsulta() {

    const location = useLocation();
    const navigate = useNavigate();

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

    const styleDet = {
        minHeight: "30vh",
        maxHeight: "90vh",
        minWidth: "40vh",
        maxWidth: "90vh",
        margin: "0 2%",
    };

    const styleAnalisis = {
        minHeight: "30vh",
        maxHeight: "90vh",
        minWidth: "30vh",
        maxWidth: "85vh",
        margin: "0 2%",
    };

    const styleMot = {
        minHeight: "30vh",
        maxHeight: "70vh",
        minWidth: "30%",
        maxWidth: "30%",
    };

    const styleResMed = {
        minHeight: "30vh",
        maxHeight: "70vh",
        minWidth: "40%",
        maxWidth: "40%",
    };

    const styleConfirm = {
        height: "clac(100vh - 52px)",
        width: "clac(100vw - 52px)",
        margin: "0 2%",
    };

    const columns = [
        {
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
        },
        {
            title: 'Medicamento',
            dataIndex: 'medicamento',
            key: 'medicamento',
        },
        {
            title: 'Dosis',
            dataIndex: 'dosis',
            key: 'dosis',
        }
    ];

    let fecha_format = new Date(Date.now());
    let mes = fecha_format.getMonth() + 1;
    let mes_correcto = mes.toString().length === 1 ? `0${mes}` : mes;
    let fechaActual = `${fecha_format.getFullYear()}-${mes_correcto}-${fecha_format.getDate()}`;

    const [IsOpenEstudio, openEstudio, CloseEstudio] = UseModal(false);
    const [IsOpeNotaDetalle, OpenNotaDetalle, CloseNotaDetalle] = UseModal(false);
    const [IsOpenCostoConsulta, OpenCostoConsulta, ClosedCostoConsulta] = UseModal(false);
    const [IsOpenResumenMedico, OpenResumenMedico, ClosedResumenMedico] = UseModal(false);
    const [IsOpenAlertConfirm, OpenAlertConfirm, ClosedAlertConfirm] = UseModal(false);

    const [EstatusModalSalirConsulta, setEstatusModalSalirConsulta] = useState(false);

    const [DatosContacto, setDatosContacto] = useState(!!location.state ? { id: location.state.id, nombre: location.state.nombre, imagen: location.state.imagen } : { id: "", nombre: "", imagen: "" });

    const [Medicamentos, setMedicamentos] = useState([]);
    const [MedicamentosFilter, setMedicamentosFilter] = useState([]);
    const [MedcSelection, setMedcSelection] = useState({});
    const [MedcSelectionDos, setMedcSelectionDos] = useState({});

    const [MedicosList, setMedicosList] = useState([]);
    const [MedicosListFilter, setMedicosListFilter] = useState([]);

    const [EstadosList, setEstadosList] = useState([]);
    const [LocalidadesList, setLocalidadesList] = useState([]);

    // VARIABLES HISTORIA CLINICA
    const [TipoSangre, setTipoSangre] = useState("");
    const [Alergias, setAlergias] = useState("");
    const [Refiere, setRefiere] = useState("");
    const [ObservacionesPaciente, setObservacionesPaciente] = useState("");
    const [EstadoCivil, setEstadoCivil] = useState(null);
    const [Ocupacion, setOcupacion] = useState("");
    const [Sexo, setSexo] = useState(null);
    const [Nacimiento, setNacimiento] = useState("");
    const [Edad, setEdad] = useState(0);
    const [Nacionalidad, setNacionalidad] = useState("");
    const [Estado, setEstado] = useState(null);
    const [Localidad, setLocalidad] = useState(null);
    const [CodigoPostal, setCodigoPostal] = useState("");
    const [NombrePareja, setNombrePareja] = useState("");
    const [EdadPareja, setEdadPareja] = useState("");
    const [OcupacionPareja, setOcupacionPareja] = useState("");
    const [NotasPareja, setNotasPareja] = useState("");
    const [AHeredoFamiliares, setAHeredoFamiliares] = useState("");
    const [APersonalesPatologicos, setAPersonalesPatologicos] = useState("");
    const [APersonalesNoPatologicos, setAPersonalesNoPatologicos] = useState("");
    const [AAndroGineGeneral, setAAndroGineGeneral] = useState("");
    const [AAndroGine, setAAndroGine] = useState("");
    const [Embarazada, setEmbarazada] = useState(0);
    const [SignosSintomas, setSignosSintomas] = useState("");
    const [AparatoCardiovascular, setAparatoCardiovascular] = useState("");
    const [AparatoRespiratorio, setAparatoRespiratorio] = useState("");
    const [AparatoDigestivo, setAparatoDigestivo] = useState("");
    const [SistemaNefrourologico, setSistemaNefrourologico] = useState("");
    const [SistemaEndocrino, setSistemaEndocrino] = useState("");
    const [SistemaHematoproyetico, setSistemaHematoproyetico] = useState("");
    const [SistemaNervioso, setSistemaNervioso] = useState("");
    const [MusculoEsqueletico, setMusculoEsqueletico] = useState("");
    const [PielTegumentos, setPielTegumentos] = useState("");
    const [OrganosSentidos, setOrganosSentidos] = useState("");
    const [EsferaPsiquica, setEsferaPsiquica] = useState("");
    const [Genitales, setGenitales] = useState("");
    const [NotasEvolucion, setNotasEvolucion] = useState([]);
    const [EstudiosAnalisis, setEstudiosAnalisis] = useState([]);

    // #### INICIO DE VARIABLES CONSULTA/NOTA EVOLUCION ####

    const [CostoConsulta, setCostoConsulta] = useState(null);
    const [ConsultaSinCargo, setConsultaSinCargo] = useState(false);
    const [TipoConsulta, setTipoConsulta] = useState(!!location.state ? location.state.tipoConsulta : "");

    const [FechaConsulta, setFechaConsulta] = useState(fechaActual);
    // MOTIVO CONSULTA
    const [MotivoConsulta, setMotivoConsulta] = useState(!!location.state ? location.state.motivo : "");
    // EVOLUCION DEL PADECIMIENTO
    const [EvolucionPadecimiento, setEvolucionPadecimiento] = useState("");
    // EXPLORACION FISICA
    const [TArterial, setTArterial] = useState("");
    const [FCardiaca, setFCardiaca] = useState("");
    const [FRespiratoria, setFRespiratoria] = useState("");
    const [Temperatura, setTemperatura] = useState("");
    const [Altura, setAltura] = useState("");
    const [Peso, setPeso] = useState("");
    const [IMC, setIMC] = useState("");
    const [Talla, setTalla] = useState("");
    const [SOxigeno, setSOxigeno] = useState("");
    const [ExploracionFisica, setExploracionFisica] = useState("");
    // OBSERVACIONES
    const [Observaciones, setObservaciones] = useState("");
    // DIAGNOSTICO CLINICO
    const [ListaDiagnosticos, setListaDiagnosticos] = useState([]);
    const [DiagnosticosSeleccionados, setDiagnosticosSeleccionados] = useState([]);
    const [Diagnostico, setDiagnostico] = useState(null);
    const [DiagnosticosSeleccionadosMostrados, setDiagnosticosSeleccionadosMostrados] = useState([]);
    const [OtrosDiagnosticos, setOtrosDiagnosticos] = useState("");
    // PLAN
    const [Plan, setPlan] = useState("");
    // RECETA
    const [Cedula, setCedula] = useState("");
    const [IdMedicamento, setIdMedicamento] = useState("");
    const [Cantidad, setCantidad] = useState(1);
    const [Medicamento, setMedicamento] = useState("");
    const [Dosis, setDosis] = useState("");
    const [ListaMedicamentos, setListaMedicamentos] = useState([]);

    // ESTUDIOS Y ANALISIS
    const [DescripcionLabTest, setDescripcionLabTest] = useState("");
    const [ListaArchivos, setListaArchivos] = useState([]);

    // ORDEN DE ESTUDIOS DE LABORATORIO
    const [OrdenLabTest, setOrdenLabTest] = useState("");

    // INTERCONSULTA
    const [InterconsultaAdjunta, setInterconsultaAdjunta] = useState(0);
    const [OrdenLaboratorioAdjunta, setOrdenLaboratorioAdjunta] = useState(0);
    const [DoctorSelect, setDoctorSelect] = useState(null);
    const [CheckNuevo, setCheckNuevo] = useState("");
    const [NombreMedico, setNombreMedico] = useState("");
    const [ApellidoPMedico, setApellidoPMedico] = useState("");
    const [ApellidoMMedico, setApellidoMMedico] = useState("");
    const [AddEspecialidadContacto, setAddEspecialidadContacto] = useState("");
    const [CelularMedico, setCelularMedico] = useState("");
    const [EmailMedico, setEmailMedico] = useState("");
    const [EspecialidadMedico, setEspecialidadMedico] = useState("");
    const [MotivoInterconsulta, setMotivoInterconsulta] = useState("");
    const [DiagnosticoInterconsulta, setDiagnosticoInterconsulta] = useState("");

    // RECOMENDACIONES
    const [Recomendaciones, setRecomendaciones] = useState("");

    // #### FIN DE VARIABLES CONSULTA/NOTA DE EVOLUCION ####

    const [DetalleConsulta, setDetalleConsulta] = useState("");
    const [DetalleEstudio, setDetalleEstudio] = useState("");

    const [RowsReceta, setRowsReceta] = useState([]);

    const [ResumenMedico, setResumenMedico] = useState("");

    const [CierreModalResumen, setCierreModalResumen] = useState(false);

    const [CedulasMedico, setCedulasMedico] = useState([]);

    const [PalabraClave, setPalabraClave] = useState("");

    const [InputDisabled, setInputDisabled] = useState(true);

    const [DisabledInputs, setDisabledInputs] = useState(true);

    const [loader, setLoader] = useState(false);
    const [loaderSearch, setLoaderSearch] = useState(false);
    const [loaderInterconsulta, setLoaderInterconsulta] = useState(false);
    const [loaderResumenMedico, setLoaderResumenMedico] = useState(false);
    const [loaderReceta, setLoaderReceta] = useState(false);
    const [loaderOrdenLabTest, setLoaderOrdenLabTest] = useState(false);

    const [Link, setLink] = useState("");

    // MANEJA DE MENSAJES
    const [open, setOpen] = useState(false);
    const [typeMessage, setTypeMessage] = useState("");
    const [message, setMessage] = useState("");

    // Estatus mensajes DATOS CONSULTA
    const [MensajeMotivoConsulta, setMensajeMotivoConsulta] = useState("true");
    // MESNAJE DIAGNOSTICO
    const [MensajeDiagnosticoCIIE, setMensajeDiagnosticoCIIE] = useState("true");
    const [MensajeOtrosDiagnosticos, setMensajeOtrosDiagnosticos] = useState("true");
    // ERRORES EN ACCORDION
    const [ErrorPlanAccordion, setErrorPlanAccordion] = useState(true);
    const [ErrorDiagnosticoAccordion, setErrorDiagnosticoAccordion] = useState(true);
    // MENSAJE PLAN
    const [MensajePlan, setMensajePlan] = useState("true");
    // MENSAJE RECETA
    const [MensajeCedula, setMensajeCedula] = useState("true");
    const [MensajeMedicamento, setMensajeMedicamento] = useState("true");
    const [MensajeDosis, setMensajeDosis] = useState("true");

    // MENSAJE DIAGNOSTICO
    const [MensajePalabraClave, setMensajePalabraClave] = useState("true");

    // MENSAJE GENERO
    const [MensajeNacimiento, setMensajeNacimiento] = useState("true");
    const [MensajeSexo, setMensajeSexo] = useState("true");

    // MENSAJES INTERCONSULTA
    const [MensajeDoctorSelect, setMensajeDoctorSelect] = useState("true");
    const [MensajeNombreMedico, setMensajeNombreMedico] = useState("true");
    const [MensajeApellidoPMedico, setMensajeApellidoPMedico] = useState("true");
    const [MensajeEspecialidadMedico, setMensajeEspecialidadMedico] = useState("true");
    const [MensajeMotivoInterconsulta, setMensajeMotivoInterconsulta] = useState("true");
    const [MensajeDiagnosticoInterconsulta, setMensajeDiagnosticoInterconsulta] = useState("true");

    const [MensajeOrdenLabTest, setMensajeOrdenLabTest] = useState("true");

    const [MensajeResumenMedico, setMensajeResumenMedico] = useState("true");

    async function getHistoriaClinica() {
        setLoader(true);
        let response = await getHistoriasClinicas_request(DatosContacto.id);
        if (response.ok) {
            // console.log(response.data);
            if (response.data.consultas !== null) {
                setNotasEvolucion(response.data.consultas);
            }
            if (response.data.estudios !== null) {
                setEstudiosAnalisis(response.data.estudios);
            }
            if (response.data.ficha !== null) {
                let fichaId = response.data.ficha;
                setSexo(!!fichaId.sexo && fichaId.sexo !== null ? fichaId.sexo : "");


                if (!!fichaId.fechaNacimiento) {
                    let arrayFechaTemp = fichaId.fechaNacimiento.split("-");
                    let fechaTemp = `${arrayFechaTemp[2]}-${arrayFechaTemp[1]}-${arrayFechaTemp[0]}`
                    getEdad(fechaTemp);
                    setNacimiento(fechaTemp);

                }
                setNacionalidad(fichaId.nacionalidad);
                if (!!fichaId.estado) {
                    setEstado(fichaId.estado);
                    getLocalidades(fichaId.estado);
                    setLocalidad(fichaId.localidad);
                }
                setCodigoPostal(fichaId.cp);
            }
            if (response.data.historial !== null) {
                let historial = response.data.historial;
                setTipoSangre(historial.tipoSangre);
                setAlergias(historial.alergias);
                setRefiere(historial.refiere);
                setObservacionesPaciente(historial.observaciones);
                setAHeredoFamiliares(historial.aHereditarios);
                setAPersonalesPatologicos(historial.aPatologicos);
                setAPersonalesNoPatologicos(historial.aNoPatologicos);
                setAAndroGineGeneral(historial.aBiologicoGeneral);
                setAAndroGine(historial.aBiologicoEspecifico);
                setSignosSintomas(historial.sGenerales);
                setAparatoCardiovascular(historial.aCardiovascular);
                setAparatoRespiratorio(historial.aRespiratorio);
                setAparatoDigestivo(historial.aDigestivo);
                setSistemaNefrourologico(historial.sNefrourologico);
                setSistemaEndocrino(historial.sEndocrino);
                setSistemaHematoproyetico(historial.sHematopoyetico);
                setSistemaNervioso(historial.sNervioso);
                setMusculoEsqueletico(historial.sMusculo);
                setPielTegumentos(historial.piel);
                setOrganosSentidos(historial.oSentidos);
                setEsferaPsiquica(historial.ePsiquica);
                setGenitales(historial.sGenitales);
                setEstadoCivil(historial.estadoCivil !== null ? historial.estadoCivil.toString() : null);
                setOcupacion(historial.ocupacion);
                setNombrePareja(historial.nombrePareja);
                setEdadPareja(historial.edadPareja);
                setOcupacionPareja(historial.ocupacionPareja);
                setNotasPareja(historial.nota);
            }
            // if (response.data.recetas !== null) {

            // }
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoader(false);
        return;
    }

    async function detailConsulta(idConsulta) {
        // console.log(idConsulta);
        let response = await detailConsultas_request(idConsulta);
        if (response.ok) {
            // console.log(response.data);
            setDetalleConsulta(response.data);
            if (response.data.Partidas.length > 0) {
                let partidas = [];
                response.data.Partidas.forEach((element, index) => {
                    partidas.push({
                        cantidad: element.Cantidad,
                        medicamento: element.Concepto,
                        dosis: element.Descripcion,
                    })
                });
                // console.log(partidas);
                setRowsReceta(partidas);
            } else if (response.data.Partidas.length === undefined) {
                let partidas = [];
                partidas.push({
                    cantidad: response.data.Partidas.Cantidad,
                    medicamento: response.data.Partidas.Concepto,
                    dosis: response.data.Partidas.Descripcion,
                })
                // console.log(partidas);
                setRowsReceta(partidas);
            }
            if (!!response.data.Consulta.Peso && !!response.data.Consulta.Altura) {
                let peso_temp = response.data.Consulta.Peso;
                let altura_temp = response.data.Consulta.Altura;
                let imc = peso_temp / (altura_temp * altura_temp);
                setIMC(imc.toFixed(2));
            }
            setDatosContacto({ id: response.data.Consulta.IdContacto, nombre: response.data.Consulta.Contacto, imagen: response.data.Consulta.Imagen_Url });
            // setNacimiento(response.data.Consulta.fechaNacimiento);
            // setListaMedicamentos(response.data.Partidas);
            // let diag_temp = response.data.Consulta.Diagnostico.split(",");
            // console.log(diag_temp);
            // let array_temp = [];
            // diag_temp.forEach(element => {
            //     array_temp.push({ index: "", diagnostico: element });
            // });
            // setDiagnosticosSeleccionadosMostrados(array_temp);
            // setSexo(response.data.Consulta.sexo);
            // setMotivoConsulta(response.data.Consulta.Motivo);
            // setEvolucionPadecimiento(response.data.Consulta.EvolucionPadecimiento);
            // setRecomendaciones(response.data.Consulta.Recomendaciones);
            OpenNotaDetalle(true);
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
    }

    async function detailConsultaToEdit() {
        // console.log(location.state.idConsulta);
        let response = await detailConsultas_request(location.state.idConsulta);
        if (response.ok) {
            // console.log(response.data);
            let consulta = response.data.Consulta;
            let receta = response.data.Receta;
            let partidas = response.data.Partidas;
            // let Documentos = response.data.Documentos;
            let estudios = response.data.Estudios;

            setMotivoConsulta(consulta.Motivo);
            setEvolucionPadecimiento(consulta.EvolucionPadecimiento);
            setPeso(consulta.Peso);
            setAltura(consulta.Altura);
            if (!!consulta.Peso && !!consulta.Altura) {
                let peso_temp = consulta.Peso;
                let altura_temp = consulta.Altura;
                let imc = peso_temp / (altura_temp * altura_temp);
                setIMC(imc.toFixed(2));
            }

            setTArterial(consulta.TensionArterial);
            setFCardiaca(consulta.FrecuenciaCardiaca);
            setFRespiratoria(consulta.FrecuenciaRespiratoria);
            setSOxigeno(consulta.SaturacionOxigeno);
            setTemperatura(consulta.Temperatura);
            setExploracionFisica(consulta.ExploracionFisica);
            setRecomendaciones(consulta.Recomendaciones);
            setPlan(consulta.Plan);

            let array_diagnostico = consulta.Diagnostico.split(",");
            let arrayTempDiagnosticos = [];
            array_diagnostico.forEach((element, index) => {
                arrayTempDiagnosticos.push({
                    index: index + 1,
                    diagnostico: element
                })
            });
            setDiagnosticosSeleccionadosMostrados(arrayTempDiagnosticos);

            if (partidas.length > 0) {
                let partidasTemp = [];
                partidas.forEach((element, index) => {
                    partidasTemp.push({
                        index: index + 1,
                        Id: "",
                        Concepto: element.Concepto,
                        Cantidad: element.Cantidad,
                        Descripcion: element.Descripcion
                    });
                });
                setListaMedicamentos(partidasTemp);
            }

            if (response.data.Documentos.length > 0 || response.data.Estudios.Descripcion !== undefined) {
                setDescripcionLabTest(estudios.Descripcion);
                let temp = [];
                response.data.Documentos.forEach((element, index) => {
                    temp.push(
                        {
                            index: index,
                            name: element.Nombre,
                            url: `https://${element.Multimedia}`,
                            file: "",
                            size: "",
                            formato: element.Nombre.substring(element.Nombre.lastIndexOf(".") + 1),
                            tipo: "1"
                        }
                    )
                });
                setListaArchivos(temp);
            }

            // setFechaConsulta();
            // setEmbarazada();
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
    }

    async function editConsulta() {

        // console.log(DatosContacto.id);
        // console.log(TipoSangre);
        // return;
        setLoader(true);

        if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan) {
            // let receta_temp = [];
            // if (ListaMedicamentos.length > 0) {
            //     ListaMedicamentos.forEach(medicamento => {
            //         receta_temp.push({
            //             id: medicamento.Id,
            //             Concepto: medicamento.Concepto,
            //             Cantidad: medicamento.Cantidad,
            //             Descripcion: medicamento.Descripcion
            //         })
            //     });
            // }
            let estudiosTemp = []
            let estudio_temp = new FormData();
            let archivoEstudioTipo = [];
            if (ListaArchivos.length > 0) {
                ListaArchivos.forEach((archivo, index) => {
                    // archivoEstudioTipo.push({
                    //     `Archivo[${index}]`: archivo.file,
                    //     `Archivo[${index}][url]`: archivo.tipo
                    // })
                    if (archivo.tipo === "0") {
                        estudio_temp.append(`Archivo[${index}][Archivo]`, archivo.file);
                        estudio_temp.append(`Archivo[${index}][url]`, parseInt(archivo.tipo));
                    } else {
                        estudio_temp.append(`Archivo[${index}][Archivo]`, archivo.url);
                        estudio_temp.append(`Archivo[${index}][url]`, parseInt(archivo.tipo));
                    }

                    // console.log(estudio_temp.get(`Archivo[${index}][Archivo]`));
                    // console.log(estudio_temp.get(`Archivo[${index}][url]`));


                });
                // estudio_temp.append(archivoEstudioTipo);
                // ListaArchivos.forEach((archivo, index) => {
                //     archivoEstudioTipo.append(`Archivo[${index}][url]`, archivo.tipo);
                // });
            }
            // setLoader(false);
            // return;
            // console.log(ListaArchivos);
            // ListaArchivos.forEach((element, index) => {
            //     // console.log(estudio_temp.get(`Archivo[${index}]`));
            //     // console.log(estudio_temp.get(`Archivo[url]`));
            // });
            // console.log(estudio_temp.toString());
            // setLoader(false);
            // return;
            // let orden_temp = []
            // if (InterconsultaAdjunta === 1) {
            //     if (!!DoctorSelect) {
            //         orden_temp.push(
            //             {
            //                 idContactoM: [
            //                     {
            //                         id: DoctorSelect,
            //                     }
            //                 ],
            //                 tipoOrden: "0",
            //                 mOrden: MotivoInterconsulta,
            //                 diagnostico: DiagnosticoInterconsulta
            //             }
            //         );
            //     } else {
            //         orden_temp.push(
            //             {
            //                 idContactoM: [
            //                     {
            //                         id: "",
            //                         nombre: NombreMedico,
            //                         apellidoPaterno: ApellidoPMedico,
            //                         apellidoMaterno: ApellidoMMedico,
            //                         celular: CelularMedico,
            //                         email: EmailMedico,
            //                         especialidad: EspecialidadMedico,
            //                     }
            //                 ],
            //                 tipoOrden: "0",
            //                 mOrden: MotivoInterconsulta,
            //                 diagnostico: DiagnosticoInterconsulta
            //             }
            //         );
            //     }
            // }

            // if (!!OrdenLabTest) {
            //     orden_temp.push(
            //         {
            //             tipoOrden: "1",
            //             descripcion: OrdenLabTest

            //         }
            //     );
            // }

            let diagnosticos_temp = []
            if (DiagnosticosSeleccionadosMostrados.length > 0) {
                DiagnosticosSeleccionadosMostrados.forEach(element => {
                    diagnosticos_temp.push(element.diagnostico);
                });
            }

            // return;
            let data = {
                // TipoConsulta: "1",
                IdUsuario: DatosContacto.id,
                // fecha: FechaConsulta,
                Motivo: MotivoConsulta,
                Evolucion: EvolucionPadecimiento,
                Peso: Peso,
                Altura: Altura,
                Talla: Talla,
                TensionArterial: TArterial,
                FrecuenciaCardiaca: FCardiaca,
                FrecuenciaRespiratoria: FRespiratoria,
                SaturacionOxigeno: SOxigeno,
                Temperatura: Temperatura,
                ExploracionFisica: ExploracionFisica,
                Diagnostico: diagnosticos_temp.toString(),
                Recomendaciones: Recomendaciones,
                // Cedula: Cedula,
                // Costo: !!CostoConsulta ? CostoConsulta : 0,
                // Status: TipoConsulta,
                DescripcionEstudio: DescripcionLabTest,
                AntecedentesHereditarios: AHeredoFamiliares,
                AntecedentesPatologicos: APersonalesPatologicos,
                AntecedentesNoPatologicos: APersonalesNoPatologicos,
                SintomasGenerales: SignosSintomas,
                AparatoRespiratorio: AparatoRespiratorio,
                AparatoDigestivo: AparatoDigestivo,
                AparatoCardiovascular: AparatoCardiovascular,
                SistemaNefrourologico: SistemaNefrourologico,
                SistemaEndocrino: SistemaEndocrino,
                SistemaHematopoyetico: SistemaHematoproyetico,
                SistemaNervioso: SistemaNervioso,
                SistemaMusculo: MusculoEsqueletico,
                Piel: PielTegumentos,
                OrganosSentidos: OrganosSentidos,
                EsferaPsiquica: EsferaPsiquica,
                // Order: orden_temp,
                // Concepto: receta_temp,
                Alergias: Alergias,
                Observaciones: ObservacionesPaciente,
                Refiere: Refiere,
                Sexo: Sexo,
                Nacimiento: Nacimiento,
                nacionalidad: Nacionalidad,
                estado: Estado,
                localidad: Localidad,
                cp: CodigoPostal,
                Plan: Plan,
                ResumenClinico: ResumenMedico,
                embarazo: Embarazada,
                aBiologicoGeneral: AAndroGineGeneral,
                aBiologicoEspecifico: AAndroGine,
                sGenitales: Genitales,
                tipoSangre: TipoSangre,
                ocupacion: Ocupacion,
                estadoCivil: EstadoCivil,
                nombrePareja: NombrePareja,
                edadPareja: EdadPareja,
                ocupacionPareja: OcupacionPareja,
                nota: NotasPareja,
            };
            // console.log(JSON.stringify(data));
            // console.log(location.state.idConsulta);
            // return;
            let response = await editConsulta_request(data, location.state.idConsulta, estudio_temp, archivoEstudioTipo);
            if (response.ok) {
                setOpen(true);
                setTypeMessage("success");
                setMessage("Consulta editada");
                window.onbeforeunload = null;
                navigate("/app/consultas", {
                    replace: false,
                    state: {
                        consulta: true
                    }
                });
            } else {
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {
            if (!!!MotivoConsulta) {
                setMensajeMotivoConsulta("false");
            }
            if (DiagnosticosSeleccionadosMostrados.length === 0) {
                setErrorDiagnosticoAccordion(false);
            }
            if (!!!Plan) {
                setErrorPlanAccordion(false);
            }

            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");
        }
        setLoader(false);
        return;
    }

    async function vistaPreviaResumenMedico() {
        setLoaderResumenMedico(true);
        let data = new FormData();
        data.append("resumen", ResumenMedico)
        data.append("IdUsuario", DatosContacto.id)
        if (!!ResumenMedico) {
            let response = await previewResumenMedico_request(data);
            if (response.ok) {
                // console.log(response.data.resumen);
                window.open(response.data.resumen, "_blank");
            } else {
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {
            setMensajeResumenMedico("false");
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");
        }
        setLoaderResumenMedico(false);
        return;
    }

    async function getCedulas() {
        let response = await getCedulas_request();
        if (response.ok) {
            // console.log(response.data);
            let cedulas = [];
            response.data.forEach(cedula => {
                cedulas.push({ label: `${cedula.Cedula} - ${cedula.Profesion}`, value: cedula.Cedula })
            });
            setCedulasMedico(cedulas);
        } else {
            setCedulasMedico([]);
        }
    }

    async function getEstados() {
        let response = await getEstado_request();
        if (response.ok) {
            // console.log(response.data);
            let estados_temp = []
            response.data.forEach(estado => {
                estados_temp.push({ value: estado.id.toString(), label: estado.Estado });
            });
            setEstadosList(estados_temp);
        } else {
            setEstadosList([]);
        }
    }

    async function getLocalidades(estado) {
        let response = await getLocalidad_request(estado);
        if (response.ok) {
            // console.log(response.data);
            let localidades_temp = []
            response.data.forEach(estado => {
                localidades_temp.push({ value: estado, label: estado });
            });
            setLocalidadesList(localidades_temp);
        } else {
            setLocalidadesList([]);
        }
    }

    async function detailEstudios(id) {
        let response = await getDetailEstudios_request(id);
        if (response.ok) {
            // console.log(response.data);
            setDetalleEstudio(response.data);
            openEstudio(true);

        } else {
            setDetalleEstudio("");
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
    }

    async function getCIIEDiagnosticos() {
        // let response = await getCIIEDiagnosticos(text.target.value);
        setLoaderSearch(true);
        if (!!PalabraClave) {
            let response = await getCIIEDiagnosticos_request(PalabraClave);
            // console.log(response.data);
            // return;
            if (response.ok) {
                let list = [];
                // console.log(response.data);
                response.data.forEach((diagnostico, index) => {
                    list.push({ key: index, value: diagnostico, label: diagnostico })
                });
                setListaDiagnosticos(list);
            } else {
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {

        }
        setLoaderSearch(false);
        return;
    }

    async function getMedicamentos() {
        let response = await getMedicamentos_request();
        if (response.ok) {
            // console.log(response.data);
            let medi_temp = [];
            response.data.forEach(medi => {
                medi_temp.push({ Numero: medi.id, Opcion: `${medi.nombreActivo} - ${medi.id}` })
            });
            // console.log(medi_temp);
            setMedicamentos(medi_temp);
            setMedicamentosFilter(medi_temp);
        } else {

        }
    }

    async function vistaPreviaInter() {
        setLoaderInterconsulta(true);
        if (!!NombreMedico && !!ApellidoPMedico && !!MotivoInterconsulta && !!DiagnosticoInterconsulta && !!EspecialidadMedico) {
            let data = new FormData();
            data.append("mOrden", MotivoInterconsulta);
            data.append("diagnostico", DiagnosticoInterconsulta);
            data.append("IdUsuario", DatosContacto.id);
            // console.log(DatosContacto.id);
            let response = await vistaPreviaInterconsulta_request(data);
            if (response.ok) {
                // console.log(response.data);
                // console.log(response.data.orden.substring(8, response.data.orden.length));
                // window.open(`https://${response.data.orden.substring(8, response.data.orden.length)}`, "_blank");
                window.open(response.data.orden, "_blank");
                // window.open(`https://ygiamedicalbucket.s3.amazonaws.com/Estudios/Di8ErysTVQH0QLmW/yKIPqBea3AzDlyF5sCTMHgGOXhBfo2NxBTYqDSwI.pdf`, "_blank");
            } else {
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {
            if (!!!NombreMedico) {
                setMensajeNombreMedico("false");
            }
            if (!!!ApellidoPMedico) {
                setMensajeApellidoPMedico("false");
            }

            if (!!!MotivoInterconsulta) {
                setMensajeMotivoInterconsulta("false");
            }

            if (!!!DiagnosticoInterconsulta) {
                setDiagnosticoInterconsulta("false");
            }

            if (!!!EspecialidadMedico) {
                setMensajeEspecialidadMedico("false");
            }

            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");
        }
        setLoaderInterconsulta(false);
        return;
    }

    async function vistaPreviaReceta() {
        setLoaderReceta(true);
        let medicamentos = [];
        ListaMedicamentos.forEach(element => {
            medicamentos.push({
                id: element.Id,
                Cantidad: element.Cantidad,
                Concepto: element.Concepto,
                Descripcion: element.Descripcion,
            })
        });
        let data = {
            "Concepto": medicamentos,
            "Cedula": Cedula,
            "IdUsuario": DatosContacto.id,
        };
        let response = await vistaPreviaReceta_request(data);
        if (response.ok) {
            // console.log(response.data);
            window.open(response.data.receta, "_blank");
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoaderReceta(false);
        return;
    }

    async function getContactos() {
        let response = await getContacts_request();
        if (response.ok) {
            // console.log(response.data);
            let array = [];
            let medicos = [
                {
                    label: "Contactos",
                    options: []
                }
            ];
            let medicos_filter = [];
            response.data.forEach((element) => {
                if (element.Type === 1) {
                    medicos[0].options.push({ value: element.IdContacto, label: <div style={{ display: "flex", gap: "1" }}><img className="ImgMedicoSelect" src={!!element.Imagen_url ? `https://${element.Imagen_url}` : ""} /> <div style={{ marginLeft: "1%" }}>{`${element.Nombre} ${element.ApellidoPaterno} ${element.ApellidoMaterno} - ${element.YgiaUser}`}</div></div> });
                    medicos_filter.push(element);
                }
            });
            setMedicosList(medicos);
            setMedicosListFilter(medicos_filter);
        } else {
        }
    }

    async function vistaPreviaOrdenDeEstudios() {
        setLoaderOrdenLabTest(true);
        let data = new FormData();
        data.append("descripcion", OrdenLabTest)
        data.append("IdUsuario", DatosContacto.id)
        if (!!OrdenLabTest) {
            let response = await vistaPreviaOrdenEstudios_request(data);
            if (response.ok) {
                // console.log(response.data);
                window.open(response.data.ordenEstudio, "_blank");
            } else {
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {
            setMensajeOrdenLabTest("false");
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");
        }
        setLoaderOrdenLabTest(false);
        return;
    }

    const onSuggestionsFetchRequested2 = ({ value }) => {
        // console.log("Filtro RECETA");
        setMedicamentos(FiltrarMedicamentos(value));
    };

    const FiltrarMedicamentos = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLenght = inputValue.Lenght;

        var filtrado = MedicamentosFilter.filter((medicamento) => {
            var textoCompleto = medicamento.Opcion;

            if (
                textoCompleto
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(inputValue)
            ) {
                return medicamento;
            }
        });
        return inputLenght === 0 ? [] : filtrado;
    };
    const onSuggestionsClearRequested2 = () => {
        setMedicamentos([]);
    };

    const getSuggestionValueDos = (suggestion) => {
        return `${suggestion.Opcion} `;
    };
    const renderSuggestionDos = (suggestion) => (

        <div className="sugerencia" onClick={() => { setMedcSelectionDos(suggestion); }}>
            {/* {// console.log(suggestion)} */}
            {`${suggestion.Opcion}`}
        </div>
    );

    const onChangeDos = (e, { newValue }) => {
        if (newValue.length > 0) {
            setMensajeMedicamento("true");
        }
        // console.log(e.target);
        // console.log(newValue);
        setMedicamento(newValue);
    };

    const inputPropsDos = {
        placeholder: "Medicamento",
        value: Medicamento,
        onChange: (e, newValue) => {
            onChangeDos(e, newValue);
        },
    };

    const eventEnterDos = (e) => {
        if (e.key == "Enter") {
            // console.log(e.target.value);
            var split = e.target.value.split("-");
            // console.log(split);
            var medicamento = {
                Opcion: split[0].trim(),
                // Numero: split[1].trim(),
            };
            // console.log(medicamento);
            setMedcSelection(medicamento);
        }
    };

    function cleanInputsDetalleConsulta() {
        setDetalleConsulta("");
    }

    // RECETA-MEDICAMENTOS
    function addMedicamento() {
        // console.log(NombreProducto);
        // console.log(CantidadProducto);
        // console.log(DescripcionUso);

        if (!!Medicamento && !!Dosis && !!Cantidad) {
            // setOpen(true);
            // setTypeMessage("success");
            // setMessage("Agregado");
            // console.log(Medicamento);
            let arrayMedi = Medicamento.split("-");
            let medicamento_temp = arrayMedi[0];
            let id_temp = arrayMedi[1];
            // console.log({ medicamento_temp, id_temp });
            let array = ListaMedicamentos;
            if (array.length > 0) {
                array.push({
                    index: ListaMedicamentos[ListaMedicamentos.length - 1].index + 1,
                    Id: id_temp !== undefined ? id_temp : "",
                    Concepto: medicamento_temp,
                    Cantidad: Cantidad,
                    Descripcion: Dosis
                });
            } else {
                array.push({
                    index: 1,
                    Id: id_temp !== undefined ? id_temp : "",
                    Concepto: medicamento_temp,
                    Cantidad: Cantidad,
                    Descripcion: Dosis
                });
            }
            // console.log(array);

            setListaMedicamentos(array);

            setMedicamento("");
            setCantidad(1);
            setDosis("");
            // setMensajeListaMedicamentos("true");
        } else {
            if (!!!Medicamento) {
                setMensajeMedicamento("false");
            }
            if (!!!Dosis) {
                setMensajeDosis("false");
            }
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");

        }

        return;
    }

    function eliminarMedicamento(posicion) {
        // console.log(posicion);
        let array = ListaMedicamentos;

        let arrayDos = [];

        let arrayTres = [];

        arrayDos = array.filter((element) => { return element.index !== posicion; });
        // console.log(arrayDos);
        arrayDos.forEach((element, index) => {
            arrayTres.push({
                index: index + 1,
                Concepto: element.Concepto,
                Cantidad: element.Cantidad,
                Descripcion: element.Descripcion
            });
        });

        setListaMedicamentos(arrayTres);
        // console.log(arrayTres);
    }

    // DIAGNOSTICOS
    function addDiagnosticoCIIE() {
        if (DiagnosticosSeleccionados.length > 0) {
            DiagnosticosSeleccionados.forEach((diagnostico, index) => {
                let array = DiagnosticosSeleccionadosMostrados;
                if (array.length > 0) {
                    // DiagnosticosSeleccionados.forEach(diagnostico => {
                    array.push({
                        index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
                        diagnostico: diagnostico
                    });
                    // });
                    setDiagnosticosSeleccionadosMostrados(array);

                } else {
                    array.push({
                        index: 1,
                        diagnostico: diagnostico
                    });

                    setDiagnosticosSeleccionadosMostrados(array);
                }
            });

            // console.log(DiagnosticosSeleccionadosMostrados);

            setDiagnosticosSeleccionados([]);
            setErrorDiagnosticoAccordion(true);
        } else {
            if (DiagnosticosSeleccionados.length === 0) {
                setMensajeDiagnosticoCIIE("false");
            }
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");

        }

        return;
    }

    function addDiagnosticoOtro() {
        if (!!OtrosDiagnosticos) {
            let array = DiagnosticosSeleccionadosMostrados;
            if (array.length > 0) {
                array.push({
                    index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
                    diagnostico: OtrosDiagnosticos
                });
            } else {
                array.push({
                    index: 1,
                    diagnostico: OtrosDiagnosticos
                });
            }

            // console.log(array);

            setDiagnosticosSeleccionadosMostrados(array);
            setOtrosDiagnosticos("");
            setErrorDiagnosticoAccordion(true);
        } else {
            if (!!!OtrosDiagnosticos) {
                setMensajeOtrosDiagnosticos("false");
            }
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");

        }

        return;
    }

    function eliminarDiagnostico(posicion) {
        // console.log(posicion);
        let array = DiagnosticosSeleccionadosMostrados;

        let arrayDos = [];

        let arrayTres = [];

        arrayDos = array.filter((element) => { return element.index !== posicion; });
        // console.log(arrayDos);
        arrayDos.forEach((element, index) => {
            arrayTres.push({
                index: index + 1,
                diagnostico: element.diagnostico
            });
        });

        setDiagnosticosSeleccionadosMostrados(arrayTres);
        // console.log(arrayTres);
    }

    function filterOptions(input, option) {
        var chars = {
            "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
            "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
            "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
            "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
        }
        var expr = /[áàéèíìóòúùñ]/ig;
        return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
    }

    function filterSort(optionA, optionB) {
        var chars = {
            "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
            "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
            "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
            "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
        }
        var expr = /[áàéèíìóòúùñ]/ig;
        return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
    }

    function crearResumenMedico() {

        let receta_temp = [];
        if (ListaMedicamentos.length > 0) {
            ListaMedicamentos.forEach(medicamento => {
                receta_temp.push(`${medicamento.Concepto} - ${medicamento.Descripcion}`);
            });
        }
        let diagnosticos_temp = []
        if (DiagnosticosSeleccionadosMostrados.length > 0) {
            DiagnosticosSeleccionadosMostrados.forEach(element => {
                diagnosticos_temp.push(element.diagnostico);
            });
        }
        // console.log(DiagnosticosSeleccionadosMostrados);
        let edad = "";
        let resumen =
            `Paciente: \n${DatosContacto.nombre}\n\nEdad:\n${!!Edad ? Edad : "No especificada"}\n\nGénero:\n${Sexo}\n\nMotivo de consulta:\n${MotivoConsulta}\n\nEvolución del padecimiento:\n${!!EvolucionPadecimiento ? EvolucionPadecimiento : "Sin evolución del padecimiento"}\n\nDiagnósticos:\n${diagnosticos_temp.toString()}\n\nTratamientos:\n${!!receta_temp.toString() ? receta_temp.toString() : "Sin tratamiento"}\n\nRecomendaciones para el paciente:\n${!!Recomendaciones ? Recomendaciones : "Sin recomendaciones"}`;
        // ${!!EvolucionPadecimiento &&
        //   `Evolucion del padecimiento:
        //   ${EvolucionPadecimiento}
        //   `}
        // ${receta_temp.length > 0 &&
        //   `Tratamientos:
        //     ${receta_temp.toString()}
        //   `}
        // ${!!Recomendaciones &&
        //   `Recomendaciones para el paciente:
        //     ${Recomendaciones}
        //   `}

        setResumenMedico(resumen);

        OpenResumenMedico(true);

    }

    function getEdad(dateString) {
        let hoy = new Date();
        let fNacimiento = new Date(dateString.replace(/-/g, "/"));
        // console.log(fNacimiento);
        let edad = hoy.getFullYear() - fNacimiento.getFullYear()
        let diferenciaMeses = hoy.getMonth() - fNacimiento.getMonth()
        if (
            diferenciaMeses < 0 ||
            (diferenciaMeses === 0 && hoy.getDate() < fNacimiento.getDate())
        ) {
            edad--
        }
        // console.log(edad);
        setEdad(edad);
        // return edad;
    }

    function salirConsulta(link, funcion) {
        // console.log("Saliendo de consultas");
        if (funcion !== undefined) {
            funcion();
        } else {
            setLink(link);
            OpenAlertConfirm(true);
        }
    }

    function areYouSure(e) {
        // alert("Desea salir de la pestaña sin guardar?");
        var confirmationMessage = "\o/";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage;
        // console.log(allowPrompt);
        // if (allowPrompt) {
        //     OpenAlertConfirm(true);
        //     if (!areYouReallySure && true) {
        //         areYouReallySure = true;
        //         var confMessage = "***************************************nn E S P E R A !!! nnAntes de abandonar nuestra web, síguenos en nuestras redes sociales como Facebook, Twitter o Instagram.nnnYA PUEDES HACER CLIC EN EL BOTÓN CANCELAR SI QUIERES...nn***************************************";
        //         // return confMessage;
        //     }
        // } else {
        //     // allowPrompt = true;
        // }
    }

    useEffect(() => {
        if (!!!location.state) {
            navigate("/app/consultas");
        }
        getCedulas();
        getEstados();
        getMedicamentos();
        getHistoriaClinica();
        getContactos();
        detailConsultaToEdit();
        window.onbeforeunload = areYouSure;
    }, [])
    return (
        <Stack>
            <NavbarNuevo
                mensajesError={{
                    setOpen: setOpen,
                    setTypeMessage: setTypeMessage,
                    setMessage: setMessage,
                }}
                consulta={salirConsulta} />
            {loader ? (<Loader />) : (
                <div className="GeneralContainerHistorial">
                    {/* <h2 ></h2> */}
                    <h3>Editar consulta</h3>
                    {/* <title>Editar consulta</title> */}

                    <div className="Row">
                        <Col className="Content">
                            <div className="Historialflexible">
                                <h3>Historial clínico</h3>
                                <div className="BotonGuardarActive">
                                    <Button
                                        type="button"
                                        size="md"
                                        style={{
                                            background: "#148F9F",
                                            padding: ".5% 5%",
                                            margin: "0",
                                            border: "#148F9F",
                                            color: "white"
                                        }}
                                        onClick={() => {
                                            if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan) {
                                                // setCierreModalResumen(false);
                                                // crearResumenMedico();
                                                editConsulta();
                                            } else {
                                                if (!!!MotivoConsulta) {
                                                    setMensajeMotivoConsulta("false");
                                                }
                                                if (DiagnosticosSeleccionadosMostrados.length === 0) {
                                                    setErrorDiagnosticoAccordion(false);
                                                }
                                                if (!!!Plan) {
                                                    setErrorPlanAccordion(false);
                                                    setMensajePlan("false");
                                                }

                                                setOpen(true);
                                                setTypeMessage("warning");
                                                setMessage("Faltan campos por completar");
                                            }
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "6px",
                                            }}
                                        >
                                            <span>Guardar</span>
                                            <img
                                                style={{ width: "12px", height: "12px" }}
                                                src={CheckGuardar}
                                                alt=""
                                            />
                                        </div>
                                    </Button>
                                    {/* <Popconfirm
                                        title="Resumen médico"
                                        description="¿Desea crear un resumen médico?"
                                        okText="Si"
                                        cancelText="No"
                                        onConfirm={() => {
                                            if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan) {
                                                setCierreModalResumen(false);
                                                crearResumenMedico();
                                            } else {
                                                if (!!!MotivoConsulta) {
                                                    setMensajeMotivoConsulta("false");
                                                }
                                                if (DiagnosticosSeleccionadosMostrados.length === 0) {
                                                    setErrorDiagnosticoAccordion(false);
                                                }
                                                if (!!!Plan) {
                                                    setErrorPlanAccordion(false);
                                                    setMensajePlan("false");
                                                }

                                                setOpen(true);
                                                setTypeMessage("warning");
                                                setMessage("Faltan campos por completar");
                                            }
                                        }}
                                        onCancel={() => {
                                            if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan) {
                                                // OpenCostoConsulta(true);
                                                editConsulta();
                                            } else {
                                                if (!!!MotivoConsulta) {
                                                    setMensajeMotivoConsulta("false");
                                                }
                                                if (DiagnosticosSeleccionadosMostrados.length === 0) {
                                                    setErrorDiagnosticoAccordion(false);
                                                }
                                                if (!!!Plan) {
                                                    setErrorPlanAccordion(false);
                                                    setMensajePlan("false");
                                                }

                                                setOpen(true);
                                                setTypeMessage("warning");
                                                setMessage("Faltan campos por completar");
                                            }
                                        }}
                                    >
                                        <Button
                                            type="button"
                                            size="md"
                                            style={{
                                                background: "#148F9F",
                                                padding: ".5% 5%",
                                                margin: "0",
                                                border: "#148F9F",
                                                color: "white"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "6px",
                                                }}
                                            >
                                                <span>guardar</span>
                                                <img
                                                    style={{ width: "12px", height: "12px" }}
                                                    src={CheckGuardar}
                                                    alt=""
                                                />
                                            </div>
                                        </Button>
                                    </Popconfirm> */}
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginRight: "3%",
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "1%",
                                        marginBottom: "1%",
                                        width: "95%",
                                    }}
                                >
                                    <div>
                                        <img className="ImgHistorialPerf" src={!!DatosContacto.imagen ? `https://${DatosContacto.imagen}` : Iconos[0]} alt="" />

                                    </div>

                                    <div>
                                        <b style={{ fontWeight: "500", margin: "0" }}>
                                            {DatosContacto.nombre}
                                        </b>
                                        <div>
                                            <div>
                                                <span style={{ fontWeight: "500", fontSize: "12px" }}>
                                                    Edad: {Edad === 0 ? "No especificada" : `${Edad} años`}
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "500", fontSize: "12px" }}>
                                                    Tipo de sangre: {!!TipoSangre ? TipoSangre : "No especificada"}
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "500", fontSize: "12px" }}>
                                                    Id YGIA: {DatosContacto.id}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {DisabledInputs ?
                                    (
                                        <div style={{ width: "5%" }} onClick={() => { setDisabledInputs(false) }}>
                                            <div>
                                                <img
                                                    style={{ width: "16px", cursor: "pointer" }}
                                                    src={Editar}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        <div style={{ width: "5%", cursor: "pointer" }} onClick={() => { setDisabledInputs(true) }}>
                                            <div>
                                                <h3 style={{ color: "#148f9f" }}>x</h3>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            {/* ### VISTA EN CONSULTA DE HISTORIA CLINICA ### */}
                            <div className="ScrollConsultas">
                                {/* INFORMACION GENERAL */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            {(MensajeSexo === "false" && MensajeNacimiento === "false") && (<><CancelRounded color="error" />&nbsp;</>)}
                                            <b style={{ color: "#ff4d4f", fontSize: "14px" }}>*&nbsp;</b>
                                            Información general
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <InputAntd
                                            label="Tipo de sangre:"
                                            className="inputblue"
                                            value={TipoSangre}
                                            setStateValue={setTipoSangre}
                                            placeholder="Tipo de sangre"
                                            vacio={true}
                                            readOnly={DisabledInputs}
                                            style={{ width: "30%" }}
                                            length={15}
                                        />
                                        <TextAreaAntd
                                            label="Alergias:"
                                            value={Alergias}
                                            placeholder="Alergias"
                                            style={{
                                                // margin: ".5% 0",
                                                marginBottom: ".5%",
                                                fontFamily: "sans-serif",
                                            }}
                                            columns="30"
                                            autoSize={true}
                                            setStateValue={setAlergias}
                                            readOnly={DisabledInputs}
                                        />

                                        <TextAreaAntd
                                            label="Quien Refiere:"
                                            value={Refiere}
                                            placeholder="Quien Refiere"
                                            style={{
                                                // margin: ".5% 0",
                                                marginBottom: ".5%",
                                                fontFamily: "sans-serif",
                                            }}
                                            columns="30"
                                            autoSize={true}
                                            setStateValue={setRefiere}
                                            readOnly={DisabledInputs}
                                        />

                                        <TextAreaAntd
                                            label="Observaciones sobre el paciente:"
                                            value={ObservacionesPaciente}
                                            placeholder="Observaciones sobre el paciente"
                                            style={{
                                                // margin: ".5% 0",
                                                marginBottom: ".5%",
                                                fontFamily: "sans-serif",
                                            }}
                                            columns="30"
                                            autoSize={true}
                                            setStateValue={setObservacionesPaciente}
                                            readOnly={DisabledInputs}
                                        />

                                        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                            <SelectAntd
                                                label="Estado civil:"
                                                value={EstadoCivil}
                                                style={{ width: "100%" }}
                                                placeholder="Seleccione un estado civil"
                                                options={
                                                    [
                                                        { value: "0", label: "Soltero/a" },
                                                        { value: "1", label: "Casado/a" },
                                                        { value: "2", label: "Viudo/a" },
                                                        { value: "3", label: "Divorciado/a" },
                                                        { value: "4", label: "Separado/a" },
                                                        { value: "5", label: "Concubinato" },
                                                    ]
                                                }
                                                setStateValue={setEstadoCivil}
                                                disabled={DisabledInputs}
                                                styleDiv={{ width: "45%" }}
                                            // setStateError={setMensajeSexo}
                                            // valueError={MensajeSexo}
                                            // textError={"Debe seleccionar un estado civil"}
                                            />
                                            <InputAntd
                                                className="inputblue"
                                                value={Ocupacion}
                                                setStateValue={setOcupacion}
                                                label="Ocupación:"
                                                placeholder="Escribe una ocupación"
                                                // valueError={MensajeAddNombrecontacto}
                                                vacio={true}
                                                // disabled={false}
                                                // expresionRegular={expresiones.Letras}
                                                readOnly={DisabledInputs}
                                                styleDiv={{ width: "45%" }}
                                            />
                                        </Row>

                                        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                            <SelectAntd
                                                label="Género:"
                                                requiredICon={true}
                                                value={Sexo}
                                                style={{ width: "100%" }}
                                                placeholder="Género"
                                                options={[{ value: "Femenino", label: "Femenino" }, { value: "Masculino", label: "Masculino" }]}
                                                setStateValue={setSexo}
                                                disabled={DisabledInputs}
                                                styleDiv={{ width: "45%" }}
                                                setStateError={setMensajeSexo}
                                                valueError={MensajeSexo}
                                                textError={"Debe seleccionar un género"}
                                                vacio={false}
                                            />
                                            <DatePickerAntd
                                                label="Nacimiento:"
                                                requiredICon={true}
                                                placeholder="DD/MM/AAAA"
                                                style={{ width: "100%", background: "white" }}
                                                value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                                                setStateValue={setNacimiento}
                                                disabled={DisabledInputs}
                                                styleDiv={{ width: "45%" }}
                                                setEdad={setEdad}
                                                setStateError={setMensajeNacimiento}
                                                valueError={MensajeNacimiento}
                                                textError={"Debe elegir una fecha de nacimiento"}
                                                vacio={false}
                                            />
                                        </Row>

                                        <InputAntd
                                            className="inputblue"
                                            value={Nacionalidad}
                                            setStateValue={setNacionalidad}
                                            label="Nacionalidad:"
                                            placeholder="Nacionalidad"
                                            // valueError={MensajeAddNombrecontacto}
                                            vacio={true}
                                            // disabled={false}
                                            // expresionRegular={expresiones.Letras}
                                            readOnly={DisabledInputs}
                                        />

                                        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                            <Col style={{ width: "45%" }}>
                                                <Label>Estado:</Label>
                                                <Select
                                                    disabled={DisabledInputs}
                                                    showSearch
                                                    style={{
                                                        width: "100%",
                                                        margin: ".5% 0"
                                                    }}
                                                    placeholder="Estado"
                                                    optionFilterProp="children"
                                                    filterOption={filterOptions}
                                                    filterSort={filterSort}
                                                    value={Estado}
                                                    onSelect={(e) => {
                                                        setEstado(e);
                                                        getLocalidades(e);
                                                    }}
                                                    options={EstadosList}
                                                />
                                            </Col>
                                            <Col style={{ width: "45%" }}>
                                                <Label>Localidad:</Label>
                                                <Select
                                                    showSearch
                                                    style={{
                                                        width: "100%",
                                                        margin: ".5% 0"
                                                    }}
                                                    placeholder="Localidad"
                                                    optionFilterProp="children"
                                                    filterOption={filterOptions}
                                                    filterSort={filterSort}
                                                    value={Localidad}
                                                    onSelect={(e) => {
                                                        setLocalidad(e);
                                                    }}
                                                    options={LocalidadesList}
                                                    disabled={LocalidadesList.length === 0 ? true : DisabledInputs ? true : false}
                                                />
                                            </Col>
                                        </Row>
                                    </AccordionDetails>
                                </Accordionn>
                                {/* DATOS DE PAREJA */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            Datos de pareja
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <InputAntd
                                            label="Nombre:"
                                            className="inputblue"
                                            value={NombrePareja}
                                            setStateValue={setNombrePareja}
                                            placeholder="Escriba un nombre completo"
                                            vacio={true}
                                            readOnly={DisabledInputs}
                                            styleDiv={{ width: "100%" }}
                                        // maxLength={15}
                                        />
                                        <Row style={{ justifyContent: "space-between" }}>
                                            <InputAntd
                                                className="inputblue"
                                                value={OcupacionPareja}
                                                setStateValue={setOcupacionPareja}
                                                label="Ocupación:"
                                                placeholder="Escribe una ocupación"
                                                // valueError={MensajeAddNombrecontacto}
                                                vacio={true}
                                                // disabled={false}
                                                // expresionRegular={expresiones.Letras}
                                                readOnly={DisabledInputs}
                                                styleDiv={{ width: "70%" }}
                                            />

                                            <InputAntd
                                                label="Edad:"
                                                className="inputblue"
                                                value={EdadPareja}
                                                setStateValue={setEdadPareja}
                                                placeholder="Edad"
                                                vacio={true}
                                                readOnly={DisabledInputs}
                                                styleDiv={{ width: "15%" }}
                                                maxLength={2}
                                            />
                                        </Row>
                                        <TextAreaAntd
                                            label="Notas/Comentarios:"
                                            value={NotasPareja}
                                            placeholder="Escriba una nota o comentario"
                                            style={{
                                                // margin: ".5% 0",
                                                marginBottom: ".5%",
                                                fontFamily: "sans-serif",
                                            }}
                                            columns="30"
                                            autoSize={true}
                                            setStateValue={setNotasPareja}
                                            readOnly={DisabledInputs}
                                        />
                                    </AccordionDetails>
                                </Accordionn>
                                {/* ANTECEDENTES HEREDO-FAMILIARES */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            Antecedentes heredo-familiares
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TextArea
                                            // readOnly
                                            placeholder="Antecedentes heredo-familiares"
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                                border: "0",
                                            }}
                                            name=""
                                            id=""
                                            cols="30"
                                            onChange={(e) => {
                                                setAHeredoFamiliares(e.target.value);
                                            }}
                                            value={AHeredoFamiliares}
                                            autoSize
                                            readOnly={DisabledInputs}
                                        />
                                    </AccordionDetails>
                                </Accordionn>
                                {/* ANTECEDENTES PATOLOGICOS */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            Antecedentes personales patológicos
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TextArea
                                            // readOnly
                                            placeholder="Antecedentes personales patológicos"
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                                border: "0",
                                            }}
                                            name=""
                                            id=""
                                            cols="30"
                                            onChange={(e) => {
                                                setAPersonalesPatologicos(e.target.value);
                                            }}
                                            value={APersonalesPatologicos}
                                            autoSize
                                            readOnly={DisabledInputs}
                                        />
                                    </AccordionDetails>
                                </Accordionn>
                                {/* ANTECEDENTES NO PATOLOGICOS */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            Antecedentes personales no patológicos
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TextArea
                                            placeholder="Antecedentes personales no patológicos"
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",

                                                border: "0",
                                            }}
                                            onChange={(e) => {
                                                setAPersonalesNoPatologicos(e.target.value);
                                            }}
                                            value={APersonalesNoPatologicos}
                                            name=""
                                            id=""
                                            cols="30"
                                            autoSize
                                            readOnly={DisabledInputs}
                                        />
                                    </AccordionDetails>
                                </Accordionn>
                                {/* ANTECEDENTES ANDROLOGICOS O GINECO-OBSTETRICOS */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            {Sexo === "Masculino" ? "Antecedentes andrológicos" : "Antecedentes gineco-obstetricos"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {Sexo === "Femenino" && (
                                            <>
                                                <Label>¿Paciente embarazada?</Label>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "30%" }}>
                                                    <Checkbox checked={Embarazada === 1 ? true : false} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setEmbarazada(1);
                                                        }
                                                    }}>Si</Checkbox>
                                                    <Checkbox checked={Embarazada === 0 ? true : false} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setEmbarazada(0);
                                                        }
                                                    }}>No</Checkbox>
                                                </div>
                                            </>
                                        )}
                                        <label htmlFor="">Generales</label>
                                        <TextArea
                                            placeholder="Inicio de vida sexual, método de planificación familiar, orientación sexual, si sostiene relaciones sexuales de alto riesgo, alteraciones de la libido, si ha padecido enfermedades de transmisión sexual."
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                            }}
                                            name=""
                                            id=""
                                            cols="30"
                                            autoSize
                                            onChange={(e) => {
                                                setAAndroGineGeneral(e.target.value);
                                            }}
                                            value={AAndroGineGeneral}
                                            readOnly={DisabledInputs}
                                        />
                                        <label htmlFor="">{Sexo === "Masculino" ? "Andrológicos específicos" : "Ginecoobstetricos"}</label>
                                        <TextArea
                                            placeholder={Sexo === "Masculino" ? "Priapismo, alteraciones de la erección y/o eyaculación, secreción uretral, dolor testicular, alteraciones escrotales, enfermedades de transmisión sexual."
                                                : "Menarca, Telarca, Pubarca, Gestas, Partos Cesáreas, Abortos, Dismenorreas, Ciclo menstrual."}
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                            }}
                                            name=""
                                            id=""
                                            cols="30"
                                            autoSize
                                            onChange={(e) => {
                                                setAAndroGine(e.target.value);
                                            }}
                                            value={AAndroGine}
                                            readOnly={DisabledInputs}
                                        />
                                    </AccordionDetails>
                                </Accordionn>
                                {/* INTERROGATORIO APARATOS Y SISTEMAS */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            Interrogatorio por aparatos y sistemas
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {(!!SignosSintomas || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Signos y síntomas generales</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Fiebre, cefalea, astenia, adinamia y anorexia."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={SignosSintomas}
                                                    onChange={(e) => {
                                                        setSignosSintomas(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!AparatoCardiovascular || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Aparato cardiovascular</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Disnea, dolor precordial, palpitaciones, sincope, lipotimia, edema, cianosis, acúfenos, fosfenos. "
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={AparatoCardiovascular}
                                                    onChange={(e) => {
                                                        setAparatoCardiovascular(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!AparatoRespiratorio || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Aparato respiratorio</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Rinorrea, rinolalia, tos, expectoración, disnea, dolor torácico, epistaxis, disfonía, hemoptisis, vómica, sibilancias audibles a distancia. "
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={AparatoRespiratorio}
                                                    onChange={(e) => {
                                                        setAparatoRespiratorio(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!AparatoDigestivo || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Aparato digestivo</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Trastornos de la deglución, de la digestión, de la defecación, nausea, vómito, dolor abdominal, diarrea, constipación, ictericia, rumiación, regurgitación, pirosis, aerofagia, eructos, meteorismos, distención abdominal, flatulencia, hematemesis, características de las heces fecales, diarrea, estreñimiento, acolia, hipocolia, melena, rectorragia, lientería, pujo, tenesmo y prurito anal."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={AparatoDigestivo}
                                                    onChange={(e) => {
                                                        setAparatoDigestivo(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!SistemaNefrourologico || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Sistema nefrourologico</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Dolor renoureteral, hematuria, piuria, coluría, oliguria, tenesmo, control de esfínteres, cólico renal, edema, trastornos en el volumen urinario, nictámero, urgencia, características del chorro urinario."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={SistemaNefrourologico}
                                                    onChange={(e) => {
                                                        setSistemaNefrourologico(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!SistemaEndocrino || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Sistema endocrino y metabolismo</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Intolerancia al calor o al frio, pérdida o aumento de peso, alteraciones del color de la piel, vello corporal, distribución de la grasa corporal, astenia o adinamia, alteraciones del volumen urinario, amenorreas, ginecomastia, galactorrea, alteraciones de la libido, espasmos o calambres musculares."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={SistemaEndocrino}
                                                    onChange={(e) => {
                                                        setSistemaEndocrino(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!SistemaHematoproyetico || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Sistema hematopoyético</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Palidez, rubicundez, adenomegalias, hemorragias, fiebre, fatigabilidad, equimosis, petequias y adenomegalias."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={SistemaHematoproyetico}
                                                    onChange={(e) => {
                                                        setSistemaHematoproyetico(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!SistemaNervioso || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Sistema nervioso</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Cefalalgia, pérdida de conocimiento, mareos vértigo y trastornos del equilibrio, movimientos anormales involuntarios, debilidad muscular, convulsiones, trastornos de la visión, dolor hormigueo y adormecimiento, trastornos del sueño."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={SistemaNervioso}
                                                    onChange={(e) => {
                                                        setSistemaNervioso(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!MusculoEsqueletico || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Sistema musculo esquelético</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Mialgias, dolor óseo, artralgias, alteraciones en la marcha, hipotonía, disminución del volumenmuscular, limitación de movimientos y deformidades."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={MusculoEsqueletico}
                                                    onChange={(e) => {
                                                        setMusculoEsqueletico(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!PielTegumentos || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Piel y tegumentos</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Coloración, pigmentación, prurito, características del pelo, uñas, lesiones (primarias y secundarias), hiperhidrosis y xerodermia."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={PielTegumentos}
                                                    onChange={(e) => {
                                                        setPielTegumentos(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}

                                        {(!!OrganosSentidos || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Órganos de los sentidos</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Alteraciones de la visión, de la audición, del olfato, del gusto y del tacto (hipo, hiper o disfunción). Mareo y sensación de líquido en el oído."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={OrganosSentidos}
                                                    onChange={(e) => {
                                                        setOrganosSentidos(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}


                                        {(!!EsferaPsiquica || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Esfera psíquica</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Tristeza, euforia, alteraciones del sueño, , terrores nocturnos, ideaciones, miedo exagerado a situaciones comunes, irritabilidad, apatía."
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={EsferaPsiquica}
                                                    onChange={(e) => {
                                                        setEsferaPsiquica(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}


                                        {(!!Genitales || !DisabledInputs) && (
                                            <>
                                                <label htmlFor="">Genitourinario</label>
                                                <TextArea
                                                    className="InputNormal"
                                                    placeholder="Hematuria, incontinencia, tenesmo vesical, disuria y ardor, poliuria, oliguria, anuria, polaquiuria, nicturia, opsiuria"
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        width: "100%",
                                                        padding: ".5%",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    readOnly={DisabledInputs}
                                                    value={Genitales}
                                                    onChange={(e) => {
                                                        setGenitales(e.target.value);
                                                    }}
                                                />
                                            </>
                                        )}
                                    </AccordionDetails>
                                </Accordionn>
                                {/* NOTAS DE EVOLUCION */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>Notas de evolución</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <>
                                            {NotasEvolucion.map((nota, index) => (
                                                <div key={index} className="ItemCard" style={{ cursor: "pointer" }} onClick={() => { detailConsulta(nota.id) }}>
                                                    <div
                                                        className="CardNotasMed"
                                                        onClick={OpenNotaDetalle}
                                                    >
                                                        <span>{nota.motivo}</span> <span>{nota.fecha}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    </AccordionDetails>
                                </Accordionn>
                                {/* ESTUDIOS Y ANALISIS */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>Estudios y análisis</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <>
                                            {EstudiosAnalisis.map((labTest, index) => (
                                                <div
                                                    key={index}
                                                    className="ItemCard"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => { detailEstudios(labTest.id) }}
                                                >
                                                    <div className="CardNotasMed">
                                                        <span>{!!labTest.descripcion ? labTest.descripcion : '\"Sin descripción\"'}</span> <span>{labTest.fecha}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    </AccordionDetails>
                                </Accordionn>
                            </div>
                        </Col>
                        <Col className="Content">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    // alignItems: "center",
                                    marginRight: "3%",
                                }}
                            >
                                <div>
                                    <h3>Nota de evolución</h3>
                                </div>

                                <div className="BotonGuardarActivation">
                                    <Button
                                        type="button"
                                        size="md"
                                        style={{
                                            background: "#148F9F",
                                            padding: ".5% 5%",
                                            margin: "0",
                                            border: "#148F9F",
                                            color: "white"
                                        }}
                                        onClick={() => {
                                            if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan) {
                                                // setCierreModalResumen(false);
                                                // crearResumenMedico();
                                                editConsulta();
                                            } else {
                                                if (!!!MotivoConsulta) {
                                                    setMensajeMotivoConsulta("false");
                                                }
                                                if (DiagnosticosSeleccionadosMostrados.length === 0) {
                                                    setErrorDiagnosticoAccordion(false);
                                                }
                                                if (!!!Plan) {
                                                    setErrorPlanAccordion(false);
                                                    setMensajePlan("false");
                                                }

                                                setOpen(true);
                                                setTypeMessage("warning");
                                                setMessage("Faltan campos por completar");
                                            }
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "6px",
                                            }}
                                        >
                                            <span>Finalizar edición</span>
                                            <img
                                                style={{ width: "12px", height: "12px" }}
                                                src={CheckGuardar}
                                                alt=""
                                            />
                                        </div>
                                    </Button>
                                </div>
                            </div>
                            {/* ### VISTA DE CONSULTA NOTA DE EVOLUCION ### */}
                            <div className="ScrollNotas">
                                <div className='headerDateConsulta'>
                                    <b><b style={{ color: "#ff4d4f", fontSize: "14px" }}>*&nbsp;</b>{`Seccion(es)/Campo(s) obligatorio(s)`}</b>
                                    {/* <DatePicker
                                        inputReadOnly={true}
                                        style={{ width: "30%", background: "white" }}
                                        value={dayjs(FechaConsulta)}
                                        format="DD/MM/YYYY"
                                        locale={locale}
                                        onSelect={(e) => {
                                            // console.log(e);
                                            let temp = e.$d;
                                            let mesObtenido = temp.getMonth() + 1;
                                            // console.log(mesObtenido);
                                            let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                                            // console.log(mesCalculo);
                                            let fecha_enviar = temp.getFullYear() + "-" + mesCalculo + "-" + temp.getDate();
                                            // console.log(fecha_enviar);
                                            setFechaConsulta(fecha_enviar);
                                        }}
                                        onBlur={(e) => {
                                            // console.log(e.target.value);
                                            // console.log(e.target.value.split("-"));
                                            let arrayFechaFormat = e.target.value.split("-");
                                            let arrayFecha = e.target.value.split("/");
                                            if (arrayFecha.length === 3) {
                                                let fecha = new Date(`${arrayFecha[1]}/${arrayFecha[0]}/${arrayFecha[2]}`);
                                                // let temp = e.$d
                                                // console.log(fecha.getMonth());
                                                let mesObtenido = fecha.getMonth() + 1;
                                                // console.log(mesObtenido);
                                                let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                                                // console.log(mesCalculo);
                                                let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
                                                // console.log(fecha_enviar);
                                                setFechaConsulta(fecha_enviar);
                                            } else if (arrayFechaFormat.length === 3) {
                                                let fecha = new Date(`${arrayFechaFormat[1]}/${arrayFechaFormat[0]}/${arrayFechaFormat[2]}`);
                                                // let temp = e.$d
                                                // console.log(fecha.getMonth());
                                                let mesObtenido = fecha.getMonth() + 1;
                                                // console.log(mesObtenido);
                                                let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                                                // console.log(mesCalculo);
                                                let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
                                                // console.log(fecha_enviar);
                                                setFechaConsulta(fecha_enviar);
                                            }

                                        }}
                                    /> */}

                                    <Campos
                                        label="Fecha:"
                                        // styleDiv={{width:"100%"}}
                                        classname="datepicker_consulta"
                                    >
                                        <DatePicker
                                            inputReadOnly={true}
                                            disabled
                                            // style={{ width: "30%", background: "white" }}
                                            value={dayjs(FechaConsulta)}
                                            format="DD/MM/YYYY"
                                            locale={locale}
                                            onSelect={(e) => {
                                                // console.log(e);
                                                let temp = e.$d;
                                                let mesObtenido = temp.getMonth() + 1;
                                                // console.log(mesObtenido);
                                                let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                                                // console.log(mesCalculo);
                                                let fecha_enviar = temp.getFullYear() + "-" + mesCalculo + "-" + temp.getDate();
                                                // console.log(fecha_enviar);
                                                setFechaConsulta(fecha_enviar);
                                            }}
                                            onBlur={(e) => {
                                                // console.log(e.target.value);
                                                // console.log(e.target.value.split("-"));
                                                let arrayFechaFormat = e.target.value.split("-");
                                                let arrayFecha = e.target.value.split("/");
                                                if (arrayFecha.length === 3) {
                                                    let fecha = new Date(`${arrayFecha[1]}/${arrayFecha[0]}/${arrayFecha[2]}`);
                                                    // let temp = e.$d
                                                    // console.log(fecha.getMonth());
                                                    let mesObtenido = fecha.getMonth() + 1;
                                                    // console.log(mesObtenido);
                                                    let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                                                    // console.log(mesCalculo);
                                                    let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
                                                    // console.log(fecha_enviar);
                                                    setFechaConsulta(fecha_enviar);
                                                } else if (arrayFechaFormat.length === 3) {
                                                    let fecha = new Date(`${arrayFechaFormat[1]}/${arrayFechaFormat[0]}/${arrayFechaFormat[2]}`);
                                                    // let temp = e.$d
                                                    // console.log(fecha.getMonth());
                                                    let mesObtenido = fecha.getMonth() + 1;
                                                    // console.log(mesObtenido);
                                                    let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                                                    // console.log(mesCalculo);
                                                    let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
                                                    // console.log(fecha_enviar);
                                                    setFechaConsulta(fecha_enviar);
                                                }

                                            }}
                                        />
                                    </Campos>
                                </div>
                                <TextAreaAntd
                                    label="Motivo de consulta:"
                                    requiredICon={true}
                                    value={MotivoConsulta}
                                    placeholder="Motivo de consulta"
                                    style={{
                                        marginBottom: ".5%",
                                        fontFamily: "sans-serif",
                                    }}
                                    columns="30"
                                    autoSize={true}
                                    setStateValue={setMotivoConsulta}
                                    setStateError={setMensajeMotivoConsulta}
                                    valueError={MensajeMotivoConsulta}
                                    textError={["Debe escribir un motivo de consulta"]}
                                    vacio={false}
                                />
                                {/* EVOLUCION PADECIMIENTO */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Evolución del padecimiento</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TextArea
                                            placeholder="Evolucion del padecimiento"
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                            }}
                                            name=""
                                            id=""
                                            cols="30"
                                            autoSize
                                            value={EvolucionPadecimiento}
                                            onChange={(e) => {
                                                setEvolucionPadecimiento(e.target.value);
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordionn>
                                {/* EXPLORACION FISICA */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography> Exploración física</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* <p style={{fontWeight:"500"}}>Signos vitales</p> */}
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                <InputAntd
                                                    label="Altura:"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    styleLabel={{ fontSize: "14px" }}
                                                    type=""
                                                    id="altura"
                                                    placeholder="Altura (Mtrs.)"
                                                    value={Altura}
                                                    setStateValue={setAltura}
                                                    vacio={true}
                                                    disabled={false}
                                                    extras={["IMC", setIMC, Peso]}
                                                />
                                            </div>
                                            <div>
                                                <InputAntd
                                                    label="Peso:"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    styleLabel={{ fontSize: "14px" }}
                                                    type=""
                                                    id="peso"
                                                    placeholder="Peso (Kg.)"
                                                    value={Peso}
                                                    setStateValue={setPeso}
                                                    vacio={true}
                                                    disabled={false}
                                                    extras={["IMC", setIMC, Altura]}
                                                />
                                            </div>
                                            <div>
                                                <InputAntd
                                                    label="IMC:"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    styleLabel={{ fontSize: "14px" }}
                                                    type=""
                                                    placeholder="IMC"
                                                    value={IMC}
                                                    setStateValue={setIMC}
                                                    vacio={true}
                                                    // disabled={true}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div>
                                                <Label></Label>
                                                <Input
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    type=""
                                                    placeholder="Tipo de peso"
                                                    value={!!IMC ? IMC < 18 ? "Insuficiente" : IMC >= 18 && IMC <= 25 ? "Peso normal" : IMC > 25 && IMC <= 30 ? "Sobrepeso" : IMC > 30 && IMC <= 40 ? "Obesidad" : IMC > 40 && "Obesidad mórbida" : ""}
                                                    readOnly={true}
                                                    status={!!IMC ? IMC < 18 ? "warning" : IMC >= 18 && IMC <= 25 ? "" : IMC > 25 && IMC <= 30 ? "warning" : IMC > 30 && IMC <= 40 ? "warning" : IMC > 40 && "error" : ""}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                <InputAntd
                                                    label="T. Arterial"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    styleLabel={{ fontSize: "14px" }}
                                                    type=""
                                                    placeholder="T. Arterial"
                                                    value={TArterial}
                                                    setStateValue={setTArterial}
                                                    // label="Nombre"
                                                    vacio={true}
                                                    disabled={false}
                                                />
                                            </div>

                                            <div>
                                                <InputAntd
                                                    label="F. Cardiaca"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    styleLabel={{ fontSize: "14px" }}
                                                    type=""
                                                    placeholder="F. Cardiaca"
                                                    value={FCardiaca}
                                                    setStateValue={setFCardiaca}
                                                    vacio={true}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div>
                                                <InputAntd
                                                    label="F. Respiratoria"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    styleLabel={{ fontSize: "14px" }}
                                                    type=""
                                                    placeholder="F. Respiratoria"
                                                    value={FRespiratoria}
                                                    setStateValue={setFRespiratoria}
                                                    vacio={true}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div>
                                                <InputAntd
                                                    label="Temperatura"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    styleLabel={{ fontSize: "14px" }}
                                                    type=""
                                                    placeholder="Temperatura"
                                                    value={Temperatura}
                                                    setStateValue={setTemperatura}
                                                    vacio={true}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div>
                                                <InputAntd
                                                    label="O2"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    type=""
                                                    placeholder="O2"
                                                    styleLabel={{ fontSize: "14px" }}
                                                    value={SOxigeno}
                                                    setStateValue={setSOxigeno}
                                                    vacio={true}
                                                    disabled={false}
                                                />
                                            </div>

                                        </div>
                                        <TextArea
                                            placeholder="Exploración Física"
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                            }}
                                            name=""
                                            id=""
                                            cols="30"
                                            autoSize
                                            value={ExploracionFisica}
                                            onChange={(e) => {
                                                setExploracionFisica(e.target.value);
                                            }}
                                        />

                                    </AccordionDetails>
                                </Accordionn>
                                {/* ESTUDIOS Y ANALISIS */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Estudios y análisis</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TextArea
                                            value={DescripcionLabTest}
                                            placeholder="Descripcion"
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                            }}
                                            name=""
                                            id=""
                                            cols="30"
                                            autoSize
                                            onChange={(e) => {
                                                setDescripcionLabTest(e.target.value);
                                            }}
                                        // readOnly={true}
                                        />
                                        <div style={{ textAlign: "center" }}>
                                            <InputFileList arrayImg={setListaArchivos} extras={[setOpen, setTypeMessage, setMessage]} valueArrayImg={ListaArchivos} formatos={5} />
                                            {ListaArchivos.length === 0 && (
                                                <span>No hay archivos selecionados</span>
                                            )}
                                        </div>
                                        {/* <div style={{ textAlign: "center", width: "100%" }}>
                                            {ListaArchivos.length > 0 ?
                                                ListaArchivos.map((documento, index) => (
                                                    // <div style={{ justifyContent: "center", padding: "3%", textAlign: "center" }} key={index}>
                                                    <div
                                                        style={{
                                                            marginTop: "1%",
                                                            padding: "1% 3%",
                                                            gap: "3%",
                                                            border: "1px solid #d7d7d7",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            textAlign: "center"

                                                        }}
                                                        key={index}
                                                    >
                                                        {
                                                            documento.Nombre.substring(
                                                                documento.Nombre.toString().length,
                                                                documento.Nombre.toString().length - 3
                                                            ) !== "png" || documento.Nombre.substring(
                                                                documento.Nombre.toString().length,
                                                                documento.Nombre.toString().length - 3
                                                            ) !== "jpeg"
                                                                ?
                                                                (
                                                                    <Row style={{ justifyContent: "space-between", cursor: "pointer" }} onClick={() => { window.open(`https://${documento.Multimedia}`, "_blank") }}>
                                                                        <img style={{ width: "10%" }} src={`https://${documento.Multimedia}`} alt="" />
                                                                        <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                            <span>{documento.Nombre}</span>
                                                                        </div>
                                                                        <div>
                                                                        </div>
                                                                    </Row>
                                                                ) : documento.Nombre.substring(
                                                                    documento.Nombre.toString().length,
                                                                    documento.Nombre.toString().length - 3
                                                                ) !== "pdf" ?
                                                                    (
                                                                        <Row style={{ justifyContent: "space-between", cursor: "pointer" }} onClick={() => { window.open(`https://${documento.Multimedia}`, "_blank") }}>
                                                                            <img style={{ width: "7%", marginRight: "3px" }} src={Icono[22]} />
                                                                            <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                                <span>{documento.Nombre}</span>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </Row>
                                                                    ) : (
                                                                        <Row style={{ justifyContent: "space-between", cursor: "pointer" }} onClick={() => { window.open(`https://${documento.Multimedia}`, "_blank") }}>
                                                                            <OndemandVideo sx={{ color: "#148F9F" }} />
                                                                            <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                                <span>{documento.Nombre}</span>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </Row>
                                                                    )
                                                        }
                                                    </div>
                                                )
                                                ) :
                                                (<span>No hay archivos selecionados</span>)
                                            }
                                        </div> */}
                                    </AccordionDetails>
                                </Accordionn>
                                {/* DIAGNOSTICO CLINICO */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            {(!ErrorDiagnosticoAccordion) && (<><CancelRounded color="error" />&nbsp;</>)}
                                            <b style={{ color: "#ff4d4f", fontSize: "14px" }}>*&nbsp;</b>
                                            Diagnóstico clínico
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <LeyendaError valido={ErrorDiagnosticoAccordion.toString()}>Debe agregar un diagnostico</LeyendaError>
                                        <Label valido={MensajeDiagnosticoCIIE}>Diagnósticos CIE</Label>
                                        <div style={{ display: "flex", gap: "1", margin: "1% 0" }}>
                                            <InputAntd
                                                className="InputNormal"
                                                type=""
                                                placeholder="Palabra clave"
                                                value={PalabraClave}
                                                setStateValue={setPalabraClave}
                                                vacio={true}
                                                disabled={false}
                                                style={{ width: "100%" }}
                                                setStateError={setMensajePalabraClave}
                                                textError={["Debe escribir una palabra clave", ""]}
                                                valueError={MensajePalabraClave}
                                            />
                                            {loaderSearch ? (<CircularProgress size={35} sx={{ color: "#148f9f" }} />) : (
                                                <Button
                                                    type="button"
                                                    style={{ margin: "0 1%", marginLeft: "1%", border: "0", background: "#148f9f", color: "white" }}
                                                    onClick={() => {
                                                        if (!!PalabraClave) {
                                                            getCIIEDiagnosticos();
                                                        } else {
                                                            setMensajePalabraClave("false");
                                                            setOpen(true);
                                                            setTypeMessage("warning");
                                                            setMessage("Debe escribir una palabra");
                                                        }
                                                    }}>Buscar</Button>
                                            )}
                                        </div>

                                        <div>
                                            {ListaDiagnosticos.length > 0 && (
                                                <>
                                                    <div style={{ display: "flex", gap: "1%" }}>
                                                        <Select
                                                            value={DiagnosticosSeleccionados}
                                                            mode="multiple"
                                                            placeholder="Seleciona tus diagnosticos"
                                                            style={{ width: "100%" }}
                                                            options={ListaDiagnosticos}
                                                            filterOption={filterOptions}
                                                            filterSort={filterSort}
                                                            onChange={(e) => {
                                                                // console.log(e);
                                                                setDiagnosticosSeleccionados(e);
                                                            }}
                                                        />
                                                        <Button
                                                            icon={<Add style={{ color: "white" }} />}
                                                            style={{ background: "#148f9f" }}
                                                            onClick={() => {
                                                                addDiagnosticoCIIE();
                                                            }}
                                                        />
                                                    </div>
                                                    <LeyendaError valido={MensajeDiagnosticoCIIE}>Debe seleccionar un diágnostico</LeyendaError>
                                                </>
                                            )}
                                            <Label valido={MensajeOtrosDiagnosticos}>Otros diagnósticos</Label>
                                            <div style={{ display: "flex", gap: "1%" }}>
                                                <TextAreaAntd
                                                    value={OtrosDiagnosticos}
                                                    placeholder="Otros diagnósticos"
                                                    style={{
                                                        marginBottom: ".5%",
                                                        fontFamily: "sans-serif",
                                                    }}
                                                    columns="100"
                                                    autoSize={true}
                                                    setStateValue={setOtrosDiagnosticos}
                                                    setStateError={setMensajeOtrosDiagnosticos}
                                                    valueError={MensajeOtrosDiagnosticos}
                                                    textError={["Debe escribir un diagnósticos"]}
                                                    vacio={true}
                                                />
                                                <Button
                                                    icon={<Add style={{ color: "white" }} />}
                                                    style={{ background: "#148f9f" }}
                                                    onClick={() => {
                                                        addDiagnosticoOtro();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <b style={{ fontSize: "16px" }}>Lista de diagnósticos</b>
                                        {DiagnosticosSeleccionadosMostrados.length > 0 ? (
                                            <>
                                                {DiagnosticosSeleccionadosMostrados.map((diagnostico, index) => (
                                                    // <div className="ItemCard">
                                                    //     <div className="CardNotasMed">
                                                    //         <span>{diagnostico.diagnostico}</span>
                                                    //         <img onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                                                    //     </div>
                                                    // </div>
                                                    <div className="ItemCard" key={index}>
                                                        <Row style={{ justifyContent: "space-between" }}>
                                                            <span>{diagnostico.diagnostico}</span>
                                                            <img style={{ width: "14px", cursor: "pointer" }} onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                                                        </Row>
                                                    </div>

                                                ))}
                                            </>
                                        ) : (
                                            <p style={{ textAlign: "center" }}>Sin diagnósticos agregados</p>
                                        )}
                                    </AccordionDetails>
                                </Accordionn>
                                {/* PLAN */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            {!ErrorPlanAccordion && (<><CancelRounded color="error" />&nbsp;</>)}
                                            <b style={{ color: "#ff4d4f", fontSize: "14px" }}>*&nbsp;</b>
                                            Plan</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TextAreaAntd
                                            label="Plan médico"
                                            requiredICon={true}
                                            id="plan_medico"
                                            value={Plan}
                                            placeholder="Plan"
                                            style={{
                                                // margin: ".5% 0",
                                                marginBottom: ".5%",
                                                fontFamily: "sans-serif",
                                            }}
                                            columns="30"
                                            autoSize={true}
                                            setStateValue={setPlan}
                                            setStateError={setMensajePlan}
                                            valueError={MensajePlan}
                                            textError={["Debe escribir un plan médico"]}
                                            vacio={false}
                                            extras={["acordionError", setErrorPlanAccordion]}
                                        />
                                        <h4>Receta</h4>
                                        {/* <Select
                                            placeholder="Cédula profesional"
                                            defaultValue={null}
                                            style={{
                                                width: "94%",
                                            }}
                                            onSelect={(e) => {
                                                setCedula(e);
                                                setMensajeCedula("true");
                                            }}
                                            options={CedulasMedico}
                                        /> */}
                                        {/* <LeyendaError valido={MensajeCedula}>Debe seleccionar una cédula</LeyendaError>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                alignItems: "center",
                                                gap: "1%",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "95%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "1%",
                                                }}
                                            >
                                                <div style={{ width: "50%", position: "relative" }}>
                                                    <Autosuggest
                                                        suggestions={Medicamentos}
                                                        onSuggestionsFetchRequested={
                                                            onSuggestionsFetchRequested2
                                                        }
                                                        onSuggestionsClearRequested={
                                                            onSuggestionsClearRequested2
                                                        }
                                                        getSuggestionValue={getSuggestionValueDos}
                                                        renderSuggestion={renderSuggestionDos}
                                                        inputProps={inputPropsDos}
                                                        onSuggestionSelected={eventEnterDos}
                                                    />
                                                    <LeyendaError valido={MensajeMedicamento}>Debe escribir un medicamento</LeyendaError>
                                                </div>
                                                <div style={{ width: "50%" }}>
                                                    <Input
                                                        // style={{ width: "100%" }}
                                                        value={Dosis}
                                                        type="text"
                                                        className="react-autosuggest__input"
                                                        placeholder="Dósis"
                                                        onChange={(e) => {
                                                            setDosis(e.target.value);
                                                            if (e.target.value.length > 0) {
                                                                setMensajeDosis("true");
                                                            }
                                                        }}
                                                        status={MensajeDosis === "false" ? "error" : ""}
                                                    />
                                                    <LeyendaError>Debe escribir una dósis</LeyendaError>
                                                </div>
                                            </div>
                                            <Button
                                                    icon={<Add style={{ color: "white" }} />}
                                                    style={{ background: "#148f9f" }}
                                                    onClick={() => {
                                                        if (!!Cedula) {
                                                            addMedicamento();
                                                        } else {
                                                            setMensajeCedula("false");
                                                            setOpen(true);
                                                            setTypeMessage("warning");
                                                            setMessage("Debe seleccionar una cédula");
                                                        }
                                                    }}
                                                />
                                        </div> */}
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                            <b>Lista de medicamentos</b>
                                            {ListaMedicamentos.length > 0 ? (
                                                loaderReceta ?
                                                    <CircularProgress size={30} sx={{ color: "#148f9f" }} /> :
                                                    <Button type="button" style={{ background: "#148f9f", border: "0", marginTop: "0", color: "white" }} onClick={() => {
                                                        vistaPreviaReceta();
                                                    }}>Vista previa</Button>

                                            ) : (
                                                <div>

                                                </div>
                                            )}
                                        </div>

                                        <div style={{ borderColor: "black" }}>
                                            {ListaMedicamentos.length > 0 ? (
                                                <>
                                                    {ListaMedicamentos.map((medicamento, index) => (
                                                        <div className="ItemCard" key={index}>
                                                            <div className="CardNotasMed">
                                                                <span>{`${medicamento.Concepto} - ${medicamento.Descripcion}`}</span>
                                                                {/* <img onClick={() => { eliminarMedicamento(medicamento.index) }} src={Eliminar} alt="" /> */}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <p style={{ textAlign: "center" }}>Sin medicamentos agregados</p>
                                            )}
                                        </div>
                                        <hr
                                            style={{
                                                width: "100%",
                                                border: "1px solid #148f9f",
                                                opacity: "100%",
                                                borderRadius: "5rem"
                                            }}
                                        />
                                        {/* ODERN DE ESTUDIOS DE LABORATORIO */}

                                        {/* <Row style={{ justifyContent: "space-between", margin: "2% 0" }}>
                                            <h4 style={{ margin: "1% 0" }}>Orden de estudios de laboratorio</h4>
                                            {loaderOrdenLabTest ?
                                                <CircularProgress size={30} sx={{ color: "#148f9f" }} /> :
                                                <Button type="button" style={{ background: "#148f9f", border: "0", marginTop: "0", color: "white" }} onClick={() => {
                                                    vistaPreviaOrdenDeEstudios();
                                                }}>Vista previa</Button>}

                                        </Row>
                                        <TextAreaAntd
                                            placeholder="Descripción"
                                            value={OrdenLabTest}
                                            style={{
                                                marginBottom: ".5%",
                                                fontFamily: "sans-serif",
                                            }}
                                            columns="30"
                                            autoSize={true}
                                            setStateValue={setOrdenLabTest}
                                            setStateError={setMensajeOrdenLabTest}
                                            valueError={MensajeOrdenLabTest}
                                            textError={["Debe escribir una descripción"]}
                                            vacio={true}
                                        /> */}

                                        {/* BOTON INTERCOSULTA */}

                                        {/* {InterconsultaAdjunta === 0 && (
                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => setInterconsultaAdjunta(1)}
                                                    style={{
                                                        // width: '60%',
                                                        background: "#148f9f",
                                                        border: "0",
                                                        color: "white",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}

                                                >
                                                    <AddIcon sx={{ color: "white" }} /> Orden interconsulta
                                                </Button>
                                            </div>
                                        )} */}

                                        {/* FORM INTERCONSULTA */}

                                        {/* {InterconsultaAdjunta === 1 && (
                                            <Form style={{ padding: '1%' }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1%" }}>
                                                    <h4>Orden de interconsulta</h4>
                                                    <Button type="button" style={{ background: "#148f9f", border: "0" }} onClick={() => {
                                                        // console.log(field);
                                                        setInterconsultaAdjunta(0);
                                                    }}>Eliminar</Button>
                                                </div>
                                                <Select
                                                    value={DoctorSelect}
                                                    showSearch
                                                    style={{
                                                        width: "100%",
                                                        margin: "1% 0"
                                                    }}
                                                    placeholder="Selecciona un médico"
                                                    optionFilterProp="children"
                                                    filterOption={filterOptions}
                                                    filterSort={filterSort}
                                                    options={MedicosList}
                                                    onSelect={(e) => {
                                                        // setInputDisabled(false);
                                                        setDoctorSelect(e);
                                                        MedicosListFilter.forEach(element => {
                                                            if (e === element.IdContacto) {
                                                                // console.log(element);
                                                                setNombreMedico(element.Nombre);
                                                                setApellidoPMedico(element.ApellidoPaterno);
                                                                setApellidoMMedico(element.ApellidoMaterno);
                                                                setCelularMedico(element.Celular);
                                                                setEmailMedico(element.Email);
                                                                // setEspecialidadMedico(element.Especialidad);
                                                                setMensajeDoctorSelect("true");
                                                                setMensajeNombreMedico("true");
                                                                setMensajeApellidoPMedico("true");
                                                                // setMensajeEspecialidadMedico("true");
                                                            }
                                                        });

                                                    }}
                                                />
                                                <LeyendaError valido={MensajeDoctorSelect}>Debe seleccionar un medico</LeyendaError>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <b>Datos del médico</b>
                                                    <Checkbox onChange={(e) => {
                                                        setInputDisabled(!InputDisabled);
                                                        setCheckNuevo(e.target.checked);
                                                        setDoctorSelect(null);
                                                        setNombreMedico("");
                                                        setApellidoPMedico("");
                                                        setApellidoMMedico("");
                                                        setCelularMedico("");
                                                        setEmailMedico("");
                                                    }}>Nuevo</Checkbox>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ width: "100%", margin: "1% 0", marginRight: "1%" }}>
                                                        <Input
                                                            value={NombreMedico}
                                                            // style={{ margin: "1% 0", marginRight: "1%" }}
                                                            placeholder="Nombre"
                                                            disabled={InputDisabled}
                                                            onChange={(e) => {
                                                                setNombreMedico(e.target.value);
                                                                if (e.target.value.length > 0) {
                                                                    setMensajeNombreMedico("true");
                                                                }
                                                            }}
                                                        />
                                                        <LeyendaError valido={MensajeNombreMedico}>Debe ecribir un nombre</LeyendaError>
                                                    </div>
                                                    <div style={{ width: "100%", margin: "1% 0", marginRight: "1%" }}>
                                                        <Input
                                                            value={ApellidoPMedico}
                                                            // style={{ margin: "1% 0", marginRight: "1%" }}
                                                            placeholder="Apellido Paterno"
                                                            disabled={InputDisabled}
                                                            onChange={(e) => {
                                                                setApellidoPMedico(e.target.value);
                                                                if (e.target.value.length > 0) {
                                                                    setMensajeApellidoPMedico("true");
                                                                }
                                                            }}
                                                        />
                                                        <LeyendaError valido={MensajeApellidoPMedico}>Debe escribir un apellido paterno</LeyendaError>
                                                    </div>
                                                    <Input
                                                        value={ApellidoMMedico}
                                                        style={{ margin: "1% 0" }}
                                                        placeholder="Apellido Materno"
                                                        disabled={InputDisabled}
                                                        onChange={(e) => {
                                                            setApellidoMMedico(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Input
                                                        value={EspecialidadMedico}
                                                        style={{ margin: "1% 0", marginRight: "1%" }}
                                                        placeholder="Especialidad"
                                                        disabled={InputDisabled}
                                                        onChange={(e) => {
                                                            setEspecialidadMedico(e.target.value);
                                                        }}
                                                    />
                                                    <Input
                                                        value={CelularMedico}
                                                        style={{ margin: "1% 0", marginRight: "1%" }}
                                                        placeholder="Celular"
                                                        disabled={InputDisabled}
                                                        onChange={(e) => {
                                                            setCelularMedico(e.target.value);
                                                        }}
                                                    />
                                                    <Input
                                                        value={EmailMedico}
                                                        style={{ margin: "1% 0" }}
                                                        placeholder="Email"
                                                        disabled={InputDisabled}
                                                        onChange={(e) => {
                                                            setEmailMedico(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <b>Datos interconsulta</b>
                                                <TextArea style={{ margin: "1% 0" }} placeholder="Motivo" autoSize
                                                    onChange={(e) => {
                                                        setMotivoInterconsulta(e.target.value);
                                                    }}
                                                />
                                                <TextArea style={{ margin: "1% 0" }} placeholder="Diagnostico" autoSize
                                                    onChange={(e) => {
                                                        setDiagnosticoInterconsulta(e.target.value);
                                                    }}
                                                />

                                                {loaderInterconsulta ? (
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <CircularProgress size={40} sx={{ color: "#148f9f" }} />
                                                    </div>
                                                ) : (
                                                    <Button type="button" style={{ background: "#148f9f", border: "0" }} onClick={() => {

                                                        vistaPreviaInter();
                                                    }}>Vista previa</Button>
                                                )}


                                            </Form>
                                        )} */}
                                    </AccordionDetails>
                                </Accordionn>
                                {/* RECOMENDACIONES */}
                                <Accordionn>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Recomendaciones para el paciente</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* <TextArea
                        placeholder="Recomendaciones"
                        style={{
                          margin: ".5% 0",
                          fontFamily: "sans-serif",
                        }}
                        name=""
                        id=""
                        cols="30"
                        autoSize
                        onChange={()=>}
                      /> */}
                                        <TextAreaAntd
                                            value={Recomendaciones}
                                            placeholder="Recomendaciones"
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                            }}
                                            columns="30"
                                            autoSize={true}
                                            setStateValue={setRecomendaciones}
                                        />
                                    </AccordionDetails>
                                </Accordionn>
                            </div>

                        </Col>
                    </div>
                </div>
            )}

            <ModalDetalleEstudio isOpen={IsOpenEstudio} closeModal={CloseEstudio} styleDet={styleAnalisis} optionClose={true}>
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    <b>Detalle de estudio o analisis</b>
                    <b onClick={CloseEstudio} style={{ cursor: "pointer" }}>
                        x
                    </b>
                </div>
                <div className="DetalleEsAna">
                    <TextArea
                        value={!!DetalleEstudio ? !!DetalleEstudio.Estudio.Descripcion ? DetalleEstudio.Estudio.Descripcion : '\"Sin descripción\"' : ""}
                        placeholder="Descripcion"
                        style={{
                            width: "100%",
                            margin: ".5% 0",
                            fontFamily: "sans-serif",
                        }}
                        name=""
                        id=""
                        cols="30"
                        autoSize
                        readOnly={true}
                    />
                    <div>
                        <b>Lista de archivos</b>
                        {!!DetalleEstudio && (
                            DetalleEstudio.Documentos.map((documento) => (
                                <div className="ListaDetalleEsAna" onClick={() => { window.open(`https://${documento.Multimedia}`, "_blank") }}>
                                    {documento.Nombre.substring(documento.Nombre.toString().length, documento.Nombre.toString().length - 3) !== "pdf" ?
                                        (
                                            <>
                                                <img style={{ width: "10%" }} src={documento.Multimedia} alt="" />
                                                <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                    <span>{documento.Nombre}</span>
                                                </div>
                                                <div>

                                                </div>
                                            </>
                                        ) :
                                        (
                                            <>
                                                <img style={{ width: "7%", marginRight: "3px" }} src={Icono[22]} />
                                                <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                    <span>{documento.Nombre}</span>
                                                </div>
                                                <div>
                                                </div>
                                            </>
                                        )}
                                </div>
                            ))
                        )}

                    </div>
                </div>
            </ModalDetalleEstudio>

            <ModalDetalleConsulta isOpen={IsOpeNotaDetalle} closeModal={CloseNotaDetalle} optionClose={true} styleDet={styleDet} extras={["detalle_consulta", setDetalleConsulta, setIMC, cleanInputsDetalleConsulta]}>
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    <b>Detalle de consulta</b>
                    <b style={{ cursor: "pointer" }} onClick={() => {
                        CloseNotaDetalle(true);
                        setDetalleConsulta("");
                        setIMC("");
                    }}>
                        x
                    </b>
                </div>
                <div>
                    <div className="scrolDetalleNotas">
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button type="button" style={{ background: "#148f9f", border: "0" }} onClick={() => {
                                CloseNotaDetalle(true);
                                setCierreModalResumen(true);
                                crearResumenMedico();
                            }}>Resumen médico <AddIcon /></Button>
                        </div>
                        <TextAreaAntd
                            label="Motivo de consulta:"
                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.Motivo : ""}
                            placeholder="Motivo de consulta"
                            style={{
                                marginBottom: ".5%",
                                fontFamily: "sans-serif",
                            }}
                            columns="30"
                            autoSize={true}
                            readOnly={true}
                        />
                        {/* Evolucion del padecimiento */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Evolucion del padecimiento</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextArea
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.EvolucionPadecimiento : ""}
                                    placeholder="Evolucion del padecimiento"
                                    style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    autoSize
                                    readOnly={true}
                                />
                            </AccordionDetails>
                        </Accordionn>
                        {/* Exploración Física */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography> Exploración física</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* <p style={{fontWeight:"500"}}>Signos vitales</p> */}
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <InputAntd
                                            label="Altura:"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            placeholder="Altura (Mtrs.)"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.Altura : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="Peso:"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="Peso (Kg.)"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.Peso : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    {!!IMC && (
                                        <>
                                            <div>
                                                <InputAntd
                                                    label="IMC:"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    type=""
                                                    placeholder="IMC"
                                                    value={IMC}
                                                    vacio={true}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div>
                                                <Label></Label>
                                                <Input
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    type=""
                                                    placeholder="IMC"
                                                    value={!!IMC ? IMC < 18 ? "Insuficiente" : IMC >= 18 && IMC <= 25 ? "Peso normal" : IMC > 25 && IMC <= 30 ? "Sobrepeso" : IMC > 30 && IMC <= 40 ? "Obesidad" : IMC > 40 && "Obesidad mórbida" : ""}
                                                    readOnly={true}
                                                    status={!!IMC ? IMC < 18 ? "warning" : IMC >= 18 && IMC <= 25 ? "" : IMC > 25 && IMC <= 30 ? "warning" : IMC > 30 && IMC <= 40 ? "warning" : IMC > 40 && "error" : ""}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <InputAntd
                                            label="T. Arterial"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="T. Arterial"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.TensionArterial : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="F. Cardiaca"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="F. Cardiaca"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.FrecuenciaCardiaca : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="F. Respiratoria"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="F. Respiratoria"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.FrecuenciaRespiratoria : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="Temperatura"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="Temperatura"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.Temperatura : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="O2"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="O2"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.SaturacionOxigeno : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <TextArea
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.ExploracionFisica : ""}
                                    placeholder="Exploración Física"
                                    style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    autoSize
                                    readOnly={true}
                                />

                            </AccordionDetails>
                        </Accordionn>
                        {/* Estudios y analisis */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Estudios y análisis</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {(!!DetalleConsulta && !!DetalleConsulta.Estudios) ? (
                                    <>
                                        <TextArea
                                            value={DetalleConsulta.Estudios.Descripcion}
                                            placeholder="Descripcion"
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                            }}
                                            name=""
                                            id=""
                                            cols="30"
                                            autoSize
                                            readOnly={true}
                                        />

                                        <div>
                                            <p style={{ fontWeight: "500" }}>Lista de archivos</p>

                                            {DetalleConsulta.Documentos.map((documento) => (
                                                <div className="ListaDetalleEsAna" onClick={() => { window.open(`https://${documento.Multimedia}`, "_blank") }}>
                                                    {documento.Nombre.substring(documento.Nombre.toString().length, documento.Nombre.toString().length - 3) !== "pdf" ?
                                                        (
                                                            // <Row style={{ justifyContent: "space-between", cursor: "pointer" }} onClick={() => { window.open(documento.Multimedia, "_blank") }}>
                                                            //   <img style={{ width: "10%" }} src={documento.Multimedia} alt="" />
                                                            //   <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                            //     <span>{documento.Nombre}</span>
                                                            //   </div>
                                                            //   <div>

                                                            //   </div>
                                                            // </Row>
                                                            <>
                                                                <img style={{ width: "10%" }} src={documento.Multimedia} alt="" />
                                                                <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                    <span>{documento.Nombre}</span>
                                                                </div>
                                                                <div>

                                                                </div>
                                                            </>
                                                        ) :
                                                        (
                                                            // <Row style={{ justifyContent: "space-between", cursor: "pointer" }} onClick={() => { window.open(documento.Multimedia, "_blank") }}>
                                                            //   <img style={{ width: "7%", marginRight: "3px" }} src={Icono[22]} />
                                                            //   <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                            //     <span>{documento.Nombre}</span>
                                                            //   </div>
                                                            //   <div>
                                                            //   </div>
                                                            // </Row>
                                                            <>
                                                                <img style={{ width: "7%", marginRight: "3px" }} src={Icono[22]} />
                                                                <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                    <span>{documento.Nombre}</span>
                                                                </div>
                                                                <div>
                                                                </div>
                                                            </>
                                                        )}
                                                </div>
                                            ))}


                                        </div>
                                    </>
                                ) : (
                                    <p style={{ textAlign: "center" }}>Sin información</p>
                                )}
                            </AccordionDetails>
                        </Accordionn>
                        {/* Diagnóstico clínico */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Diagnóstico clínico</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextArea
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.Diagnostico : ""}
                                    placeholder="Diagnóstico clínico"
                                    style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    autoSize
                                    readOnly={true}
                                />
                                {/* <div style={{ position: "relative" }}>
                  <Autosuggest
                    suggestions={Diagnostico}
                    onSuggestionsFetchRequested={
                      onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      onSuggestionsClearRequested
                    }
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    onSuggestionSelected={eventEnter}
                  />
                </div> */}
                            </AccordionDetails>
                        </Accordionn>
                        {/* Plan */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Plan</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextAreaAntd
                                    label="Plan médico"
                                    id="plan_medico"
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.Plan : ""}
                                    placeholder="Plan"
                                    style={{
                                        // margin: ".5% 0",
                                        marginBottom: ".5%",
                                        fontFamily: "sans-serif",
                                    }}
                                    columns="30"
                                    autoSize={true}
                                    vacio={false}
                                    readOnly={true}
                                />
                                <h6>Receta</h6>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        gap: "1%",
                                    }}
                                >
                                </div>
                                {/* {DetalleConsulta.Receta.Partidas} */}
                                {RowsReceta.length > 0 ? (
                                    <Table columns={columns} dataSource={RowsReceta} />
                                ) : (
                                    <p style={{ textAlign: "center" }}>Sin información</p>
                                )}

                                <hr
                                    style={{
                                        width: "100%",
                                        border: "1px solid #148f9f",
                                        opacity: "100%",
                                        borderRadius: "5rem",
                                    }}
                                />
                            </AccordionDetails>
                        </Accordionn>
                        {/* Recomendaciones */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Recomendaciones para el paciente</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextArea
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.Recomendaciones : ""}
                                    placeholder="Recomendaciones"
                                    style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    autoSize
                                    readOnly={true}
                                />
                            </AccordionDetails>
                        </Accordionn>
                    </div>

                </div>
            </ModalDetalleConsulta>

            {/* <ModalCostoConsulta isOpen={IsOpenCostoConsulta} closeModal={ClosedCostoConsulta} optionClose={true} styleDet={styleMot}>
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    <b>Costo de consulta</b>
                    <b style={{ cursor: "pointer" }} onClick={ClosedCostoConsulta}>
                        x
                    </b>
                </div>
                <div className="scrolMotivoConsulta">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <InputNumber
                            prefix="$"
                            value={CostoConsulta}
                            placeholder="0.00"
                            className="inputblue"
                            style={{ width: "100%", margin: "3% 0" }}
                            formatter={(value) => `${value}`
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(e) => {
                                // console.log(e);
                                setCostoConsulta(e);

                            }}
                            disabled={ConsultaSinCargo}
                        />
                    </div>
                    <Checkbox
                        style={{ margin: "3% 0" }}
                        onChange={(e) => {
                            setConsultaSinCargo(e.target.checked);
                            setCostoConsulta(null);
                        }}
                    >Consulta sin costo</Checkbox>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            style={{
                                background: "#148F9F",
                                border: "#148F9F",
                                color: "white"
                            }}
                            onClick={() => {
                                ClosedCostoConsulta(true);
                                addConsulta();
                            }}>Continuar</Button>
                    </div>
                </div>
            </ModalCostoConsulta> */}

            <ModalResumenMedico isOpen={IsOpenResumenMedico} closeModal={ClosedResumenMedico} optionClose={false} styleDet={styleResMed}>
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    <b>Resumen médico</b>
                    <b style={{ cursor: "pointer" }} onClick={() => { ClosedResumenMedico(true); setResumenMedico(""); }}>
                        x
                    </b>
                    {/* <div>

                    </div> */}
                </div>
                <div className="scrolMotivoConsulta">
                    {/* <div style={{ display: "flex", justifyContent: "end" }}>
                        {loaderResumenMedico ? (
                            <CircularProgress size={40} sx={{ color: "#148f9f" }} />
                        ) : (
                            <Button type="button" style={{ background: "#148f9f", border: "0" }} onClick={() => {

                                vistaPreviaResumenMedico();
                            }}>Vista previa</Button>
                        )}
                    </div> */}

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <TextAreaAntd
                            label=""
                            value={ResumenMedico}
                            placeholder="Resumen médico"
                            style={{
                                marginBottom: ".5%",
                                fontFamily: "sans-serif",
                            }}
                            columns="30"
                            autoSize={true}
                            setStateValue={setResumenMedico}
                            setStateError={setMensajeResumenMedico}
                            valueError={MensajeResumenMedico}
                            textError={["Debe escribir un resumen médico"]}
                            vacio={true}
                        />
                    </div>
                    {loaderResumenMedico ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress size={40} sx={{ color: "#148f9f" }} />
                        </div>
                    ) : (
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Button
                                style={{
                                    background: "white",
                                    border: "1",
                                    borderColor: "#148F9F",
                                    color: "#148F9F"
                                }}
                                onClick={() => {
                                    vistaPreviaResumenMedico();
                                }}>Vista previa</Button>
                            <Button
                                style={{
                                    background: "#148F9F",
                                    border: "#148F9F",
                                    color: "white"
                                }}
                                onClick={() => {
                                    if (!!ResumenMedico) {
                                        ClosedResumenMedico(true);
                                        // OpenCostoConsulta(true);
                                        editConsulta();

                                    } else {
                                        setMensajeResumenMedico("false");
                                        setOpen(true);
                                        setTypeMessage("warning");
                                        setMessage("Faltan campos por completar");
                                    }
                                }}>Continuar</Button>

                        </div>
                    )}



                </div>
            </ModalResumenMedico>

            <ModalAlertConfirm
                isOpen={IsOpenAlertConfirm}
                closeModal={ClosedAlertConfirm}
                optionClose={true}
                styleDet={styleConfirm}
            >
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    <b>¡Advertencia!</b>
                    <b style={{ cursor: "pointer" }} onClick={ClosedAlertConfirm}>
                        x
                    </b>
                </div>
                <div className="ModalListContactosConsult" style={{ padding: "2%" }}>
                    <div style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}>
                        <b>¿Desea salir de la consulta?</b>
                    </div>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <Button
                            style={{
                                width: "100%",
                                background: "#148f9f",
                                borderColor: "#148f9f",
                                color: "white",
                            }}
                            size="sm"
                            onClick={() => {
                                ClosedAlertConfirm(true);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{
                                width: "100%",
                                background: "#148f9f",
                                borderColor: "#148f9f",
                                color: "white",
                            }}
                            size="sm"
                            onClick={() => {
                                ClosedAlertConfirm(true);
                                navigate(Link, { replace: true });
                            }}
                        >
                            Salir
                        </Button>
                        <Button
                            style={{
                                width: "100%",
                                background: "#148f9f",
                                borderColor: "#148f9f",
                                color: "white",
                            }}
                            size="sm"
                            onClick={() => {

                                if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan) {
                                    ClosedAlertConfirm(false);
                                    editConsulta();
                                } else {
                                    setEstatusModalSalirConsulta(false);
                                    if (!!!MotivoConsulta) {
                                        setMensajeMotivoConsulta("false");
                                    }
                                    if (DiagnosticosSeleccionadosMostrados.length === 0) {
                                        setErrorDiagnosticoAccordion(false);
                                    }
                                    if (!!!Plan) {
                                        setErrorPlanAccordion(false);
                                        setMensajePlan("false");
                                    }

                                    setOpen(true);
                                    setTypeMessage("warning");
                                    setMessage("Faltan campos por completar");
                                }
                            }}
                        >
                            Salir y finalizar
                        </Button>
                    </div>
                </div>
            </ModalAlertConfirm>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
            >
                <Alert onClose={() => setOpen(false)} severity={typeMessage} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    )
}

export default EditConsulta