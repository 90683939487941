import "./styles/Recuperacion.scss"
// logo Ygia
import logo from "../logo.svg";

// importar componente modal
import Modal from "../componentes/GeneralComponents/ModalSinTacha"
import React from 'react'
import { UseModal } from "../hooks/UseModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../componentes/GeneralComponents/Loader";
import { sendRecoveryCodeToEmail_request, resendCode_request, changePassword_request } from "../services/login_services";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
import { ContenedorBotonCentrado } from "../componentes/GeneralComponents/Formularios";
import ModalCodigo from "../componentes/GeneralComponents/ModalDetalle";
import ModalPassword from "../componentes/GeneralComponents/ModalDetalle";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import SelectAntd from "../componentes/GeneralComponents/SelectAntd";
import Campos from "../componentes/GeneralComponents/campos";
import { Input } from "antd";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Recuperacion() {

  const styleDet = {
    minHeight: "30vh",
    maxHeight: "90vh",
    minWidth: "56vh",
    maxWidth: "90vh",
  };

  const navigate = useNavigate();

  // ******* USE STATES *******
  const [isopenModalCodigo, openModalCodigo, closeModalCodigo] = UseModal(false);
  const [isopenModalPassword, openModalPassword, closeModalPassword] = UseModal(false);
  // validar correo
  const [validoCorreo, setvalidoCorreo] = useState(false);
  // const [correo, setcorreo] = useState('');
  const [Correo, setCorreo] = useState("");
  const [TipoUsuario, setTipoUsuario] = useState(null);

  // validar codigo
  const [validoCodigo, setvalidoCodigo] = useState(false);
  const [Codigo, setCodigo] = useState("");
  // Declaracion de HookModal && useModal
  const [CloseModalRecu] = UseModal(false);

  //Declaracion de inputs
  const [Password, setPassword] = useState("");
  const [Password2, setPassword2] = useState("");

  const [TextoMensajePassword, setTextoMensajePassword] = useState("");
  const [TextoMensajePassword2, setTextoMensajePassword2] = useState("");
  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);
  const [LoaderModal, setLoaderModal] = useState(false);

  const [MensajeCorreo, setMensajeCorreo] = useState("true");
  const [MensajeTipoUsuario, setMensajeTipoUsuario] = useState("true");
  const [MensajeCodigo, setMensajeCodigo] = useState("true");
  const [MensajePassword, setMensajePassword] = useState("true");
  const [MensajePassword2, setMensajePassword2] = useState("true");

  // ****** VALIDACIONES *******

  const expresiones = {
    password: /^[a-zA-Z0-9]{8,}$/, // Letras y numeros.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    codigo: /^\d{1,4}$/, // 7 a 14 numeros.
  };

  const validarPassword2 = (pass) => {
    if (Password.length > 0) {
      if (Password !== pass) {
        setTextoMensajePassword2("Las contraseñas no coinciden");
        setMensajePassword2("false");
      } else {
        setMensajePassword2("true");
      }
    }
  };

  async function recoveryPassword() {
    setLoader(true);
    if (!!Correo && MensajeCorreo === "true" && !!TipoUsuario) {
      let data = new FormData();
      data.append("email", Correo);
      data.append("Tipo", TipoUsuario);
      let response = await sendRecoveryCodeToEmail_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Código enviado");
        openModalCodigo(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Correo) {
        setMensajeCorreo("false");
      }

      if (!!!TipoUsuario) {
        setMensajeTipoUsuario("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por llenar");
    }
    setLoader(false);

  }

  async function ResendCode() {
    let data = new FormData();
    data.append("email", Correo);
    let response = await sendRecoveryCodeToEmail_request(data);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Código enviado");
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
  }

  async function sendCodeToValidation() {
    if (!!Codigo.trim()) {
      let response = await resendCode_request(Codigo);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Código verificado");
        closeModalCodigo(true);
        openModalPassword(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }

    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Debe escribir un código");
    }

  }

  async function changePassword() {
    if (!!Password && MensajePassword === "true" && !!Password2 && MensajePassword2 === "true") {
      let data = new FormData();
      data.append("email", Correo);
      data.append("password", Password);
      data.append("token", Codigo);

      let response = await changePassword_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Contraseña cambiada");
        navigate("/login", { replace: true });
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Password) {
        setTextoMensajePassword("Debe escribir una contraeña");
        setMensajePassword("false");
      }
      if (!!!Password2) {
        setTextoMensajePassword2("Debe confirmar la contraseña");
        setMensajePassword2("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }

  }

  return (
    <Stack>
      {loader ? (<Loader />) : (
        <div className="contenedorRec">
          <div className="contenidoRec">
            <form action="">
              <h3>Recuperación de contraseña</h3>
              {/* <Inputt
                  label="Ingresa el correo con el que te registraste"
                  tipo="email"
                  placeholder="usuario@ejemplo.com"
                  name="correo"
                  estado={correo}
                  cambiarEstado={setcorreo}
                  leyendaError="Formato de correo incorrecto."
                  expresionRegular={expresiones.correo}
                /> */}
              <SelectAntd
                label="Tipo de usuario:"
                requiredICon={true}
                vacio={false}
                value={TipoUsuario}
                style={{ width: "100%", marginBottom: "1%" }}
                placeholder="Selecciona un tipo"
                options={[{ value: "1", label: "Médico" }, { value: "4", label: "Asistente" }]}
                setStateValue={setTipoUsuario}
                setStateError={setMensajeTipoUsuario}
                valueError={MensajeTipoUsuario}
                textError={["Debe seleccionar un tipo"]}
              />
              <InputAntd
                label="Correo electrónico:"
                requiredICon={true}
                style={{ width: "100%", marginBottom: "1%", color: "black" }}
                className="inputblue"
                value={Correo}
                type="email"
                setStateValue={setCorreo}
                placeholder="usuario@ejemplo.com"
                valueError={MensajeCorreo}
                setStateError={setMensajeCorreo}
                textError={["Este campo es obligatorio", "Formato correo erroneo", ""]}
                vacio={false}
                expresionRegular={expresiones.correo}
              />
              <br />
              {/* <button style={{ width: "100%", border: "1px solid #148f9f", borderRadius: "1rem", padding: "1%" }}>Recuperar</button> */}
              <ContenedorBotonCentrado>
                <ButtonAntd
                  onClick={() => {
                    recoveryPassword();
                  }}
                >
                  Recuperar
                </ButtonAntd>
              </ContenedorBotonCentrado>
            </form>
          </div>
        </div>
      )}

      <ModalCodigo styleDet={styleDet} optionClose={false} isOpen={isopenModalCodigo} closeModal={closeModalCodigo}>
        {LoaderModal ? (<LoaderModals />) : (
          <div style={{ padding: "1%" }}>
            <div style={{ textAlign: "center" }}>
              <img style={{ width: "25%", marginBottom: "3%" }} src={logo} alt="" className="lgo" />
              <form action="">
                <p>Se te ha enviado un correo a:</p>
                <b>{Correo}</b>

                <InputAntd
                  style={{ width: "100%", marginBottom: "1%" }}
                  className="inputblue"
                  type="text"
                  id="codigo"
                  value={Codigo}
                  setStateValue={setCodigo}
                  label="Código:"
                  placeholder="Escribe tu código"
                  valueError={MensajeCodigo}
                  setStateError={setMensajeCodigo}
                  textError={["Debe escribir un código", "Solo puede contener numeros", "Debe contener 4 digitos"]}
                  vacio={false}
                  expresionRegular={expresiones.codigo}
                  maxLength={4}
                />
                <br />
                <div>
                  <ContenedorBotonCentrado>
                    <ButtonAntd
                      style={{ width: "100%" }}
                      onClick={() => {
                        sendCodeToValidation();
                      }}
                    >
                      Verificar
                    </ButtonAntd>

                    <ButtonAntd
                      style={{ width: "100%" }}
                      tipo={2}
                      onClick={() => {
                        ResendCode();
                      }}
                    >
                      Reenviar
                    </ButtonAntd>
                  </ContenedorBotonCentrado>

                  {/* <button
                  type="submit"
                  style={{ width: "50%", padding: " 1%", border: "1px solid #148f9f", borderRadius: "1rem" }}

                >
                  
                </button>
                <br />
                <br />
                <button
                  type="button"
                  style={{ width: "50%", padding: " 1%", border: "1px solid #148f9f", borderRadius: "1rem" }}
                  onClick={() => resendCode()}
                >
                  
                </button> */}
                </div>
              </form>
            </div>
          </div>
        )}
      </ModalCodigo>

      <ModalPassword styleDet={styleDet} optionClose={false} isOpen={isopenModalPassword} closeModal={closeModalPassword}>
        <div style={{ padding: "1%" }}>
          {LoaderModal ? (<LoaderModals />) : (
            <form action="">
              <b>Nueva contraseña</b>
              {/* <InputAntd
                label="Contraseña:"
                requiredICon={true}
                style={{ width: "100%", marginBottom: "1%" }}
                className="inputblue"
                value={Password}
                type="password"
                setStateValue={setPassword}
                placeholder="escribe una contraseña"
                valueError={MensajePassword}
                setStateError={setMensajePassword}
                textError={["Este campo es obligatorio", "Min. 8 caracteres (letras y numeros)", ""]}
                vacio={false}
                expresionRegular={expresiones.password}
              /> */}

              <Campos
                label="Contraseña:"
                requiredICon={true}
                valueError={MensajePassword}
                textError={TextoMensajePassword}
                vacio={false}
                styleDiv={{ marginBottom: "1%" }}
              >
                <Input.Password
                  className="inputblue"
                  style={{ width: "100%", marginBottom: "1%", padding: "0 10px" }}
                  placeholder="Escribe una contraseña"
                  status={MensajePassword === "false" && "error"}
                  onChange={(e) => {
                    if (expresiones.password.test(e.target.value)) {
                      setPassword(e.target.value);
                      if (e.target.value.length < 8) {
                        setTextoMensajePassword("Minimo 8 caracteres")
                        setMensajePassword("false");
                      } else {
                        setTextoMensajePassword("");
                        setMensajePassword("true");
                      }
                    } else {
                      if (!!e.target.value) {
                        if (e.target.value.length < 8) {
                          setTextoMensajePassword("Minimo 8 caracteres");
                          setMensajePassword("false");
                        } else {
                          setTextoMensajePassword("Solo se permiten letras y numeros");
                          setMensajePassword("false");
                        }
                      } else {
                        setTextoMensajePassword("Este campo es obligatorio");
                        setMensajePassword("false");
                      }
                    }
                  }}
                  maxLength={16}
                />
              </Campos>

              {/* <InputAntd
                label="Repetir contraseña:"
                requiredICon={true}
                style={{ width: "100%", marginBottom: "1%" }}
                className="inputblue"
                type="password"
                value={Password2}
                id="password"
                setStateValue={setPassword2}
                placeholder="Repita su contraseña"
                valueError={MensajePassword2}
                setStateError={setMensajePassword2}
                textError={["Este campo es obligatorio", "Min. 8 caracteres (letras y numeros)", ""]}
                vacio={false}
                expresionRegular={expresiones.password}
                extras={validarPassword2}
              /> */}

              <Campos
                label="Repetir contraseña:"
                requiredICon={true}
                valueError={MensajePassword2}
                textError={TextoMensajePassword2}
                vacio={false}
                styleDiv={{ marginBottom: "1%" }}
              >
                <Input.Password
                  className="inputblue"
                  style={{ width: "100%", marginBottom: "1%", padding: "0 10px" }}
                  placeholder="Repita su contraseña"
                  status={MensajePassword2 === "false" && "error"}
                  onChange={(e) => {
                    if (expresiones.password.test(e.target.value)) {
                      setPassword2(e.target.value);
                      if (e.target.value.length < 8) {
                        setTextoMensajePassword2("Minimo 8 caracteres")
                        setMensajePassword2("false");
                      } else {
                        validarPassword2(e.target.value);
                        // setTextoMensajePassword2("");
                        // setMensajePassword2("true");
                      }
                    } else {
                      if (!!e.target.value) {
                        if (e.target.value.length < 8) {
                          setTextoMensajePassword2("Minimo 8 caracteres");
                          setMensajePassword2("false");
                        } else {
                          setTextoMensajePassword2("Solo se permiten letras y numeros");
                          setMensajePassword2("false");
                        }
                      } else {
                        setTextoMensajePassword2("Este campo es obligatorio");
                        setMensajePassword2("false");
                      }
                    }
                  }}
                  maxLength={16}
                />
              </Campos>
              <ContenedorBotonCentrado>
                <ButtonAntd
                  onClick={() => {
                    changePassword();
                  }}
                >
                  Cambiar contraseña
                </ButtonAntd>
              </ContenedorBotonCentrado>
              {/* <div style={{ textAlign: "center" }}>
        <button
          type="submit"
          style={{ width: "50%", padding: " 1%", border: "1px solid #148f9f", borderRadius: "1rem" }}

        >
          
        </button>


      </div> */}
            </form>
          )}
        </div>
      </ModalPassword>
      
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={typeMessage} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>

  )
}

export default Recuperacion