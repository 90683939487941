import React from "react";
import listaCobros from "../GeneralComponents/General-Json";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Iconos from "../IconosDeSitio";
import { useState } from "react";

// importando scss
import "../comp-styles/IngYEgreCard.scss";
import PositionedMenu from "./menuvertical";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";

function IngresosCard({ OpendetailIngyegr, Datos, extras }) {
    // console.log(Datos);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return (
        <>
            {Datos.map((Ingresos) => (
                <div className="ContenedorPrinCard">
                    <div className="menutrespuntos" style={{ cursor: "pointer", width:!!!Ingresos.FolioOrigen ? "100%" : "100%", padding:"0 2%"}} onClick={() => { OpendetailIngyegr(true); extras[1](Ingresos); }}>
                        <div className="ContenedorCard">
                            <div>
                                <p>{Ingresos.Concepto}</p>
                                {(Ingresos.Estado === "Activo" ? (<p style={{ color: "green" }}>{Ingresos.Estado}</p>) : (<p style={{ color: "red" }}>{Ingresos.Estado}</p>))}
                            </div>
                            <div>
                                {(Ingresos.TipoMovimiento === "Ingreso" ? (<p style={{ color: "green", textAlign: "right" }}>+ ${Ingresos.Cantidad}</p>) : (<p style={{ color: "red", textAlign: "right" }}>- ${Ingresos.Cantidad}</p>))}
                                <p>{Ingresos.Fecha}</p>
                            </div>
                        </div>
                    </div>
                    {(!!!Ingresos.FolioOrigen && Ingresos.Estado !== "Cancelado") && (
                        <StyledEngineProvider injectFirst>
                            <CssVarsProvider>
                                <PositionedMenu funciones={[Ingresos, extras[0], extras[2], extras[3], extras[4], extras[5], extras[6], extras[7]]} />
                            </CssVarsProvider>
                        </StyledEngineProvider>
                    )}
                </div>
            ))}
        </>
    );
}

export default IngresosCard;