import React from "react";

import GeneralJson from "../GeneralComponents/General-Json";
import AddListAsit from "../../Img/Iconos/Agregar.svg"
import "../comp-styles/ListaDeAsistente.scss"
import Iconos from "../IconosDeSitio";
export default function ListaDeAsistentes({
  setAccionesAsist,
  setActiveMoadalAsist,
  CloseModal,
  AssistantListToAdd,
  setIdAssistant,
  setInputsEditable,
  setAction,
  setInfoToAddAssistant,
  openModalDAE
}) {
  return (
    <>
      {AssistantListToAdd.map((asistente) => (
        <div className="ContenedorListaAsistente"
          onClick={() => {
            setAccionesAsist(3);
            setActiveMoadalAsist(1);
            setIdAssistant(asistente.id);
            setInputsEditable(false);
            setAction(0);
            setInfoToAddAssistant({ nombre: asistente.nombre, imagen: asistente.imagen });
            openModalDAE(true);
            CloseModal(true);
          }}
        >
          <div
            style={{
              width: "90%"
              , display: "flex", alignItems: "center"
            }}
          >
            <img className="ImagPerfilListaAsist" src={!!asistente.imagen ? "https://" + asistente.imagen : Iconos[0]} alt="" />
            <b style={{ marginLeft: "3px" }}>{asistente.nombre}</b>
          </div>

        </div>

      ))}
    </>
  );
}
