import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    Prefijo: null,
    Nombre: "",
    ApellidoPaterno: "",
    ApellidoMaterno: "",
    NombreComercial: "",
    TelefonoOficina: "",
    Direccion: "",
    Celular: "",
    Cel: "",
    Email: "",
    Correo: "",
    Nacimiento: "",
    Localidad: null,
    Estado: null,
    Universidad: null,
    Sexo: null,
    TelefonoEmergencia: "",
    Tipo: null,
    Imagen_Url: "",
    Status: null,
    Paquete: "",
    IdPaquete: "",
    IdAlmacenamiento: null,
    PaqueteStatus: null,
    FechaPaquete: "",
    fecha: "",
};

export const userSlice = createSlice({
    name: "infoUser",
    initialState,
    reducers: {

        infoUser: (state, action) => {
            const {
                id,
                Prefijo,
                Nombre,
                ApellidoPaterno,
                ApellidoMaterno,
                NombreComercial,
                TelefonoOficina,
                Direccion,
                Celular,
                Cel,
                Email,
                Correo,
                Nacimiento,
                Localidad,
                Estado,
                Universidad,
                Sexo,
                TelefonoEmergencia,
                Tipo,
                Imagen_Url,
                Status,
                Paquete,
                IdPaquete,
                IdAlmacenamiento,
                PaqueteStatus,
                FechaPaquete,
                fecha,
            } = action.payload;
            state.id = id;
            state.Prefijo = Prefijo;
            state.Nombre = Nombre;
            state.ApellidoPaterno = ApellidoPaterno;
            state.ApellidoMaterno = ApellidoMaterno;
            state.NombreComercial = NombreComercial;
            state.TelefonoOficina = TelefonoOficina;
            state.Direccion = Direccion;
            state.Celular = Celular;
            state.Cel = Cel;
            state.Email = Email;
            state.Correo = Correo;
            state.Nacimiento = Nacimiento;
            state.Localidad = Localidad;
            state.Estado = Estado;
            state.Universidad = Universidad;
            state.Sexo = Sexo;
            state.TelefonoEmergencia = TelefonoEmergencia;
            state.Tipo = Tipo;
            state.Imagen_Url = Imagen_Url;
            state.Status = Status;
            state.Paquete = Paquete;
            state.IdPaquete = IdPaquete;
            state.IdAlmacenamiento = IdAlmacenamiento;
            state.PaqueteStatus = PaqueteStatus;
            state.FechaPaquete = FechaPaquete;
            state.fecha = fecha;
        },
    }
})


export const { infoUser } = userSlice.actions;

export default userSlice.reducer;