import React, { useState, useEffect } from "react";
import {
  Formulario,
  Label,
  ContenedorTerminos,
  ContenedorBotonCentrado,
  Boton,
  LeyendaError
} from "../componentes/GeneralComponents/Formularios";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Inputt from "../componentes/GeneralComponents/Input";
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import SelectAntd from "../componentes/GeneralComponents/SelectAntd";
import { register_request, emailValidation_request, activationAccount_request } from "../services/register_services";
import { getEstado_request, getLocalidad_request } from "../services/expediente_service";
import { infoUser_request } from "../services/perfil_services";

// navegacion de verificacion
import { Link, useNavigate } from "react-router-dom";

//importando libreria Hormiga
import { Button, Checkbox, DatePicker, Drawer, Input, Row, Select, Space } from "antd";

// logo Ygia
import logo from "../logo.svg";

// impotando iconos
import icono from "../componentes/IconosDeSitio";
import Modal from "../componentes/GeneralComponents/ModalDetalle";

import { UseModal } from "../hooks/UseModal";
import { login_request } from "../services/login_services";
import TerminosModal from "../componentes/Registro/Terminos";

import dayjs from 'dayjs';
import "dayjs/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DatePickerAntd from "../componentes/GeneralComponents/DatePickerAntd";
import { Loader } from "../componentes/GeneralComponents/Loader";
import InputFileSingle from "../componentes/GeneralComponents/InputFileSingle";
import Licencia from "./Licencia";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
import Campos from "../componentes/GeneralComponents/campos";
import ReactGA from "react-ga4";
import { useDispatch } from 'react-redux';
import { infoUser } from "../redux/userSlice";
import { Sendrequest, getTokenFirebase } from "../firebase";
import { showSnackbar } from "../redux/snackbarSlice";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Registro = () => {

  // ******* VARIABLES *******

  const expresiones = {
    textos: /^[a-zA-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
    // password: /^.{8,}$/, // 4 a 12 digitos.
    password: /^[a-zA-Z0-9]{8,}$/, // Letras y numeros.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{1,}$/, // 7 a 14 numeros.
    codigo: /^\d{1,4}$/, // 7 a 14 numeros.
    textNumber: /^[a-zA-Z0-9À-ÿ\s]{4,}$/, // Letras, Numeros y espacios, pueden llevar acentos.
    cedula: /^[0-9a-zA-Z]{1,}$/,
    direccion: /^[-/#a-zA-Z0-9À-ÿ\s]{10,}$/, // Letras, Numeros y espacios, pueden llevar acentos.
    // nacimiento: getEdad, // Letras y espacios, pueden llevar acentos.
  };

  const dispatch = useDispatch();
  //navegacion
  const navigate = useNavigate();

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

  // ******* USE STATES *******

  // Metodos de modalales
  const [CloseModalR] = UseModal(false);
  const [isopenModal2r, openModal2r, closeModal2r] = UseModal(false)
  const [isopenModalCodigo, openModalCodigo, closeModalCodigo] = UseModal(false)

  // Desplegar inputs y divs
  const [Desplegar, setDesplegar] = useState(0);

  const [EstadosList, setEstadosList] = useState([]);
  const [LocalidadesList, setLocalidadesList] = useState([]);

  // Sacar nombre de imagen de file
  const [CedulaFile, setCedulaFile] = useState({ name: "", url: "", file: "", size: 0, formato: "" });
  const [ProfileImage, setProfileImage] = useState("");
  const [Typefile, setTypefile] = useState(0);
  const [Edad, setEdad] = useState(false);

  // Declarando estado de inputs
  const [Nombre, setNombre] = useState("");
  const [Password, setPassword] = useState("");
  const [Password2, setPassword2] = useState("");
  const [Correo, setCorreo] = useState("");
  const [Telefono, setTelefono] = useState("");
  const [TelEmergencia, setTelEmergencia] = useState("");
  const [Terminos, setTerminos] = useState(false);
  const [FormularioValido, setFormularioValido] = useState(null);
  const [Direccion, setDireccion] = useState("");
  const [ApePaterno, setApePaterno] = useState("");
  const [ApeMaterno, setApeMaterno] = useState("");
  const [NombreComercial, setNombreComercial] = useState("");
  const [Subespecialidad, setSubespecialidad] = useState("");
  const [Cedula, setCedula] = useState("");
  const [Universidad, setUniversidad] = useState("");
  const [Nacimiento, setNacimiento] = useState(null);
  const [Sexo, setSexo] = useState(null);
  const [UserRoll, setUserRoll] = useState(null);
  const [Prefijo, setPrefijo] = useState(null);
  const [Profesion, setProfesion] = useState(null);
  const [Estado, setEstado] = useState(null);
  const [Localidad, setLocalidad] = useState(null);


  const [MensajeNombre, setMensajeNombre] = useState("true");
  const [MensajeApePaterno, setMensajeApePaterno] = useState("true");
  const [MensajeApeMaterno, setMensajeApeMaterno] = useState("true");
  const [MensajePassword, setMensajePassword] = useState("true");
  const [TextoMensajePassword, setTextoMensajePassword] = useState("true");

  const [MensajePassword2, setMensajePassword2] = useState("true");
  const [TextoMensajePassword2, setTextoMensajePassword2] = useState("true");
  const [MensajeCorreo, setMensajeCorreo] = useState("true");
  const [MensajeTelefono, setMensajeTelefono] = useState("true");
  const [MensajeTelEmergencia, setMensajeTelEmergencia] = useState("true");
  const [MensajeNacimiento, setMensajeNacimiento] = useState("true");
  const [MensajeCedula, setMensajeCedula] = useState("true");
  const [MensajeUniversidad, setMensajeUniversidad] = useState("true");
  const [MensajeProfesion, setMensajeProfesion] = useState("true");
  const [MensajeNombreComercial, setMensajeNombreComercial] = useState("true");
  const [MensajeDireccion, setMensajeDireccion] = useState("true");
  const [MensajeSubespecialidad, setMensajeSubespecialidad] = useState("true");
  const [MensajeCodigo, setMensajeCodigo] = useState("true");
  const [MensajeEstado, setMensajeEstado] = useState("true");
  const [MensajeLocalidad, setMensajeLocalidad] = useState("true");

  // imagen de
  const [image, setimage] = useState("");

  // Validacion de codigo de modal
  const [Codigo, setCodigo] = useState("");

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  const [openCode, setOpenCode] = useState(false);

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [comboRol, setComboRol] = useState(null);
  const [comboGenero, setComboGenero] = useState(null);
  const [comboPrefijo, setComboPrefijo] = useState(null);
  const [checkTerminos, setCheckTerminos] = useState(null);
  const [archivoExist, setArchivoExist] = useState(null);

  // ******* FUNCIONES *******

  async function getInfoUser() {
    setLoaderModal(true);
    let response = await infoUser_request();
    if (response["ok"]) {
      dispatch(infoUser(response.data.Usuario));
      let info = response.data.Usuario;
      sessionStorage.setItem("infoUser", JSON.stringify(info));
      localStorage.setItem("tipoUsuario", response.data.Usuario.Tipo);
      localStorage.setItem("loginAsistente", "0");
      navigate("/", {
        replace: true,
        state: {
          logged: true
        },
      });
      closeModalCodigo(true);
    } else {
      if (response.status === 404) {
        localStorage.removeItem("token");
        localStorage.removeItem("tipoUsuario");
        sessionStorage.removeItem("infoUser");
        navigate('/login', { replace: true });
      } else {
        dispatch(showSnackbar({
          show: true,
          text: response.mensaje,
          type: "error",
        }));
      }
    }
    setLoaderModal(true);
    return;
  }

  const validacion = async () => {
    setLoaderModal(true);
    if (!!Codigo && MensajeCodigo !== "false") {
      let firebasetoken = localStorage.getItem("firebaseToken");
      let requestData = {
        "Codigo": Codigo,
      };
      let response = await activationAccount_request(requestData);
      if (response["ok"]) {
        ReactGA.event({
          category: "Registro",
          action: "Codigo_exitoso",
          label: "Código de verificación correcto", // optional
          value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
        if (document.domain !== "ygia.app") {
          console.log(Correo);
          console.log(UserRoll);
        }
        // return;

        let request = {
          "Correo": Correo,
          "password": Password,
          "device_token": !!!firebasetoken ? "" : firebasetoken,
          "Tipo": UserRoll
        };

        let responseLogin = await login_request(request);
        if (responseLogin["ok"]) {
          localStorage.setItem("logged", true);
          getInfoUser();
        } else {
          dispatch(showSnackbar({
            show: true,
            text: responseLogin.mensaje,
            type: "error",
          }));
          navigate('/', { replace: true });
        }

      }
      else {
        dispatch(showSnackbar({
          show: true,
          text: response.mensaje,
          type: "error",
        }));
      }
    } else {
      setMensajeCodigo("false");
      dispatch(showSnackbar({
        show: true,
        text: "Escriba un código",
        type: "warning",
      }));
    }
    setLoaderModal(false);
    return;
  };

  const validarPassword2 = (texto) => {
    if (Password !== texto) {
      // console.log("No coinciden");
      setTextoMensajePassword2("Las contraseñas no coinciden");
      setMensajePassword2("false");
    } else {
      // console.log("coinciden");
      setTextoMensajePassword2("");
      setMensajePassword2("true");
    }
  };

  const onSubmit = async () => {
    // openModalCodigo(true);
    // setShowLicencias(1);
    // return;
    setLoader(true);
    // console.log(Telefono);
    // console.log(Correo);
    if (UserRoll === "1") {
      if (MensajeNombre === "true" && !!Nombre &&
        MensajeApePaterno === "true" && !!ApePaterno &&
        MensajePassword === "true" && !!Password &&
        MensajePassword2 === "true" && !!Password2 &&
        MensajeCorreo === "true" && !!Correo &&
        MensajeTelefono === "true" && !!Telefono &&
        Terminos &&
        MensajeNacimiento === "true" && !!Nacimiento &&
        !!Sexo &&
        !!Prefijo && !!Profesion &&
        !!Cedula && MensajeCedula !== "false" &&
        MensajeEstado === "true" && !!Estado &&
        MensajeLocalidad === "true" && !!Localidad
        // !!CedulaFile.file &&
        // MensajeUniversidad === "true" && !!Universidad
      ) {
        let email_data = {
          "Email": Correo,
          "Tipo": UserRoll

        };

        let mailVal = await emailValidation_request(email_data);
        if (mailVal["ok"]) {
          let estado = "";
          EstadosList.forEach(element => {
            if (element.id === Estado) {
              estado = element.Estado;
            }
          });
          let registerData = new FormData();
          registerData.append("Nombre", Nombre);
          registerData.append("ApellidoPaterno", ApePaterno);
          registerData.append("ApellidoMaterno", ApeMaterno);
          registerData.append("Genero", Sexo);
          registerData.append("Nacimiento", Nacimiento);
          registerData.append("Prefijo", Prefijo);
          registerData.append("Profesion", Profesion);
          registerData.append("NumeroCedula", Cedula);
          registerData.append("Universidad", Universidad);
          registerData.append("Celular", Telefono);
          registerData.append("Email", Correo);
          registerData.append("Password", Password);
          registerData.append("TelefonoEmergencia", TelEmergencia);
          registerData.append("NombreComercial", NombreComercial);
          registerData.append("Direccion", Direccion);
          registerData.append("Estado", estado);
          registerData.append("Localidad", Localidad);
          // console.log(image);
          if (!!image) {
            registerData.append("Imagen", ProfileImage, image);
          }

          // registerData.append("CedulaFile", CedulaFile.file, CedulaFile.name);
          registerData.append("Medico", "1");

          let response = await register_request(registerData);

          if (response["ok"]) {
            ReactGA.event({
              category: "Registro",
              action: "Creacion de cuenta medico exitoso",
              label: "Cuenta creada con exito", // optional
              value: 99, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            dispatch(showSnackbar({
              show: true,
              text: "Registro exitoso",
              type: "success",
            }));
            openModalCodigo(true);
          } else {
            ReactGA.event({
              category: "Registro",
              action: "Creacion de cuenta medico erroneo",
              label: `${response.mensaje}`, // optional
              value: 99, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            dispatch(showSnackbar({
              show: true,
              text: response.mensaje,
              type: "error",
            }));
          }

        } else {
          dispatch(showSnackbar({
            show: true,
            text: mailVal.mensaje,
            type: "error",
          }));

        }
      } else {

        if (!!!Sexo) {
          setComboGenero("false");
        }

        if (!!!Nombre) {
          setMensajeNombre("false");
        }

        if (!!!ApePaterno) {
          setMensajeApePaterno("false");
        }

        if (!!!Password) {
          setMensajePassword("false");
        }

        if (!!!Password2) {
          setMensajePassword2("false");
        }

        if (!!!Correo) {
          setMensajeCorreo("false");
        }
        if (!!!Telefono) {
          setMensajeTelefono("false");
        }

        if (!Terminos) {
          setCheckTerminos("false");
        }

        if (!!!Nacimiento) {
          setMensajeNacimiento("false");
        }

        if (!!!Prefijo) {
          setComboPrefijo("false");
        }
        if (!!!Profesion) {
          setMensajeProfesion("false");
        }

        if (!!!Cedula) {
          setMensajeCedula("false");
        }

        if (!!!Universidad) {
          setMensajeUniversidad("false");
        }

        if (!!!CedulaFile.file) {
          setArchivoExist("false");
        }

        if (!!!Estado) {
          setMensajeEstado("false");
        }

        if (!!!Localidad) {
          setMensajeLocalidad("false");
        }

        dispatch(showSnackbar({
          show: true,
          text: "Faltan campos por completar",
          type: "warning",
        }));
      }
    } else {
      if (MensajeNombre === "true" && !!Nombre &&
        MensajeApePaterno === "true" && !!ApePaterno &&
        MensajePassword === "true" && !!Password &&
        MensajePassword2 === "true" && !!Password2 &&
        MensajeCorreo === "true" && !!Correo &&
        MensajeTelefono === "true" && !!Telefono &&
        Terminos &&
        MensajeNacimiento === "true" && !!Nacimiento &&
        MensajeEstado === "true" && !!Estado &&
        MensajeLocalidad === "true" && !!Localidad &&
        !!Sexo) {
        let email_data = {
          "Email": Correo,
          "Tipo": UserRoll
        };

        let mailVal = await emailValidation_request(email_data);
        if (mailVal["ok"]) {
          let registerData = new FormData();
          registerData.append("Nombre", Nombre);
          registerData.append("ApellidoPaterno", ApePaterno);
          registerData.append("ApellidoMaterno", ApeMaterno);
          registerData.append("Genero", Sexo);
          registerData.append("Nacimiento", Nacimiento);
          registerData.append("Prefijo", "");
          registerData.append("Profesion", "");
          registerData.append("NumeroCedula", "");
          registerData.append("Celular", Telefono);
          registerData.append("Email", Correo);
          registerData.append("Password", Password);
          registerData.append("TelefonoEmergencia", TelEmergencia);
          registerData.append("NombreComercial", "");
          registerData.append("Direccion", "");
          registerData.append("Estado", Estado);
          registerData.append("Localidad", Localidad);
          // console.log(image);
          if (!!image) {
            registerData.append("Imagen", ProfileImage, image);
          }

          registerData.append("Asistente", "1");

          let response = await register_request(registerData);

          if (response["ok"]) {
            ReactGA.event({
              category: "Registro",
              action: "Creacion de cuenta asistente exitoso",
              label: "Cuenta creada con exito", // optional
              value: 99, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            dispatch(showSnackbar({
              show: true,
              text: "Registro exitoso",
              type: "success",
            }));
            openModalCodigo(true);
          } else {
            ReactGA.event({
              category: "Registro",
              action: "Creacion de cuenta asistente erroneo",
              label: `${response.mensaje}`, // optional
              value: 99, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            dispatch(showSnackbar({
              show: true,
              text: response.mensaje,
              type: "error",
            }));
          }

        } else {
          dispatch(showSnackbar({
            show: true,
            text: mailVal.mensaje,
            type: "error",
          }));

        }
      } else {

        if (!!!Sexo) {
          setComboGenero("false");
        }

        if (!!!Nombre) {
          setMensajeNombre("false");
        }

        if (!!!ApePaterno) {
          setMensajeApePaterno("false");
        }

        if (!!!Password) {
          setMensajePassword("false");
        }

        if (!!!Password2) {
          setMensajePassword2("false");
        }

        if (!!!Correo) {
          setMensajeCorreo("false");
        }
        if (!!!Telefono) {
          setMensajeTelefono("false");
        }

        if (!Terminos) {
          setCheckTerminos("false");
        }

        if (!!!Nacimiento) {
          setMensajeNacimiento("false");
        }

        if (!!!Estado) {
          setMensajeEstado("false");
        }

        if (!!!Localidad) {
          setMensajeLocalidad("false");
        }

        dispatch(showSnackbar({
          show: true,
          text: "Faltan campos por completar",
          type: "warning",
        }));
      }

    }
    setLoader(false);
    return;
  };

  async function getEstados() {
    let response = await getEstado_request();
    if (response.ok) {
      // console.log(response.data);
      let estados_temp = []
      response.data.forEach(estado => {
        estados_temp.push({ value: estado.id.toString(), label: estado.Estado });
      });
      setEstadosList(estados_temp);
    } else {
      setEstadosList([]);
    }
  }

  async function getLocalidades(estado) {
    let response = await getLocalidad_request(estado);
    if (response.ok) {
      // console.log(response.data);
      let localidades_temp = []
      response.data.forEach(estado => {
        localidades_temp.push({ value: estado, label: estado });
      });
      setLocalidadesList(localidades_temp);
    } else {
      setLocalidadesList([]);
    }
  }

  function cleanInputsDoctor() {
    setCedulaFile({ name: "", url: "", file: "", size: 0, formato: "" });
    setCedula("");
    setSubespecialidad("");
    setDireccion("");
    setNombreComercial("");
  }

  function filterOptions(input, option) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
  }

  function filterSort(optionA, optionB) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
  }

  useEffect(() => {
    getEstados();
  }, [])

  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };


  return (
    <main>
      <Stack>
        {loader ? (<Loader />) : (
          <>
            {/* {ShowLicencias === 0 ? ( */}
            <div>
              <Formulario action="" onSubmit={onSubmit}>
                <h2>Registro</h2>
                <hr />

                <InputAntd
                  label="Correo electrónico:"
                  requiredICon={true}
                  style={{ width: "100%", marginBottom: "1%" }}
                  className="inputblue"
                  value={Correo}
                  type="email"
                  setStateValue={setCorreo}
                  placeholder="escribe tu correo"
                  valueError={MensajeCorreo}
                  setStateError={setMensajeCorreo}
                  textError={["Este campo es obligatorio", "Formato correo erroneo", ""]}
                  vacio={false}
                  expresionRegular={expresiones.correo}
                  maxLength={70}
                />

                <Campos
                  label="Contraseña:"
                  requiredICon={true}
                  valueError={MensajePassword}
                  textError={TextoMensajePassword}
                  vacio={false}
                  styleDiv={{ height: "25px", marginBottom: "40px" }}
                >
                  <Input.Password
                    className="inputblue"
                    style={{ width: "100%", padding: "0 10px" }}
                    placeholder="Escribe una contraseña"
                    status={MensajePassword === "false" && "error"}
                    onChange={(e) => {
                      if (expresiones.password.test(e.target.value)) {
                        setPassword(e.target.value);
                        if (e.target.value.length < 8) {
                          setTextoMensajePassword("Min. 8 caracteres (letras y numeros)")
                          setMensajePassword("false");
                        } else {
                          setTextoMensajePassword("");
                          setMensajePassword("true");
                        }
                      } else {
                        if (!!e.target.value) {
                          if (e.target.value.length < 8) {
                            setTextoMensajePassword("Min. 8 caracteres (letras y numeros)");
                            setMensajePassword("false");
                          } else {
                            setTextoMensajePassword("Solo se permiten letras y numeros");
                            setMensajePassword("false");
                          }
                        } else {
                          setTextoMensajePassword("Este campo es obligatorio");
                          setMensajePassword("false");
                        }
                      }
                    }}
                    maxLength={16}
                  />
                </Campos>

                <Campos
                  label="Repetir contraseña:"
                  requiredICon={true}
                  valueError={MensajePassword2}
                  textError={TextoMensajePassword2}
                  vacio={false}
                  styleDiv={{ height: "40px", marginBottom: "57px" }}
                >
                  <Input.Password
                    className="inputblue"
                    style={{ width: "100%", padding: "0 10px" }}
                    placeholder="Repita su contraseña"
                    status={MensajePassword2 === "false" && "error"}
                    onChange={(e) => {
                      if (expresiones.password.test(e.target.value)) {
                        setPassword2(e.target.value);
                        if (e.target.value.length < 8) {
                          setTextoMensajePassword2("Min. 8 caracteres (letras y numeros)")
                          setMensajePassword2("false");
                        } else {
                          validarPassword2(e.target.value);
                          // setTextoMensajePassword2("");
                          // setMensajePassword2("true");
                        }
                      } else {
                        if (!!e.target.value) {
                          if (e.target.value.length < 8) {
                            setTextoMensajePassword2("Min. 8 caracteres (letras y numeros)");
                            setMensajePassword2("false");
                          } else {
                            setTextoMensajePassword2("Solo se permiten letras y numeros");
                            setMensajePassword2("false");
                          }
                        } else {
                          setTextoMensajePassword2("Este campo es obligatorio");
                          setMensajePassword2("false");
                        }
                      }
                    }}
                    maxLength={16}
                  />
                </Campos>

                <div style={{ textAlign: "center", marginBottom: "1%" }}>
                  <label htmlFor="imagenPerfil">
                    <img
                      style={{
                        width: "70px",
                        height: "70px",
                        border: "2px solid #d7d7d7",
                        borderRadius: ".5rem",
                        cursor: "pointer",
                        overflow: "hidden",
                        marginBottom: "4px",
                        objectFit: "cover",
                      }}
                      src={image !== "" ? image : icono[0]}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="imagenPerfil"
                    accept="/image/x-PNG,image/jpeg, image/png, image/*"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.type.substring(0, 5) === "image") {
                        setimage(URL.createObjectURL(e.target.files[0]));
                        setProfileImage(e.target.files[0]);
                      } else {
                        setOpen(true);
                        setTypeMessage("warning");
                        setMessage("Formato no valido");
                      }
                    }}
                  />
                </div>

                <InputAntd
                  label="Nombre:"
                  requiredICon={true}
                  style={{ width: "100%", marginBottom: "1%" }}
                  className="inputblue"
                  value={Nombre}
                  setStateValue={setNombre}
                  placeholder="escribe tu nombre"
                  valueError={MensajeNombre}
                  setStateError={setMensajeNombre}
                  textError={["Este campo es obligatorio", "Solo se permiten letras", ""]}
                  vacio={false}
                  expresionRegular={expresiones.textos}
                  maxLength={50}
                />

                <InputAntd
                  label="Apellido paterno:"
                  requiredICon={true}
                  style={{ width: "100%", marginBottom: "1%" }}
                  className="inputblue"
                  type="text"
                  value={ApePaterno}
                  setStateValue={setApePaterno}
                  placeholder="Escribe tu primer apellido"
                  valueError={MensajeApePaterno}
                  setStateError={setMensajeApePaterno}
                  textError={["Este campo es obligatorio", "Solo se permiten letras", ""]}
                  vacio={false}
                  expresionRegular={expresiones.textos}
                  maxLength={20}
                />

                <InputAntd
                  label="Apellido materno:"
                  style={{ width: "100%", marginBottom: "1%" }}
                  className="inputblue"
                  type="text"
                  value={ApeMaterno}
                  setStateValue={setApeMaterno}
                  placeholder="Escribe tu segundo apellido"
                  valueError={MensajeApeMaterno}
                  setStateError={setMensajeApeMaterno}
                  textError={["", "Solo se permiten letras", ""]}
                  vacio={true}
                  expresionRegular={expresiones.textos}
                  maxLength={20}
                />

                <InputAntd
                  label="Teléfono:"
                  requiredICon={true}
                  style={{ width: "100%", marginBottom: "1%" }}
                  className="inputblue"
                  value={Telefono}
                  id="celular"
                  setStateValue={setTelefono}
                  placeholder="escribe un teléfono"
                  valueError={MensajeTelefono}
                  setStateError={setMensajeTelefono}
                  textError={["Este campo es obligatorio", "Solo se permiten numeros", "Debe contener 10 digitos"]}
                  vacio={false}
                  expresionRegular={expresiones.telefono}
                  maxLength={10}
                />

                <InputAntd
                  label="Teléfono de emergencia:"
                  style={{ width: "100%", marginBottom: "1%" }}
                  className="inputblue"
                  id="celular"
                  value={TelEmergencia}
                  setStateValue={setTelEmergencia}
                  placeholder="escribe un teléfono de emergencia"
                  valueError={MensajeTelEmergencia}
                  setStateError={setMensajeTelEmergencia}
                  textError={["", "Solo se permiten numeros", "Debe contener 10 digitos"]}
                  vacio={true}
                  expresionRegular={expresiones.telefono}
                  maxLength={10}
                />

                <SelectAntd
                  label="Género:"
                  requiredICon={true}
                  vacio={false}
                  value={Sexo}
                  style={{ width: "100%", marginBottom: "1%" }}
                  placeholder="Selecciona un género"
                  options={[{ value: "Femenino", label: "Femenino" }, { value: "Masculino", label: "Masculino" }]}
                  setStateValue={setSexo}
                  setStateError={setComboGenero}
                  valueError={comboGenero}
                  textError={["Debe seleccionar un género"]}
                />

                <DatePickerAntd
                  label="Fecha de nacimiento:"
                  requiredICon={true}
                  vacio={false}
                  placeholder="Selecciona una fecha"
                  style={{ width: "100%", background: "white", marginBottom: "1%" }}
                  value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                  setStateValue={setNacimiento}
                  setStateError={setMensajeNacimiento}
                  valueError={MensajeNacimiento}
                  textError={["Debe seleccionar una fecha", "Debe ser mayor de edad"]}
                  mayorEdad={true}
                />

                <Campos
                  label="Estado:"
                  requiredICon={true}
                  vacio={false}
                  valueError={MensajeEstado}
                  textError={"Debe seleccionar un estado"}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Estado"
                    optionFilterProp="children"
                    filterOption={filterOptions}
                    filterSort={filterSort}
                    value={Estado}
                    onSelect={(e) => {
                      setMensajeEstado("true");
                      setEstado(e);
                      getLocalidades(e);
                    }}
                    options={EstadosList}
                    status={MensajeEstado === "false" && "error"}
                  />
                </Campos>

                <Campos
                  label="Localidad:"
                  requiredICon={true}
                  vacio={false}
                  textError={"Debe seleccionar una localidad"}
                  valueError={MensajeLocalidad}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Localidad"
                    optionFilterProp="children"
                    filterOption={filterOptions}
                    filterSort={filterSort}
                    value={Localidad}
                    onSelect={(e) => {
                      setMensajeLocalidad("true");
                      setLocalidad(e);
                    }}
                    options={LocalidadesList}
                    disabled={!!Estado ? false : true}
                    status={MensajeLocalidad === "false" && "error"}
                  />
                </Campos>

                <SelectAntd
                  id="rol"
                  label="Rol de usuario:"
                  requiredICon={true}
                  vacio={false}
                  value={UserRoll}
                  style={{ width: "100%" }}
                  placeholder="Selecciona un rol"
                  options={[{ value: "1", label: "Médico" }, { value: "4", label: "Asistente" }]}
                  setStateValue={setUserRoll}
                  setStateError={setComboRol}
                  valueError={comboRol}
                  textError={["Debe seleccionar un rol"]}
                  extras={[setDesplegar, cleanInputsDoctor]}
                />

                {Desplegar === 1 && (
                  <InputAntd
                    label="Nombre comercial:"
                    style={{ width: "100%", marginBottom: "1%" }}
                    className="inputblue"
                    type="text"
                    value={NombreComercial}
                    setStateValue={setNombreComercial}
                    placeholder="Escribe tu nombre comercial"
                    valueError={MensajeNombreComercial}
                    setStateError={setMensajeNombreComercial}
                    textError={["", "Solo se permiten letra y numeross (min. 5 caracteres)", ""]}
                    vacio={true}
                    expresionRegular={expresiones.textNumber}
                    maxLength={100}
                  />
                )}
                {Desplegar === 1 && (
                  <InputAntd
                    label="Dirección comercial:"
                    style={{ width: "100%", marginBottom: "1%" }}
                    className="inputblue"
                    type="text"
                    value={Direccion}
                    setStateValue={setDireccion}
                    placeholder="Escribe tu dirección comercial"
                    valueError={MensajeDireccion}
                    setStateError={setMensajeDireccion}
                    textError={["", "Debe contener un min. de 10 caracteres (numeros, letras y (/,#,-))", ""]}
                    vacio={true}
                    expresionRegular={expresiones.direccion}
                    maxLength={100}
                  />
                )}
                {Desplegar === 1 &&
                  <>
                    <h2>Datos profesionales</h2>
                    <hr />
                  </>
                }
                {Desplegar === 1 && (
                  <SelectAntd
                    label="Prefijo:"
                    requiredICon={true}
                    vacio={false}
                    value={Prefijo}
                    style={{ width: "100%" }}
                    placeholder="Selecciona un prefijo"
                    options={[
                      { value: "Dr.", label: "Dr." },
                      { value: "Dra.", label: "Dra." },
                      { value: "Sr.", label: "Sr." },
                      { value: "Sra.", label: "Sra." },
                      { value: "Lic.", label: "Lic." },
                      { value: "Mtro.", label: "Mtro." },
                      { value: "Mtra.", label: "Mtra." },
                    ]}
                    setStateValue={setPrefijo}
                    setStateError={setComboPrefijo}
                    valueError={comboPrefijo}
                    textError={["Debe seleccionar un prefijo"]}
                  />
                )}
                {Desplegar === 1 && (
                  <SelectAntd
                    label="Especialidad:"
                    requiredICon={true}
                    vacio={false}
                    value={Profesion}
                    style={{ width: "100%" }}
                    placeholder="Selecciona una especialidad"
                    options={[
                      { value: "Anestesiología", label: "Anestesiología" },
                      { value: "Cardiología", label: "Cardiología" },
                      { value: "Cirugía", label: "Cirugía" },
                      { value: "Dermatología", label: "Dermatología" },
                      { value: "Endocrinología", label: "Endocrinología" },
                      { value: "Gastroenterología", label: "Gastroenterología" },
                      { value: "Gineco obstetricia", label: "Gineco obstetricia" },
                      { value: "Infectología", label: "Infectología" },
                      { value: "Medicina Estética", label: "Medicina Estética" },
                      { value: "Medicina Familiar", label: "Medicina Familiar" },
                      { value: "Medicina Física y Rehabilitación", label: "Medicina Física y Rehabilitación" },
                      { value: "Medicina General", label: "Medicina General" },
                      { value: "Medicina Intensiva", label: "Medicina Intensiva" },
                      { value: "Médicina Interna", label: "Médicina Interna" },
                      { value: "Nefrología", label: "Nefrología" },
                      { value: "Neumología", label: "Neumología" },
                      { value: "Neurología", label: "Neurología" },
                      { value: "Nutrición", label: "Nutrición" },
                      { value: "Odontología", label: "Odontología" },
                      { value: "Oftalmología", label: "Oftalmología" },
                      { value: "Oncología", label: "Oncología" },
                      { value: "Otorrinolaringología", label: "Otorrinolaringología" },
                      { value: "Patología", label: "Patología" },
                      { value: "Pediatría", label: "Pediatría" },
                      { value: "Psicología", label: "Psicología" },
                      { value: "Psiquiatría", label: "Psiquiatría" },
                      { value: "Radiología", label: "Radiología" },
                      { value: "Urología", label: "Urología" },
                    ]}
                    setStateValue={setProfesion}
                    setStateError={setMensajeProfesion}
                    valueError={MensajeProfesion}
                    textError={["Debe seleccionar una especialidad"]}
                  />
                )}

                {Desplegar === 1 && (
                  <InputAntd
                    label="Subespecialidad:"
                    style={{ width: "100%", marginBottom: "1%" }}
                    className="inputblue"
                    type="text"
                    value={Subespecialidad}
                    setStateValue={setSubespecialidad}
                    placeholder="Escribe una subespecialidad"
                    valueError={MensajeSubespecialidad}
                    setStateError={setMensajeSubespecialidad}
                    textError={["", "Solo puede contener letras", ""]}
                    vacio={true}
                    expresionRegular={expresiones.textos}
                    maxLength={40}
                  />
                )}

                {Desplegar === 1 && (
                  <InputAntd
                    label="Cédula:"
                    style={{ width: "100%", marginBottom: "1%" }}
                    requiredICon={true}
                    className="inputblue"
                    type="text"
                    id={"cedula"}
                    value={Cedula}
                    setStateValue={setCedula}
                    placeholder="Escribe un número de cédula"
                    valueError={MensajeCedula}
                    setStateError={setMensajeCedula}
                    textError={["Debe escribir una cédula", "Solo puede contener letras y numeros", "Debe contener al menos 4 caracteres"]}
                    vacio={false}
                    expresionRegular={expresiones.cedula}
                    maxLength={20}
                  />
                )}

                {Desplegar === 1 && (
                  <InputAntd
                    label="Universidad de egreso:"
                    style={{ width: "100%", marginBottom: "1%" }}
                    requiredICon={true}
                    className="inputblue"
                    type="text"
                    value={Universidad}
                    setStateValue={setUniversidad}
                    placeholder="Escribe una universidad"
                    valueError={MensajeUniversidad}
                    setStateError={setMensajeUniversidad}
                    textError={["Debe escribir una universidad", "", ""]}
                    vacio={false}
                    maxLength={50}
                  />
                )}

                <ContenedorTerminos>
                  {Desplegar === 1 && (
                    <div>
                      {/* <div> */}
                      <Campos
                        label={"Archivo(PDF) o imagen de cédula"}
                        valueError={archivoExist}
                        textError={"Debe añadir un archivo"}
                        styleDiv={{ textAlign: "center" }}
                        requiredICon={true}
                        vacio={false}
                      >
                        <InputFileSingle archivoValue={CedulaFile} archivo={setCedulaFile} formatos={4} extras={[setOpen, setTypeMessage, setMessage, setArchivoExist]} />
                      </Campos>
                    </div>
                  )}

                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Checkbox
                      checked={Terminos}
                      onChange={(e) => {
                        setTerminos(e.target.checked);
                        if (e.target.checked) {
                          setCheckTerminos("true");
                        } else {
                          setCheckTerminos("false");
                        }
                      }}
                    />

                    <b className="Activar" onClick={openModal2r}> &nbsp; Acepto los términos y condiciones</b>

                  </div>
                  <div style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                    <LeyendaError valido={checkTerminos}>Debe aceptar los Términos y Condiciones</LeyendaError>
                  </div>

                </ContenedorTerminos>

                <ContenedorBotonCentrado>
                  <ButtonAntd
                    onClick={() => {
                      if (!!UserRoll) {
                        onSubmit();
                      } else {

                        if (!!!UserRoll) {
                          setComboRol("false");
                        }
                        if (!!!Sexo) {
                          setComboGenero("false");
                        }

                        if (!!!Nombre) {
                          setMensajeNombre("false");
                        }

                        if (!!!ApePaterno) {
                          setMensajeApePaterno("false");
                        }

                        if (!!!Password) {
                          setMensajePassword("false");
                        }

                        if (!!!Password2) {
                          setMensajePassword2("false");
                        }

                        if (!!!Correo) {
                          setMensajeCorreo("false");
                        }
                        if (!!!Telefono) {
                          setMensajeTelefono("false");
                        }

                        if (!Terminos) {
                          setCheckTerminos("false");
                        }

                        if (!!!Nacimiento) {
                          setMensajeNacimiento("false");
                        }

                        if (!!!Estado) {
                          setMensajeEstado("false");
                        }

                        if (!!!Localidad) {
                          setMensajeLocalidad("false");
                        }

                        setOpen(true);
                        setTypeMessage("warning");
                        setMessage("Faltan campos por completar");
                      }
                    }}
                  >
                    Crear cuenta
                  </ButtonAntd>
                  <h5 style={{ marginTop: "10px" }}>
                    <span>¿Ya tienes cuenta? </span>
                    <Link replace={true} to="/login"><span style={{ color: "#3b7db2" }}>Iniciar sesión</span></Link>
                  </h5>
                </ContenedorBotonCentrado>
              </Formulario>

            </div>
          </>
        )}

        <Modal styleDet={styleDet} isOpen={isopenModal2r} closeModal={closeModal2r}>
          <div style={{ color: "white", width: "100%", padding: "1% 2%", background: "#148f9f", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <b style={{ cursor: "pointer" }}>Términos y condiciones</b>
            <b style={{ cursor: "pointer" }} onClick={closeModal2r}>x</b>
          </div>
          <div className="ModalTerminos">

            <div style={{ textAlign: "center", padding: "3%" }}>
              <img style={{ width: "5rem" }} src={logo} alt="" />
            </div>
            <div className="ModalRContainer">
              <TerminosModal />
            </div>
          </div>
        </Modal>

        <Modal isOpen={isopenModalCodigo} styleDet={styleDet}>
          <div className="ModalCorreoR">
            <div style={{ textAlign: "center" }}>
              {loaderModal ? (<LoaderModals />) : (<div>
                <img
                  style={{ width: "25%", marginBottom: "3%" }}
                  src={logo}
                  alt=""
                  className="lgo"
                />
                <form
                  action=""
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    validacion();
                  }}
                >
                  <p>Se te ha enviado un correo a:</p>
                  <p>
                    <b>{Correo}</b>
                  </p>
                  <InputAntd
                    style={{ width: "80%", marginBottom: "1%" }}
                    className="inputblue"
                    type="text"
                    id="codigo"
                    value={Codigo}
                    setStateValue={setCodigo}
                    label="Código:"
                    placeholder="Escribe tu código"
                    valueError={MensajeCodigo}
                    setStateError={setMensajeCodigo}
                    textError={["Debe escribir un código", "Solo puede contener numeros", "Debe contener 4 digitos"]}
                    vacio={false}
                    expresionRegular={expresiones.codigo}
                    maxLength={4}
                  />

                  <ContenedorBotonCentrado>
                    <div style={{ width: "80%" }}>

                      <b style={{ fontSize: "12px" }}>Nota: Recuerda revisar tu bandeja de spam si tu correo no ha llegado</b>
                    </div>
                    <ButtonAntd
                      style={{ width: "80%" }}
                      onClick={validacion}
                    >
                      Verificar
                    </ButtonAntd>

                  </ContenedorBotonCentrado>
                </form>
              </div>
              )}
            </div>
          </div>
        </Modal>

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          <Alert onClose={() => setOpen(false)} severity={typeMessage} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </main>
  );
};

export default Registro;
