import React from 'react'
import Iconos from "../../IconosDeSitio";
import "../../comp-styles/EstudiosCard.scss"

export default function EstudiosCard({ EstudiosList, DetailEstudios }) {

  return (
    EstudiosList.map((estudio) => (
      <div onClick={() => {
        DetailEstudios(estudio.id);
      }}
        className='CardEstudExpediente'
        key={estudio.id}
      >
        <p className='overflow-ellipsis'>{estudio.descripcion}</p>
        <p>{estudio.fecha}</p>
      </div>
    ))
  )
}
