import Modal from "../componentes/GeneralComponents/ModalDetalle";
import { UseModal } from "../hooks/UseModal";
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import "./styles/selectMedicos.scss";
import { AddCircle, ArrowBack, PowerSettingsNew } from "@mui/icons-material";
import { Input, Row } from "antd";
import Iconos from "../componentes/IconosDeSitio";
import AddList from "../Img/Iconos/Agregar.svg"
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import { getDoctorsLink_request, getDoctorsToAdd_request, loginMedico_request } from "../services/asistentes_services";
import React, { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { sendSolicitud_request } from "../services/vinculacion_services";
import { logout_request } from "../services/login_services";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { infoDoctorLog } from "../redux/infoDoctorLog";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SelectMedico() {
    // ******** VARIABLES ********
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const style = {
        height: "clac(100vh - 52px)",
        width: "clac(100vw - 52px)",
        margin: "0 2%",
    };
    // ******** USE STATES ********
    const [isOpen, Open, Closed] = UseModal(true);
    const [ListaMedicosVinculados, setListaMedicosVinculados] = useState([]);
    const [ListaMedicosVinculadosFilter, setListaMedicosVinculadosFilter] = useState([]);
    const [ListaMedicos, setListaMedicos] = useState([]);
    const [ListaMedicosFilter, setListaMedicosFilter] = useState([]);

    // MANEJA DE MENSAJES
    const [open, setOpen] = useState(false);
    const [typeMessage, setTypeMessage] = useState("");
    const [message, setMessage] = useState("");

    const [Accion, setAccion] = useState(0);

    const [loader, setLoader] = useState(false);

    // ******** FUNCIONES ********

    async function getDoctorsLink() {
        let response = await getDoctorsLink_request();
        if (response.ok) {
            // console.log(response.data);
            setListaMedicosVinculados(response.data);
            setListaMedicosVinculadosFilter(response.data);
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
    }

    async function getDoctorsToAdd() {
        setLoader(true);
        let response = await getDoctorsToAdd_request();
        if (response.ok) {
            // console.log(response.data);
            setListaMedicos(response.data);
            setListaMedicosFilter(response.data);
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoader(false);
        return;
    }

    async function logInMedico(datos, idMedico) {
        // console.log(datos);
        // return;
        let response = await loginMedico_request(idMedico);
        if (response.ok) {
            dispatch(infoDoctorLog(datos));
            // console.log("########## DATA ##########");
            // console.log(response.data);
            // console.log("########## DATOS ##########");
            // console.log(datos);
            localStorage.setItem("datosMedico", JSON.stringify(datos));
            localStorage.setItem("permisos", JSON.stringify(response.data));
            localStorage.setItem("loginAsistente", 1);
            navigate("/app/citas", {
                replace: true,
                state: {
                    logged: true,
                    datos: datos
                },
            });
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
    }

    async function sendLinkMedico(idMedico) {
        setLoader(true);
        let response = await sendSolicitud_request(idMedico);
        if (response.ok) {
            setOpen(true);
            setTypeMessage("success");
            setMessage("Solicitud enviada");
            // getDoctorsToAdd();
            setAccion(0);
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoader(false);
        return;
    }

    function filterMedicos(text) {
        // console.log(text.target.value);
        // console.log(solicitudesSearch);
        var chars = {
            á: "a",
            é: "e",
            í: "i",
            ó: "o",
            ú: "u",
            à: "a",
            è: "e",
            ì: "i",
            ò: "o",
            ù: "u",
            ñ: "n",
            Á: "A",
            É: "E",
            Í: "I",
            Ó: "O",
            Ú: "U",
            À: "A",
            È: "E",
            Ì: "I",
            Ò: "O",
            Ù: "U",
            Ñ: "N",
        };
        var expr = /[áàéèíìóòúùñ]/gi;
        let filter = [];
        // console.log(MedicosList);
        // console.log(MedicosListFilter);
        ListaMedicosVinculadosFilter.forEach((contacto) => {
            if (
                contacto.Nombre
                    .replace(expr, function (e) { return chars[e]; })
                    .toLowerCase().indexOf(text.target.value
                        .replace(expr, function (e) { return chars[e]; })
                        .toLowerCase()) > -1
            ) {
                filter.push(contacto);
            }
        });

        setListaMedicosVinculados(filter);
    }

    function filterMedicosAdd(text) {
        // console.log(text.target.value);
        // console.log(solicitudesSearch);
        var chars = {
            á: "a",
            é: "e",
            í: "i",
            ó: "o",
            ú: "u",
            à: "a",
            è: "e",
            ì: "i",
            ò: "o",
            ù: "u",
            ñ: "n",
            Á: "A",
            É: "E",
            Í: "I",
            Ó: "O",
            Ú: "U",
            À: "A",
            È: "E",
            Ì: "I",
            Ò: "O",
            Ù: "U",
            Ñ: "N",
        };
        var expr = /[áàéèíìóòúùñ]/gi;
        let filter = [];
        // console.log(MedicosList);
        // console.log(MedicosListFilter);
        ListaMedicosFilter.forEach((contacto) => {
            if (
                contacto.Nombre
                    .replace(expr, function (e) { return chars[e]; })
                    .toLowerCase().indexOf(text.target.value
                        .replace(expr, function (e) { return chars[e]; })
                        .toLowerCase()) > -1 ||
                contacto.Profesiones
                    .replace(expr, function (e) { return chars[e]; })
                    .toLowerCase().indexOf(text.target.value
                        .replace(expr, function (e) { return chars[e]; })
                        .toLowerCase()) > -1
            ) {
                filter.push(contacto);
            }
        });

        setListaMedicos(filter);
    }

    const onLogout = async () => {
        let response = await logout_request();
        if (response.ok) {
            navigate('/', {
                replace: true,
                state: {
                    logged: false
                },
            });
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        localStorage.removeItem("token");
        return;
    };

    useEffect(() => {
        getDoctorsLink();
        return;
    }, [])

    return (
        <Stack>
            <Modal isOpen={isOpen} closeModal={Closed} styleDet={style} optionClose={false}>
                {Accion === 0 && (
                    <>
                        <div className="header">
                            <div style={{ width: "70%" }}>
                                <b>Médicos vinculados</b>
                            </div>
                            <Row style={{ justifyContent: "space-between" }}>
                                <AddCircle sx={{ color: "#ffffff", cursor: "pointer" }} onClick={() => { setAccion(1); getDoctorsToAdd(); }} />
                                <PowerSettingsNew sx={{ color: "#ffffff", cursor: "pointer" }} onClick={() => onLogout()} />
                            </Row>
                        </div>
                        <div className="contenidoLista">
                            <p style={{ marginBottom: "1.5%" }}>Nombre:</p>
                            <Input
                                placeholder="Buscar..."
                                type="search"
                                style={{ width: "100%", marginBottom: "10px" }}
                                onChange={filterMedicos}
                            />
                            {ListaMedicosVinculados.map((medico) => (
                                <div onClick={() => logInMedico(medico, medico.IdMedico)}>
                                    <div className="CardVinculacion" style={{ cursor: "pointer" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div style={{ width: "90%", display: "flex", alignItems: "center", gap: "1%" }}>
                                                <div>
                                                    <img className="imgVinculCard" src={!!medico.Imagen ? "https://" + medico.Imagen : Iconos[0]} alt="" />
                                                </div>
                                                <div className="UsuVinc">
                                                    <b>{medico.Nombre}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {ListaMedicosVinculados.length === 0 && (
                                <p style={{ textAlign: "center" }}>No hay médicos</p>
                            )}
                        </div>
                    </>
                )}

                {Accion === 1 && (
                    <>
                        <div className="header">
                            <ArrowBack sx={{ color: "#ffffff", cursor: "pointer" }} onClick={() => setAccion(0)} />
                            <b>Agregar médico</b>
                            <div>

                            </div>
                            {/* <Row style={{ justifyContent: "space-between", width: "15%" }}>
                                <AddCircle sx={{ color: "#ffffff" }} />
                                <PowerSettingsNew sx={{ color: "#ffffff" }} />
                            </Row> */}
                        </div>
                        {loader ? (<LoaderModals className={"contenidoLista"} />) : (
                            <div className="contenidoLista">
                                <p style={{ marginBottom: "1.5%" }}>Nombre o profesión:</p>
                                <Input
                                    placeholder="Buscar..."
                                    type="search"
                                    style={{ width: "100%", marginBottom: "10px" }}
                                    onChange={filterMedicosAdd}
                                />
                                {ListaMedicos.map((medico) => (
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div style={{ width: "100%" }} onClick={() => { sendLinkMedico(medico.IdMedico); }}>
                                            <div className="CardVinculacion" style={{ cursor: "pointer" }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "1%" }}>
                                                        <div>
                                                            <img className="imgVinculCard" src={!!medico.Imagen ? "https://" + medico.Imagen : Iconos[0]} alt="" />
                                                        </div>
                                                        <div >
                                                            <div>
                                                                <b>{medico.Nombre}</b>
                                                            </div>
                                                            <div>
                                                                <b>{medico.Profesiones}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <img
                                            onClick={() => { sendLinkMedico(medico.IdMedico); }}
                                            style={{ width: "20px", cursor: "pointer" }}
                                            src={AddList}
                                            alt="" /> */}
                                    </Row>
                                ))}
                                {ListaMedicos.length === 0 && (
                                    <p style={{ textAlign: "center" }}>No hay médicos</p>
                                )}
                            </div>
                        )}

                    </>
                )}
            </Modal>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
            >
                <Alert onClose={() => setOpen(false)} severity={typeMessage} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}