/*Importando Librerias*/
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
/*Importando estilos*/
import './App.scss';
import AppRouter from './routers/AppRouter';

import ReactGA from "react-ga4";
import { Stack } from '@mui/material';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from './redux/snackbarSlice';
import { gAnalytics_ID } from './services/apiUrl';
import { SetFechaInicial, SetFechaFinal, SetCalendarDate } from './redux/citas';
import { getCalendarRange } from './utils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// import "./firebase"

function App() {
  const dispatch = useDispatch();

  ReactGA.initialize(gAnalytics_ID);

  const variable = useSelector((state) => state.snackbar);

  useEffect(() => {
    if (document.domain !== "localhost") {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "App"
      });
    }
    const dateFilter = getCalendarRange();
    dispatch(SetFechaInicial(dateFilter[0]));
    dispatch(SetFechaFinal(dateFilter[1]));
    dispatch(SetCalendarDate(new Date().toISOString()));
  }, []);


  return (
    <div className='App'>

      <Stack>
        <AppRouter />
        <Snackbar
          open={variable.show}
          autoHideDuration={6000}
          onClose={() => dispatch(showSnackbar(false))}
        >
          <Alert
            onClose={() => dispatch(showSnackbar(false))}
            severity={variable.type}
            sx={{ width: "100%" }}
          >
            {variable.text}
          </Alert>
        </Snackbar>
      </Stack>
    </div>

  );
}

export default App;

