import "./GenaralStyles/Modal.scss"

const Recuperar = ({children, isOpen}) => {

  const handleModalContainerClick  = e => e.stopPropagation();

  return (
    // <div className={`modal ${isOpen &&"is-open"}`}>
    <div className={isOpen ? "modal is-open" : "modal"}>
       <div className="modal-container" onClick={handleModalContainerClick}> 
        {children}
       </div>
    </div>
  )
}

export default Recuperar