import React, { useEffect } from "react";
import { useState } from "react";
import "../../comp-styles/Loader.scss"
// Hook Modal
import { UseModal } from "../../../hooks/UseModal";

// importando style
import "../styles/EstudioExpediente.scss";

import Iconos from "../../IconosDeSitio";
import AgregarBlanco from "../../../Img/Iconos/AgregarBlanco.svg";
import Genero from "../../../Img/Iconos/Genero.svg";
import SearchIcon from "@mui/icons-material/Search";
import { CancelRounded, OndemandVideo } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

import EstudioTabla from "./ExpedienteTablaEstudio";
import SelectAntd from "../../GeneralComponents/SelectAntd";
import TextAreaAntd from "../../GeneralComponents/TextAreaAntd";
import InputFile from "../../GeneralComponents/InputFileList";
import SelectMedicos from "../../GeneralComponents/lista_medicos_reenviar";
import { Button, Empty, Image, Input, Row } from "antd";
import Campos from "../../GeneralComponents/campos";
import TextArea from "antd/es/input/TextArea";
import EstudiosCard from "./EstudiosCard";
import { LeyendaError } from "../../GeneralComponents/Formularios";

import LoaderModals from "../../GeneralComponents/Loader_modals";

import ModalAddEstud from "../../GeneralComponents/ModalDetalle";
import ModalDetalleEstud from "../../GeneralComponents/ModalDetalle";
import Modal from "../../GeneralComponents/ModalDetalle";
// lista de medicos

import { getHistoriasClinicas_request } from "../../../services/expediente_service";
import { getEstudios_request, getDetailEstudios_request, addEstudio_request, forwardEstudio_request, getEstudiosFilter_request } from "../../../services/estudios_services";
import { getContacts_request } from "../../../services/contactos_services";
import InputAntd from "../../GeneralComponents/InputAntd";
import ModalMedicos from "../../Consultas/ModalMedicos";

import { Checkbox } from "antd";

function EstudiosExpediente({ DatosContacto, Estudios, AccionesModal, AccionesMensaje, getHistoria }) {
  let data = JSON.parse(sessionStorage.getItem("infoUser"));
  // Estados de modales
  const [isOpenModalDetalleEstudi, OpenModalDetalleEstudi, CloseModalDetalleEstudi] = UseModal(false);

  const [IsOpenModalMedicos, OpenModalMedicos, ClosedModalMedicos] = UseModal(false);

  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  // inputfile
  const [ImagesListDetalle, setImagesListDetalle] = useState([]);
  const [ImagesList, setImagesList] = useState([]);

  // inputs editables
  const [inputsEditable, setinputsEditable] = useState(true);

  const [MedicosList, setMedicosList] = useState([]);
  const [MedicosListFilter, setMedicosListFilter] = useState([]);
  // Maneja mensajes
  const [IdMedico, setIdMedico] = useState(null);

  const [loader, setLoader] = useState(false);
  const [loaderDetalle, setLoaderDetalle] = useState(false);

  const [DetailEstudio, setDetailEstudio] = useState("");
  const [TipoEstudioAnalisis, setTipoEstudioAnalisis] = useState(null);

  const [EnviarLabTest, setEnviarLabTest] = useState(true);
  const [DescripcionEstudio, setDescripcionEstudio] = useState("");

  const [DatosMedico, setDatosMedico] = useState({ id: "", nombre: "", imagen: "" });

  const [ComboAddTipoEstudio, setComboAddTipoEstudio] = useState("true");
  const [textAreaAddEstudio, setTextAreaDescripcionEstudio] = useState("true");
  const [imagenesListError, setImagenesListError] = useState("true");

  const [MensajeDoctorSelect, setMensajeDoctorSelect] = useState("true");

  async function DetailEstudios(idEstudio) {
    // console.log(idEstudio);
    AccionesModal.OpenModalDetalleEstudi(true);
    setLoaderDetalle(true);
    let response = await getDetailEstudios_request(idEstudio);
    if (response.ok) {
      // console.log(response.data);
      setDetailEstudio(response.data.Estudio);
      setTipoEstudioAnalisis(response.data.Estudio.Tipo);
      setImagesListDetalle(!!!response.data.Documentos ? [] : response.data.Documentos);
      setinputsEditable(true);
    } else {
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("error");
      AccionesMensaje.setMessage(response.mensaje);
      AccionesModal.CloseModalDetalleEstudi(true);
    }
    setLoaderDetalle(false);
    return;
  }

  async function addLabTest() {
    // console.log(TipoEstudioAnalisis);
    // console.log(DescripcionEstudio);
    // console.log(IdContact);
    // console.log(ImagesList);
    // console.log(DatosContacto.id);
    // console.log(TipoEstudioAnalisis);
    // console.log(DescripcionEstudio);
    // console.log(ImagesList);
    // return;
    setLoaderDetalle(true);
    if (ImagesList.length > 0 || !!DescripcionEstudio) {
      let data = new FormData();
      data.append("Tipo", "Estudio");
      data.append("Descripcion", DescripcionEstudio);
      data.append("IdContacto", DatosContacto.id);
      data.append("Enviar", EnviarLabTest ? 1 : 0);

      if (IdMedico !== "") {
        data.append("IdUsuario", IdMedico);
      }

      ImagesList.forEach((element, index) => {
        data.append(`Archivo[${index}]`, element.file, element.name);
      });
      let response = await addEstudio_request(data, IdMedico);
      if (response.ok) {
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("success");
        AccionesMensaje.setMessage(`Estudio guardado`);
        AccionesModal.CloseModalAddEstudi(true);
        cleanInputs();
        getHistoria();
      } else {
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("error");
        AccionesMensaje.setMessage(response.mensaje);
      }
    } else {

      if (ImagesList.length === 0) {
        setImagenesListError("false");
      }

      if (!!!DescripcionEstudio) {
        setTextAreaDescripcionEstudio("false");
      }

      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("warning");
      AccionesMensaje.setMessage("Faltan campos por completar");
    }
    setLoaderDetalle(false);
    return;
  }

  async function reenviarEstudio(e) {
    e.preventDefault();
    if (IdMedico !== "") {
      let data = new FormData();
      data.append("IdUsuario", IdMedico)
      let response = await forwardEstudio_request(data);
      if (response.ok) {
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("success");
        AccionesMensaje.setMessage("Reenviado con éxito");
        AccionesModal.CloseModalDetalleEstudi(true);
        cleanInputs();
      } else {
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("error");
        AccionesMensaje.setMessage(response.mensaje);
      }
    } else {
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("warning");
      AccionesMensaje.setMessage("Debe seleccionar un médico");
    }

    return;
  }

  async function getContactos() {
    let response = await getContacts_request();
    if (response.ok) {
      // console.log(response.data);
      let medicos = [];
      response.data.forEach(element => {
        if (element.Type === 1 && element.YgiaUser === "Con Ygia") {

          medicos.push(element);
        }
      });
      setMedicosList(medicos);
      setMedicosListFilter(medicos);
    } else {

    }
  }

  function filterContacts(text) {
    // console.log(text.target.value);
    // console.log(solicitudesSearch);
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];
    // console.log(MedicosList);
    // console.log(MedicosListFilter);
    MedicosListFilter.forEach((contacto) => {
      if (
        contacto.Nombre
          .replace(expr, function (e) { return chars[e]; })
          .toLowerCase().indexOf(text.target.value
            .replace(expr, function (e) { return chars[e]; })
            .toLowerCase()) > -1 ||
        contacto.ApellidoPaterno
          .replace(expr, function (e) { return chars[e]; })
          .toLowerCase().indexOf(text.target.value
            .replace(expr, function (e) { return chars[e]; })
            .toLowerCase()) > -1 ||
        contacto.ApellidoMaterno
          .replace(expr, function (e) { return chars[e]; })
          .toLowerCase().indexOf(text.target.value
            .replace(expr, function (e) { return chars[e]; })
            .toLowerCase()) > -1
      ) {
        filter.push(contacto);
      }
    });

    setMedicosList(filter);
  }

  function cleanInputs() {
    setDescripcionEstudio("");
    setImagesList([]);
    setComboAddTipoEstudio("true");
    setTextAreaDescripcionEstudio("true");
    setImagenesListError("true");
    setinputsEditable(true);
    setIdMedico(null);
  }

  useEffect(() => {
    getContactos();
  }, [])

  return (
    <>
      {/* {loader ? (<Loader />) : (
        <EstudioTabla estudios={ListaEstudios} estudiosFilter={ListaEstudiosFilter} detailEstudio={DetailEstudios} OpenModalAddEstudi={OpenModalAddEstudi} setinputsEditable={setinputsEditable} />
      )} */}

      <EstudiosCard
        EstudiosList={Estudios.slice().sort((a, b) => new Date(`${b.fecha.substring(6, 10)}-${b.fecha.substring(3, 5)}-${b.fecha.substring(0, 2)}`) - new Date(`${a.fecha.substring(6, 10)}-${a.fecha.substring(3, 5)}-${a.fecha.substring(0, 2)}`))}
        DetailEstudios={DetailEstudios}

      />
      {Estudios.length === 0 && (
        <Empty
          description={
            <span style={{ color: "black" }}>Sin estudios</span>
          }
        />

      )}

      <ModalDetalleEstud
        isOpen={AccionesModal.isOpenModalDetalleEstudi}
        closeModal={AccionesModal.CloseModalDetalleEstudi}
        styleDet={styleDet}
      >
        <div className="TopModalNewEstud">
          Detalle de estudio de laboratorio
          <CloseIcon style={{ fontSize: "20px", cursor: "pointer", }} onClick={() => {
            AccionesModal.CloseModalDetalleEstudi(true);
            setDetailEstudio("");
            setTipoEstudioAnalisis("");
            setImagesListDetalle([]);
          }} />
        </div>
        {loaderDetalle ? (<LoaderModals className="MidModalEstud" />) : (
          <div className="MidModalEstud">
            <div style={{ display: "flex", alignItems: "center" }}>
              {(imagenesListError === "false" || textAreaAddEstudio === "false") && <><CancelRounded color="error" />&nbsp;</>}<LeyendaError style={{ fontSize: "14px" }} valido={imagenesListError === "false" || textAreaAddEstudio === "false" ? "false" : "true"}>Debe adjuntar un archivo o escribir una descripción</LeyendaError>
            </div>
            {/* <TextAreaAntd

              placeholder="Descripción"

              style={{
                margin: "2% 0",
                fontFamily: "sans-serif",
              }}
              columns="30"
              autoSize={true}
              value={!inputsEditable ? DescripcionEstudio : DetailEstudio.Descripcion}
              setStateValue={setDescripcionEstudio}
              vacio={true}
              readOnly={inputsEditable}
            /> */}

            <Campos
              label="Descripción:"
              children={
                <TextArea
                  placeholder="Descripción"

                  style={{
                    margin: "2% 0",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  value={!!DetailEstudio ? DetailEstudio.Descripcion : ""}
                  readOnly={true}
                />
              }
            />
            {ImagesListDetalle.map((imagen, index) => (
              <div key={index}>
                <div
                >
                  {imagen.Multimedia.substring(imagen.Multimedia.toString().length, imagen.Multimedia.toString().length - 3) === "jpeg" || imagen.Multimedia.substring(imagen.Multimedia.toString().length, imagen.Multimedia.toString().length - 3) === "png" ||
                    imagen.Multimedia.substring(imagen.Multimedia.toString().length, imagen.Multimedia.toString().length - 3) === "jpg" ?
                    (
                      <Row className="ListaDetalleEsAna">
                        <Image style={{ width: "50px" }} src={"https://" + imagen.Multimedia} alt="" />
                        <div style={{ width: "60%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                          <span>{DatosContacto.nombre + "_" + TipoEstudioAnalisis + "_" + DetailEstudio.Fecha + "_" + index + 1}</span>
                        </div>
                        <div>

                        </div>
                      </Row>
                    ) : imagen.Multimedia.substring(imagen.Multimedia.toString().length, imagen.Multimedia.toString().length - 3) === "pdf" ?
                      (
                        <Row className="ListaDetalleEsAna" style={{ cursor: "pointer" }} onClick={() => { window.open("https://" + imagen.Multimedia, "_blank") }}>
                          <img style={{ width: "7%", marginRight: "3px" }} src={Iconos[22]} />
                          <div style={{ width: "60%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            <span>{DatosContacto.nombre + "_" + TipoEstudioAnalisis + "_" + DetailEstudio.Fecha + "_" + index + 1}</span>
                          </div>
                          <div>

                          </div>
                        </Row>
                      ) :
                      (
                        <Row className="ListaDetalleEsAna" style={{ cursor: "pointer" }} onClick={() => { window.open(imagen.Multimedia, "_blank") }}>
                          <OndemandVideo sx={{ color: "#148F9F" }} />
                          <div style={{ width: "60%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            <span>{DatosContacto.nombre + "_" + TipoEstudioAnalisis + "_" + DetailEstudio.Fecha + "_" + index + 1}</span>
                          </div>
                        </Row>
                      )
                  }
                </div>
                <br />
              </div>
            ))}
            {(data.Tipo === 1 && (<div>
              <SelectAntd
                label="Enviar a:"
                value={
                  !!DatosMedico.id
                    ? {
                      value: "",
                      label: (
                        <div style={{ display: "flex", gap: "1" }}>
                          <img
                            className="ImgMedicoSelect"
                            src={
                              !!DatosMedico.imagen
                                ? `https://${DatosMedico.imagen}`
                                : ""
                            }
                          />{" "}
                          <div style={{ marginLeft: "1%" }}>
                            {DatosMedico.nombre}
                          </div>
                        </div>
                      ),
                    }
                    : null
                }
                style={{
                  width: "100%",
                  margin: "1% 0",
                }}
                placeholder="Selecciona un médico"
                onClick={() => {
                  AccionesModal.CloseModalDetalleEstudi(true);
                  OpenModalMedicos(true);
                }}
                setStateError={setMensajeDoctorSelect}
                valueError={MensajeDoctorSelect}
                textError="Debe seleccionar un médico"
                readOnly={true}
                open={false}
                vacio={false}
              />
              <b style={{ fontWeight: "500", color: "gray" }}>
                {" "}
                Nota: Seleccionar, solo si se desea compartir con otro médico
              </b>
              <div className="Filtro3Botones">
                <Button
                  // className="GeneralBoton" style={{widows:"95%"}}
                  type="button"
                  id="boton_reenviar"
                  onClick={reenviarEstudio}
                >
                  Reenviar
                </Button>
              </div>
            </div>))}
          </div>
        )}
      </ModalDetalleEstud>

      <ModalAddEstud
        isOpen={AccionesModal.isOpenModalAddEstudi}
        closeModal={AccionesModal.CloseModalAddEstudi}
        styleDet={styleDet}
      >
        <div className="TopModalNewEstud">
          Nuevo estudio de laboratorio
          <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => {
            AccionesModal.CloseModalAddEstudi(true);
            cleanInputs();
          }} />

        </div>
        {loaderDetalle ? (<LoaderModals className={"MidModalEstud"} />) : (
          <div className="MidModalEstud">
            <div style={{ display: "flex", alignItems: "center" }}>
              {(imagenesListError === "false" || textAreaAddEstudio === "false") && <><CancelRounded color="error" />&nbsp;</>}<LeyendaError style={{ fontSize: "14px" }} valido={imagenesListError === "false" || textAreaAddEstudio === "false" ? "false" : "true"}>Debe adjuntar un archivo o escribir una descripción</LeyendaError>
            </div>
            <Checkbox
              defaultChecked
              value={EnviarLabTest}
              onChange={(e) => setEnviarLabTest(e.checked)}
              style={{ marginBottom: "2%" }}
            >Enviar a paciente</Checkbox>
            <Campos
              label="Descripción:"
              children={
                <TextArea
                  placeholder="Descripción"

                  style={{
                    margin: "2% 0",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  value={DescripcionEstudio}
                  onChange={(e) => {
                    setDescripcionEstudio(e.target.value);
                    if (e.target.value.length > 0) {
                      setTextAreaDescripcionEstudio("true");
                      setImagenesListError("true");
                    }

                  }}
                />
              }
            />
            <div style={{ textAlign: "center" }}>
              <InputFile valueArrayImg={ImagesList} arrayImg={setImagesList} formatos={5} extras={[AccionesMensaje.setOpen, AccionesMensaje.setTypeMessage, AccionesMensaje.setMessage, (temp) => {
                // console.log(temp.length);
                if (temp.length > 0) {
                  setTextAreaDescripcionEstudio("true");
                  setImagenesListError("true");
                }
              }]} />
              {/* <LeyendaError valido={imagenesListError === "false" || ? "false" : "true"}>Debe agregar un archivo</LeyendaError> */}
            </div>
            <div>
              <p style={{ fontWeight: "500", padding: "0", margin: "0" }}>
                Enviar a:
              </p>
              <SelectMedicos lista={MedicosList} acciones={[1, setIdMedico]} />
              <b style={{ fontWeight: "500", color: "gray" }}>
                {" "}
                Nota: Seleccionar, solo si se desea compartir con otro médico
              </b>
              <div className="Filtro3Botones">
                <Button
                  type="button"
                  id="boton_guardar"
                  onClick={addLabTest}
                >
                  Guardar
                </Button>
              </div>

            </div>
          </div>
        )}
      </ModalAddEstud>

      <Modal styleDet={styleDet} optionClose={true} isOpen={IsOpenModalMedicos} closeModal={ClosedModalMedicos}>
        <div
          style={{
            display: "flex", justifyContent: "space-between", padding: "1% 2%", background: "#148f9f", color: "#fff"
          }}
        >
          <h3>Reenviar estudio</h3> <b onClick={() => {
            ClosedModalMedicos(true);
            AccionesModal.OpenModalDetalleEstudi(true);
          }} style={{ cursor: "pointer" }} >x</b>
        </div>
        <div className="ModalDetalEs" style={{ padding: "2%" }}>
          <p style={{ marginBottom: "1.5%" }}>Nombre:</p>
          <Input
            placeholder="Buscar..."
            type="search"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={filterContacts}
          />

          <div
            className="contCardModal"
            style={{ borderTop: "1px solid #d5d5d5" }}
          >
            <ModalMedicos
              contactList={MedicosList}
              closeModal={ClosedModalMedicos}
              openModal={AccionesModal.OpenModalDetalleEstudi}
              setDatosMedico={setDatosMedico}
              mensajeError={setMensajeDoctorSelect}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EstudiosExpediente;
