import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, onSnapshot, query, where, collection, getDocs } from "firebase/firestore";
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateDeviceToken_request } from "./services/login_services";
import { useDispatch } from "react-redux";
import { badges } from "./redux/badges";
import { notifications } from "./redux/notifications";
import { firebaseConfig, fireB_User, fireB_Pass, fireB_VapidKey } from "./services/apiUrl";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

const signIn = async () => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, fireB_User, fireB_Pass);
    // Usuario autenticado correctamente
    // console.log('Usuario autenticado:', userCredential.user);
    // console.log('Usuario autenticado');
  } catch (error) {
    // console.error('Error al autenticar');
  }
};
signIn();
// const db = getDatabase();

export const getTokenFirebase = () => {
  return getToken(messaging, { vapidKey: fireB_VapidKey })
    .then((currentToken) => {
      if (currentToken) {
        // if (document.domain === "localhost") {
        //   console.log('Client Token: ', currentToken);
          
        // }
        localStorage.setItem("firebaseToken", currentToken);
        let data = new FormData();
        data.append("device_token", currentToken);
        updateDeviceToken_request(data);
      } else {

        // console.log('Failed to generate the registration token.');
      }
    })
    .catch((err) => {
      // console.log('An error occurred when requesting to receive the token.', err);
    });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log(payload);
      resolve(payload);
    });
  });


export const inactiveNotifications = () => {
  return deleteToken(messaging);
}

export function getBadgeNotificationsFirestore(dispatch, idUser) {
  // const notificationsCol = collection(db, 'notifications');
  // const notiDoc = query(doc(db, "notifications/lMs2iYInxnRq5dlfEr1E"), where("", "==", "CA"));
  // const notiDoc = doc(db, "notifications/lMs2iYInxnRq5dlfEr1E");
  const notiDoc = doc(db, `notificationBadges/${idUser}`);
  // const notiDoc = db.collection("notificationBadges").doc(idUser);

  const unsub = onSnapshot(notiDoc, (snapshot) => {
    let menuCount = 0;
    // snapshot.docs.map(doc => doc.data());
    // console.log("BADGE NOTIFICACIONES");
    // console.log(snapshot.data());
    // console.log("############################");
    let data = snapshot.data();
    if (data !== undefined) {

      if (data.citas !== undefined) {
        dispatch(badges({ modulo: "citas", numero: data.citas }));
      } else {
        dispatch(badges({ modulo: "citas", numero: 0 }));
      }
      if (data.chat !== undefined) {
        dispatch(badges({ modulo: "chat", numero: data.chat }));
      } else {
        dispatch(badges({ modulo: "chat", numero: 0 }));
      }
      if (data.vinculacion !== undefined) {
        dispatch(badges({ modulo: "vinculacion", numero: data.vinculacion }));
        menuCount += data.vinculacion;
      } else {
        dispatch(badges({ modulo: "vinculacion", numero: 0 }));
      }
      if (data.estudios !== undefined) {
        dispatch(badges({ modulo: "estudios", numero: data.estudios }));
        menuCount += data.estudios;
      } else {
        dispatch(badges({ modulo: "estudios", numero: 0 }));
      }

      dispatch(badges({ modulo: "menu", numero: menuCount }));

    }
    // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
    // console.log(source, " data: ", doc.data());
  });
}

export function getNotificationsFirestore(dispatch, idUser) {
  // const notificationsCol = collection(db, 'notifications');
  // const notiDoc = query(doc(db, "notifications"), where("contactUserId", "==", `${idUser}`));

  const notiDoc = query(collection(db, "notifications"), where("contactUserId", "==", `${idUser}`), where("module", "!=", "chat"));
  // const notiDoc = collection(db, "notificationsBadges");
  // const notiDoc = doc(db, `notifications/${idUser}`);
  const unsub = onSnapshot(notiDoc, (snapshot) => {
    let count = 0;

    dispatch(badges({ modulo: "general", numero: count }));
    // snapshot.docs.map(doc => doc.data());
    // console.log("NOTIFICACIONES GENERALES");
    // console.log(snapshot);
    let array = [];
    snapshot.forEach((doc) => {
      array.push({
        id: doc.id,
        channel: doc.data().channel,
        contactId: doc.data().contactId,
        contactUserId: doc.data().contactUserId,
        // createdTimeUtc: doc.data().createdTimeUtc,
        createdTimeUtc: new Date(doc.data().createdTimeUtc).toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',

        }),

        fecha: new Date(doc.data().createdTimeUtc).toLocaleDateString('es-ES', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',

        }),

        module: doc.data().module,
        notificationTitle: doc.data().notificationTitle,
        ref: doc.data().ref,
        status: doc.data().status,
        subjectId: doc.data().subjectId,
        type: doc.data().type,
        userId: doc.data().userId,
        userImg: doc.data().userImg,
        userName: doc.data().userName,
        values: doc.data().values,
      })
      if (doc.data().status === 0) {
        count++;
      }
      // console.log(doc.id);
      // console.log(doc.data().channel);
      // console.log(doc.data().contactUserId);
    });
    // console.log(array);
    // dispatch(notifications({ notificationsList: array }));
    dispatch(notifications({ notificationsList: array }));
    dispatch(badges({ modulo: "general", numero: count }));
    // console.log("############################");
    // let data = snapshot.data();
    // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
    // console.log(source, " data: ", doc.data());
  });
}

// export const inactiveNotifications = (token) => {
//   messaging.unsubscribeFromTopic(token)
//     .then((response) => {
//       // See the MessagingTopicManagementResponse reference documentation
//       // for the contents of response.
//       console.log('Successfully unsubscribed from topic:', response);
//     })
//     .catch((error) => {
//       console.log('Error unsubscribing from topic:', error);
//     });
// }
