import React from 'react'
import Iconos from "../IconosDeSitio";
import "../comp-styles/EstudiosCard.scss"

export default function EstudiosCard({ OpenDetalleEstud, EstudiosList, DetailEstudios }) {

  return (
    EstudiosList.map((estudio) => (
      <div onClick={() => {
        OpenDetalleEstud(true);
        // TipoDetalleEstudios(1);
        DetailEstudios(estudio.IdEstudio);
      }}
        className='ContenedorCardEstud'
        id={estudio.IdEstudio}
      >
        <img className='ImgEstudiosCard' src={!!estudio.Imagen_url ? "https://" + estudio.Imagen_url : Iconos[0]} alt="" />
        <div>
          <b>{estudio.Nombre}</b>
          <p>{estudio.Fecha}</p>
          {/* <b style={{ color: "rgb(194, 59, 10)" }}>{!!estudio.Tipo && estudio.Tipo !== "null" ? estudio.Tipo : "Estudio"}</b> */}
        </div>
      </div>
    ))
  )
}
