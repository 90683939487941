import React from "react";
import "../comp-styles/VinculacionCard.scss";

// Importando json vinnculacionCard.js
 import Vinculajson from "../GeneralComponents/General-Json";

function VinculacionCard({solicitudes, detalleSolicitud, OpenDetalleVinc}) {
  // console.log("SOLICITUDES");
  // console.log(solicitudes.solicitudes);
  // let listas = solicitudes;
  // console.log(solicitudes.solicitudes[0].Imagen_Url);


  // console.log(Vinculajson);

  return (
    <>
      {solicitudes.map((lista) => (
        <div key={lista.IdSolicitud} id="solicitudCard" onClick={()=>{detalleSolicitud(lista.IdSolicitud); OpenDetalleVinc(true);}}>
          <div className="CardVinculacion" style={{cursor:"pointer"}}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width:"90%" , display: "flex", alignItems: "center", gap:"1%" }}>
                <div>
                  <img className="imgVinculCard" src={"https://"+lista.Imagen_Url} alt="" />
                </div>
                <div className="UsuVinc">
                  <b style={{textOverflow:"ellipsis", overflow:"hidden", width:"90%"}}>{lista.Usuario}</b>
                  <p>{lista.Tipo}</p>
                </div>
              </div>
              {/* <div>
                <b>{lista.Estatus}</b>
              </div> */}
              <div>
                <p>{lista.Estado}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default VinculacionCard;
