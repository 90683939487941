import { Row } from "antd";
import React from "react";
import { useState } from "react";
import Adjuntar from "../../Img/Iconos/AdjuntarArchivo.svg";
import Eliminar from "../../Img/Iconos/eliminar.svg";

export default function InputFile({ texto, setImagen, archivosAdmitidos, extras, imagen }) {
  // Variable para identificar los tipos de archivos admitidos "archivosAdmitidos"
  // 0 - ambos imagenes y pdf
  // 1 - solo imagenes
  // 2 - solo pdf
  // console.log(imagen);
  // subir Archivos lista
  const [OpenArchivo, setOpenArchivo] = useState(imagen !== undefined && imagen.url !== "" ? 1 : 0);
  const [inputValue, setInputValue] = useState('');
  const [Archivo, setArchivo] = useState({ name: "", url: imagen !== undefined && imagen.url !== "" ? imagen.url : "", file: "" });

  return (
    <div>
      <label htmlFor="FileArchivo" style={{ cursor: "pointer" }}>
        <img style={{ width: "16px" }} src={Adjuntar} alt="" />
        <b>{texto}</b>
        <input
          value={inputValue}
          id="FileArchivo"
          type="file"
          accept={archivosAdmitidos === 0 ? "/image/x-PNG,image/jpeg,application/pdf" : archivosAdmitidos === 1 ? "/image/x-PNG,image/jpeg" : "application/pdf"}
          onChange={(e) => {
            const file = e.target.files[0];

            if (archivosAdmitidos === 0) {
              if (file && file.type.substring(0, 5) === "image") {
                setArchivo({ nombre: e.target.value, url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
                setImagen({ nombre: e.target.value, url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
                setInputValue(e.target.value);
                setOpenArchivo(1);
              } else {
                if (extras !== undefined) {
                  if (extras[0] === 1) {
                    extras[1](true);
                    extras[2]("warning");
                    extras[3]("Formato no válido (solo imagenes)");
                  }

                }
              }
            } else if (archivosAdmitidos === 1) {
              if (file && file.type.substring(0, 5) === "image") {
                setArchivo({ name: e.target.value, url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
                setImagen({ name: e.target.value, url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
                setInputValue(e.target.value);
                setOpenArchivo(1);
                if (extras !== undefined) {
                  extras[4]("true");
                }
              } else {
                if (extras !== undefined) {
                  extras[1](true);
                  extras[2]("warning");
                  extras[3]("Formato no válido (solo imagenes)");
                }
              }
            }


          }}
          style={{ display: "none" }}
        />
      </label>
      {OpenArchivo === 0 ? (
        <div>
          <br />
          <p>sin imagen adjuntada</p>
        </div>
      ) : (
        <div>
          {OpenArchivo === 1 &&
            (
              <Row style={{ justifyContent: "center" }}>

                <img style={{ width: "30%", cursor: "pointer" }} src={Archivo.url} alt="" onClick={() => { window.open(Archivo.url, "_blank") }} />

                {/* <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                  <span>{Archivo.name}</span>
                </div> */}
                <img
                  style={{ width: "4%", cursor: "pointer" }}
                  onClick={() => {
                    setArchivo({ name: "", url: "", file: "" });
                    setImagen({ name: "", url: "", file: "" });
                    setInputValue("");
                    setOpenArchivo(0);
                    if (extras !== undefined) {
                      extras[4]("false");
                    }
                  }}
                  src={Eliminar}
                  alt=""
                />
              </Row>
            )
          }


        </div>
      )}
    </div>
  );
}