import React, { useState } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Weekday = styled.div`
  flex: 1;
  margin: 5px;
  padding: 10px;
  background: lightblue;
  width: 100px;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

function Agenda() {
    const [currentDate, setCurrentDate] = useState(dayjs());

    const startOfWeek = currentDate.startOf('week');
    const endOfWeek = currentDate.endOf('week');

    const daysArray = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
        daysArray.push(day.toDate());
        day = day.add(1, 'day');
    }

    const handlePrevWeek = () => {
        setCurrentDate(currentDate.subtract(1, 'week'));
    };

    const handleNextWeek = () => {
        setCurrentDate(currentDate.add(1, 'week'));
    };

    return (
        <Container>
            <Header>
                <Button onClick={handlePrevWeek}>Previous</Button>
                <Button onClick={handleNextWeek}>Next</Button>
            </Header>
            {daysArray.map((date, index) => (
                <Weekday key={index}>
                    {dayjs(date).format('dddd, MMM D')}
                </Weekday>
            ))}
        </Container>
    );
}

export default Agenda;