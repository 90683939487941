import { createSlice } from "@reduxjs/toolkit";

const initialState = { modulo: "", detalle: null, searchBar: null, consulta: null };

export const navBarPropsSlice = createSlice({
    name: "navBarProps",
    initialState,
    reducers: {
        navBarProps: (state, action) => {
            action.type = "";
            const { modulo, detalle, searchBar, consulta } = action.payload;
            state.modulo = modulo;
            state.detalle = detalle;
            state.searchBar = searchBar;
            state.consulta = consulta;
        },
    }
})


export const { navBarProps } = navBarPropsSlice.actions;

export default navBarPropsSlice.reducer;