import axios from "axios"
import baseUrl, { baseUrlPrueba } from "./apiUrl";

export async function getTarjetas_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/RecuperarTarjeta",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addTarjeta_request(tarjetaData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrlPrueba + "/CrearTarjeta",
            method: "POST",
            data: tarjetaData,
            headers:
            {

                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("ADD TARJETA");
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log("ERROR REGISTRO "+error.response);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function deleteTarjeta_request(idTarjeta) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/EliminarTarjeta/" + idTarjeta,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getClabeInterbancaria_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/ObtenerCuentaBancaria",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addClabeInterbancaria_request(tarjetaData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CrearCuenta",
            method: "POST",
            data: tarjetaData,
            headers:
            {

                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("ADD CUENTA");
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        console.log(error);
        // console.log("ERROR REGISTRO "+error.response);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function defaultCard_request(idTarjeta) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: `${baseUrl}/TarjetaPredeterminada/${idTarjeta}`,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

