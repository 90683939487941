import React from "react";

function Terminos() {
  return (
    <div style={{ textAlign: "justify" }}>
      <p>
        Aceptación de los términos y condiciones Los presentes Términos y Condiciones
        de Servicios regulan el uso y acceso a la Plataforma Ygia, incluyendo el
        sitio web Ygia, el Software Ygia y cualquier otra extensión de la Plataforma,
        así como los servicios que se suministran a través de la misma. Al aceptar
        estos términos y condiciones, el usuario (en adelante “el Usuario”) se obliga
        a regirse por los mismos y acepta estar legalmente vinculado por ellos.
        En caso de no estar de acuerdo con los presentes términos y condiciones,
        el Usuario deberá abstenerse de usar y registrarse a la Plataforma.
      </p>
      <br />
      <b>Definiciones</b>
      <br />
      <p>
        Los Servicios: Significa los de administración de información ofrecidos a través
        del sitio de Internet www.ygia.com.mx y consisten -sin limitación- en la
        autorización al Usuario de acceso y utilización de herramientas electrónicas
        de búsqueda y consulta de información relacionada con los datos clínicos y/o
        documentación médica de los pacientes del Usuario, para la consulta y uso de
        estos en relación con el desempeño de la atención médica que el Usuario proporciona
        a dichos pacientes. Usuario: Significa la persona física que se haya registrado a
        la Plataforma, luego de aceptar los Términos y Condiciones de Uso y haber aceptado
        el Aviso de Privacidad. Al efecto sólo podrán tener el carácter de usuario las
        personas mayores de edad, en pleno ejercicio de sus derechos y que cuenten con
        las credenciales correspondientes para ejercer como Médicos o Asistentes Médicos.
        Los tipos de usuarios son los siguientes: i) Médico Usuario y ii) Asistente de Médico Usuario.
        Paciente: Significa la persona no Usuario de los Servicios que se encuentra bajo la atención
        médica del Usuario de la Plataforma y que ha aceptado el Aviso de Privacidad que al
        efecto se le ha puesto a disposición.
      </p>
      <br />
      <p>
        Plataforma: Significa el sitio de internet www.ygia.com.mx desarrollado por Ygia S.A.P.I. de C.V. que
        incluye el Software, el sitio web, las aplicaciones y cualquier otro programa que sirva como extensión
        de la misma. Expediente Clínico: Significa la recopilación de datos clínicos del paciente compilados
        utilizada a través de la Plataforma, misma que cumple con la NOM-004-SSA-2012.
      </p>
      <br />
      <p>
        Contraseña: Significa la clave secreta que será utilizada por cada Usuario para efectos de registrarse y acceder a la plataforma.
      </p>
      <br />
      <p>
        Las Partes: Grupo Ygia Medical S.C. y el Usuario. Uso de la Plataforma Grupo Ygia Medical S.C. pone a
        disposición del Usuario el Software denominado Ygia a través del sitio web www.Ygia.com.mx y para su descarga
        a través de la aplicación móvil misma que se encuentra disponible para los sistemas operativos IOS y Android.
        Las Partes convienen en que a partir de la fecha en que el Usuario se registre a la Plataforma ya sea en su
        versión web o móvil, éste podrá hacer uso del mismo.
      </p>
      <br />
      <p>
        Al ingresar a la Plataforma a través del sitio web
      </p>
      <br />
      <p>
        4. La Plataforma Ygia es una plataforma virtual que tiene por objeto permitir a los usuarios el acceso a los
        servicios de salud proporcionados por los profesionales de la salud que se encuentran registrados en la plataforma.
        La plataforma proporciona diferentes planes de servicios a los usuarios, que se describen en la sección 5 de estos Términos.
      </p>
      <br />
      <p>Uso de la Plataforma</p>
      <br />
      <p>
        5 . El Usuario deberá sujetarse a las limitaciones y condiciones establecidas de los presentes términos y no podrá, en ningún caso:
        a. Obtener cualquier beneficio económico a través de la difusión, enajenación o transmisión material o electrónica de la Plataforma Ygia;
        b. Utilizar Plataforma Ygia para fines diversos de los expresamente autorizados;
        c. Redistribuir total o parcialmente el Software, y cualquier contenido asociado a éste;
        d. Realizar trabajos de ingeniería inversa, descompilar o desensamblar el Software o intentar, por otros medios, obtener su código fuente;
        e. Modificar en forma alguna los códigos originales, ejecutables, documentos y recursos de información, así como realizar cualquier acción o atentado en contra de los mismos;
        f. Permitir o fomentar a cualquier tercero a realizar alguna de las actividades anteriormente descritas;
        g. Involucrarse en actividades que interfieran o interrumpan los servicios de la Plataforma y/o del Software;
        h. Eludir o tratar de eliminar las medidas de protección tecnológica que se hayan implementado para la protección de la Plataforma;
        i. Utilizar los datos personales de terceros y la información que se encuentra en la Plataforma para fines de lucro y/o uso ilegal de dicha información.
        j. Transmitir a través de la Plataforma cualquier virus o mecanismo tendiente a afectar negativamente el uso de esta.
        Al momento en que los Usuarios suben documentación relativa a su historial profesional y académico, la Plataforma se encarga de cotejar
        que dicha información que acredita sus credenciales, coincida con la información pública establecida en la base de datos que a su efecto
        el gobierno emita. Sin embargo, Ygia no se hace responsable de la falsedad o legitimidad de los documentos subidos a la Plataforma por
        los Usuarios, incluidos su INE, título profesional, cédula profesional, cédula de especialidad.
      </p>
      <br />
      <p>6. Limitación de Responsabilidad</p>
      <br />
      <p>
        Ygia no será responsable por daños de cualquier naturaleza que se deriven del uso de su Plataforma o de la incapacidad para usarlo,
        ni de aquellos que se generen por la pérdida o robo del Software, aun cuando Ygia haya sido informado sobre la posibilidad de dichos
        daños. Además, Ygia no asume responsabilidad alguna por la descarga, instalación y uso de su Plataforma, así como del deterioro,
        uso indebido o ilícito y pérdida de información derivada de su uso.
        Ygia no es responsable de cualquier incompatibilidad con el Software y otros sitios de Internet, servicios, Softwares, hardwares,
        o cualquier retraso o falla que se pueda producir al iniciar, realizar o llevar a cabo cualquier transmisión con el dispositivo
        electrónico. La información contenida en la Plataforma se presenta con la mayor oportunidad posible y con base en la información
        que su vez le es proporcionada por terceros, por lo que eventualmente podría haber discrepancias con la información definitiva,
        razón por la cual el Usuario está consciente y acepta que Ygia no asume responsabilidad alguna por tales discrepancias.
        Adicionalmente, con la aceptación de los presentes, el Usuario libera a Ygia de toda responsabilidad legal o de cualquier otra
        índole, por la precisión, oportunidad, contenido o uso que terceros den a la información dada a conocer a través de la Plataforma.
        Ygia manifiesta que:
        I. La Plataforma a través de la cual presta los servicios no es una empresa promotora de salud o institución sanitaria, por lo que
        únicamente funge como intermediaria y es una herramienta para los médicos, que incluyen de manera enunciativa más no limitativa,
        la agenda de citas y el mantenimiento de la historia clínica.
        II. La Plataforma no tiene responsabilidad sobre la veracidad o falsedad de los documentos que los médicos hayan ingresado a la
        plataforma según las leyes mexicanas aplicables.
        III. La Plataforma no tiene injerencia o asesora al Paciente en la elección de los médicos.
        IV. La Plataforma no tiene responsabilidad alguna respecto de las opiniones, diagnósticos o recomendaciones que emita el médico a
        través de la misma.
        Actualizaciones al Software Ygia
        Ygia puede realizar de forma automática las modificaciones al sistema que considere necesarias para la mejora, actualización y
        desarrollo en mayor grado del Software, mismas que el usuario apreciará de forma automática en la plataforma, ya sea en el sitio
        web o en la aplicación móvil. Asimismo puede eliminar, en cualquier momento y sin previo aviso, la funcionalidad del Software por
        este motivo sin responsabilidad alguna a su cargo.
        Al utilizar la plataforma de medicina virtual proporcionada por Ygia, usted acepta estos términos y condiciones en su totalidad.
        Si no está de acuerdo con estos términos y condiciones, su único y exclusivo recurso consiste en dejar de utilizar la Plata
      </p>
      <br />
      <p>
        7. Ygia ofrece una prueba gratis para permitir a los nuevos miembros probar los servicios que se especifican en los presentes términos y
        condiciones.
      </p>
      <br />
      <p>
        8. La duración del periodo de prueba gratis se especifica en el punto 2 de los presentes términos y condiciones y puede ser limitado por
        Ygia para evitar su abuso.
      </p>
      <br />
      <p>
        9. Ygia se reserva el derecho de revocar la prueba gratuita y suspender la cuenta de cualquier Usuario que no cumpla con los requisitos
        para ser un médico válido para utilizar la plataforma.
      </p>
      <br />
      <p>
        10. Para hacer uso del servicio de la plataforma Ygia, el Usuario debe cumplir con los requisitos técnicos mencionados en el sitio web y contratar
        alguno de los planes a través de una forma de pago válida mencionada en el apartado de “Pagos” en la Plataforma.
      </p>
      <br />
      <p>
        11. Una vez contratado alguno de los planes, el Usuario entrará a un servicio de suscripción de renovación automática mensual o anual, según sea el caso
        del plan contratado. La suscripción continuará hasta que el Usuario decida cancelarla.
      </p>
      <br />
      <p>
        12. El Usuario autoriza a Ygia a cobrarle la suscripción del siguiente ciclo de facturación a su forma de pago establecida si no realiza el proceso de
        cancelación antes de la fecha de corte mencionada en el apartado de “Pagos”.
      </p>
      <br />
      <p>
        13. Ygia se reserva el derecho de suspender el acceso al servicio si el pago no pudiera ejecutarse de manera exitosa debido a la fecha de vencimiento,
        la falta de fondos o si el Usuario no actualiza la información de su forma de pago.
      </p>
      <br />
      <p>
        14. El Usuario puede visualizar la próxima fecha de pago dirigiéndose a “Planes y Pagos” dentro de su cuenta en la Plataforma Ygia y seleccionando
        la opción “Mis pagos”.
      </p>
      <br />
      <p>
        15. Ygia se reserva el derecho de cambiar los planes de suscripción, fechas de corte, fechas de pago y el precio de los servicios. Cualquier
        modificación será notificada con anterioridad para que el Usuario decida si desea permanecer con la suscripción al mismo plan, cambiarlo de plan o
        cancelar la suscripción.
      </p>
      <br />
      <p>
        16. La factura se realizará de forma automática una vez recibido el pago, teniendo como fecha límite el último día del mes en que se realizó el pago.
        El Usuario debe capturar su información fiscal en el apartado de “Facturación” en la Plataforma para poder facturar.
      </p>
      <br />
      <p>
        17 .Un cambio de Plan Ygia se puede realizar en cualquier momento tomando en cuenta las siguientes consideraciones:
        a) Para cambios entre los planes mensuales, los pagos no son reembolsables y no se otorgarán devoluciones ni créditos por los días de suscripción
        pendientes por utilizar en el mes.
        b) Para cambios entre los planes anuales, los pagos no son reembolsables, sin embargo, se realizará una equivalencia entre el monto no consumido del
        plan anterior y los días de servicio que corresponden a dicho monto en el nuevo Plan contratado.
        c) Para cambios de un plan anual a un plan mensual, se evaluará el costo de los días utilizados hasta el momento y dicho monto se asignará en “días sin costo”
        al nuevo plan.
      </p>
      <br />
      <p>
        18. Condiciones de Facturación y Reembolsos
        Emisión de Facturas 4.0
        La factura fiscal se emitirá siguiendo las normas y lineamientos establecidos por el Servicio de Administración Tributaria (SAT), entregando los archivos PDF y
        XML correspondientes en la versión 4.0.
        Los datos fiscales del Usuario se proporcionarán en el momento de la contratación del servicio y deben ser los mismos que los expedidos por la constancia de su
        situación fiscal.
        En caso de que el Usuario necesite actualizar sus datos de facturación, deberán cargarse en el sistema de la Plataforma a más tardar el 31 de marzo de 2023. Si
        el Usuario no ha proporcionado sus datos fiscales, el pago se facturará como Venta al Público en General con el RFC genérico establecido por el SAT.
        Los pagos realizados durante el mes se facturarán durante el mes en curso.
        Los pagos realizados el último día hábil del mes en curso se facturarán el primer día hábil del siguiente mes. Esto solo se aplica a los pagos realizados por
        transferencia.
        No se podrán emitir facturas de pagos de meses anteriores, excepto en los pagos realizados el último día hábil del mes inmediato anterior. Esto solo se aplica
        a los pagos realizados por transferencia.
        En las renovaciones anuales, el Usuario recibirá un correo electrónico notificando el mes de vencimiento de su plan para preparar el pago de la suscripción.
      </p>
      <br />
      <p>
        19. Contenido de la factura 4.0
        Las facturas incluirán los siguientes detalles: RFC, Código Postal, régimen del receptor, nombre o razón social (debe validarse con la constancia de situación fiscal),
        atributo para indicar si es objeto o no de impuesto, información del uso del CFDI, que se registrará como “Gastos en General” y el Método de pago (pago en una sola
        exhibición o pago diferido o en parcialidades).
        El complemento de Pagos, que debe ser expedido junto con el CFDI cuando se reciben pagos en parcialidades o de manera diferida, también actualiza su formato con las
        siguientes novedades:
        Agrega el campo para expresar el total de los pagos que se desprenden de los nodos Pago.
        Añade el atributo para indicar si el pago es objeto o no de impuestos.
        Añade un elemento nuevo para los impuestos que incluye el detalle de los Trasladados y Retenidos.
        El complemento de pagos solo se puede usar con el tipo de comprobante ‘P’.
        Se realizan ajustes en las reglas de validación y catálogos.
        El código y concepto se indicará el servicio contratado por el Usuario, alineado al catálogo de servicios establecido por el SAT.
        Recibo electrónico de pago
        El CFDI del recibo electrónico de pago se emitirá cuando se haya recibido el pago de las facturas con el método de “Pago Diferido o en parcialidades”, de acuerdo
        con los lineamientos de la facturación 4.0.
        Envío de la factura
        La factura se enviará al correo proporcionado por el Usuario y tendrá un plazo de 4 dias habiles para cualquier modificación o cancelación
      </p>
      <br />
      <p>
        20. Datos personales
        Para utilizar los servicios de la Plataforma, el usuario deberá proporcionar ciertos datos personales a través del formulario respectivo. La información proporcionada
        se considerará como datos personales y se regirá por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su reglamento. Al llenar el
        formulario, el usuario manifiesta expresamente haber leído el aviso de privacidad de datos personales de la Plataforma y acepta los términos y condiciones de dicho
        aviso. La Compañía no se hace responsable en caso de que los datos personales no sean actualizados por el usuario en un plazo de cinco (5) días hábiles.
      </p>
      <br />
      <p>
        21. Obligaciones del usuario
        El usuario garantiza la veracidad, exactitud, vigencia y autenticidad de los datos personales proporcionados a la Plataforma, y se obliga a mantenerlos actualizados.
        El usuario se compromete a obtener la autorización previa y expresa del titular de los datos considerados como sensibles para que estos sean tratados por el usuario y
        se hace responsable de cualquier incumplimiento o violación de la legislación de protección de datos personales. El usuario libera a la Compañía de cualquier
        responsabilidad legal que pudiera resultar del tratamiento indebido de los datos personales de terceros obtenidos mediante el uso de la Plataforma.
      </p>
      <br />
      <p>
        22. Modificaciones a los términos y condiciones de uso
        La Compañía se reserva el derecho de modificar en cualquier momento los términos y condiciones de uso de la Plataforma. El usuario deberá revisar periódicamente
        el apartado correspondiente en la Plataforma y mantenerse informado respecto de cualquier cambio o modificación. En caso de no estar de acuerdo con cualquier
        modificación a los términos y condiciones de uso, el usuario deberá interrumpir el uso de la Plataforma.
      </p>
      <br />
      <p>
        23. Propiedad intelectual
        La Plataforma y su contenido, incluyendo sin limitación, el software, los textos, las imágenes, los gráficos, los logotipos, los íconos, las fotografías, los videos,
        los audios, los nombres comerciales y las marcas registradas, son propiedad exclusiva de la Compañía o de terceros que hayan autorizado su uso a la Compañía. El
        usuario se compromete a no utilizar, reproducir, distribuir, modificar, transmitir, publicar, exhibir o explotar de ninguna manera la Plataforma o su contenido,
        salvo con la autorización previa y por escrito de la Compañía.
      </p>
      <br />
      <p>
        29 Limitación de responsabilidad
        La Compañía no garantiza que la Plataforma esté libre de errores o que funcione de manera ininterrumpida. El usuario reconoce y acepta que el uso de la plataforma puede ir
        modificándose debido a constante actualización, mejoras y nuevas funcionalidades.
      </p>
    </div>
  );
}

export default Terminos;
