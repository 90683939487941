import Citasaddjson from "../GeneralComponents/General-Json"
import "../comp-styles/ModalCitasAdd.scss"
import Iconos from "../IconosDeSitio"

import React from 'react'

function ModalCitasAdd({ contactList, closeModal, extras }) {
  // console.log(contactos);
  let lista = [];
  return (
    <>
      <div className="GeneralcontainCitasdd" >
        {contactList.map((contacto) => (
          <div className="PrinCardCitasdd" key={contacto.IdContacto} onClick={() => {
            closeModal(false);
            extras[0]({ id: contacto.IdContacto, nombre: `${contacto.Nombre} ${contacto.ApellidoPaterno} ${contacto.ApellidoMaterno}`, imagen: contacto.Imagen_url });
            extras[1](true);
          }}>
            <div className="ContainerCitasdd">
              <div className="CardCitasdd">
                <p className="BgrImgCitasdd">
                  <img
                    id={`img_add_contacto_cita_${contacto.IdContacto}`}
                    src={!!contacto.Imagen_url ? "https://" + contacto.Imagen_url : Iconos[0]}
                    onError={() => {
                      let img = document.getElementById(`img_add_contacto_cita_${contacto.IdContacto}`);
                      img.setAttribute("src", Iconos[0]);
                    }}
                  />
                </p>
                <div style={{ width: "95%" }}>
                  <b>{`${contacto.Nombre} ${contacto.ApellidoPaterno} ${contacto.ApellidoMaterno}`}</b>
                </div>
              </div>
              {/* <div>
                <p onClick={() => {
                  closeModal(false);
                  extras[0]({ id: contacto.IdContacto, nombre: `${contacto.Nombre} ${contacto.ApellidoPaterno} ${contacto.ApellidoMaterno}`, imagen: contacto.Imagen_url });
                  extras[1](true);
                  // saveCita(contacto.IdContacto);
                }}>
                  <img style={{ width: "25px", cursor: "pointer" }} src={Iconos[17]} />
                </p>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default ModalCitasAdd;