import React from 'react'
import { useState } from 'react';
import { Input, Row, Button, Layout, Modal, Empty } from "antd";
import { Stack } from '@mui/material';
import NavbarNuevo from '../componentes/GeneralComponents/Navbar_Nuevo';
import Tutorialcard from '../componentes/GeneralComponents/Tutoriales/Tutorialcard';

function Tutoriales({ videos }) {
  const { Header, Content, Footer } = Layout;
  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");



  return (
    // <Stack>
    //     <NavbarNuevo
    //      mensajesError={{
    //         setOpen: setOpen,
    //         setTypeMessage: setTypeMessage,
    //         setMessage: setMessage,
    //       }}
    //       searchBar={
    //         <div className="Encabezadocontacto">
    //         <div className="espaciado">
    //           <div>
    //             <b>Contactos</b>
    //           </div>
    //         </div>
    //         <Input
    //           className="inputblue"
    //           placeholder="Buscar..."
    //           type="text"
    //           style={{ width: "31%", marginBottom: "10px" }}
    //           // onChange={filterList}
    //         />
    //       </div>
    //       }
    //     />
    //     <Content
    //     className="site-layout"
    //     style={{
    //       padding: '1% 3%',
    //       width: "100%",
    //       overflow: 'initial',
    //     }}>
    //       <div style={{width:"100%"}}>

    //         <Tutorialcard 
    //         videos={videos} 
    //         />

    //       </div>

    //     </Content>
    // </Stack>
    <div className='tutorialesContent'>

      <Tutorialcard
        videos={videos}
      />

    </div>
  )
}

export default Tutoriales