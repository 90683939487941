import axios from "axios"
import baseUrl from "./apiUrl";

export async function getMovimientos_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/Movimientos",
            method: "GET",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta.Movimientos
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        }

    }
}

export async function getMovimientosFilter_request(filter, filterData) {
    let routeFilter = "";
    // console.log(filter);
    switch (filter) {
        case 1:
            routeFilter = "MovimientosHoy";
            break;

        case 2:
            routeFilter = "MovimientosAyer";
            break;

        case 3:
            routeFilter = "MovimientosSemana";
            break;

        case 4:
            routeFilter = "MovimientosPeriodo";
            break;

        default:
            break;
    }
    try {
        let resp;
        // console.log(filterData);
        // console.log(routeFilter);
        const response = await axios({
            url: baseUrl + `/${routeFilter}`,
            method: "POST",
            data: filterData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta.Movimientos
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function detailMovimiento_request(idMovimiento) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/MovimientoDetalles/" + idMovimiento,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta.Movimiento
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addMovimiento_request(movimientoData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/MovimientoAlta",
            method: "POST",
            data: movimientoData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("AGREGAR MOVIMIENTO");
        // console.log(response);
        if (response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR REGISTRO ");
        // console.log(error);
        // console.log(error.response);
        // console.log(error.response.status);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function editMovimiento_request(movimientoData, idMovimiento) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/EditarMovimiento/" + idMovimiento,
            method: "POST",
            data: movimientoData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log("EDITAR MOVIMIENTO");
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        // console.log("ERROR REGISTRO ");
        // console.log(error);
        // console.log(error.response);
        // console.log(error.response.status);
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function cancelMovimiento_request(idMovimiento) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/CancelarMovimiento/" + idMovimiento,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}