import { useEffect, useState } from "react";
import { acceptSolicitud_request, cancelSolicitud_request, solicitudDetail_request } from "../../services/vinculacion_services";
import { showSnackbar } from "../../redux/snackbarSlice";
import { useDispatch } from "react-redux";
import "../../pages/styles/Vinculacion.scss";
import CloseIcon from '@mui/icons-material/Close';
import iconos from "../../componentes/IconosDeSitio";
import ModalDetalle from "../GeneralComponents/ModalDetalle";
import { Button } from "antd";
import LoaderModals from "../GeneralComponents/Loader_modals";
import DynamicLoader from "../GeneralComponents/loaderDynamic";

export default function DetailVinculacion({ setInfoSolicitud, infoSolicitud, isOpen, close, style, optionClose, actions, loader, setLoader }) {
    const dispatch = useDispatch();

    async function acceptSolicitudes() {
        setLoader(true);
        // CloseDetalleVinc(true);
        // return;
        let response = await acceptSolicitud_request(infoSolicitud.id);
        if (response.ok) {
            // console.log(response.data);
            if (actions !== undefined) {
                actions.getSolicitudes();
            }
            dispatch(showSnackbar({
                show: true,
                text: "Solicitud aceptada",
                type: "success",
            }));
            close(true);
            setInfoSolicitud("");
        } else {
            dispatch(showSnackbar({
                show: true,
                text: response.mensaje,
                type: "error",
            }));
        }
        setLoader(false);
        return;
    }

    async function cancelSolicitudes() {
        setLoader(true);
        // CloseDetalleVinc(true);
        // return;
        let response = await cancelSolicitud_request(infoSolicitud.IdSolicitud);
        if (response.ok) {
            // console.log(response.data);
            if (actions !== undefined) {
                actions.getSolicitudes();
            }
            dispatch(showSnackbar({
                show: true,
                text: "Solicitud rechazada",
                type: "success",
            }));
            close(true);
            setInfoSolicitud("");
        } else {
            dispatch(showSnackbar({
                show: true,
                text: response.mensaje,
                type: "error",
            }));
        }
        setLoader(false);
        return;
    }

    return (
        <ModalDetalle
            isOpen={isOpen}
            closeModal={close}
            styleDet={style}
            optionClose={optionClose}
        >
            <div id="VinculacionDetalle" className="VinculacionDetalle">
                <div className="TopDetalleModalVincu" style={{ color: "white" }}>
                    <p style={{ textAlign: "center" }}>
                        Solicitud de vinculación
                    </p>{" "}
                    <CloseIcon style={{ cursor: "pointer", fontSize: 20 }} onClick={() => {
                        setInfoSolicitud("");
                        close(true);
                    }} />


                </div>
                {loader ? (
                    <DynamicLoader classname={"ModalwidthVincu"} />
                ) : (
                    <div className="ModalwidthVincu">

                        {infoSolicitud !== "" && infoSolicitud !== undefined && (
                            <>
                                <div className="imgDetalleVincul">
                                    <img
                                        style={{ textAlign: "center" }}
                                        src={
                                            infoSolicitud.Imagen_Url !== null
                                                ? "https://" + infoSolicitud.Imagen_Url
                                                : iconos[19]
                                        }
                                        alt=""
                                    />
                                    {infoSolicitud.Usuario.length !== 0 ? (
                                        <h3>{infoSolicitud.Usuario}</h3>
                                    ) : (
                                        <h3>Sin datos</h3>
                                    )}

                                    {/*
                                TIPOS DE USUARIO
                                  Tipo 1 = Medico
                                  Tipo 3 = Paciente
                                  Tipo 4 = Asistente
                                  Tipo 5 = Familiar
                                */}
                                </div>
                                <div className="informacion">
                                    <b>Estado de solicitud:</b>&nbsp;
                                    <br />
                                    <span>{infoSolicitud.Estatus}</span>
                                    <hr />
                                    {infoSolicitud.Type === 1 && (
                                        <>
                                            <b>Institución:</b>
                                            <br />
                                            <span></span>
                                            <hr />
                                        </>
                                    )}
                                    <b>Id:</b>&nbsp;
                                    <br />
                                    <span>{infoSolicitud.id}</span>
                                    <hr />
                                    {infoSolicitud.Type === 1 && (
                                        <>
                                            <b>Especialidad:</b>
                                            <br />
                                            <span></span>
                                            <hr />
                                        </>
                                    )}
                                    <b>Celular:</b>
                                    <br />
                                    <span>{infoSolicitud.Celular}</span>
                                    <hr />
                                    <b>Correo:</b>
                                    <br />
                                    <span>{infoSolicitud.Email}</span>
                                    <div className="BtnsVincul">
                                        <Button
                                            // style={{ background: "red", padding: ".5%" }}
                                            type="button"
                                            className="GeneralBotonWhite"
                                            onClick={() => cancelSolicitudes()}
                                        >
                                            {infoSolicitud.Estado !== "Enviado"
                                                ? "Cancelar solicitud"
                                                : "Eliminar solicitud"}
                                        </Button>

                                        {infoSolicitud.Estado !== "Enviado" && (
                                            <Button
                                                className="GeneralBoton"
                                                style={{ background: "#148f9f", color: "white" }}
                                                onClick={() => acceptSolicitudes()}
                                            >
                                                Aceptar solicitud
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                )}
            </div>

        </ModalDetalle >
    );
}