import React from "react";
// importando scss
import "../comp-styles/Contactocard.scss";

// importar iconos
import Iconos from "../IconosDeSitio";
import PositionedMenu from "./menuvertical";


export default function ContactosCard({ openDetalleCont, setOpenDetail, lista, contactDetail, deleteContact, setIdContacto, setInputsEditable, validateInputs }) {
  // console.log("cards");
  return (
    lista.map((contacto) => (
      <div className="ContenedorPrincipalCard">
        <div
          className="TarjetaPincipal"
          onClick={() => {
            contactDetail(contacto.IdContacto, 1);
            openDetalleCont(true);
          }}
        >
          <div className="ContenedorContacto">
            <div className="Cardcontactos">
              <div className="BgrImg">
                <img id={`img_contacto_card${contacto.IdContacto}`} src={contacto.Imagen_url !== null ? "https://" + contacto.Imagen_url : Iconos[0]} alt=""
                  onError={() => {
                    let img = document.getElementById(`img_contacto_card${contacto.IdContacto}`);
                    img.setAttribute("src", Iconos[0]);
                  }}
                />
              </div>
              <div>
                <b>{contacto.Nombre + " " + contacto.ApellidoPaterno + " " + contacto.ApellidoMaterno}</b>
                <div>
                  <span>
                    <b style={{ color: "#148f9f" }}>{contacto.Tipo}&nbsp;</b>
                    {contacto.YgiaUser}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <StyledEngineProvider injectFirst>
          <CssVarsProvider> */}
            <PositionedMenu
              setIdContacto={setIdContacto}
              extras={[
                contacto,
                setOpenDetail,
                contactDetail,
                setInputsEditable,
                validateInputs,
                deleteContact
              ]} />
          {/* </CssVarsProvider>
        </StyledEngineProvider> */}
      </div>)
    )
  );
}
