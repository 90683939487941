import axios from "axios"
import baseUrl, { baseUrlPrueba } from "./apiUrl";

export async function subscriptionDetail_request() {
    try {
        let resp;
        const response = await axios({
            url: baseUrl + "/Suscripcion",
            method: "GET",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200 || response.status === 201) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function buySubscription_request(dataTarjeta) {
    try {
        let resp;
        const response = await axios({
            url: baseUrlPrueba + "/ContratarSuscripcion",
            method: "POST",
            data: dataTarjeta,
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200 || response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function cancelSubscription_request() {
    try {
        let resp;
        const response = await axios({
            url: baseUrl + "/CancelarSuscripcion",
            method: "GET",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200 || response.status === 201) {
            resp = {
                "ok": true,
                "status": 200,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function nextSubscriptionPay_request() {
    try {
        let resp;
        const response = await axios({
            url: baseUrl + "/PagoSiguiente",
            method: "GET",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200 || response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getSubscriptionTickets_request() {
    try {
        let resp;
        const response = await axios({
            url: baseUrl + "/ListaPagos",
            method: "GET",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200 || response.status === 201) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}