import { createSlice } from '@reduxjs/toolkit';

const initialState = { fechaInicio: '', fechaFinal: '', calendarDate:''};

const setFechaInicial = (state, data) => {
    state.fechaInicio = data;
}

const setFechaFinal = (state, data) => {
    state.fechaFinal = data;
}

const setFechaCalendar = (state, data) => {
    state.calendarDate = data;
}

export const citasSlice = createSlice({
    name: 'citas',
    initialState,
    reducers: {
        SetFechaInicial: (state, action) => setFechaInicial(state, action.payload),
        SetFechaFinal: (state, action) => setFechaFinal(state, action.payload),
        SetCalendarDate: (state, action) => setFechaCalendar(state, action.payload)
    }
});

export const { SetFechaInicial, SetFechaFinal, SetCalendarDate } = citasSlice.actions;

export default citasSlice.reducer;