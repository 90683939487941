import React, { useState } from 'react';
import { Label, LeyendaError } from '../GeneralComponents/Formularios';
import "../../pages/styles/Registro.scss"
import { Input } from 'antd';

const Campos = ({children, label, id, textError, valueError, styleLabel, styleDiv, requiredICon, vacio, classname }) => {

	return (
		<div style={styleDiv} className={classname}>
			{(label !== undefined && !!label) && (<Label style={styleLabel} valido={valueError}>{vacio !== undefined ? (!vacio && requiredICon) && <b style={{ color: "#ff4d4f", fontSize: "14px", marginInlineEnd: "4px", fontFamily: "SimSun,sans-serif" }}>*</b> : ""}{label}</Label>)}
			{children}
			<LeyendaError valido={valueError}>{textError}</LeyendaError>

		</div>
	);
}

export default Campos;