import React from "react";
import "../comp-styles/ChatsCard.scss";
import { Avatar } from "antd";


function ChatCard({ ChatsCarga, chatSolicitud, OpenChatModal }) {


  return (
    <>
      {ChatsCarga.map((lista, index) => (
        <div key={index} id="solicitudCard" onClick={() => { chatSolicitud(lista); OpenChatModal(true) }}>
          <div className="CardChat" style={{ cursor: "pointer" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap:"1%",width:"100%" }}>
                <Avatar
                  size={45}
                className="BgrImg"
                style={{display:"flex", alignItems:"center", justifyContent:"center"}}
                src={"https://" + lista.Imagen_Url}
                />
                 
            
                <div>
                  <b>{lista.Usuario}</b>
                  <p style={{ width: "80%", display: "flex", overflow: "hidden", textOverflow: "ellipsis", height: "24px" }}>{lista.message}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );

}

export default ChatCard;