import { textAlign } from "@mui/system";
import { Image, Row } from "antd";
import React from "react";
import { useState } from "react";
import Adjuntar from "../../Img/Iconos/AdjuntarArchivo.svg";
import Eliminar from "../../Img/Iconos/eliminar.svg";
import Icono from "../IconosDeSitio";
import { OndemandVideo } from "@mui/icons-material";
import { FileWordOutlined } from "@ant-design/icons";
import PreviewImage from "./previewImage";

export default function InputFileList({ arrayImg, extras, formatos, valueArrayImg }) {
  // console.log(valueArrayImg);
  // subir Archivos lista
  const [OpenArchivo, setOpenArchivo] = useState(1);
  const [Formatos, setFormatos] = useState(formatos);
  const [UrlImg, setUrlImg] = useState(valueArrayImg !== undefined ? valueArrayImg.length > 0 ? valueArrayImg : [] : []);
  const [returnFor, setReturnFor] = useState(false);
  const handleModalContainerClick = e => e.stopPropagation();
  const [TotalMegas, setTotalMegas] = useState(0);

  function addImagesToArray(e) {
    // console.log(Formatos);
    //esto es el indice que se le dará a cada imagen, a partir del indice de la ultima foto
    let indexImg = valueArrayImg.length > 0 ? valueArrayImg[valueArrayImg.length - 1].index + 1 : 0;
    //aquí evaluamos si ya hay imagenes antes de este input, para saber en dónde debe empezar el index del proximo array
    let array_files = e.currentTarget.files;
    let total_temp = 0;
    Object.keys(e.currentTarget.files).forEach(x => {
      // console.log(array_files[x].size);
      total_temp += array_files[x].size;
    });
    // console.log(total_temp);
    // console.log(e.currentTarget.files[0].type);

    // console.log(formato);
    if (total_temp <= 20000000) {
      // console.log(formato[1]);
      const files = e.currentTarget.files;
      //el array con las imagenes nuevas
      const arrayImages = [];

      Object.keys(files).forEach((i) => {
        const file = files[i];
        // console.log("FOR EACH fuera" + i);
        let url = URL.createObjectURL(file);
        let formato = file.type.split("/");
        // console.log(file.type);

        if (formato[1] === "pdf" || formato[1] === "jpeg" || formato[1] === "png" || formato[1] === "doc" || formato[1] === "docx"
          || formato[1] === "msword" || formato[1] === "vnd.openxmlformats-officedocument.wordprocessingml.document" || formato[1] === "jpg") {
          if (file.size <= 5000000) {
            // if (UrlImg.length > 0) {
            //   indexImg = UrlImg[UrlImg.length - 1].index + 1;
            // } else {
            //   indexImg = 0;
            // }

            arrayImages.push({
              index: indexImg,
              name: file.name,
              url,
              file,
              size: file.size,
              formato: formato[1],
              tipo: "0",
            });

            let newImgsState = [...valueArrayImg, ...arrayImages];

            indexImg++;

            if (extras[3] !== undefined) {
              extras[3](newImgsState);
            }

            setUrlImg(newImgsState);
            arrayImg(newImgsState);
            // document.getElementById("FileArchivo").value = "";
            let total = TotalMegas + file.size;
            setTotalMegas(total);
            // console.log(newImgsState);
          } else {
            if (extras !== undefined) {
              extras[0](true);
              extras[1]("warning");
              extras[2]("El archivo excede el tamaño maximo permitido (5MB)");
            }
          }

        } else {
          if (formato[0] === "video") {
            if (file.size <= 10000000) {
              // if (UrlImg.length > 0) {
              //   indexImg = UrlImg[UrlImg.length - 1].index + 1;
              // } else {
              //   indexImg = 0;
              // }

              arrayImages.push({
                index: indexImg,
                name: file.name,
                url,
                file,
                size: file.size,
                formato: formato[1],
                tipo: "0",
              });

              let newImgsState = [...valueArrayImg, ...arrayImages];

              indexImg++;

              if (extras[3] !== undefined) {
                extras[3](newImgsState);
              }

              setUrlImg(newImgsState);
              arrayImg(newImgsState);
              // document.getElementById("FileArchivo").value = "";
              let total = TotalMegas + file.size;
              setTotalMegas(total);

            } else {
              if (extras !== undefined) {
                extras[0](true);
                extras[1]("warning");
                extras[2]("El video excede el tamaño maximo permitido (10MB)");
              }
              // document.getElementById("FileArchivo").value = "";
            }
          } else {

            extras[0](true);
            extras[1]("warning");
            if (formatos === 1) {
              // console.log("1");
              extras[2]("Tipo de archivo no valido. Archivos permitidos: imagenes ( png, jpeg )");
            } else if (formatos === 2) {
              // console.log("2");
              extras[2]("Tipo de archivo no valido. Archivos permitidos: videos");
            } else if (formatos === 3) {
              // console.log("3");
              extras[2]("Tipo de archivo no valido. Archivos permitidos: archivos (pdf)");
            } else if (formatos === 4) {
              // console.log("4");
              extras[2]("Tipo de archivo no valido. Archivos permitidos: imagenes y archivos ( png, jpeg, pdf )");
            } else if (formatos === 5 || formatos === undefined) {
              // console.log("4");
              extras[2]("Tipo de archivo no valido. Archivos permitidos: imagenes, archivos y videos ( png, jpeg, pdf, doc, docx )");
            }
          }
        }
      });
    } else {
      extras[0](true);
      extras[1]("warning");
      extras[2]("El listado de archivos excede el tamaño maximo permitido (20MB)");
    }
    document.getElementById("FileArchivoList").value = "";
  }

  function deleteImg(indice) {
    //// console.log("borrar img " + indice);

    const newImgs = valueArrayImg.filter(function (element) {
      return element.index !== indice;
    });
    // console.log(newImgs);
    if (newImgs.length === 0) {
      document.getElementById("FileArchivoList").value = "";
    }

    setUrlImg(newImgs);
    arrayImg(newImgs);
    return;
  };

  function selectFormato() {
    const Imagenes = {
      input: "image/png,image/jpeg",
      validacion: ["png", "jpeg"]
    };
    const Videos = {
      input: "video/*",
      validacion: []
    };
    const Pdf = {
      input: "application/pdf",
      validacion: ["pdf"]
    };
    const Archivos = {
      input: "image/png,image/jpeg,application/pdf",
      validacion: ["png", "jpeg", "pdf"]
    }
    const Todos = {
      input: ".doc,.docx,image/png,image/jpeg,application/pdf,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      validacion: ["png", "jpeg", "pdf", "doc", "docx", "msword", "vnd.openxmlformats-officedocument.wordprocessingml.document"]
    }

    if (formatos === 1) {
      return Imagenes.input;
    } else if (formatos === 2) {
      return Videos.input;
    } else if (formatos === 3) {
      return Pdf.input;
    } else if (formatos === 4) {
      return Archivos.input;
    } else if (formatos === 5 || formatos === undefined) {
      return Todos.input;
    }
  }

  return (
    <>
      <label htmlFor="FileArchivoList" style={{ cursor: "pointer" }}>
        <img style={{ width: "16px" }} src={Adjuntar} alt="" />
        <b>Adjuntar</b>
        <input
          hidden={true}
          id="FileArchivoList"
          type="file"
          accept={`${selectFormato}`}
          onChange={(e) => {
            setReturnFor(false);
            addImagesToArray(e);
          }}
          style={{ display: "none" }}
          multiple
        />
      </label>
      <br />
      <br />
      {OpenArchivo === 0 ? (
        <div>
          <p>Aun no se agregan archivos</p>
        </div>
      ) : (
        <div>
          {OpenArchivo === 1 &&
            valueArrayImg.map((imagen, index) => (
              <Row style={{ justifyContent: "space-between", padding: "1% 3%", border: "1px solid #d7d7d7", margin: "10px 0" }} key={index}>
                {
                  (imagen.formato === "png" || imagen.formato === "jpeg" || imagen.formato === "jpg") ?
                    (
                      <Row
                        style={{ justifyContent: "space-between", cursor: "pointer", alignItems: "center", width: "90%" }}

                      >
                        <PreviewImage urlImage={imagen.url} style={{ width: "50px", height: "50px" }} imageName={imagen.name}/>
                        {/* <Image
                          style={{ width: "50px", height: "50px" }}
                          src={imagen.url}
                          alt=""

                          preview={{
                            toolbarRender: (
                              _,
                              {
                                transform: { scale },
                                actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                              },
                            ) => (
                              <Space size={12} className="toolbar-wrapper">
                                <DownloadOutlined onClick={() => { onDownloadImage(imagen.url); }} />
                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                <SwapOutlined onClick={onFlipX} />
                                <RotateLeftOutlined onClick={onRotateLeft} />
                                <RotateRightOutlined onClick={onRotateRight} />
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                              </Space>
                            ),
                          }}
                        /> */}
                        <div onClick={() => { window.open(imagen.url, "_blank") }} style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                          <span>{imagen.name}</span>
                        </div>
                        <div >
                          {!!imagen.size && `${(imagen.size / 1000000).toFixed(2)} MB`}
                        </div>
                      </Row>
                    ) : imagen.formato === "pdf" ?
                      (
                        <Row
                          style={{ justifyContent: "space-between", cursor: "pointer", alignItems: "center", width: "90%" }}
                          onClick={() => { window.open(imagen.url, "_blank") }}
                        >
                          <img style={{ width: "5%", marginRight: "3px" }} src={Icono[22]} />
                          <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            <span>{imagen.name}</span>
                          </div>
                          <div>
                            {!!imagen.size && `${(imagen.size / 1000000).toFixed(2)} MB`}
                          </div>
                        </Row>
                      ) : (imagen.formato === "doc" || imagen.formato === "docx" || imagen.formato === "msword" || imagen.formato === "vnd.openxmlformats-officedocument.wordprocessingml.document") ? (
                        <Row
                          style={{ justifyContent: "space-between", cursor: "pointer", alignItems: "center", width: "90%" }}
                          onClick={() => { window.open(imagen.url, "_blank") }}
                        >
                          <FileWordOutlined style={{ fontSize: "250%", color: "#148F9F" }} />
                          <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            <span>{imagen.name}</span>
                          </div>
                          <div>
                            {!!imagen.size && `${(imagen.size / 1000000).toFixed(2)} MB`}
                          </div>
                        </Row>
                      ) :
                        (
                          <Row
                            style={{ justifyContent: "space-between", cursor: "pointer", alignItems: "center", width: "90%" }}
                            onClick={() => { window.open(imagen.url, "_blank") }}
                          >
                            {/* <img style={{ width: "7%", marginRight: "3px" }} src={Icono[22]} /> */}
                            <OndemandVideo sx={{ color: "#148F9F" }} />
                            <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                              <span>{imagen.name}</span>
                            </div>
                            <div>
                              {!!imagen.size && `${(imagen.size / 1000000).toFixed(2)} MB`}
                            </div>
                          </Row>
                        )
                }
                <img
                  style={{ width: "18px", cursor: "pointer" }}
                  onClick={() => { deleteImg(imagen.index); }}
                  src={Eliminar}
                  alt=""
                />
              </Row>
            ))}
        </div>
      )}

    </>
  );
}
