import axios from "axios"
import baseUrl from "./apiUrl";



export async function getEstudios_request() {
    try {
        let resp;
        const response = await axios({
            url: baseUrl + "/Estudios",
            method: "GET",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getDetailEstudios_request(idEstudio) {
    try {
        let resp;
        const response = await axios({
            url: baseUrl + "/EstudiosDetalles/" + idEstudio,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function addEstudio_request(estudioData, IdMedico) {
    try {
        let resp;
        const response = await axios({
            url: !!IdMedico ? baseUrl + "/EnviarEstudio" : baseUrl + "/GuardarEstudio",
            method: "POST",
            data: estudioData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function forwardEstudio_request(idEstduio, estudioData) {
    try {
        let resp;
        const response = await axios({
            url: baseUrl + "/CompartirEstudioYgia/" + idEstduio,
            method: "POST",
            data: estudioData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": response.status
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": error.status,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function getEstudiosFilter_request(filter, filterData) {
    let routeFilter = "";
    switch (filter) {
        case 1:
            routeFilter = "EstudiosHoy";
            break;

        case 2:
            routeFilter = "EstudiosAyer";
            break;

        case 3:
            routeFilter = "EstudiosSemana";
            break;

        case 4:
            routeFilter = "EstudiosPeriodo";
            break;

        default:
            break;
    }
    try {
        let resp;
        const response = await axios({
            url: baseUrl + `/${routeFilter}`,
            method: "POST",
            data: filterData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}