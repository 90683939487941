import axios from "axios"
import baseUrl from "./apiUrl";

export async function getOrdenes_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/Ordenes",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function saveOrdenes_request(ordenData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/agregarOrden",
            method: "POST",
            // data: ordenData,
            params: ordenData,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 201) {
            resp = {
                "ok": true,
                "status": response.status,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": response.status
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}