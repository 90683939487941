import Citasaddjson from "../GeneralComponents/General-Json"
import "../comp-styles/ModalCitasAdd.scss"
import Iconos from "../IconosDeSitio"

import React from 'react'

function ModalMedicos({ contactList, closeModal, openModal, setDatosMedico, extras, mensajeError }) {
  // console.log(contactList);
  let lista = [];
  return (
    <>
      <div  >
        {contactList.map((contacto) => (
          <div
            className="cardContactosModals"
            key={contacto.IdContacto}
            onClick={() => {
              closeModal(1);
              if (openModal !== undefined) {
                openModal(true);
              }
              if (mensajeError !== undefined) {
                mensajeError("true");
              }
              setDatosMedico({ id: contacto.IdContacto, nombre: `${contacto.Nombre} ${contacto.ApellidoPaterno} ${contacto.ApellidoMaterno}`, imagen: contacto.Imagen_url });
              if (extras !== undefined) {
                extras.setDoctorSelect(contacto.IdContacto);
                extras.setNombreMedico(contacto.Nombre);
                extras.setApellidoPMedico(contacto.ApellidoPaterno);
                extras.setApellidoMMedico(contacto.ApellidoMaterno);
                extras.setCelularMedico(contacto.Celular);
                extras.setEmailMedico(contacto.Email);
                // setEspecialidadMedico(element.Especialidad);
                extras.setMensajeDoctorSelect("true");
                if (contacto.Nombre) {
                  extras.setMensajeNombreMedico("true");
                }
                if (contacto.ApellidoPaterno) {
                  extras.setMensajeApellidoPMedico("true");
                }
                if (contacto.Especialidad) {
                  extras.setMensajeEspecialidadMedico("true");
                }
              }

            }}>
            <div className="containerCardContactosModals">
              <div className="contentCardContactosModals">
                <p>
                  <img
                    id={`img_reenviarEstudio_contacto_estudios_${contacto.IdContacto}`}
                    src={!!contacto.Imagen_url ? "https://" + contacto.Imagen_url : Iconos[0]}
                    onError={() => {
                      let img = document.getElementById(`img_reenviarEstudio_contacto_estudios_${contacto.IdContacto}`);
                      img.setAttribute("src", Iconos[0]);
                    }}
                  />
                </p>
                <div style={{ width: "95%" }}>
                  <b>{`${contacto.Nombre} ${contacto.ApellidoPaterno} ${contacto.ApellidoMaterno}`}</b>
                  <p>{contacto.YgiaUser}</p>
                </div>
              </div>
              {/* <div>
                <p onClick={() => {
                  closeModal(false);
                  extras[0]({ id: contacto.IdContacto, nombre: `${contacto.Nombre} ${contacto.ApellidoPaterno} ${contacto.ApellidoMaterno}`, imagen: contacto.Imagen_url });
                  extras[1](true);
                  // saveCita(contacto.IdContacto);
                }}>
                  <img style={{ width: "25px", cursor: "pointer" }} src={Iconos[17]} />
                </p>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default ModalMedicos;